<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar back-link="Back">

          <!-- Page Title -->
          <f7-nav-title>
                Personal Details
          </f7-nav-title>

    </f7-navbar>

    <div>

      <az-list class="fab-wrapper">
        <az-list-input label="Marital Status" v-model="form.marital_status" type="select">
          <option 
            v-for="(status, value) in maritalStatuses"
            :key="value"
            :value="value">{{ status }}</option>
        </az-list-input>
        <az-list-input v-if="this.form.marital_status === 'Y'" type="datemask" label="Anniversary Date" v-model="form.anniversary_date"></az-list-input>
        <az-list-input v-if="this.form.marital_status === 'Y'" label="Your Spouse's Name" v-model="form.spouse_name"></az-list-input>
        <az-list-input v-if="this.form.marital_status !== 'N'" label="Number of Children" type="number" v-model="form.no_of_children"></az-list-input>
        <az-list-input label="Personal Email" type="email" v-model="form.personal_email"></az-list-input>
        <az-list-input label="Home Address" type="textarea" v-model="form.home_address"></az-list-input>
      </az-list>
    </div>

    <az-loader v-if="pageState == 'loading'"></az-loader>

    <f7-fab class="fixed"
            v-if="pageState == 'loaded'"
            position="right-bottom"
            @click="onSaveClick">
        <f7-icon ios="f7:floppy_disk" 
            aurora="f7:floppy_disk"
            md="material:save" />
    </f7-fab>

  </f7-page>
</template>

<script>
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import { participantAPI, errorHandler, makeFormData } from '@/js/api'
import AZDateMask from '@/components/ui/AZDateMask.vue';
import { MaritalStatuses } from '@/js/consts'

export default {
    props: {
      form_data_prop: Object
    },

    components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-loader': AZLoader,
    'az-error-message': AZPageErrorMessage,
    'az-date-mask': AZDateMask
  },

  mounted() {
    this.form = this.form_data_prop
  },

  data() {
      return {
        pageState: 'loaded',
        maritalStatuses: MaritalStatuses,
        
        form: {
          marital_status: null,
          anniversary_date: null,
          spouse_name: null,
          no_of_children: null,
          personal_email: null,
          home_address: null
        }
          
      }
    },

    methods: {
      onSaveClick() {
      let data = {
        _method: 'PUT',
        ...this.form
      }
      let formData = makeFormData(data)
      // return
      this.pageState = 'loading'
      participantAPI().post(
        '/personal-profile',
        formData
      )
      .then(res => {
        this.$f7router.back()
      })
      .catch(error => {
        this.pageState = 'loaded'
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },
    },

    watch: {
      'form.marital_status': {
        handler(val) {
          if (val !== 'Y') {
            this.form.anniversary_date = null
            this.form.spouse_name = null
          }

          if (val === 'N') {
            this.form.no_of_children = null
          }
          else {
            if (this.form.no_of_children === null) {
              this.form.no_of_children = 0
            }
          }
        }
      }
    }
}
</script>

<style>

</style>