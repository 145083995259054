<template>
    <div>
        <f7-list-input
            :class="{'hidden': hidden}"
            readonly
            outline
            floating-label
            :label="label"
            type="text"
            :value="selectedText"
            @click.native="popupOpened = true"
        >
      </f7-list-input>
      
        <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="closePopup">
            <f7-page>
                <f7-navbar :title="label">
                <f7-nav-right>
                    <f7-link popup-close >Close</f7-link>
                </f7-nav-right>
                </f7-navbar>
                    <f7-list v-if="items.length > 0">
                        <f7-list-item   v-for="item in items"
                                        :key="item.key"
                                        checkbox 
                                        :value="item.key"
                                        @change.native="updateSelect"
                                        :title="item.value" 
                                        :checked="isPresent(item.key)"
                                        name="demo-checkbox"></f7-list-item>
                    </f7-list>
                    <div class="flex-center" v-else>
                        <p>No items to show</p>
                    </div>
            </f7-page>
        </f7-popup>
    </div>
    
</template>

<script>
export default {
    name: 'AZSmartSelect',
    props: {
        items: Array,
        label: String,
        selected: Object|String|Array,
        type: String,
        hidden: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            smartSelectValue: '',
            popupOpened: false,
            selection: [],
        }
    },

    mounted() {
        setTimeout(() => {
            this.selection = this.selected
        }, 0)
    },

    methods: {

        closePopup() {
            this.popupOpened = false
            this.$emit('close')
        },

        updateSelect(e) {
            let current_val = isNaN(parseInt(e.target.value)) ? e.target.value : parseInt(e.target.value)

            if (this.type == 'multiple') {
                if (e.target.checked) {
                    this.selection.push(current_val)
                }
                else {
                    const index = this.selection.indexOf(current_val);
                    if (index > -1) {
                        this.selection.splice(index, 1);
                    }
                }
            }

            else {
                if (e.target.checked) {
                    this.selection = current_val
                }
                else {
                    this.selection = null
                }  
            }

            this.$emit('input', this.selection)
        }, 

        isPresent(value) {
            return this.type == 'multiple' ? this.selected.includes(value) : this.selected === value
        },

        shortenText(arr) {
            if (arr.length > 1) {
                return arr.map(item => item.substring(0, 10) + '...')
            }
            return arr
        },
    },

    computed: {
        selectedText: {
            cache: false,
            get() {
                if (this.type == 'multiple') {
                    return this.selection.length > 0 ? this.selection.length + ' selected' : 'None selected'
                }
                
                else {
                    if (this.selection) {
                        let res = this.items.filter(item => item.key == this.selection)
                        if (res.length) {
                            return res[0].value
                        }
                        return 'None selected'
                    }
                    return 'None selected'
                }
            }
        }
    },
}
</script>

<style scoped>
    
    .hidden {
        display: none;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>