<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>My Projects ©</f7-nav-title>
    </f7-navbar>

    <!-- Page Content -->
    <div class="az-page">
      <div class="az-scrollable">

        <!-- List -->
        <cq-project-list v-if="projects.length > 0">
          <cq-project-item 
            v-for="project in projects"
            :key="project.id"
            :name="project.name"
            :description="project.description ? project.description : '...'"
            @click.native="onEditClick(project)"
          ></cq-project-item>
        </cq-project-list>

        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>

        <!-- Add Button -->
        <f7-fab position="right-bottom" slot="" @click="onAddClick">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-fab>
      </div>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import CQProjectList from '@/components/projects/CQProjectList';
import CQProjectItem from '@/components/projects/CQProjectItem';

export default {
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'cq-project-list': CQProjectList,
    'cq-project-item': CQProjectItem
  },

  data() {
    return {
      pageState: 'loading',
      projects: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {    
    loadDataFromAPI () {      
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      // Load data for project tag
      participantAPI().get(`batch/${batchId}/project`)
        .then((response) => {
          this.projects = response.data.data;          
          this.pageState = 'loaded';
        }).catch((error) => {
            errorHandler(error);
            this.pageState = 'failed';
        });
    },

    // Button Click Events 

    refreshData () {
      this.projects = [];
      this.pageState = 'loading';
      this.loadDataFromAPI(); 
    },

    // Project Click Events
    onAddClick() {
      this.$f7router.navigate('/project/create/');
    },

    onEditClick(project) {
      this.$f7router.navigate(`/project/create/`, {
        props: {
          form_data_prop: project
        }
      })
    },
  }
}
</script>