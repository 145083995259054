<template>
  <div class="az-tab-page">

    <!-- List -->
    <div class="az-tab-scrollable">
      <div v-if="breakdowns.length > 0">
        <f7-block-title>Obstacles</f7-block-title>
        <cq-breakdown-list class="fab-wrapper">
          <cq-breakdown-item 
              v-for="breakdown in breakdowns"
              :key="breakdown.id"
              :breakdown="breakdown.obstacle"
              :action="breakdown.action"
              :who-where="breakdown.who_where"
              :resolved="breakdown.resolved == 1" 
              @click.native="onEditClick(breakdown)" />
        </cq-breakdown-list>

      </div>
     
      <!-- No Data Message -->
      <template v-else>
        <az-page-message v-if="pageState != 'failed'" />
      </template>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

    <!-- Add Button -->
    <f7-fab class="fab-tab" position="right-bottom"  href="/accomplishment-blueprint/breakdown-obstacles/create/">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

  </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import CQBreakdownList from '@/components/accomplishment-blueprint/breakdown/CQBreakdownList';
import CQBreakdownItem from '@/components/accomplishment-blueprint/breakdown/CQBreakdownItem';

export default {
  name: 'BreakdownIndex',
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'cq-breakdown-list': CQBreakdownList,
    'cq-breakdown-item': CQBreakdownItem
  },

  props: ['flag'],

  data() {
    return {
      pageState: 'loading',
      breakdowns: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  watch: {
    flag (value) {
      this.loadDataFromAPI()
    }
  },

  methods: {
    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/breakdown`)
        .then(response => {
          this.breakdowns = response.data.data
          this.pageState = 'loaded'
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    // Button Click Events
    
    refreshData () {
      this.breakdowns = [];
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    onEditClick(item) {
      this.$f7router.navigate(`/accomplishment-blueprint/breakdown-obstacles/create/`, {
        props: {
          form_data_prop: item
        }
      })
    },

    
  }
}
</script>

<style scoped>
</style>