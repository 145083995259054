<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>

      <!-- Page Title -->
      <f7-nav-title>Moment I am Living for ©</f7-nav-title>

    </f7-navbar>

    <div class="az-page">
      <div class="az-scrollable">
        <!-- List -->
        <az-custom-list class="fab-wrapper" v-if="moments.length > 0">
          <az-custom-list-item v-for="item in moments"
                          :key="item.id"
                          @item-click="onEditClick(item)"
                          :noMenu="true"
                          :title="item.title"
                          :subtitle="item.screenplay">

            <template v-slot:icon>
              <div class="pin">{{ item.how_connected }}</div>
            </template>
          </az-custom-list-item>
        </az-custom-list>

        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>

        <!-- Add Button -->
        <f7-fab position="right-bottom" slot="fixed" @click="onAddClick">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-fab>
      </div>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import moment from 'moment'
import AZLoader from '@/components/ui/AZLoader';
import AZCustomList from '@/components/ui/AZCustomList';
import AZCustomListItem from '@/components/ui/AZCustomListItem';

export default {
    name: 'MomentIndexPage',
    components: {
        'az-page-message': AZPageMessage,
        'az-page-error-message': AZPageErrorMessage,
        'az-loader': AZLoader,
        'az-custom-list-item': AZCustomListItem,
        'az-custom-list': AZCustomList
    },
    data() {
        return {
            pageState: 'loading',
            moments: [],
            calculated_moments: []
        }
    },
    mounted() {
        this.loadDataFromAPI()
    },
    methods: {
        loadDataFromAPI() {
            let batchId = this.$store.getters['auth/getCurrentBatchID'];
            participantAPI().get(`batch/${batchId}/moment`)
                .then(response => {          
                    this.moments = response.data.data
                    this.pageState = 'loaded'
                })
                .catch(error => {
                    errorHandler(error)
                    this.pageState = "failed"
                })
        },

        onEditClick(item) {
            this.$f7router.navigate('/moments/create', {
                props: {
                    form_data_prop: item
                }
            })
        },

        // Helpers

        getFancyDate (date) {
          return moment(date, 'DD-MM-YYYY').format('MMMM').toUpperCase() + " " + 
                  moment(date, 'DD-MM-YYYY').format('D') + ", " +
                  moment(date, 'DD-MM-YYYY').format('YYYY')
        },

        onAddClick() {
            this.$f7router.navigate('/moments/create/');
        },
    }
}
</script>

<style scoped>
  .item-media i {
    color: var(--f7-theme-color) !important;
  }
  .list >>> .item-title {
    font-weight: 600 !important;
  }

  .list >>> .pin {
      display: inline-flex;
      background: red;
      color: white;
      align-items: center;
      justify-content: center;
      padding: 1px 15px;
      border-radius: 20px;
      position: absolute;
      right: 20px;
      top: 14px;
  }
</style>