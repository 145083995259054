<template>
  <div class="timeline">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'az-timeline'
}
</script>