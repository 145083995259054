import { render, staticRenderFns } from "./StatsFragment.vue?vue&type=template&id=352d787c&scoped=true&"
import script from "./StatsFragment.vue?vue&type=script&lang=js&"
export * from "./StatsFragment.vue?vue&type=script&lang=js&"
import style0 from "./StatsFragment.vue?vue&type=style&index=0&id=352d787c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "352d787c",
  null
  
)

export default component.exports