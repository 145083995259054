<template>
  <div class="list media-list">
    <ul>
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'cq-clarity-list'
}
</script>