<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar
      :title="(isEditMode ? 'Edit ' : 'Add ') + 'Meeting'"
      back-link="Back"
    >
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Body -->
    <az-steps 
      :prev-visible="currentStepIndex > 0"
      :next-visible="currentStepIndex < 23"
      :save-visible="currentStepIndex == 23"

      @prev-clicked="onPrevClicked"
      @next-clicked="onNextClicked"
      @save-clicked="onSaveClicked"
    >

      <!-- Page 1 -->
      <az-step-page v-if="currentStepIndex == 0" key="0">
        <az-list>
          <az-list-input label="Meeting Date" type="datepicker" v-model="formattedDate" />
          <az-list-input label="Meeting name" type="text" v-model="form_data.meeting_name" />
        </az-list>
      </az-step-page>
      
      <!-- Page 2 -->
      <az-step-page v-if="currentStepIndex == 1" key="1">
        <h5>Who are you meeting with (person/group)?<br>(Mention all names and designations you know)</h5>
        <div style="margin-top: 20px;">
          <f7-row
            v-for="(attendee, index) in form_data.attendees"
            :key="index"
            class="repeater-row"
          >
            <!-- Form Fields -->
            <f7-col>
              <az-list class="repeater-margin">
                <az-list-input label="Name" v-model="form_data.attendees[index].name" />
              </az-list>
            </f7-col>
            <f7-col>
              <az-list class="repeater-margin">
                <az-list-input label="Designation" v-model="form_data.attendees[index].designation" />
              </az-list>
            </f7-col>
            
            <!-- Remove Button -->
            <f7-button
              v-if="form_data.attendees.length > 1"
              class="remove-button"
              icon-ios="f7:trash"
              icon-aurora="f7:trash"
              icon-md="material:clear"
              @click="form_data.attendees.splice(index, 1)" 
            ></f7-button>
          </f7-row>
        </div>
        
        <f7-button raised fill style="margin: 15px;" @click="addAttendee">Add Person +</f7-button>
      </az-step-page>

      <!-- Page 3 -->
      <az-step-page v-if="currentStepIndex == 2" key="2">
        <h5>How much time do you think this meeting will take?</h5>
        <az-list>
          <az-list-input type="time" :complete="true" v-model="form_data.duration"/>\
        </az-list>
      </az-step-page>

      <!-- Page 4 -->
      <az-step-page v-if="currentStepIndex == 3" key="3">
        <h5>What is the reason for this meeting? Why are you having this meeting?
        </h5>
        <az-list>
          <az-list-input placeholder="Example, I want to not just introduce but sell to him in this meeting" type="textarea" v-model="form_data.reason"/>
        </az-list>
      </az-step-page>

      <!-- Page 5 -->
      <az-step-page v-if="currentStepIndex == 4" key="4">
        <h5>If this meeting was a "Grand Success" for you, what is the OUTCOME that you would have accomplished from this meeting?</h5>
        <az-list>
          <az-list-input placeholder="Example, I have collected Purchase Order of 50 lakhs" type="textarea" v-model="form_data.outcome"/>
        </az-list>
      </az-step-page>

       <!-- Page 6 -->
      <az-step-page v-if="currentStepIndex == 5" key="5">
        <h5>What "PURPOSE" OF YOURS gets fulfilled for you personally if you accomplish the Outcome</h5>
        <az-list>
          <az-list-input placeholder="Example, I get to be someone who is capable of doing what I say, or I get to be a leader" type="textarea" v-model="form_data.my_purpose"/>
        </az-list>
      </az-step-page>

       <!-- Page 7 -->
      <az-step-page v-if="currentStepIndex == 6" key="6">
        <h5>What is in it for the person/group you are meeting with? What is their benefit? Why should they care about what outcome you want?</h5>
        <az-list>
          <az-list-input placeholder="Example, They will save 10% if they buy from me v/s my competition" type="textarea" v-model="form_data.carrot"/>
        </az-list>
      </az-step-page>

       <!-- Page 8 -->
      <az-step-page v-if="currentStepIndex == 7" key="7">
        <h5>What "PURPOSE" OF THEIRS gets fulfilled for them personally if you accomplish the Outcome</h5>
        <az-list>
          <az-list-input placeholder="Example, They get to prove their leadership to their management, or they get to be brilliant in front of their bosses" type="textarea" v-model="form_data.their_purpose"/>
        </az-list>
      </az-step-page>

       <!-- Page 9 -->
      <az-step-page v-if="currentStepIndex == 8" key="8">
        <h5>Is the Decision Maker going to be present in the room? </h5>
        <az-list class="no-margin">
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.decider_present == 'Y'" @click="form_data.decider_present = 'Y'">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.decider_present == 'N'" @click="form_data.decider_present = 'N'">No</az-toggle-btn>
            <az-toggle-btn :active="form_data.decider_present == 'M'" @click="form_data.decider_present = 'M'">Maybe</az-toggle-btn>
            <az-toggle-btn :active="form_data.decider_present == 'D'" @click="form_data.decider_present = 'D'">Don't Know</az-toggle-btn>
          </az-toggle-btn-group>
        </az-list>
        <h5>How are you going to ensure that he is present?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.ensure_decider_present"/>
        </az-list>
        <h5>Have you checked with your counterparts that they will be present and ready for the meeting?</h5>
        <az-list class="no-margin">
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.checked_counter_part == 'Y'" @click="form_data.checked_counter_part = 'Y'">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.checked_counter_part == 'N'" @click="form_data.checked_counter_part = 'N'">No</az-toggle-btn>
            <az-toggle-btn :active="form_data.checked_counter_part == 'M'" @click="form_data.checked_counter_part = 'M'">Maybe</az-toggle-btn>
            <az-toggle-btn :active="form_data.checked_counter_part == 'D'" @click="form_data.checked_counter_part = 'D'">Don't Know</az-toggle-btn>
          </az-toggle-btn-group>
        </az-list>
        <h5>Whose absence from your or their side will defeat the Outcome of this meeting?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.who_defeat_outcome"/>
        </az-list>
      </az-step-page>

      <!-- Page 10 -->
      <az-step-page v-if="currentStepIndex == 9" key="9">
        <h5>What are the roles of all the people involved in this meeting? Name each person and the role of each person separtely for clarity.</h5>
        <az-list>
          <az-list-input v-for="(person,index) in form_data.attendees"
            :key="index"
            :label="`${person.name || person.designation}'s role`"
            v-model="form_data.attendees[index].role"
          ></az-list-input>
        </az-list>
      </az-step-page>

      <!-- Page 11 -->
      <az-step-page v-if="currentStepIndex == 10" key="10">
        <h5>What information you must have before the meeting or during the meeting? </h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.information"/>
        </az-list>
        <h5>Who, where and how will you get this information?</h5>
        <az-list class="no-margin">
          <az-list-input placeholder="Example, Who are they currently buying from at what price, will they switch, are they happy with their current vendor, what is the kind of business they can give you, who are the people who are inlfuencers, what type of people are they, are they logical or emotional, etc." type="textarea" v-model="form_data.information_source"/>
        </az-list>
      </az-step-page>

      <!-- Page 12 -->
      <az-step-page v-if="currentStepIndex == 11" key="11">
        <h5>Do others need to do something before the meeting, or during the meeting?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.others_tasks"/>
        </az-list>
        <h5>What do you need to do to make sure that they do their part on time?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.ensure_others_tasks"/>
        </az-list>
      </az-step-page>

      <!-- Page 13 -->
      <az-step-page v-if="currentStepIndex == 12" key="12">
        <h5>Do others need to contribute in any other way for you to realize the outcome you want?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.others_contribution"/>
        </az-list>
        <h5>What can you do to get them to give you what you need?</h5>
        <az-list class="no-margin">
          <az-list-input placeholder="Example, Your friend who has referred you needs to put in a good word for you before you meet them" type="textarea" v-model="form_data.ensure_others_contribution"/>
        </az-list>
      </az-step-page>

      <!-- Page 14 -->
      <az-step-page v-if="currentStepIndex == 13" key="13">
        <h5>Will you need any presentation, equipment or brochure for this meeting?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.collateral"/>
        </az-list>
        <h5>Do you need to send any material to them before your meeting? </h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.pre_collateral"/>
        </az-list>
        <h5>Do you need to carry any prints or hard copies with you to the meeting? </h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.physical_collateral"/>
        </az-list>
        <h5>Is all the information/materials ready? </h5>
        <az-list class="no-margin">
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.collateral_ready == 'Y'" @click="form_data.collateral_ready = 'Y'">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.collateral_ready == 'N'" @click="form_data.collateral_ready = 'N'">No</az-toggle-btn>
            <az-toggle-btn :active="form_data.collateral_ready == 'M'" @click="form_data.collateral_ready = 'M'">Maybe</az-toggle-btn>
            <az-toggle-btn :active="form_data.collateral_ready == 'D'" @click="form_data.collateral_ready = 'D'">Don't Know</az-toggle-btn>
          </az-toggle-btn-group>
        </az-list>
        <h5>Have you gone through your presentation for the meeting along with the animations and what you will say at each slide? Where will you pause? Where will you take questions? How will you come back to the slide? Can you run through the whole flow of the meeting in your mind? </h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.presentation_pitch"/>
        </az-list>
      </az-step-page>

      <!-- Page 15 -->
      <az-step-page v-if="currentStepIndex == 14" key="14">
        <h5>Is this a Sales Meeting?</h5>
        <az-list>
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.sales_meeting == 1" @click="form_data.sales_meeting = 1">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.sales_meeting == 0" @click="form_data.sales_meeting = 0">No</az-toggle-btn>
            <!-- <az-toggle-btn :active="form_data.collateral_ready == 'M'" @click="form_data.collateral_ready = 'M'">Maybe</az-toggle-btn>
            <az-toggle-btn :active="form_data.collateral_ready == 'D'" @click="form_data.collateral_ready = 'D'">Don't Know</az-toggle-btn> -->
          </az-toggle-btn-group>
        </az-list>
      </az-step-page>

      <!-- Page 16 -->
      <az-step-page v-if="currentStepIndex == 15" key="15">
        <h5>Do you have a written and memorized Sales Pitch? </h5>
        <az-list class="no-margin">
          <az-list-input placeholder="Write what you say here" type="textarea" v-model="form_data.sales_pitch"/>
        </az-list>
        <h5>Who can you do a mock with? What feedback did they give you? Have you reworked on your pitch? </h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.sales_pitch_feedback"/>
        </az-list>
        <h5>Do you need to do a mock with your Designated Consultant for feedback and input? </h5>
        <az-list class="no-margin">
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.sales_pitch_mock == 'Y'" @click="form_data.sales_pitch_mock = 'Y'">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.sales_pitch_mock == 'N'" @click="form_data.sales_pitch_mock = 'N'">No</az-toggle-btn>
            <az-toggle-btn :active="form_data.sales_pitch_mock == 'M'" @click="form_data.sales_pitch_mock = 'M'">Maybe</az-toggle-btn>
            <az-toggle-btn :active="form_data.sales_pitch_mock == 'D'" @click="form_data.sales_pitch_mock = 'D'">Don't Know</az-toggle-btn>
          </az-toggle-btn-group>
        </az-list>
        <h5>Have you done your Sales Pitch in front of the mirror and did the person in the mirror buy what you are selling?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.sales_pitch_mirror"/>
        </az-list>
        <h5>Your Notes</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.sales_pitch_notes"/>
        </az-list>
      </az-step-page>

      <!-- Page 17 -->
      <az-step-page v-if="currentStepIndex == 16" key="16">
        <h5>Is it possible for you to "SHOW" a demo or a video of any kind so that the person actually gets to see instead of you just "DESCRIBING" what it will be like? </h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.demo_describe"/>
        </az-list>
        <h5>If no, is there a case study of any of your past customers/clients you can show?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.demo_case_study"/>
        </az-list>
        <h5>If no, is there any example that you can actually demonstrate that will explain the benefit to the customer and they have an instant PROOF OF CONCEPT?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.demo_example"/>
        </az-list>
        <h5>Your Notes</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.demo_notes"/>
        </az-list>
      </az-step-page>

      <!-- Page 18 -->
      <az-step-page v-if="currentStepIndex == 17" key="17">
        <h5>Are you open to negotation?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.negotiation"/>
        </az-list>
        <h5>Do you know your NO REGRET PRICE? </h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.negotiation_price"/>
        </az-list>
        <h5>Have you done your calculations of what will be left for you at different stages if the customer bargains with you?</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.negotiation_calculation"/>
        </az-list>
        <h5>Your Notes</h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.negotiation_notes"/>
        </az-list>
      </az-step-page>

      <!-- Page 19 -->
      <az-step-page v-if="currentStepIndex == 18" key="18">
        <h5>List down all possible questions that they can actually ASK you BEFORE, DURING AND AFTER the meeting. <br>
           List down your answers to each of these questions. <br>
           (This will ensure that you are as ready as you can be for anything external that you can currently think of.)
        </h5>
        <div style="margin-top: 20px;">
          <div
            v-for="(question, index) in form_data.questions"
            :key="index"
            class="repeater-row"
          >
            <!-- Form Fields -->
          
            <az-list>
              <az-list-input type="select" label="Type" v-model="form_data.questions[index].type">
                <option value="B">Before</option>
                <option value="D">During</option>
                <option value="A">After</option>
              </az-list-input>
            
              <az-list-input label="Question" type="textarea" v-model="form_data.questions[index].question" />
            
              <az-list-input label="Answer" type="textarea" v-model="form_data.questions[index].answer" />
            </az-list>
            
            
            <!-- Remove Button -->
            <f7-button
              v-if="form_data.questions.length > 0"
              class="remove-button-2"
              icon-ios="f7:trash"
              icon-aurora="f7:trash"
              icon-md="material:clear"
              @click="form_data.questions.splice(index, 1)" 
            ></f7-button>
          </div>
        </div>
        <f7-button raised fill style="margin: 15px;" @click="addQuestion">Add Question +</f7-button>
      </az-step-page>

      <!-- Page 20 -->
      <az-step-page v-if="currentStepIndex == 19" key="19">
        <h5>List down all possible things that can go wrong BEFORE, DURING, AFTER your meeting. <br>
           List down your Solutions against each of the things that can go wrong.<br>
           (This will ensure that you are as prepared for any breakdowns as you can be.)
        </h5>
        <div style="margin-top: 20px;">
          <div
            v-for="(fail, index) in form_data.fails"
            :key="index"
            class="repeater-row"
          >
            <!-- Form Fields -->
          
            <az-list>
              <az-list-input type="select" label="Type" v-model="form_data.fails[index].type">
                <option value="B">Before</option>
                <option value="D">During</option>
                <option value="A">After</option>
              </az-list-input>
            
              <az-list-input label="What can go wrong" type="textarea" v-model="form_data.fails[index].situation" />
            
              <az-list-input label="Solution" type="textarea" v-model="form_data.fails[index].solution" />
            </az-list>
            
            
            <!-- Remove Button -->
            <f7-button
              v-if="form_data.fails.length > 1"
              class="remove-button-2"
              icon-ios="f7:trash"
              icon-aurora="f7:trash"
              icon-md="material:clear"
              @click="form_data.fails.splice(index, 1)" 
            ></f7-button>
          </div>
        </div>
        <f7-button raised fill style="margin: 15px;" @click="addFail">Add Situation +</f7-button>
      </az-step-page>

      <!-- Page 21 -->
      <az-step-page v-if="currentStepIndex == 20" key="20">
        <h5>List down all possible REASONS that they can potentially come up with that will defeat the OUTCOME you want from this meeting BEFORE, DURING, AFTER your meeting. <br>
           List down your Solutions against each of the reasons that they can come up with. <br>
           (This will ensure that you are as ready as you can be for the meeting.)
        </h5>
        <div style="margin-top: 20px;">
          <div
            v-for="(excuse, index) in form_data.excuses"
            :key="index"
            class="repeater-row"
          >
            <!-- Form Fields -->
          
            <az-list>
              <az-list-input type="select" label="Type" v-model="form_data.excuses[index].type">
                <option value="B">Before</option>
                <option value="D">During</option>
                <option value="A">After</option>
              </az-list-input>
            
              <az-list-input label="Reason" type="textarea" v-model="form_data.excuses[index].reason" />
            
              <az-list-input label="Solution" type="textarea" v-model="form_data.excuses[index].solution" />
            </az-list>
            
            
            <!-- Remove Button -->
            <f7-button
              v-if="form_data.excuses.length > 1"
              class="remove-button-2"
              icon-ios="f7:trash"
              icon-aurora="f7:trash"
              icon-md="material:clear"
              @click="form_data.excuses.splice(index, 1)" 
            ></f7-button>
          </div>
        </div>
        <f7-button raised fill style="margin: 15px;" @click="addExcuse">Add Reason +</f7-button>
      </az-step-page>

      <!-- Page 22 -->
      <az-step-page v-if="currentStepIndex == 21" key="21">
        <h5>What needs to be agreed upon, resolved, signed, produced or aligned and by which people or group at the end of the meeting?</h5>
        <az-list>
          <az-list-input type="textarea" v-model="form_data.agreed_upon"></az-list-input>
        </az-list>
      </az-step-page>

      <!-- Page 23 -->
      <az-step-page v-if="currentStepIndex == 22" key="22">
        <h5>If you had to make an agenda of the meeting so that you get the outcome that you want, can you make an Agenda/Flow/Points to Discuss for this meeting? </h5>
        <az-list class="no-margin">
          <az-list-input type="textarea" v-model="form_data.agenda"/>
        </az-list>
        <h5>Do you want to send this to the other party so that they are sufficiently prepared as well and they know your intentons?</h5>
        <az-list class="no-margin">
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.sharing_agenda == 'Y'" @click="form_data.sharing_agenda = 'Y'">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.sharing_agenda == 'N'" @click="form_data.sharing_agenda = 'N'">No</az-toggle-btn>
            <az-toggle-btn :active="form_data.sharing_agenda == 'M'" @click="form_data.sharing_agenda = 'M'">Maybe</az-toggle-btn>
            <az-toggle-btn :active="form_data.sharing_agenda == 'D'" @click="form_data.sharing_agenda = 'D'">Don't Know</az-toggle-btn>
          </az-toggle-btn-group>
        </az-list>
      </az-step-page>

      <!-- Page 24 -->
      <az-step-page v-if="currentStepIndex == 23" key="23">
        <h5>Now that you have enough clarity and you are sufficiently prepared, how much time do you need for this meeting? </h5>
        <az-list class="no-margin">
          <az-list-input type="time" :complete="true" v-model="form_data.new_duration"/>
        </az-list>
        <h5>Do you need to re-schedule? </h5>
        <az-list class="no-margin">
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.reschedule == 'Y'" @click="form_data.reschedule = 'Y'">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.reschedule == 'N'" @click="form_data.reschedule = 'N'">No</az-toggle-btn>
            <az-toggle-btn :active="form_data.reschedule == 'M'" @click="form_data.reschedule = 'M'">Maybe</az-toggle-btn>
            <az-toggle-btn :active="form_data.reschedule == 'D'" @click="form_data.reschedule = 'D'">Don't Know</az-toggle-btn>
          </az-toggle-btn-group>
        </az-list>
        <h5>Have you checked if they have sufficient time for this meeting?</h5>
        <az-list class="no-margin">
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.attendee_has_time == 'Y'" @click="form_data.attendee_has_time = 'Y'">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.attendee_has_time == 'N'" @click="form_data.attendee_has_time = 'N'">No</az-toggle-btn>
            <az-toggle-btn :active="form_data.attendee_has_time == 'M'" @click="form_data.attendee_has_time = 'M'">Maybe</az-toggle-btn>
            <az-toggle-btn :active="form_data.attendee_has_time == 'D'" @click="form_data.attendee_has_time = 'D'">Don't Know</az-toggle-btn>
          </az-toggle-btn-group>
        </az-list>
      </az-step-page>

      

    </az-steps>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler, formatDate, unformatDate } from '@/js/api';
import moment from 'moment';
import AZSteps from '@/components/ui/AZSteps.vue'
import AZStepPage from '@/components/ui/AZStepPage.vue'
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader.vue'
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';
import AZToggleButton from '@/components/ui/AZToggleButton';

export default {
  props: {
    form_data_prop: Object,
    current_id_prop: String|Number
  },
  components: {
    'az-loader': AZLoader,
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-steps': AZSteps,
    'az-step-page': AZStepPage,
    'az-toggle-btn-group': AZToggleButtonGroup,
    'az-toggle-btn': AZToggleButton
  },

  mounted() {
    if (this.isEditMode) {
      this.form_data = this.form_data_prop
      this.currentId = this.current_id_prop
    }
  },

  data() {
    return {
      pageState: 'loaded',
      currentStepIndex: 0,
      currentId: null,
      form_data: {
        date: '',
        meeting_name: '',
        attendees: [
          
        ],
        duration: 0,
        reason: '',
        outcome: '',
        my_purpose: '',
        carrot: '',
        their_purpose: '',

        decider_present: null,
        ensure_decider_present: '',
        checked_counter_part: null,
        who_defeat_outcome: '',

        information: '',
        information_source: '',

        others_tasks: '',
        ensure_others_tasks: '',

        others_contribution: '',
        ensure_others_contribution: '',

        collateral: '',
        pre_collateral: '',
        physical_collateral: '',
        collateral_ready: null,
        presentation_pitch: '',

        sales_meeting: 0,

        sales_pitch: '',
        sales_pitch_feedback: '',
        sales_pitch_mock: null,
        sales_pitch_mirror: '',
        sales_pitch_notes: '',

        demo_describe: '',
        demo_case_study: '',
        demo_example: '',
        demo_notes: '',

        negotiation: '',
        negotiation_price: '',
        negotiation_calculation: '',
        negotiation_notes: '',

        questions: [
          
        ],

        fails: [
          
        ],

        excuses: [
          
        ],

        agreed_upon: '',

        agenda: '',
        sharing_agenda: null,

        new_duration: 0,
        reschedule: null,
        attendee_has_time: null,

        rounds_saved: 0,
        time_saved: 0,
        confidence: 0,
        clarity: 0,
        productivity: 0,
        successful: 0,
        feedback: ''
      }
    }
  },

  computed: {
    isEditMode () {
      return this.form_data_prop !== undefined
    },

    formattedDate: {
      get() {
        return unformatDate(this.form_data.date)
      },
      set(value) {
        this.form_data.date = formatDate(value[0])
      }
    }
  },

  methods: {

    addAttendee() {
      this.form_data.attendees.push({
        name: '',
        designation: '',
        role: ''
      })
    },

    addQuestion() {
      this.form_data.questions.push({
        type: 'B',
        question: '',
        answer: ''
      })
    },

    addFail() {
      this.form_data.fails.push({
        type: 'B',
        situation: '',
        solution: ''
      })
    },

    addExcuse() {
      this.form_data.excuses.push({
        type: 'B',
        reason: '',
        solution: ''
      })
    },

    onPrevClicked() {
      this.saveData()
      .then(res =>{
        if (this.currentStepIndex == 18 && this.form_data.sales_meeting == 0) {
          this.currentStepIndex -= 4
        }
        else this.currentStepIndex--
      })
    },
    
    onNextClicked() {
      this.saveData()
      .then(res => {
        if (this.currentStepIndex == 14 && this.form_data.sales_meeting == 0) {
          this.currentStepIndex += 4
        }
        else this.currentStepIndex++
      })
    },

    onSaveClicked() {
      this.saveData()
      .then(res => {
        this.$f7router.back()
      })
    },

    saveData() {
      return new Promise((resolve, reject) => {
        
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        let url = `batch/${batchId}/meeting`
        let method = 'POST'
        
        if (this.currentId) {
          url += `/${this.currentId}`
          method = 'PUT'
        }

        participantAPI().post(url, {
          _method: method,
          ...this.form_data,
          step: this.currentStepIndex + 1
        })
        .then(res => {
          if (!this.currentId) {
            this.currentId = res.data.data
          }
          resolve(res)
        })
        .catch(err => {
          errorHandler(err, (error) => {
            if (Array.isArray(error)) {
              error = error[0]
            }
            this.$f7.dialog.alert(error)
          })
          // reject(err)
        })
      })
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/meeting/${this.currentId}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.back();
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    }
  },
}
</script>

<style scoped>
  .repeater-margin {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .repeater-row {
    position: relative;
    justify-content: start;
    background: white;
  }
  .remove-button {
    position: absolute;
    top: 25px;
    right: -5px;
    z-index: 999
  }
</style>