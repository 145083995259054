<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar title="Add Time Saving" back-link="Back"></f7-navbar>

    <!-- Time in Mins -->
    <div class="wrapper" v-if="pageState == 'loaded'">
      <az-list>
        <az-list-input label="Action" type="select" v-model="form_data.action">
          <option value="A">Automate</option>
          <option value="D">Delegate</option>
          <option value="R">Reduce</option>
        </az-list-input>

        <az-list-input label="Possible Time Saving (In Minutes)" type="number" v-model="form_data.time_saving" />
      </az-list>
      
    </div>

    <!-- Save Button -->
    <f7-fab position="right-bottom" slot="fixed" @click="onSaveTimeClick">
      <f7-icon ios="f7:floppy_disk" aurora="f7:floppy_disk" md="material:save"></f7-icon>
    </f7-fab>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="onTryAgainClick" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZLoader from '@/components/ui/AZLoader.vue';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';

export default {
  props: ['time_id', 'routine_id', 'form_data_prop'],
  components: {
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage,
    'az-list': AZList,
    'az-list-input': AZListInput,
  },

  data() {
    return {
      pageState: this.isEditMode ? 'loading' : 'loaded',
      form_data: {
        action: '',
        time_saving: ''
      }
    }
  },

  mounted() {
    if (this.isEditMode) {
      if (!this.form_data_prop) {
        this.loadDataForId()
      } else {
         this.form_data = {
           action: this.form_data_prop.action,
           time_saving: this.form_data_prop.time_saving
         }
         this.pageState = 'loaded';
      }
    }
  },

  methods: {
    onSaveTimeClick() {
      this.pageState = 'loading';
      
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = this.isEditMode ? `batch/${batchId}/time-finder/${this.time_id}/time-finder-routine/${this.routine_id}` : `batch/${batchId}/time-finder/${this.time_id}/time-finder-routine`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
      })
      .then(response => {
        // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.back();
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    loadDataForId() {
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/time-finder/${this.time_id}/time-finder-routine/${this.routine_id}`)
      .then(response => {
        this.form_data = response.data.data
        this.pageState = 'loaded'
      })
      .catch(error => {
        errorHandler(error)
        this.pageState = 'failed'
      })
    },

    onTryAgainClick() {
      this.pageState = 'loading';
      this.loadDataForId();

    }
  },

  computed: {
    isEditMode() {
      return (this.routine_id !== undefined);
    },
  }
}
</script>

<style scoped>
  .list >>> option {
    color: var(--f7-input-text-color);
    background-color: var(--f7-page-bg-color);
  }
</style>