<template>
  <div>

    <!-- List -->
    <div class="wrapper" style="margin-top: 35px">
      <v-date-picker 
        is-inline 
        v-model="date"
        :input-props='{
                class:"w-full"
            }'
      />
      <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

      <div class="list-layout" style="position: relative; min-height: 30vh">
          <div class="list">
              <az-custom-list media-list v-if="actions.length > 0">
                  <az-custom-list-item
                      v-for="action in actions"
                      :key="action.id"
                      :title="action.summary"
                      :subtitle="getActionTimeString(action)"
                      :background="action.status == 1 ? '#66bb6a' : ''"
                      :noMenu="true"
                      @menu-click="onMenuClick(action)"
                      @item-click="onEditClick(action)"
                  >
                  </az-custom-list-item>
              </az-custom-list>

              <az-page-message message="No actions on this day" v-else></az-page-message>
          </div>

          

          <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData"></az-page-error-message>
      </div>
    </div>
  </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import AZCustomList from '@/components/ui/AZCustomList'
import AZCustomListItem from '@/components/ui/AZCustomListItem'
import AZList from '@/components/ui/AZList'
import AZListInput from '@/components/ui/AZListInput'
import moment from 'moment'

export default {
  name: 'PowerPlannerCalendar',

  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'az-custom-list': AZCustomList,
    'az-custom-list-item': AZCustomListItem,
    'v-date-picker': DatePicker,
    'az-list-input': AZListInput,
    'az-list': AZList
  },

  data() {
    return {
      pageState: 'loading',
      actions: [],
      date: moment().toDate(),
      currentAction: null,
      sheetOpened: false
    }
  },

  mounted() {
    if (this.gapi) this.loadDataFromAPI()
  },

  methods: {
    loadDataFromAPI() {
        let batchId = this.$store.getters['auth/getCurrentBatchID']
        this.$store.dispatch('googleCalendar/getGoogleEvents', this.gcalDate)
        .then(res => {
            this.pageState = 'loaded'
            this.actions = res.items.sort((a, b) => {
              if (moment(a.start.dateTime).isSameOrBefore(moment(b.start.dateTime))) return -1
              return 1
            })
        })
        .catch(err => {
            this.pageState = 'failed'
            errorHandler(err)
        })
    },

    onMenuClick(action) {
      this.currentAction = action
      this.$refs.actionsGroup.open()
    },

    onEditClick(action) {
      this.pageState = 'loading'
      let google_event_id = action.id.split('_')[0]
      let batchId = this.$store.getters['auth/getCurrentBatchID']
      participantAPI().get(`batch/${batchId}/event/${google_event_id}`)
      .then(res => {
        this.$f7router.navigate('/event/edit/planner/', {
          props: {
            form_data_prop: res.data.data
          }
        })
      })
      .catch(err => {
        this.pageState = 'loaded'
        if (err.response.status === 404) {
          window.$f7.dialog.alert('This is a non Colloquium event')
        }
        else {
          errorHandler(err)
        }
      })
    },

    onItemClick(action) {
      this.currentAction = action
      this.onEditClick()
    },

    refreshData() {
        this.pageState = 'loading'
        this.actions = []
        this.loadDataFromAPI()
    },

    getActionTimeString(action) {
      return `${moment(action.start.dateTime).format('hh:mma') } - ${moment(action.end.dateTime).format('hh:mm a')}`
    }
  },

  computed: {
      dbDate() {
          return moment(this.date).format('YYYY-MM-DD')
      },

      gcalDate() {
        return moment(this.date).toDate()
      },

      statusText() {
        return this.currentAction ? (this.currentAction.status == 1 ? 'Mark as not done' : 'Mark as done') : ''
      },

      gapi() {
        return this.$store.getters['googleAuth/gapiLoaded']
      },

      some() {
        if(this.actions.length > 0) {
          return moment(this.actions[0].start.dateTime).format('h mm')
        }
        return 'NA'
      }
  },

  watch: {
      date(val) {
          this.refreshData()
      },

      gapi(val) {
        if (val) {
          console.log('ya', val)
          this.loadDataFromAPI()
        }
      }
  }
}
</script>

<style scoped>
  .list >>> .item-title{
    white-space: initial !important;
  }

  .item-media i {
    color: var(--f7-theme-color) !important;
  }

  .vc-container {
      width: 100% !important;
  }

</style>