<template>
  <f7-list no-hairlines-md>
    <slot></slot>
  </f7-list>
</template>

<script>
export default {
  name: 'az-list'
}
</script>

<style scoped>
  .list >>> ul {
    padding: 10px 0;
  }
</style>