<template>
  <f7-page @page:reinit="loadDataFromAPI">
    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link 
            panel-open="left"
            icon-ios="f7:menu" 
            icon-aurora="f7:menu"
            icon-md="material:menu"
            >
        </f7-link>
      </f7-nav-left>

      <!-- Page Title -->
      <f7-nav-title>
          Profile
      </f7-nav-title>

    </f7-navbar>

    <div v-if="pageState == 'loaded'">
      
      <div class="profile-img">
        <img v-if="currentPictureThumb" :src="`${currentPictureThumb}`" @error="test" alt="Profile Image" @click="$refs.standalone.open()">
        <div class="no-img" v-else></div> 
        <f7-link @click="pictureEdit">Edit</f7-link>
      </div>

      <f7-photo-browser
        :photos="photos"
        ref="standalone"
      ></f7-photo-browser>

      <f7-card class="demo-card">
        <f7-card-header
          valign="center"
          class="az-card-header">
          <div class="az-card-heading">
            <span>Basic Details</span>
            <f7-link @click="onBasicEditClick">
              <f7-icon
                color="black"
                md="material:edit"
                ios="f7:pencil"
                aurora="f7:pencil"
              ></f7-icon>
            </f7-link>
          </div>
        </f7-card-header>
        <f7-card-content>
            <div class="row">
                <div class="col-100 medium-33 large-25">
                    <span>First Name</span><br>
                    <p><b>{{ form_data.first_name }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Middle Name</span><br>
                    <p><b>{{ form_data.middle_name || 'NA' }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Last Name</span><br>
                    <p><b>{{ form_data.last_name }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Preffered Name</span><br>
                    <p><b>{{ form_data.preferred_name }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Gender</span><br>
                    <p><b>{{ genderForShow }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Date of Birth</span><br>
                    <p><b>{{ fancyBirthDate }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Cell No</span><br>
                    <p><b>{{ form_data.phone }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Alternate No</span><br>
                    <p><b>{{ form_data.alternate_phone || 'NA' }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Work Email</span><br>
                    <p><b>{{ form_data.work_email }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Organization</span><br>
                    <p><b>{{ form_data.organization }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Designation</span><br>
                    <p><b>{{ form_data.designation }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Website</span><br>
                    <p><b>{{ form_data.website || 'NA' }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Office address</span><br>
                    <p><b>{{ form_data.address }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Industry type</span><br>
                    <p><b>{{ industryTypesForShow }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Nature of work</span><br>
                    <p><b>{{ form_data.nature_of_work }}</b></p>
                </div>
                <div class="col-100 medium-33 large-25">
                    <span>Workshops done with us</span><br>
                    <p><b>NA</b></p>
                </div>
            </div>
            
        </f7-card-content>
      </f7-card>

      <f7-card class="demo-card">
        <f7-card-header
          class="az-card-header"
          valign="center">
          <div class="az-card-heading">
            <span>Personal Details</span>
            <f7-link @click="onPersonalEditClick">
              <f7-icon
                color="black"
                md="material:edit"
                ios="f7:pencil"
                aurora="f7:pencil"
              ></f7-icon>
            </f7-link>
          </div>
        </f7-card-header>
        <f7-card-content>
          <div class="row">
              <div class="col-100 medium-33 large-25">
                  <span>Marital Status</span><br>
                  <p><b>{{ maritalStatusForShow }}</b></p>
              </div>
              <div v-if="form_data.marital_status === 'Y'" class="col-100 medium-33 large-25">
                  <span>Anniversary Date</span><br>
                  <p><b>{{ anniversaryDateForShow }}</b></p>
              </div>
              <div v-if="form_data.marital_status === 'Y'" class="col-100 medium-33 large-25">
                  <span>Name of Spouse</span><br>
                  <p><b>{{ form_data.spouse_name }}</b></p>
              </div>
              <div v-if="form_data.marital_status && form_data.marital_status !== 'N'" class="col-100 medium-33 large-25">
                  <span>No. of children</span><br>
                  <p><b>{{ form_data.no_of_children }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Personal email</span><br>
                  <p><b>{{ form_data.personal_email || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Home address</span><br>
                  <p><b>{{ form_data.home_address || 'NA' }}</b></p>
              </div>
              
          </div>
        </f7-card-content>
      </f7-card>

      <f7-card class="demo-card">
        <f7-card-header
          class="az-card-header"
          valign="center">
          <div class="az-card-heading">
            <span>Business Details</span>
            <f7-link @click="onBusinessEditClick">
              <f7-icon
                color="black"
                md="material:edit"
                ios="f7:pencil"
                aurora="f7:pencil"
              ></f7-icon>
            </f7-link>
          </div>
        </f7-card-header>
        <f7-card-content>
          <div class="row">
              <div class="col-100 medium-33 large-25">
                  <span>Company Logos</span><br>
                  
                  <p><f7-link href="/profile/logos/select/">Go to logo gallery...</f7-link></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Business Description</span><br>
                  <p><b>{{ form_data.business_description || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>USP</span><br>
                  <p><b>{{ form_data.usp || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Keywords</span><br>
                  <p><b>{{ form_data.keywords || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Accomplishments</span><br>
                  <p><b>{{ form_data.accomplishments || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Case studies</span><br>
                  <p><b>{{ form_data.case_studies || 'NA' }}</b></p>
              </div>
          </div>
        </f7-card-content>
      </f7-card>

      <f7-card class="demo-card">
        <f7-card-header
          class="az-card-header"
          valign="center">
          <div class="az-card-heading">
            <span>Your Connection Details</span>
            <f7-link @click="onConnectionEditClick">
              <f7-icon
                color="black"
                md="material:edit"
                ios="f7:pencil"
                aurora="f7:pencil"
              ></f7-icon>
            </f7-link>
          </div>
        </f7-card-header>
        <f7-card-content>
          <div class="row">
              <div class="col-100 medium-33 large-25">
                  <span>Facebook Link</span><br>
                  <p><b>{{ form_data.facebook || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>LinkedIn Profile</span><br>
                  <p><b>{{ form_data.linkedin || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Instagram Handle</span><br>
                  <p><b>{{ form_data.instagram || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Twitter Handle</span><br>
                  <p><b>{{ form_data.twitter || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Business Networks/Organisations</span><br>
                  <p><b>{{ form_data.part_of_organizations || 'NA' }}</b></p>
              </div>
              <div class="col-100 medium-33 large-25">
                  <span>Workshops done before Colloquium</span><br>
                  <p><b>{{ form_data.workshops_before_cq || 'NA' }}</b></p>
              </div>
          </div>
        </f7-card-content>
      </f7-card>
    </div>

    <az-loader v-if="pageState == 'loading'"></az-loader>

    <az-error-message v-if="pageState == 'failed'" @try-again="loadDataFromAPI"></az-error-message>

  </f7-page>
</template>

<script>
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import { participantAPI, errorHandler, makeFormData, BASEURL } from '@/js/api'
import logo from '@/assets/logo.png'
import moment from 'moment'
import defaultImg from '@/assets/no-img.jpg';
import { IndustryTypes, Genders, MaritalStatuses } from '@/js/consts';

export default {
  components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-loader': AZLoader,
    'az-error-message': AZPageErrorMessage
  },

  data() {
    return {
      baseUrl: BASEURL,
      pageState: 'loaded',
      logo: logo,
      defaultImg: defaultImg,
      imageError: false,
      photos: [],
      form_data: {
        first_name: '',
        last_name: '',
        email: '',
        organization: '',
        designation: '',
        nature_of_work: '',
        website: '',
        address: '',
        pincode: '',
        city: '',
        state: '',
        country: '',
        birth_date: '',
        linkedin: '',
        facebook: '',
        instagram: '',
        twitter: '',
        files: [null, null],
        pictureChange: false,
      }
    }
  },

  computed: {
    formattedDate: {
      get() {
        let parts = this.form_data.birth_date.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.birth_date = dateValue.format('DD-MM-YYYY');
      }
    },

    currentPictureThumb() {
      return this.imageError || this.photos.length === 0 ? this.defaultImg : this.photos[0].thumb
    },

    fancyBirthDate() {
      let date = this.form_data.birth_date
      return moment(date, 'DD-MM-YYYY').format('DD MMMM, YYYY')
    },

    industryTypesForShow() {
      return this.form_data.industry_types ? this.form_data.industry_types.map(key => IndustryTypes[key]).join(' | ') : ''
    },

    genderForShow() {
      return Genders[this.form_data.gender]
    },

    maritalStatusForShow() {
      return MaritalStatuses[this.form_data.marital_status] || 'NA'
    },

    anniversaryDateForShow() {
      let date = this.form_data.anniversary_date
      return moment(date, 'DD-MM-YYYY').format('DD MMMM, YYYY')
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {

    onBasicEditClick() {
      this.$f7router.navigate('/profile/basic/', {
        props: {
          form_data_prop: this.form_data
        }
      })
    },

    onPersonalEditClick() {
      this.$f7router.navigate('/profile/personal/', {
        props: {
          form_data_prop: this.form_data
        }
      })
    },

    onBusinessEditClick() {
      this.$f7router.navigate('/profile/business/', {
        props: {
          form_data_prop: this.form_data
        }
      })
    },

    onConnectionEditClick() {
      this.$f7router.navigate('/profile/communication/', {
        props: {
          form_data_prop: this.form_data
        }
      })
    },

    test() {
      this.imageError= true
    },

    pictureEdit() {
      this.$f7router.navigate('/profile/pictures/select/', {
        props: {
          pictures_prop: this.photos
        }
      })
    },

    onSaveClick() {
      let data = {
        _method: 'PUT',
        ...this.form_data
      }
      let formData = makeFormData(data)
      // return
      this.pageState = 'loading'
      participantAPI().post(
        '/profile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        this.loadDataFromAPI()
      })
      .catch(error => {
        this.pageState = 'loaded'
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    loadDataFromAPI() {
      this.pageState = 'loading'
      participantAPI().get('/profile')
      .then(res => {
        this.form_data = res.data.data
        if (this.form_data.profile_pictures.length > 0) {

          this.photos = this.form_data.profile_pictures.map(pic => {
            return {
              id: pic.id,
              url: BASEURL + '/' + pic.path,
              thumb: BASEURL + '/' + pic.thumb_path
            }
          })
        }
        if (this.form_data.birth_date === null) {
          this.form_data.birth_date = ''
        }
        this.pageState = 'loaded'
      })
      .catch(err => {
        this.pageState = 'failed'
        errorHandler(err)
      })
    }
  }
}
</script>

<style scoped>
  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-img img{
    max-width: 50%;
    width: 100vw;
    height: auto;
    border-radius: 50%;
    display: block;
    margin: 30px auto 0 auto;
    margin-bottom: 20px;
    /* padding-top: 30px; */
  }

  .demo-card >>> .col-50 {
      word-break: break-all;
  }

  .demo-card >>> p {
      margin-top: 0;
  }

  @media only screen and (min-width: 992px){
    .profile-img img{
      max-width: 20%;
    }
  }

  .no-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: grey;
    margin: 10px;
  }

  .az-card-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .az-card-header {
    border-bottom: 1px solid #c5c5c5;
  }
</style>