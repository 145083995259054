<template>
  <f7-page class="re-analyze">

    <!-- Navbar -->
    <f7-navbar :title="currentTitle" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>
    
    <div>
      <az-steps :prev-visible="true"
                :next-visible="currentStepIndex < (routines.length - 1)"
                :save-visible="currentStepIndex == (routines.length - 1)"

                :save-icon-md="saveIconMd"
                :save-icon-ios="saveIconIos"

                @prev-clicked="onPrevClicked"
                @next-clicked="onNextClicked"
                @save-clicked="onSaveClicked"
      >
        
        <!-- Pages -->
        <div v-for="(routine, index) in routines"
            :key="index"
            v-if="index == currentStepIndex"
            :style="{ width: '100%', position: 'absolute', top: ($f7.device.ios ? '44px' : '56px'), paddingBottom: '70px' }"
            >

          <f7-block-title>Edit</f7-block-title>
          <az-list>
              <az-list-input type="text" v-model="currentTitle"></az-list-input>
          </az-list>
          
          <f7-block-title>Time Taken</f7-block-title>
          <az-list style="margin: 0">
              <az-list-input label="Time Taken (In Minutes)" type="time" :all_minutes="true" v-model="currentTimeTaken" />
          </az-list>

          <div style="background: white;">
              <p style="margin: 0; margin-left: 20px;">Repeats Every</p>
              <f7-row style="background: white">
                  <az-list class="col az-m-none">
                      <az-list-input label="Number" type="number" v-model="currentFrequency"></az-list-input>
                  </az-list>
                  <az-list class="col az-m-none">
                      <az-list-input label="Interval" type="select" v-model="currentRecurrence">
                          <option value="D">Daily</option>
                          <option value="W">Weekly</option>
                          <option value="M">Monthly</option>
                      </az-list-input>
                  </az-list>
              </f7-row>

              <az-toggle-btn-group style="margin-top: 0" v-show="currentRecurrence == 'W'">

                <az-toggle-btn
                    v-for="(item, key) in currentRoutine.meta"
                    :key="currentIndex + 'weekly' + key"
                    :active="currentRoutine.meta[key]"
                    @click="currentRoutine.meta[key] = !currentRoutine.meta[key]"
                >
                {{ key }}
                </az-toggle-btn>
                
            </az-toggle-btn-group>
          </div>
          

          <!-- Edit -->
          <f7-block-title>Time Saving</f7-block-title>
          <az-toggle-btn-group label="Method for time saving"
                                style="margin-bottom: 0"      
          >
                <az-toggle-btn :active="currentAction == 'A'"
                                @click="currentAction = 'A'"        
                >Automate
                </az-toggle-btn>
                <az-toggle-btn :active="currentAction == 'D'"
                                @click="currentAction = 'D'"        
                >Delegate
                </az-toggle-btn>
                <az-toggle-btn :active="currentAction == 'R'"
                                @click="currentAction = 'R'"        
                >Reduce
                </az-toggle-btn>
                <az-toggle-btn :active="currentAction == 'S'"
                                @click="stopClick"        
                >Stop
                </az-toggle-btn>
          </az-toggle-btn-group>
          <az-list style="margin-top:0">
              <az-list-input label="Possible Time Saving (In Minutes)" :all_minutes="true" type="time" v-model="currentTimeSaving" />
          </az-list>
        </div>
      </az-steps>

      <!-- Loading Screen -->
      <az-loader v-if="pageState == 'loading'"/>
    </div>
    

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import moment from 'moment';
import AZSteps from '@/components/ui/AZSteps.vue'
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader.vue'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';
import AZToggleButton from '@/components/ui/AZToggleButton';

export default {
  name: 'AnalyseReason',
  props: {
    routines_prop : {
      type: Array
    },
    currentIndex: {
      default: 0
    },
    timeFinderId: {
      type: String,
      required: true
    },
    saveIconMd: {
      type: String,
      default: "material:save"
    },
    saveIconIos: {
      type: String,
      default: "f7:floppy_disk"
    }
  },
  components: {
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage,
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-steps': AZSteps,
    'az-toggle-btn-group': AZToggleButtonGroup,
    'az-toggle-btn': AZToggleButton
  },
  data() {
    return {
      pageState: 'loaded',
      routines: [],
      currentStepIndex: 0,
      customRecurrence: ''
    }
  },

  mounted() {
    if (!this.routines_prop) {
      setTimeout(() => {
        this.$f7router.back();
      }, 500)
    }
    else {
      this.currentStepIndex = this.currentIndex
      this.routines = this.routines_prop
    }
  },

  methods: {
    // Button Clicks
    stopClick() {
      this.currentAction = 'S'
      this.currentTimeSaving = this.currentTimeTaken
    },

    onPrevClicked() {
      this.onSaveClick()
        .then(() => {
          if (this.currentStepIndex == 0) {
            // Go back to Index Page 
            this.$f7router.back();
          }
          else {
            this.pageState = 'loaded'
            this.currentStepIndex--;
          }
        })
    },

    onNextClicked() {
      this.onSaveClick()
        .then(() => {
          this.pageState = 'loaded'
          this.currentStepIndex++;
        })
    // this.currentStepIndex++
      
    },

    onSaveClicked() {
      this.onSaveClick()
        .then(() => {
          this.pageState = 'loaded'
          this.$emit('save-clicked');
        })
      // Go back to Index Page 
      this.$f7router.back();
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/time-finder/${this.timeFinderId}/time-finder-routine/${this.currentRoutineId}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          this.pageState = 'loaded'
          this.routines.splice(this.currentStepIndex, 1)
          if (this.routines.length <= this.currentStepIndex || this.routines.length == 0) {
            // Go back to Index Page 
            this.$f7router.back();
          }
        })
        .catch(error => {
          // Handle Errors          
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    },

    onSaveClick() {
      this.pageState = 'loading';
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = `batch/${batchId}/time-finder/${this.timeFinderId}/time-finder-routine/${this.currentRoutineId}`;

      return new Promise((res, rej) => {
        participantAPI().post(url, {
          _method: 'PUT',
          ...this.routines[this.currentStepIndex],
        })
        .then(response => {
          // Show Toast
            window.$f7.toast.create({
                text: 'Your data has been saved',
                closeTimeout: 3500,
            }).open();
            res()
        })
        .catch(error => {
          this.pageState = 'loaded';
          errorHandler(error, 
          (errors) => {
            window.$f7.dialog.alert(errors[0], "Error");
          })
          rej(error)
        })
      })
      
    },
  },

  computed: {

      currentRoutine() {
        return this.routines[this.currentStepIndex]
      },

      currentTitle: {
          get() {
              return this.routines[this.currentStepIndex] ? this.routines[this.currentStepIndex].task : ''
          },

          set(val) {
              this.routines[this.currentStepIndex].task = val
          }
      },

      currentAction: {
          get() {
              return this.routines[this.currentStepIndex].action
          },

          set(val) {
              this.routines[this.currentStepIndex].action = val
          }
      },

      currentTimeSaving: {
          get() {
              return this.routines[this.currentStepIndex].time_saving
          },

          set(val) {
              this.routines[this.currentStepIndex].time_saving = val
          }
      },

      currentTimeTaken: {
          get() {
              return this.routines[this.currentStepIndex].time_taken
          },

          set(val) {
              this.routines[this.currentStepIndex].time_taken = val
          }
      },

      currentRecurrence: {
          get() {
              return this.routines[this.currentStepIndex].recurrence
          },

          set(val) {
              this.routines[this.currentStepIndex].recurrence = val
          }
      },

      currentFrequency: {
        get() {
          return this.routines[this.currentStepIndex].frequency
        },

        set(val) {
          this.routines[this.currentStepIndex].frequency = val
        }
      },

      currentRoutineId() {
        return this.routines[this.currentStepIndex].id
      }

  }

  
}
</script>

<style scoped>
  .wrapper {
    padding-bottom: 50px;
  }

  .az-m-none {
      margin: 0 !important;
  }
</style>