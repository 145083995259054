<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Insight'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Wrapper -->
    <div class="az-page">
      <div class="az-scrollable">

        <az-list>
          <az-list-input label="Date Noticed" type="datepicker" v-model="formattedDate" />

          <az-list-input label="My Insight" type="textarea" v-model="form_data.my_insight" />

          <az-list-input label="New Action" type="textarea" v-model="form_data.new_action" />

          <f7-block-title>Insight Status</f7-block-title>
            <az-toggle-btn-group>
              <az-toggle-btn
                v-for="(status, key) in statuses"
                :key="key"
                :active="form_data.implemented == key"
                @click="form_data.implemented = key"
              >
                {{ status }}
              </az-toggle-btn>
              
            </az-toggle-btn-group>
          <!-- </div> -->
        </az-list>

        <!-- Save Button -->
        <f7-fab position="right-bottom"
                @click="onSaveClick">
          <f7-icon ios="f7:floppy_disk" 
                  aurora="f7:floppy_disk"
                  md="material:save" />
        </f7-fab>
      </div>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader.vue'
import moment from 'moment';
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';
import AZToggleButton from '@/components/ui/AZToggleButton';
import { ImplementationStatuses } from '@/js/consts';

export default {
  props: ['form_data_prop'],
  components: {
    'az-loader': AZLoader,
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-toggle-btn-group': AZToggleButtonGroup,
    'az-toggle-btn': AZToggleButton
  },
  mounted() {
    if (this.isEditMode) {
      this.form_data = this.form_data_prop;
    }
  },
  data() {
    return {
      pageState: this.isEditMode ? 'loading' : 'loaded',
      statuses: ImplementationStatuses,
      form_data: {
        implemented: 'Y',
        date: '',
        my_insight: '',
        new_action: ''
      }
    }
  },
  methods: {

    onSaveClick() {
      this.pageState = 'loading';
      
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = this.isEditMode ? `batch/${batchId}/insight/${this.form_data.id}` : `batch/${batchId}/insight`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
      })
      .then(response => {
        // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.back();
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/insight/${this.form_data.id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.back();
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    }
  },

  computed: {
    isEditMode() {
      return (this.form_data_prop !== undefined);
    },
    formattedDate: {
      get() {
        let parts = this.form_data.date.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];     
          return [new Date(year, month, day).toString()];
        }
        return [];

      },
      set(value) {        
        let dateValue = moment(value[0]);
        this.form_data.date = dateValue.format('DD-MM-YYYY');
      }
    }
  }
}
</script>