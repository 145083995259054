<template>
  <div class="az-steps">
    
    <!-- Steps -->
    <az-transition-group :direction="direction">
      <slot></slot>
    </az-transition-group>

    <!-- Previous Button -->
    <f7-fab position="left-bottom"
            v-if="prevVisible"
            @click="onPrevClick">
      <f7-icon ios="f7:arrow_left"
               aurora="f7:arrow_left"
               md="material:arrow_backward" />
    </f7-fab>

    <!-- Next Button -->
    <f7-fab position="right-bottom"
            v-if="nextVisible"
            @click="onNextClick">
      <f7-icon ios="f7:arrow_right" 
               aurora="f7:arrow_right"
               md="material:arrow_forward" />
    </f7-fab>

    <!-- Save Button -->
    <f7-fab position="right-bottom"
            v-if="saveVisible"
            @click="onSaveClick">
      <f7-icon :ios="saveIconIos" 
               :aurora="saveIconIos"
               :md="saveIconMd" />
    </f7-fab>

  </div>
</template>

<script>
import AZTransitionGroup from '@/components/ui/AZTransitionGroup.vue'

export default {
  name: 'az-steps',
  props: {
    prevVisible: {
      required: true,
      type: Boolean
    },
    nextVisible: {
      required: true,
      type: Boolean
    },
    saveVisible: {
      required: true,
      type: Boolean
    },
    saveIconMd: {
      type: String,
      default: "material:save"
    },
    saveIconIos: {
      type: String,
      default: "f7:floppy_disk"
    }
  },
  components: {
    'az-transition-group': AZTransitionGroup
  },
  data() {
    return {
      direction: "F"
    }
  },
  mounted() {
    this.addListener()
  },
  methods: {
    onPrevClick() {
      this.direction = "B";
      this.$emit('prev-clicked');
    },
    onNextClick() {
      this.direction = "F";
      this.$emit('next-clicked');
    },
    onSaveClick() {
      this.$emit('save-clicked');
    },
    keyPress(e) {
      var evtobj = window.event? event : e

      if (evtobj.key === '.'  &&  evtobj.altKey) {  // case sensitive
          if (!this.saveVisible) this.onNextClick()
          else this.onSaveClick()
          evtobj.stopPropagation();
          evtobj.preventDefault()
      }

      if (evtobj.key === ','  &&  evtobj.altKey) {  // case sensitive
          if (this.prevVisible) this.onPrevClick()
          evtobj.stopPropagation();
          evtobj.preventDefault()
      }
      
    },
    addListener() {
      document.addEventListener('keydown', this.keyPress)
    }
  }
}
</script>

<style scoped>
  .fab {
    position: fixed;
  }

  @media screen and (min-width: 960px) {
    .fab-left-bottom {
      left: 340px;
    }
  }
</style>