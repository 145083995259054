<template>
  <f7-page>
        <!-- Navbar -->
        <f7-navbar>
        <!-- Sidebar Button -->
        <f7-nav-left>
            <f7-link 
                panel-open="left"
                icon-ios="f7:menu" 
                icon-aurora="f7:menu"
                icon-md="material:menu"
                >
            </f7-link>

            <!-- Page Title -->
            <f7-nav-title>
                Google Sign In
            </f7-nav-title>

        </f7-nav-left>
        </f7-navbar>

        <div class="wrapper">
            <f7-block-title>Your Active Account</f7-block-title>
            <f7-list>
                <f7-list-item
                    :title="activeEmail"
                ></f7-list-item>
            </f7-list>
        </div>
        <f7-fab class="fixed"
                position="right-bottom"
                @click="fabClick" >
            <f7-icon :ios="fabIconIos"
                :aurora="fabIconIos"
                :md="fabIconMd"
                />
        </f7-fab>

        <az-loader v-if="pageState == 'loading'"></az-loader>
  </f7-page>
</template>

<script>
import AZLoader from '@/components/ui/AZLoader'
export default {
    name: 'GoogleLogin',
    components: {
        'az-loader': AZLoader
    },
    data() {
        return {
            isSignedIn: false,
            activeEmail: '',
            pageState: 'loaded'
        }
    },

    mounted() {
        if (this.$store.getters['googleAuth/getIsSignedIn']()) {
            this.isSignedIn = true
            this.activeEmail = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()
        }
        else {
            this.activeEmail = 'No google account synced. Sign in to continue'
        }
    },

    methods: {
        fabClick() {
            if (this.isSignedIn) {
                window.$f7.dialog.confirm("Are you sure you want to logout?", "Logout", 
                () => {
                    this.pageState = 'loading'
                    this.$store.dispatch('googleAuth/googleSignOut')
                    .then(() => {
                        this.isSignedIn = false
                        this.activeEmail = 'No google account synced. Sign in to continue'
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .then(() => {
                        this.pageState = 'loaded'
                    })
                });
            }

            else {
                this.$store.dispatch('googleAuth/googleSignIn').then(() => {
                        this.isSignedIn = true
                        this.activeEmail = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()
                    })
                    .catch(err => {
                        if (err.error === 'popup_closed_by_user') {
                            window.$f7.dialog.alert(
                                'Please log in with Google to continue',
                                'Error'
                            )
                        }
                    })
                    .then(() => {
                        this.pageState = 'loaded'
                    })
            }
        }
    },

    computed: {
        // isSignedIn() {
        //     return this.$store.getters['googleAuth/getIsSignedIn']()
        // },


        // activeEmail: {
        //     get() {
        //         if (this.isSignedIn) {

        //             return gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()
        //         }
        //         return 'No google account synced. Sign in to continue'
        //     }
        // },

        fabIconMd() {
            if (this.isSignedIn) {
                return 'material:directions_run'
            }
            return 'material:input'
        },

        fabIconIos() {
            if (this.isSignedIn) {
                return 'f7:square_arrow_right'
            }
            return 'f7:square_arrow_left'
        }
    }
}
</script>

<style scoped>
</style>