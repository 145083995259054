<template>
  <li>
    <a :href="href" class="item-link item-content">
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title">{{ name }}</div>
          <div class="item-after">
            <f7-icon 
              slot="media"
              ios="f7:list_dash"
              aurora="f7:list_dash"
              md="material:list"
              color="gray"
            ></f7-icon>
          </div>
        </div>
        <div class="item-subtitle">{{ description }}</div>        
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: 'cq-project-item',
  props: ['href', 'name', 'description']
}
</script>