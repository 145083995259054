<template>
  <div class="az-tab-page">

    <!-- Body -->
    <div class="az-tab-scrollable">

      <cq-timefinder-list class="fab-wrapper" v-if="timeFinders.length > 0">
        <cq-timefinder-item 
            v-for="timeFinder in timeFinders"
            :key="timeFinder.id"
            :date="timeFinder.date" 
            :total-time="timeFinder.total_time"
            :time-saved="timeFinder.time_save"
            @click.native="onViewRoutinesClick(timeFinder)" />
      </cq-timefinder-list>

      <!-- No Data Message -->
      <template v-else>
        <az-page-message v-if="pageState != 'failed'" />
      </template>
    </div>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

    <!-- Add Button -->
    <f7-fab class="fab-tab" position="right-bottom" slot="fixed" @click="onAddClick">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import CQTimeFinderList from '@/components/time-finder/CQTimeFinderList';
import CQTimeFinderItem from '@/components/time-finder/CQTimeFinderItem';
import AZLoader from '@/components/ui/AZLoader'
import moment from 'moment';

export default {
  name: 'TimeFinder',
  props: {
    timeFinders: Array,
    pageState: String
  },
  components: {
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'cq-timefinder-list': CQTimeFinderList,
    'cq-timefinder-item': CQTimeFinderItem,
    'az-loader': AZLoader
  },

  data() {
    return {
    }
  },

  mounted() {
    // this.loadDataFromAPI()
  },

  methods: {
    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/time-finder`)
        .then(response => {
          this.timeFinders = response.data.data.time_finders
          this.pageState = 'loaded'
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    // Button Click Events

    refreshData () {
      this.$emit('refresh')
    },

    onAddClick() {
      this.$f7router.navigate('/time-finder/create/');
    },

    onViewRoutinesClick(timeFinder) {
      this.$f7router.navigate(`/time-finder/${timeFinder.id}/routines/`)
    },

  },

}
</script>

<style scoped>
  
</style>