<template>
    <div>
        <div v-if="pageState == 'loaded'">
            <div v-if="count > 0">
                <f7-card class="summary-card">
                    <p class="no-margin">Number of times this tool has been used</p>
                    <h1 class="no-margin">{{ count }}</h1>
                </f7-card>

                <f7-card class="summary-card">
                    <p class="no-margin">You are saving</p>
                    <h1 class="no-margin">{{ timeSaved }}</h1>
                </f7-card>

                <f7-card class="summary-card">
                    <h2 class="no-margin">Possible Time Saving</h2>
                    <div class="wrapper">
                        <donut-chart
                            style="width: 270px"
                            id="time-finder-donut"
                            :colors="colors" 
                            :data="graphData" 
                            :resize="true">
                        </donut-chart>
                    </div>
                </f7-card>

            </div>

            <div v-else>
                <f7-card class="summary-card">
                    <h1 class="no-margin">No data to show</h1>
                    <p>Create entries to see your stats here</p>
                </f7-card>
            </div>
        </div>

        <!-- Error Message -->
        <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

        <!-- Loading Screen -->
        <az-loader v-if="pageState == 'loading'"/>
    </div>
</template>

<script>
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZLoader from '@/components/ui/AZLoader';
// Do not forget to import raphael
import Raphael from 'raphael/raphael'
global.Raphael = Raphael

import { DonutChart } from 'vue-morris'
import { CategoryColors, Categories } from '@/js/consts'

export default {
    name: 'TimeAuditorStatsFragment',
    props: {
        stats: Object,
        pageState: String
    },
    components: {
        DonutChart,
        'az-page-error-message': AZPageErrorMessage,
        'az-loader': AZLoader
    },
    data() {
        return {
            colors: CategoryColors,
            categories: Categories
        }
    },

    methods: {
        refreshData() {
            this.$emit('refresh')
        }
    },

    computed: {
        count() {
            return this.stats ? this.stats.count : ''
        },

        split() {
            return this.stats ? this.stats.split : null
        },

        graphData() {
            if (this.split) {
                return Object.values(this.split).map(item => {
                    let temp = JSON.parse(JSON.stringify(item))
                    temp.key = item.key
                    temp.label = item.label
                    temp.value = Math.round(item.count)
                    return temp
                })
            }
            return []
        },

        timeSaved() {
            let time = ''
            if (this.stats) {
                let hours = Math.round(this.stats.time_saved / 60)
                if (hours > 0) {
                    time += `${hours} Hr`
                }
                if (hours > 1) {
                    time += `s`
                }
                time += ' '
                let minutes = Math.round(this.stats.time_saved % 60)
                if (minutes > 0) {
                    time += `${minutes} Min `
                }
            }
            return time
        }
    }
}
</script>

<style scoped>
    .summary-card {
        text-align: center;
        max-width: 300px;
        padding: 10px;
        margin: var(--f7-list-margin-vertical) auto 0 auto;
    }

    .wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #time-finder-donut {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>