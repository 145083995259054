<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Lead'" back-link="Back">
      <f7-link v-if="isEditMode" slot="right" @click="onDeleteClick">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Wrapper -->
    <div class="az-page">
      <div class="az-scrollable">

        <az-list class="fab-wrapper">
          <az-list-input label="Lead Acquired On *" type="datepicker" v-model="acquiredFormattedDate" />
          <az-list-input label="First Name" type="text" v-model="form_data.first_name" />
          <az-list-input label="Last Name" type="text" v-model="form_data.last_name" />
          <az-list-input label="Organisation" type="text" v-model="form_data.organisation" />
          <az-list-input label="Cell No." type="number" v-model="form_data.phone" />
          <az-list-input label="Email Address" type="text" v-model="form_data.email" />
          <az-list-input label="Referred By" type="text" v-model="form_data.referred_by" />
          <az-list-input
            label="Assigned to"
            type="select"
            v-model="form_data.person_id" 
          >
            <option value="">None</option>
            <option v-for="person in people" 
              :key="person.id"
              :value="person.id">
              {{ person.first_name + ' ' + person.last_name }}
            </option>
          </az-list-input>
          <az-list-input label="Product/Service" type="text" v-model="form_data.product" />
          <az-list-input
            label="Lead Source"
            type="select" 
            v-model="form_data.lead_source" 
          >
            <option v-for="(source, key) in lead_sources" 
              :key="key"
              :value="key">
              {{ source }}
            </option>
          </az-list-input>
          <az-list-input label="Lead Verified" type="toggle" v-model="form_data.lead_verified"></az-list-input>
          <az-list-input
            label="Lead Status *"
            type="select" 
            v-model="form_data.lead_status" 
          >
            <option v-for="(status, key) in lead_statuses" 
              :key="key"
              :value="key">
              {{ status.name }}
            </option>
          </az-list-input>
          <az-list-input
            label="Lead Stage *"
            type="select"
            v-model="form_data.lead_stage"  
          >
            <option v-for="(stage, key) in lead_stages" 
              :key="key"
              :value="key">
              {{ stage.name }}
            </option>
          </az-list-input>
          <az-list-input label="Supervisor/Manager Notes" type="textarea" v-model="form_data.notes" />
          <az-list-input label="Asignee Notes" type="textarea" v-model="form_data.team_note" />
          <az-list-input label="Last Spoken On *" type="datepicker" v-model="spokenFormattedDate" />
          <az-list-input label="Next Follow Up" type="datepicker" v-model="followupFormattedDate" />
          <az-list-input label="Expected to Close by" type="datepicker" v-model="expectedFormattedDate" />
          <az-list-input label="Approximate Business Value" type="number" v-model="form_data.approx_business_value" />
          <az-list-input label="Lead's Nature of Work" type="text" v-model="form_data.nature_of_work" />
          <az-list-input label="Lead's Website" type="text" v-model="form_data.website" />
          <az-list-input label="Lead's Actual Business Value" type="number" v-model="form_data.actual_business_value" />
          <az-list-input label="Reason for Loss" type="text" v-model="form_data.reason_for_loss" />
        </az-list>

        <!-- Save Button -->
        <f7-fab position="right-bottom"
                @click="onSaveClick">
          <f7-icon ios="f7:floppy_disk" 
                  aurora="f7:floppy_disk"
                  md="material:save" />
        </f7-fab>
      </div>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="onTryAgainClick" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import axios from 'axios'
import AZLoader from '@/components/ui/AZLoader.vue'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import moment from 'moment';
import { LeadSources, LeadStatuses, LeadStages } from '@/js/consts'
// import { LeadStatuses } from '@/js/consts'

export default {
  props: ['id', 'form_data_prop'],
  components: {
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage,
    'az-list': AZList,
    'az-list-input': AZListInput,
  },

  mounted() {
    this.pageInit()
    // if (this.isEditMode) {
    //   if (!this.form_data_prop) {
    //     this.loadDataForId()
    //   } else {
    //      this.form_data = this.form_data_prop;
    //      this.pageState = 'loaded';
    //   }
    // }
  },

  data() {
    return {
      pageState: this.isEditMode ? 'loading' : 'loaded',
      lead_sources: LeadSources,
      lead_statuses: LeadStatuses,
      lead_stages: LeadStages,
      people: [],
      form_data: {
        lead_acquired_on: '',
        first_name: '',
        last_name: '',
        organisation: '',
        phone: '',
        email: '',
        referred_by: '',
        last_spoken_on: '',
        lead_verified: 0,
        lead_stage: 'ENQ',
        person_id: '',
        lead_status: 'CLD',
        lead_source: 'EX',
        notes: '',
        team_note: '',
        next_follow_up: '',
        expected_to_close_by: '',
        approx_business_value: '',
        nature_of_work: '',
        website: '',
        actual_business_value: '',
        reason_for_loss: ''
      }
    }
  },

  methods: {
    onLeadAcquiredClick() {
      this.form_data.lead_acquired = 1 - this.form_data.lead_acquired
    },

    onSaveClick() {
      this.pageState = 'loading';
      
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = this.isEditMode ? `batch/${batchId}/money-maker/${this.id}` : `batch/${batchId}/money-maker`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
      })
      .then(response => {
        // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.back();
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    pageInit() {
      let apis = []
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      
      apis.push(participantAPI().get(`batch/${batchId}/people`))
      
      if (this.isEditMode) {
        if (!this.form_data_prop) {
          apis.push(participantAPI().get(`batch/${batchId}/money-maker/${this.id}`))
        }
        else {
          this.form_data = this.form_data_prop
        }
        
      }

      axios.all(apis)
        .then((...res) => {

          let peopleResponse = res[0][0]

          if (this.isEditMode && !this.form_data_prop) {
            let moneyMakerResponse = res[0][1]
            this.form_data = moneyMakerResponse.data.data
          }

          this.people = peopleResponse.data.data
          console.log(this.people)
          this.pageState = 'loaded'
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = 'failed'
        })
    },

    onTryAgainClick() {
      this.pageState = 'loading';
      this.pageInit();

    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/money-maker/${this.id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Go Back
        this.$f7router.back();
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    },

  },

  computed: {
    isEditMode() {
      return (this.id !== undefined);
    },
    acquiredFormattedDate: {
      get() {
        let parts = this.form_data.lead_acquired_on.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.lead_acquired_on = dateValue.format('DD-MM-YYYY');
      }
    },

    spokenFormattedDate: {
      get() {
        let parts = this.form_data.last_spoken_on.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.last_spoken_on = dateValue.format('DD-MM-YYYY');
      }
    },

    followupFormattedDate: {
      get() {
        let parts = this.form_data.next_follow_up.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.next_follow_up = dateValue.format('DD-MM-YYYY');
      }
    },

    expectedFormattedDate: {
      get() {
        let parts = this.form_data.expected_to_close_by.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.expected_to_close_by = dateValue.format('DD-MM-YYYY');
      }
    },
  }
}
</script>

<style scoped>
</style>