<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Time Savers ©</f7-nav-title>
    </f7-navbar>

    <!-- Summary -->
    <div class="az-page">
      <div class="az-scrollable" v-if="((time_saver.implemented.length > 0) || (time_saver.not_implemented.length > 0 ))">
        
        <f7-card class="summary-card">
          <p class="no-margin">Total Time Saved</p>
          <h1 class="no-margin">{{ time_saver.hours_saved }} Hr {{ time_saver.mins_saved }} Mins </h1>
        </f7-card>

        <!-- List -->
        <div v-if="(time_saver.implemented.length > 0)">
          <f7-block-title>Things Started</f7-block-title>
          <f7-list>
            <f7-list-item 
              v-for="(implemented, index) in calculatedImplemented"
              :key="implemented.id"
              :title="implemented.task" 
              :after="`${implemented.time_saved} MIN`" 
              link="#"
              @click="onEditClick(time_saver.implemented[index])">
            </f7-list-item>
          </f7-list>
        </div>

        <div v-if="(time_saver.not_implemented.length > 0)">
          <f7-block-title>Things Stopped</f7-block-title>
          <f7-list class="fab-wrapper">
            <f7-list-item 
              v-for="(not_implemented, index) in calculatedNotImplemented"
              :key="not_implemented.id"
              :title="not_implemented.task" 
              :after="convertTimeToHrs(not_implemented.time_saved)" 
              link="#"
              @click="onEditClick(time_saver.not_implemented[index])">
            </f7-list-item>
          </f7-list>
        </div>
      </div>

      <!-- No Data Message -->
      <template v-else>
        <az-page-message v-if="pageState != 'failed'" />
      </template>

      <!-- Add Button -->
      <f7-fab position="right-bottom" slot="fixed" @click="onAddClick">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import AZLoader from '@/components/ui/AZLoader';
import { participantAPI, errorHandler } from '@/js/api';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';


export default {
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
  },
  data() {
    return {
      currentId: 0,
      pageState: 'loading',
      time_saver: {
        hours_saved: '',
        mins_saved: '',
        implemented: [],
        not_implemented: [],
      },
      calculatedImplemented: [],
      calculatedNotImplemented: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {

    convertTimeToHrs(minutes) {
      minutes = parseInt(minutes)
      if (minutes < 59) {
        return `${minutes} MIN` 
      }
      let hrs = Math.floor(minutes / 60)
      let mins = minutes % 60
      return `${hrs} HRS ${mins} MIN`
    },

    mediaListener(ruleset) {
      let res = JSON.parse(JSON.stringify(this.time_saver))
      if (ruleset.matches) { // If media query matches
        this.calculatedImplemented = res.implemented.map(acc => {
          acc.task = this.shortenText(acc.task, 150)
          return acc
        })
        this.calculatedNotImplemented = res.not_implemented.map(acc => {
          acc.task = this.shortenText(acc.task, 150)
          return acc
        })
      } else {
        this.calculatedImplemented = res.implemented.map(acc => {
          acc.task = this.shortenText(acc.task, 500)
          return acc
        })
        this.calculatedNotImplemented = res.not_implemented.map(acc => {
          acc.task = this.shortenText(acc.task, 500)
          return acc
        })
      }
    },

    shortenTextRoutine() {
      var ruleset = window.matchMedia("(max-width: 700px)")
      this.mediaListener(ruleset) // Call listener function at run time
      ruleset.addListener(this.mediaListener) // Attach listener function on state changes
    },

    shortenText(text, limit) {
      if (text !== undefined && text !== null && text.length > limit) {
        text = text.substring(0, limit) + '...'
      }
      return text
    },

    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/time-saver`)
        .then(response => {
          this.time_saver = response.data.data
          this.pageState = 'loaded'
          this.shortenTextRoutine()
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    // Button Click Events
    
    refreshData () {
      this.time_saver = {
        total: '',
        implemented: [],
        not_implemented: []
      };
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    onAddClick() {
      this.$f7router.navigate('/time-savers/create/');
    },

    onEditClick(item) {
      this.$f7router.navigate(`/time-savers/create/`,{
        props: {
          form_data_prop: item
        }
      })
    },

  }
}
</script>

<style scoped>
  .summary-card {
    text-align: center;
    max-width: 300px;
    padding: 10px;
    margin: var(--f7-list-margin-vertical) auto 0 auto;
  }
</style>