<template>
  <!-- Page -->
  <f7-page no-toolbar no-navbar no-swipeback login-screen>
    
    <!-- Logo Image -->
    <f7-login-screen-title><img :src="logo"></f7-login-screen-title>
    
    <!-- Form -->
    <f7-list no-hairlines-md class="login-fields">
      <f7-list-input
        v-if="currentStep == 1"
        label="Phone Number"
        type="text"
        :value="form_data.phone"
        @input="form_data.phone = $event.target.value"
        outline
        floating-label
      ></f7-list-input>
      <f7-list-input
        v-if="currentStep == 2"
        label="OTP"
        type="number"
        :value="form_data.otp"
        @input="form_data.otp = $event.target.value"
        outline
        floating-label
      ></f7-list-input>
      <f7-list-input
        v-if="adminMode"
        label="Password"
        type="password"
        :value="form_data.password"
        @input="form_data.password = $event.target.value"
        outline
        floating-label
      ></f7-list-input>
    </f7-list>

    <!-- Sign In Button -->
    <f7-button v-if="!adminMode" fill color="red" class="signup-btn" @click="signIn">
      {{ currentStep == 1 ? 'Send OTP' : 'Verify OTP' }}
    </f7-button>

    <f7-button v-else fill color="red" class="signup-btn" @click="adminLogin">
      Login
    </f7-button>

  </f7-page>
</template>

<script>
import api, { errorMessages } from '@/js/api';
import logo from '@/assets/logo.png';

export default {
  // Lifecycle Hooks
  created() {
    this.$store.commit('ui/hideSidebar');
  },
  mounted() {
    // Check if already logged in
    let token = this.$store.getters['auth/getToken'];
    let user = this.$store.getters['auth/getUser'];
    if (user && token) {
      // Go to home page
      this.$store.commit('ui/goToHome');
    }
  },

  // Data & Methods
  data() {
    return {
      // Page State
      logo,
      currentStep: 1,
      // Form Data
      form_data: {
        phone: '',
        otp: '',
        password: ''
      },
      adminMode: false
    }
  },
  methods: {
    signIn() {
      if (this.currentStep == 1) {
        this.sendOTP();
      } else if (this.currentStep == 2) {
        this.verifyOTP();
      }
    },
    adminLogin() {
      this.$store.dispatch('auth/adminLogin', this.form_data)
    },
    sendOTP() {
      if (this.form_data.phone.includes('-')) {
        let parts = this.form_data.phone.split('-')
        if (parts[1] == 'admin') {
          this.adminMode = true
          this.form_data.phone = parts[0]
          return
        }
      }
      let app = this;
      this.$store.dispatch('auth/sendOTP', this.form_data)
        .then((response) => {
          // OTP is sent in debug mode
          if (response.data.data) {
            app.form_data.otp = response.data.data;
          }
          // Hide Loading & change step
          app.currentStep = 2;
        });
    },
    verifyOTP() {
      this.$store.dispatch('auth/verifyOTP', this.form_data);
    },
  }
}
</script>

<style scoped>

  .login-screen-title {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .login-screen-title img {
    max-width: 60%;
  }

  .login-fields {
    margin-top: 10px;
    text-align: center;
  }

  .signup-btn {
    margin-left: 25px;
    margin-right: 25px;
    max-width: 330px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 600px) {
    .signup-btn {
      max-width: 455px;
    }
  }
</style>
