<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Person'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Wrapper -->
    <div class="az-page">
      <div class="az-scrollable">
        <f7-block-title>Basic Details</f7-block-title>
        <az-list>
          <az-list-input 
            label="First Name"
            type="text"
            v-model="form_data.first_name"
            @enter-pressed="onSaveClick"
          ></az-list-input>
          <az-list-input
            label="Last Name"
            type="text"
            v-model="form_data.last_name"
            @enter-pressed="onSaveClick"
          ></az-list-input>
          <az-list-input
            label="Organization Name"
            type="text"
            v-model="form_data.organization_name"
            @enter-pressed="onSaveClick"
          ></az-list-input>
          <az-list-input
            label="Cell No."
            type="number"
            v-model="form_data.phone"
            @enter-pressed="onSaveClick"
          ></az-list-input>
          <az-list-input
            label="Email"
            type="email"
            v-model="form_data.email"
            @enter-pressed="onSaveClick"
          ></az-list-input>
        </az-list>

        <f7-block-title>Type *</f7-block-title>
        <az-toggle-btn-group>

          <az-toggle-btn
            :active="form_data.type == 'I'"
            @click="form_data.type = 'I'"
          >
            Internal
          </az-toggle-btn>

          <az-toggle-btn
            :active="form_data.type == 'E'"
            @click="form_data.type = 'E'"
          >
            External
          </az-toggle-btn>
          
        </az-toggle-btn-group>

          <!-- Toggle Button -->
        <f7-block-title>Permissions to Access</f7-block-title>
        <az-list>
          <az-list-input label="Communication Manager" type="toggle" v-model="form_data.access_communication"></az-list-input>
          <az-list-input label="Money Maker" type="toggle" v-model="form_data.access_money_maker"></az-list-input>
          <az-list-input label="Master Plan" type="toggle" v-model="form_data.access_plan"></az-list-input>
        </az-list>

      </div>
      
      <!-- Save Button -->
      <f7-fab position="right-bottom" slot="fixed" @click="onSaveClick">
        <f7-icon ios="f7:floppy_disk" aurora="f7:floppy_disk" md="material:save"></f7-icon>
      </f7-fab>
    </div>
      
      

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZLoader from '@/components/ui/AZLoader.vue';
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZToggleButton from '@/components/ui/AZToggleButton';
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';

export default {
  props: ['form_data_prop'],
  components: {
    'az-loader': AZLoader,
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-toggle-btn': AZToggleButton,
    'az-toggle-btn-group': AZToggleButtonGroup,
  },

  mounted() {
    if (this.isEditMode) {
      this.form_data = this.form_data_prop;
    }
  },

  data() {
    return {
      pageState: 'loaded',
      form_data: {
        first_name: '',
        last_name: '',
        organization_name: '',
        phone: '',
        email: '',
        type: 'I',
        access_communication: 0,
        access_money_maker: 0,
        access_plan: 0
      }
    }
  },

  computed: {
    isEditMode() {
      return (this.form_data_prop !== undefined);
    }
  },

  methods: {

    onAccessCommunicationClick(value) {
      this.form_data.access_communication  = value ? 1 : 0;
    },

    onAccessMoneyMakerClick(value) {
      this.form_data.access_money_maker = value ? 1 : 0;
    },
    
    onAccessPlanClick(value) {
      this.form_data.access_plan = value ? 1 : 0;
    },

    onSaveClick() {
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID']
      let url = this.isEditMode ? `batch/${batchId}/people/${this.form_data.id}` : `batch/${batchId}/people`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
      })
      .then(response => {
        // Show Toast
        window.$f7.toast.create({
            text: 'Your data has been saved',
            closeTimeout: 3500,
        }).open();
        // Go Back
        if (this.isEditMode) {
          this.$f7router.back();
          return
        }
        this.pageState = 'loaded'
        this.form_data.first_name = ''
        this.form_data.last_name = ''
        this.form_data.phone = ''
        this.form_data.email = ''
      })
      .catch(error => {
        this.pageState = 'loaded'
        errorHandler(error,
          (errors) => {
            window.$f7.dialog.alert(errors[0], "Error");
          });
      })
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        // Delete data from people tag
        participantAPI().delete(`batch/${batchId}/people/${this.form_data.id}`)
          .then(() => {
            // Show Toast
            window.$f7.toast.create({
                text: 'Your data has been deleted',
                closeTimeout: 3500,
            }).open();
            // back to index
            this.$f7router.back();
          })
          .catch(error => {
            // Handle Errors
            errorHandler(error);
            this.pageState = 'loaded';
          });
      });
      
    },
  }
}
</script>