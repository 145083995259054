import SplashPage from '../pages/SplashPage.vue';
import LoginPage from '../pages/LoginPage.vue';
import ProfilePage from '../pages/Profile/Index.vue';
import BasicProfile from '../pages/Profile/Basic.vue';
import PersonalProfile from '../pages/Profile/Personal.vue';
import BusinessProfile from '../pages/Profile/Business.vue';
import CommunicationProfile from '../pages/Profile/Communication.vue';
import SelectPictures from '../pages/Profile/EditPictures.vue';
import SelectLogos from '../pages/Profile/EditLogos.vue';
import BusinessAttachments from '../pages/Profile/BusinessAttachments.vue';
import HomePage from '../pages/HomePage.vue';
import BatchSelect from '../pages/BatchSelect.vue';
import GoogleLogin from '../pages/GoogleLogin.vue';

// 1.X
import ParticipantInformation from '../pages/ParticipantInformation.vue';

// 2.X
import KeyHandouts from '../pages/KeyHandouts.vue';

// 6.X
import ImplementFrameworkShow from '../pages/WhyImplementFramework/ShowPage.vue'
import ImplementFrameworkCreate from '../pages/WhyImplementFramework/CreatePage.vue'
import TopThingsCreate from '../pages/TopThingsPaid/TopThings/CreatePage.vue';
import TopThingsPaidIndex from '../pages/TopThingsPaid/IndexPage.vue';
import TopThingsEntriesCreate from '../pages/TopThingsPaid/Entries/CreatePage.vue';
import TopThingShow from '../pages/TopThingsPaid/TopThings/ShowPage.vue';

// 3.X
import TimeAuditorEntriesIndex from '../pages/TimeAuditor/Entries/IndexPage';
import TimeAuditorEntriesCreate from '../pages/TimeAuditor/Entries/CreatePage';
import TimeAuditorAnalyze from '../pages/TimeAuditor/Entries/AnalyzePage';
import GameCreatorIndex from '../pages/GameCreator/IndexPage.vue';
import GameCreatorCreate from '../pages/GameCreator/CreatePage.vue';
import GameCreatorShow from '../pages/GameCreator/ShowPage.vue';
import TimeAuditorIndex from '../pages/TimeAuditor/TabsIndex.vue';
import TimeAuditorCreate from '../pages/TimeAuditor/CreatePage.vue';
import TimeAuditorActivity from '../pages/TimeAuditor/ActivityPage.vue';
import AccomplishmentBlueprintIndex from '../pages/AccomplishmentBlueprint/IndexPage.vue';
import AccomplishmentBlueprintMasterCreate from '../pages/AccomplishmentBlueprint/Master/CreatePage.vue';
import AccomplishmentBlueprintClarityCreate from '../pages/AccomplishmentBlueprint/ClarityMissing/CreatePage.vue';
import AccomplishmentBlueprintBreakdownCreate from '../pages/AccomplishmentBlueprint/BreakdownsObstacles/CreatePage.vue';

import ReasonsEliminatorIndex from '../pages/ReasonsEliminator/TabsIndex.vue';
import ReasonsEliminatorCreate from '../pages/ReasonsEliminator/CreatePage.vue';
import ReasonsEliminatorAnalyze from '../pages/ReasonsEliminator/AnalyzePage.vue';
import TimeFinderIndex from '../pages/TimeFinder/TabsIndex.vue';
import TimeFinderRoutineEdit from '../pages/TimeFinder/EditPage.vue';
import TimeFinderCreate from '../pages/TimeFinder/CreatePage.vue';
import TimeFinderShow from '../pages/TimeFinder/ShowPage.vue';
import TimeFinderRoutineIndex from '../pages/TimeFinder/Routine/IndexPage.vue';
import TimeFinderRoutineCreate from '../pages/TimeFinder/Routine/CreatePage.vue';
import TimeFinderRoutineTimeSpend from '../pages/TimeFinder/Routine/TimeTakenPage.vue';
import TimeFinderRoutineTimeSave from '../pages/TimeFinder/Routine/TimeSaverPage.vue';
import TimeFinderAnalyze from '../pages/TimeFinder/Routine/AnalyzePage';
import MoneyMakerIndex from '../pages/MoneyMaker/IndexPage.vue';
import MoneyMakerCreate from '../pages/MoneyMaker/CreatePage.vue';
import PeopleIndex from '../pages/People/IndexPage.vue'
import PeopleCreate from '../pages/People/CreatePage.vue'
import ProjectsIndex from '../pages/Projects/IndexPage.vue'
import ProjectsCreate from '../pages/Projects/CreatePage.vue'
import MomentIndexPage from '../pages/Moment/IndexPage.vue'
import MomentCreatePage from '../pages/Moment/CreatePage.vue'

// 4.X
import PerformanceBuilderIndex from '../pages/PerformanceBuilder/IndexPage.vue';
import PerformanceBuilderCreate from '../pages/PerformanceBuilder/CreatePage.vue';
import PerformanceBuilderShow from '../pages/PerformanceBuilder/ShowPage.vue';
import PerformanceBuilderEdit from '../pages/PerformanceBuilder/EditPage.vue';
import FrameworkMasteryIndex from '../pages/FrameworkMastery/IndexPage.vue';
import FrameworkMasteryCreate from '../pages/FrameworkMastery/CreatePage.vue';
import PowerPlannerIndex from '../pages/PowerPlanner/IndexPage.vue';

import CommunicationManagerIndex from '../pages/CommunicationManager/IndexPage.vue';
import CommuncationManagerCreate from '../pages/CommunicationManager/CreatePage.vue';

import MeetingSuccessMaximizerIndex from '../pages/MeetingSuccessMaximizer/IndexPage.vue';
import MeetingSuccessMaximizerCreate from '../pages/MeetingSuccessMaximizer/CreatePage.vue';
import MeetingSuccessMaximizerShow from '../pages/MeetingSuccessMaximizer/ShowPage.vue';
import HabitCreate from '../pages/HabitsEnhancer/Habits/CreatePage.vue';
import HabitShow from '../pages/HabitsEnhancer/Habits/ShowPage.vue';

import HabitsEnhancerIndex from '../pages/HabitsEnhancer/IndexPage.vue';
import HabitEnhancerCreate from '../pages/HabitsEnhancer/Entries/CreatePage.vue';

// 5.X
import AccomplishmentsDiaryIndex from '../pages/AccomplishmentsDiary/IndexPage.vue';
import AccomplishmentsDiaryCreate from '../pages/AccomplishmentsDiary/CreatePage.vue';
import HabitChangeJournalIndex from '../pages/HabitChangeJournal/IndexPage.vue';
import HabitChangeJournalCreate from '../pages/HabitChangeJournal/CreatePage.vue';
import InsightsLogIndex from '../pages/InsightsLog/IndexPage.vue';
import InsightsLogCreate from '../pages/InsightsLog/CreatePage.vue';
import TimeSaversIndex from '../pages/TimeSavers/IndexPage.vue';
import TimeSaversCreate from '../pages/TimeSavers/CreatePage.vue';

// Other
import AboutPage from '../pages/AboutPage.vue';
import FormPage from '../pages/FormPage.vue';
import DynamicRoutePage from '../pages/DynamicRoutePage.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';

var routes = [
  {
    path: '/',
    component: SplashPage,
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/profile/',
    component: ProfilePage,
  },
  {
    path: '/profile/pictures/select/',
    component: SelectPictures
  },
  {
    path: '/profile/logos/select/',
    component: SelectLogos
  },
  {
    path: '/profile/business-attachments/',
    component: BusinessAttachments
  },
  {
    path: '/profile/basic/',
    component: BasicProfile
  },
  {
    path: '/profile/personal/',
    component: PersonalProfile
  },
  {
    path: '/profile/business/',
    component: BusinessProfile
  },
  {
    path: '/profile/communication/',
    component: CommunicationProfile
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/batch',
    component: BatchSelect
  },
  {
    path: '/google-login/',
    component: GoogleLogin
  },
  // 1.X
  {
    path: '/participant-information',
    component: ParticipantInformation
  },

  // 2.X
  {
    path: '/key-handouts',
    component: KeyHandouts
  },

  // 3.X
  {
    path: '/time-auditor/entries/',
    component: TimeAuditorEntriesIndex
  },
  {
    path: '/time-auditor/entries/create',
    component: TimeAuditorEntriesCreate
  },
  {
    path: '/time-auditor/analyze',
    component: TimeAuditorAnalyze
  },
  {
    path: '/game-creator/',
    component: GameCreatorIndex,
  },
  {
    path: '/game-creator/create/',
    component: GameCreatorCreate,
  },
  {
    path: '/game-creator/show/',
    component: GameCreatorShow,
  },
  {
    path: '/time-auditor/',
    component: TimeAuditorIndex,
  },
  {
    path: '/time-auditor/create/',
    component: TimeAuditorCreate,
  },
  {
    path: '/time-auditor/activity/',
    component: TimeAuditorActivity,
  },
  {
    path: '/accomplishment-blueprint/',
    component: AccomplishmentBlueprintIndex,
  },
  {
    path: '/event/edit/:mode/',
    component: AccomplishmentBlueprintMasterCreate,
  },
  {
    path: '/event/create/:mode/',
    component: AccomplishmentBlueprintMasterCreate,
  },
  
  {
    path: '/accomplishment-blueprint/clarity/create/',
    component: AccomplishmentBlueprintClarityCreate,
  },
  {
    path: '/accomplishment-blueprint/clarity/:id/edit/',
    component: AccomplishmentBlueprintClarityCreate,
  },
  {
    path: '/accomplishment-blueprint/breakdown-obstacles/create/',
    component: AccomplishmentBlueprintBreakdownCreate,
  },
  {
    path: '/accomplishment-blueprint/breakdown-obstacles/:id/edit/',
    component: AccomplishmentBlueprintBreakdownCreate,
  },
  {
    path: '/reasons-eliminator/',
    component: ReasonsEliminatorIndex,
  },
  {
    path: '/reasons-eliminator/create/',
    component: ReasonsEliminatorCreate,
  },
  {
    path: '/reasons-eliminator/:id/edit/',
    component: ReasonsEliminatorCreate,
  },
  {
    path: '/reasons-eliminator/analyze/',
    component: ReasonsEliminatorAnalyze,
  },
  {
    path: '/time-finder/',
    component: TimeFinderIndex,
  },
  {
    path: '/time-finder/create/',
    component: TimeFinderCreate,
  },
  {
    path: '/time-finder/analyze',
    component: TimeFinderAnalyze
  },
  {
    path: '/time-finder/:id/',
    component: TimeFinderShow,
  },
  {
    path: '/time-finder/:id/routines/',
    component: TimeFinderRoutineIndex,
  },
  {
    path: '/time-finder/:time_id/routines/create/',
    component: TimeFinderRoutineCreate,
  },
  {
    path: '/time-finder/:time_id/routines/edit',
    component: TimeFinderRoutineEdit
  },
  {
    path: '/time-finder/:time_id/routines/:routine_id/edit/',
    component: TimeFinderRoutineCreate,
  },
  {
    path: '/time-finder/:time_id/routines/:routine_id/spend/',
    component: TimeFinderRoutineTimeSpend,
  },
  {
    path: '/time-finder/:time_id/routines/:routine_id/save/',
    component: TimeFinderRoutineTimeSave,
  },
  {
    path: '/money-maker/',
    component: MoneyMakerIndex,
  },
  {
    path: '/money-maker/create/',
    component: MoneyMakerCreate,
  },
  {
    path: '/money-maker/:id/edit/',
    component: MoneyMakerCreate,
  },
  {
    path: '/people/',
    component: PeopleIndex,
  },
  {
    path: '/people/create/',
    component: PeopleCreate,
  },
  {
    path: '/project/',
    component: ProjectsIndex,
  },
  {
    path: '/project/create/',
    component: ProjectsCreate,
  },
  {
    path: '/moments/',
    component: MomentIndexPage
  },
  {
    path: '/moments/create',
    component: MomentCreatePage
  },


  // 4.X
  {
    path: '/performance-builder/',
    component: PerformanceBuilderIndex,
  },
  {
    path: '/performance-builder/create/',
    component: PerformanceBuilderCreate,
  },
  {
    path: '/performance-builder/show/',
    component: PerformanceBuilderShow
  },
  {
    path: '/performance-builder/edit/',
    component: PerformanceBuilderEdit
  },
  {
    path: '/framework-mastery/',
    component: FrameworkMasteryIndex,
  },
  {
    path: '/framework-mastery/create/',
    component: FrameworkMasteryCreate,
  },
  {
    path: '/power-planner/',
    component: PowerPlannerIndex,
  },
  
  {
    path: '/communication-manager/',
    component: CommunicationManagerIndex
  },
  {
    path: '/communication-manager/create/',
    component: CommuncationManagerCreate
  },

  {
    path: '/meeting-success-maximizer/',
    component: MeetingSuccessMaximizerIndex,
  },
  {
    path: '/meeting-success-maximizer/create/',
    component: MeetingSuccessMaximizerCreate,
  },
  {
    path: '/meeting-success-maximizer/show/',
    component: MeetingSuccessMaximizerShow,
  },
  {
    path: '/habits-enhancer/',
    component: HabitsEnhancerIndex,
  },
  {
    path: '/habits-enhancer/habits/create/',
    component: HabitCreate,
  },
  {
    path: '/habits-enhancer/habits/:id',
    component: HabitShow
  },
  {
    path: '/habits-enhancer/entries/create/',
    component: HabitEnhancerCreate,
  },
  
  
  // 5.X
  {
    path: '/accomplishments-diary/',
    component: AccomplishmentsDiaryIndex,
  },
  {
    path: '/accomplishments-diary/create/',
    component: AccomplishmentsDiaryCreate,
  },
  {
    path: '/habit-change-journal/',
    component: HabitChangeJournalIndex,
  },
  {
    path: '/habit-change-journal/create/',
    component: HabitChangeJournalCreate,
  },
  {
    path: '/insights-log/',
    component: InsightsLogIndex,
  },
  {
    path: '/insights-log/create/',
    component: InsightsLogCreate,
  },
  {
    path: '/time-savers/',
    component: TimeSaversIndex,
  },
  {
    path: '/time-savers/create/',
    component: TimeSaversCreate,
  },

  // 6.X
  {
    path: '/framework/',
    component: ImplementFrameworkShow
  },
  {
    path: '/framework/create',
    component: ImplementFrameworkCreate
  },
  {
    path: '/top-things-paid/',
    component: TopThingsPaidIndex,
  },
  {
    path: '/top-things-paid/top-things/create/',
    component: TopThingsCreate,
  },
  {
    path: '/top-things-paid/top-things/:id',
    component: TopThingShow
  },
  {
    path: '/top-things-paid/entries/create/',
    component: TopThingsEntriesCreate,
  },
  

  // Other
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
