<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Habit'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Wrapper -->
    <div class="wrapper" v-if="pageState == 'loaded'">
      <az-list>
        
        <az-list-input label="Name" type="text" v-model="form_data.name" @enter-pressed="onSaveClick" />
        
        <az-list-input label="Type" type="select" v-model="form_data.type" @enter-pressed="onSaveClick" >
          <option v-for="(type, key) in types" 
            :key="key"
            :value="key">
            {{ type }}
          </option>
          <option value="X">Other</option>
        </az-list-input>

      </az-list>
    </div>

    <!-- Save Button -->
    <f7-fab position="right-bottom"
            @click="onSaveClick">
      <f7-icon ios="f7:floppy_disk" 
               aurora="f7:floppy_disk"
               md="material:save" />
    </f7-fab>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import moment from 'moment';

import { GameTypes } from '@/js/consts';
import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader.vue';
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';

export default {
  
  props: ['form_data_prop'],

  components: {
    'az-loader': AZLoader,
    'az-list': AZList,
    'az-list-input': AZListInput
  },
  
  mounted() {
    if (this.isEditMode) {
      this.form_data = this.form_data_prop;
    }
  },
  
  data() {
    return {
      pageState: 'loaded',
      types: GameTypes,
      form_data: {
        name: '',
        type: 'X',
      }
    }
  },

  computed: {
    isEditMode() {
      return (this.form_data_prop !== undefined);
    }
  },

  methods: {

    onSaveClick() {
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID']
      let url = this.isEditMode ? `batch/${batchId}/habit/${this.form_data.id}` : `batch/${batchId}/habit`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
      }).then((response) => {
        // Show Toast
        window.$f7.toast.create({
            text: 'Your data has been saved',
            closeTimeout: 3500,
        }).open();
        // Go Back
        if (this.isEditMode) {
          this.$f7router.back();
          return
        }
        this.form_data = {
          name: '',
          type: 'X'
        }
        this.pageState = 'loaded'
      })
      .catch((error) => {
        errorHandler(error,
          (errors) => {
            window.$f7.dialog.alert(errors[0], "Error");
          });
      })
      .finally(() => {
        this.pageState = 'loaded';
      });
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/habit/${this.form_data.id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Go Back
          this.$f7router.back();
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
      
    }
  }
}
</script>