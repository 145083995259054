<template>
  <div class="timeline-item">
    <div class="timeline-item-date" v-html="date"></div>
    <div class="timeline-item-divider"></div>
    <div class="timeline-item-content" @click="onItemClick">
      <div class="timeline-item-inner">
        <div class="timeline-item-time" v-if="time" v-html="time"></div>
        <div class="timeline-item-title" v-if="title" v-html="title"></div>
        <div class="timeline-item-subtitle" v-if="subtitle" v-html="subtitle"></div>
        <div class="timeline-item-text" v-if="text" v-html="text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'az-timeline-item',
  props: ['date', 'time', 'title', 'subtitle', 'text'],
  methods: {
    onItemClick() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
  .timeline-item-content{
    cursor: pointer;
  }
</style>