<template>
  <div class="az-tab-page">

    <!-- List -->
    <div
      v-if="pageState == 'loaded'"
      class="az-tab-scrollable"
    >
    
      <!-- Data List -->
      <f7-list 
        v-if="entries.length > 0"
        class="fab-wrapper"
      >
        <f7-list-item
          v-for="entry in entries"
          :key="entry.id"
          :title="entry.date"
          link="#"
          @click="onEntryClick(entry)"
        >
          <f7-icon 
            slot="media"
            color="primary"
            ios="f7:calendar_fill"
            aurora="f7:calendar_fill"
            md="material:today"
          ></f7-icon>
          
        </f7-list-item>

      </f7-list>

      <!-- No Data Message -->
      <az-page-message
        v-else
      ></az-page-message>
    
    </div>

    <!-- Loading Screen -->
    <az-loader
      v-if="pageState == 'loading'"
    ></az-loader>

    <!-- Error Message -->
    <az-page-error-message
      v-if="pageState == 'failed'"
      @try-again="refreshData"
    ></az-page-error-message>

    <!-- Add Button -->
    <f7-fab v-if="fabBtnVisible" position="right-bottom" href="/habits-enhancer/entries/create/">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

  </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'

import AZLoader from '@/components/ui/AZLoader'
import AZPageMessage from '@/components/ui/AZPageMessage'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage'

export default {

  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
  },

  data () {
    return {
      pageState: 'loading',
      entries: [],
      fabBtnVisible: false
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {

    loadDataFromAPI () {
      let batchId = this.$store.getters['auth/getCurrentBatchID']
      participantAPI().get(`batch/${batchId}/habit-enhancer`)
        .then((response) => {
          this.pageState = 'loaded'
          this.entries = response.data.data
        }).catch((error) => {
            errorHandler(error)
            this.pageState = 'failed'
        });
    },

    refreshData () {
      this.entries = []
      this.pageState = 'loading'
      this.loadDataFromAPI()
    },

    onEntryClick (item) {
      this.$f7router.navigate('/habits-enhancer/entries/create/', {
        props: {
          id: item.id
        }  
      })
    },

  }
}
</script>