// Import Libs
import Vue from 'vue';
import Vuex from 'vuex';

// Import Modules
import auth from './modules/auth';
import ui from './modules/ui';
import blueprint from './modules/blueprint';
import googleAuth from './modules/googleAuth';
import googleCalendar from './modules/googleCalendar';

// Init Vuex
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        ui,
        auth,
        blueprint,
        googleAuth,
        googleCalendar
    }
});