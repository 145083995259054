<template>
  <div class="az-tab-page">

    <!-- List -->
    <div
      v-if="pageState == 'loaded'"
      class="az-tab-scrollable"
    >
    
      <!-- Data List -->
      <f7-list 
        v-if="habits.length > 0"
        class="fab-wrapper"
      >
        <f7-list-item
          v-for="habit in habits"
          :key="habit.id"
          :title="habit.name"
          link="#"
          @click="onHabitClick(habit)"
        >
          <f7-icon 
            slot="media"
            color="primary"
            :ios="typeIcons[habit.type].ios"
            :aurora="typeIcons[habit.type].ios"
            :md="typeIcons[habit.type].md"
          ></f7-icon>
          
        </f7-list-item>

      </f7-list>

      <!-- No Data Message -->
      <az-page-message
        v-else
      ></az-page-message>
    
    </div>

    <!-- Loading Screen -->
    <az-loader
      v-if="pageState == 'loading'"
    ></az-loader>

    <!-- Error Message -->
    <az-page-error-message
      v-if="pageState == 'failed'"
      @try-again="refreshData"
    ></az-page-error-message>

    <!-- Add Button -->
    <f7-fab position="right-bottom" href="/habits-enhancer/habits/create/">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

  </div>
</template>

<script>
import { GameTypeIcons } from '@/js/consts'
import { participantAPI, errorHandler } from '@/js/api'

import AZLoader from '@/components/ui/AZLoader'
import AZPageMessage from '@/components/ui/AZPageMessage'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage'

export default {

  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
  },

  data () {
    return {
      pageState: 'loading',
      typeIcons: GameTypeIcons,
      habits: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {

    loadDataFromAPI () {
      let batchId = this.$store.getters['auth/getCurrentBatchID']
      participantAPI().get(`batch/${batchId}/habit`)
        .then((response) => {
          this.pageState = 'loaded'
          this.habits = response.data.data
          this.$emit('dataUpdate', this.habits.length)
        }).catch((error) => {
            errorHandler(error)
            this.pageState = 'failed'
        });
    },

    refreshData () {
      this.habits = []
      this.pageState = 'loading'
      this.loadDataFromAPI()
    },

    onHabitClick (item) {
      this.$f7router.navigate(`/habits-enhancer/habits/${item.id}/`,{
        props: {
          form_data_prop: item
        }  
      })
    },

  }
}
</script>