<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar back-link="Back">

          <!-- Page Title -->
          <f7-nav-title>
                Basic
          </f7-nav-title>

    </f7-navbar>

    <div v-if="pageState == 'loaded'">

      <az-list class="fab-wrapper">
        <az-list-input label="First Name *" v-model="form_data.first_name"></az-list-input>

        <az-list-input label="Middle Name" v-model="form_data.middle_name"></az-list-input>

        <az-list-input label="Last Name *" v-model="form_data.last_name"></az-list-input>

        <az-list-input label="Preferred Name *" v-model="form_data.preferred_name"></az-list-input>

        <az-list-input label="Gender *" type="select" v-model="form_data.gender">
          <option
            v-for="(value, key) in genders"
            :key="key"
            :value="key"
          >
          {{ value }}
          </option>
        </az-list-input>

        <az-list-input label="Date of Birth *" type="datepicker" v-model="formattedDate"></az-list-input>

        <az-list-input label="Your Cell No. *" type="number" v-model="form_data.phone"></az-list-input>

        <az-list-input label="Your Alternate Cell No." type="number" v-model="form_data.alternate_phone"></az-list-input>

        <az-list-input label="Work Email *" v-model="form_data.work_email"></az-list-input>
        
        <az-list-input label="Name of Your Organization *" v-model="form_data.organization"></az-list-input>

        <az-list-input label="Your Designation*" v-model="form_data.designation"></az-list-input>

        <az-list-input label="Your Website" v-model="form_data.website"></az-list-input>

        <az-list-input label="Your Office Address *" type="textarea" v-model="form_data.address"></az-list-input>

        <az-list-input label="Industry Type" type="smart-select" selection_type="multiple" :options="industry_types" v-model="form_data.industry_types">
        </az-list-input>
        
        <az-list-input label="Nature of Your Work" v-model="form_data.nature_of_work"></az-list-input>

      </az-list>
    </div>

    <az-loader v-if="pageState == 'loading'"></az-loader>

    <f7-fab class="fixed"
            v-if="pageState == 'loaded'"
            position="right-bottom"
            @click="onSaveClick">
        <f7-icon ios="f7:floppy_disk" 
            aurora="f7:floppy_disk"
            md="material:save" />
    </f7-fab>

  </f7-page>
</template>

<script>
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import { participantAPI, errorHandler, makeFormData } from '@/js/api'
import logo from '@/assets/logo.png'
import moment from 'moment'
import { IndustryTypes, Genders } from '@/js/consts'

export default {
  components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-loader': AZLoader,
    'az-error-message': AZPageErrorMessage
  },

  props: {
    form_data_prop: Object
  },

  data() {
    return {
      pageState: 'loaded',
      logo: logo,
      industry_types: Object.entries(IndustryTypes).map(([key, value]) => {
        return {
          key: key,
          value: value
        }
      }),
      genders: Genders,
      form_data: {
        first_name: '',
        middle_name: '',
        last_name: '',
        preferred_name: '',
        work_email: '',
        phone: '',
        alternate_phone: '',
        organization: '',
        designation: '',
        website: '',
        address: '',
        industry_types: [],
        nature_of_work: '',
      }
    }
  },

  computed: {
    formattedDate: {
      get() {
        if (this.form_data.birth_date) {
          let parts = this.form_data.birth_date.split('-');
          if (parts.length >= 3) {
            let day = parts[0];
            let month = parts[1] - 1;
            let year = parts[2];
            return [new Date(year, month, day).toString()];
          }
          return []
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.birth_date = dateValue.format('DD-MM-YYYY');
      }
    },
  },

  mounted() {
    this.form_data = this.form_data_prop
  },

  methods: {

    onSaveClick() {
      let data = {
        _method: 'PUT',
        ...this.form_data
      }
      let formData = makeFormData(data)
      // return
      this.pageState = 'loading'
      participantAPI().post(
        '/basic-profile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        this.$f7router.back()
      })
      .catch(error => {
        this.pageState = 'loaded'
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },
  }
}
</script>

<style scoped>
  .profile-img img{
    max-width: 50%;
    height: auto;
    border-radius: 50%;
    display: block;
    margin: 30px auto 0 auto;
    /* padding-top: 30px; */
  }

  @media only screen and (min-width: 992px){
    .profile-img img{
      max-width: 20%;
    }
  }
</style>