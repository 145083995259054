<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar back-link="Back">
        <!-- Page Title -->
        <f7-nav-title>
            Business Attachments
        </f7-nav-title>
    </f7-navbar>

    <div class="wrapper">

      
      <f7-block-title>Brochures</f7-block-title>
      <div class="pic-section" v-if="brochures.length > 0">
        <div class="parent">
          <div class="child"
            v-for="brochure in brochures"
            :key="brochure.id"
          >
            <div class="doc" @click.self="openDoc(brochure.url)">
                <div class="cross" @click="removeBrochure(brochure.id)">
                    <f7-icon 
                        md="material:close"
                        ios="f7:multiply"
                        aurora="f7:multiply"
                    ></f7-icon>
                </div>
                <f7-icon
                    @click.native="openDoc(brochure.url)"
                    class="file-icon"
                    md="material:insert_drive_file"
                    ios="f7:doc"
                    aurora="f7:doc"
                ></f7-icon>
            </div>
            <p style="word-break: break-all">{{ brochure.original_name }}</p>
          </div>
        </div>
      </div>
      <div v-else class="no-pic">
        <p>No Brochures</p>
      </div>
      <f7-button v-if="pageState !== 'failed'" class="add-btn" raised fill @click="addBrochures">Add Brochures</f7-button>

    
      <f7-block-title>Case Studies</f7-block-title>
      <div class="pdf-section" v-if="case_studies.length > 0">
        <div class="parent">
          <div class="child"
            v-for="case_study in case_studies"
            :key="case_study.id"
          >
            <div class="doc" @click.self="openDoc(case_study.url)">
                <div class="cross" @click="removeCaseStudy(case_study.id)">
                    <f7-icon 
                        md="material:close"
                        ios="f7:multiply"
                        aurora="f7:multiply"
                    ></f7-icon>
                </div>
                <f7-icon
                    @click.native="openDoc(case_study.url)"
                    class="file-icon"
                    md="material:insert_drive_file"
                    ios="f7:doc"
                    aurora="f7:doc"
                ></f7-icon>
            </div>
            <p style="word-break: break-all">{{ case_study.original_name }}</p>
          </div>
        </div>
      </div>
      <div v-else class="no-pic">
        <p>No Case Studies</p>
      </div>
      <f7-button v-if="pageState !== 'failed'" class="add-btn" raised fill @click="addCaseStudies">Add Case Studies</f7-button>

      <div class="no-pics" v-else>
          <h4>No attachments to show... yet</h4>
      </div>

      <az-loader v-if="pageState === 'loading'"></az-loader>

      <az-page-error-message v-if="pageState === 'failed'" @try-again="loadDataFromAPI"></az-page-error-message>
    </div>
  </f7-page>

</template>

<script>
import { participantAPI, errorHandler, makeFormData, BASEURL } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import axios from 'axios';

export default {

    components: {
      'az-loader': AZLoader,
      'az-page-error-message': AZPageErrorMessage
    },

    mounted() {
     this.loadDataFromAPI()
    },

    data() {
      return {
        pageState: 'loading',
        brochures: [],
        case_studies: []
      }
    },
    
    methods: {
      loadDataFromAPI() {
          this.pageState = 'loading'
          let apis = [
            participantAPI().get('/case-studies'),
            participantAPI().get('/brochures')
          ]

          axios.all(apis)
          .then(response => {
            console.log(response)
            this.case_studies = response[0].data.data.map(study => {
                study.url = BASEURL + '/' + study.path
                return study
            })

            this.brochures = response[1].data.data.map(brochure => {
                brochure.url = BASEURL + '/' + brochure.path
                return brochure
            })
            this.pageState = 'loaded'
          })
          .catch(err => {
              this.pageState = 'failed'
          })
      },

      openDoc(path) {
          window.open(path)
      },

      addBrochures() {
          var input = document.createElement('input')
          input.type = 'file'
          input.multiple = true
          input.click()
          input.onchange = e => {
              this.pageState = 'loading'
              let fileList = e.target.files
              let files = [...Array(fileList.length)].map(x => null)
              for (let i = 0; i < fileList.length; i++) {
                files[i] = fileList[i]
              }
              let data = {
                  _method: 'POST',
                  files
              }
              let formData = makeFormData(data)

              participantAPI().post(
                  '/brochures',
                  formData,
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data'
                      }
                  }
              )
              .then(res => {
                  // Show Toast
                  window.$f7.toast.create({
                      text: 'Brochures have been added successfully',
                      closeTimeout: 3500,
                  }).open();
                  this.loadDataFromAPI()
              })
              .catch(err => {
                  this.pageState = 'loaded'
                  errorHandler(err, (error) => {
                      if (Array.isArray(error)) {
                          error = error[0]
                      }
                      this.$f7.dialog.alert(error)
                  })
              })
          }
      },

      addCaseStudies() {
          var input = document.createElement('input')
          input.type = 'file'
          input.multiple = true
          input.click()
          input.onchange = e => {
              this.pageState = 'loading'
              let fileList = e.target.files
              let files = [...Array(fileList.length)].map(x => null)
              for (let i = 0; i < fileList.length; i++) {
                  files[i] = fileList[i]
              }
              let data = {
                  _method: 'POST',
                  files
              }
              let formData = makeFormData(data)

              participantAPI().post(
                  '/case-studies',
                  formData,
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data'
                      }
                  }
              )
              .then(res => {
                  // Show Toast
                  window.$f7.toast.create({
                      text: 'Case studies have been added successfully',
                      closeTimeout: 3500,
                  }).open();
                  this.loadDataFromAPI()
              })
              .catch(err => {
                  this.pageState = 'loaded'
                  errorHandler(err, (error) => {
                      if (Array.isArray(error)) {
                          error = error[0]
                      }
                      this.$f7.dialog.alert(error)
                  })
              })
          }
      },

      removeBrochure(id) {
          this.pageState = 'loading'
          participantAPI().delete(`/brochure/${id}`)
          .then(() => {
              // Show Toast
              window.$f7.toast.create({
                  text: 'Brochure has been removed',
                  closeTimeout: 3500,
              }).open();
              this.loadDataFromAPI()
          })
          .catch(err => {
              this.pageState = 'loaded'
              errorHandler(err, (error) => {
                  if (Array.isArray(error)) {
                      error = error[0]
                  }
                  this.$f7.dialog.alert(error)
              })
          })
      },

      removeCaseStudy(id) {
          this.pageState = 'loading'
          participantAPI().delete(`/case-studies/${id}`)
          .then(() => {
              // Show Toast
              window.$f7.toast.create({
                  text: 'Case Study has been removed',
                  closeTimeout: 3500,
              }).open();
              this.loadDataFromAPI()
          })
          .catch(err => {
              this.pageState = 'loaded'
              errorHandler(err, (error) => {
                  if (Array.isArray(error)) {
                      error = error[0]
                  }
                  this.$f7.dialog.alert(error)
              })
          })
      },
    },
}
</script>

<style scoped>
    .wrapper {
        margin: 10px;
        box-sizing: border-box;
    }

    img.demo-lazy {
        width: 100%;
        height: auto;
        display: block;
    }

    .logos p {
        margin-bottom: 20px;
    }

    .parent {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        height: auto;
    }

    .add-btn {
        margin-top: 10px;
    }

    .pic {
        position: relative;
    }

    .cross {
        position: absolute;
        top: -5px;
        right: -5px;
        background: black;
        color: white;
        z-index: 99;
        padding: 0px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 2px;
        justify-content: center;
    }

    .cross i {
        font-size: 1rem;
    }

    .no-pics {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .doc {
        position: relative;
        display: flex;
        width: 100%;
        height: 125px;
        align-items: center;
        justify-content: center;
        border: 1px solid #959595;
    }

    .doc i.file-icon {
        font-size: 60px;
    }

    .block-title {
        margin-left: 0;
    }
</style>