<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Habit Change Journal ©</f7-nav-title>
    </f7-navbar>

    <!-- Timeline -->
    <div class="az-page">
      <div class="az-scrollable">

        <az-timeline v-if="habitChanges.length > 0" class="fab-wrapper">
          <az-timeline-item 
            v-for="(habitChange, index) in calculatedHabitChanges"
            :key="habitChange.id"
            :date="getFancyDate(habitChange.date)"
            :text="getHabitText(habitChange)"
                @click="onEditClick(habitChanges[index])"
                />
        </az-timeline>  

        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>

        <!-- Add Button -->
        <f7-fab position="right-bottom" slot="fixed" @click="onAddClick">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-fab>
      </div>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import moment from 'moment';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZTimeline from '@/components/ui/AZTimeline';
import AZTimelineItem from '@/components/ui/AZTimelineItem';
import { ImplementationStatusNames } from '@/js/consts';

export default {
  components: {
    'az-timeline': AZTimeline,
    'az-timeline-item': AZTimelineItem,
    'az-page-message': AZPageMessage,
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage
  },

  data() {
    return {
      pageState: 'loading',
      habitChanges: [],
      calculatedHabitChanges: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {
    onAddClick() {
      this.$f7router.navigate('/habit-change-journal/create/');
    },

    getFancyDate (date) {
      return moment(date, 'DD-MM-YYYY').format('DD') + " " +
            "<small>" + 
              moment(date, 'DD-MM-YYYY').format('MMM').toUpperCase() + " " + 
              moment(date, 'DD-MM-YYYY').format('YYYY') + 
            "</small>";
    },

    mediaListener(ruleset) {
      let res = JSON.parse(JSON.stringify(this.habitChanges))
      if (ruleset.matches) { // If media query matches
        this.calculatedHabitChanges = res.map(acc => {
          acc.old_habit = this.shortenText(acc.old_habit, 150)
          acc.new_habit = this.shortenText(acc.new_habit, 150)
          return acc
        })
      } else {
        this.calculatedHabitChanges = res.map(acc => {
          acc.old_habit = this.shortenText(acc.old_habit, 500)
          acc.new_habit = this.shortenText(acc.new_habit, 500)
          return acc
        })
      }
    },

    shortenTextRoutine() {
      var ruleset = window.matchMedia("(max-width: 700px)")
      this.mediaListener(ruleset) // Call listener function at run time
      ruleset.addListener(this.mediaListener) // Attach listener function on state changes
    },

    shortenText(text, limit) {
      if (text !== undefined && text !== null && text.length > limit) {
        text = text.substring(0, limit) + '...'
      }
      return text
    },

    getHabitText(habitChange) {
      let text = ''
      if (habitChange.new_habit) {
        text += `<div><b class='text-theme text-heavy'>New:</b> ${habitChange.new_habit}<br>`
      }
      
      if (habitChange.old_habit && habitChange.new_habit) {
        text += '<br>'
      }
      if (habitChange.old_habit) {
        text += `<b class='text-theme text-heavy'>Old:</b> ${habitChange.old_habit} <br></div>`
      }
      
      text += `<div class="impl-btn"> ${ImplementationStatusNames[habitChange.implemented]}</div>`
      return text
    },

    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/habit-change`)
        .then(response => {
          this.habitChanges = response.data.data
          this.pageState = 'loaded'
          this.shortenTextRoutine()
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    refreshData () {
      this.habitChanges = [];
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    onEditClick(habitChange) {
      this.$f7router.navigate(`/habit-change-journal/create/`, {
        props: {
          form_data_prop: habitChange
        }
      })
    },
  }
}
</script>