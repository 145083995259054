<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Obstacle'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Wrapper -->
    <div class="az-page">
      <div class="az-scrollable">

        <az-list>
        
          <az-list-input ref="obstacle" label="Possible Obstacles" type="text" v-model="form_data.obstacle" @enter-pressed="onSaveClick"/>

          <az-list-input label="Possible actions to overcome" type="text" v-model="form_data.action" @enter-pressed="onSaveClick"/>

          <az-list-input label="Who/Where can I get a solution from?" type="text" v-model="form_data.who_where" @enter-pressed="onSaveClick"/>

          <az-list-input label="Resolved" type="select" v-model="form_data.resolved">
            <option value="0">No</option>
            <option value="1">Yes</option>
          </az-list-input>

          <az-list-input type="select" label="Schedule" v-model="form_data.sync">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </az-list-input>
        </az-list>
        
        <f7-block-title v-if="form_data.sync == 1">Scheduling Details</f7-block-title>
        <az-list v-if="form_data.sync == 1" class="fab-wrapper">

          <az-list-input label="Date" type="datepicker" v-model="formattedDate" />

          <az-list-input label="Start Time" type="select" v-model="form_data.start_time">
            <option v-for="(time,key) in all_times" :key="key" :value="key">{{ time }}</option>
          </az-list-input>

          <az-list-input label="End Time" type="select" v-model="form_data.end_time">
            <option v-for="(time,key) in all_times" :key="key" :value="key">{{ time }}</option>
          </az-list-input>

          <az-list-input label="Recurring Event" type="select" v-model="form_data.is_recurring">
            <option value="0">No</option>
            <option value="1">Yes</option>
          </az-list-input>

          <az-list-input v-if="parseInt(form_data.is_recurring) == 1" type="recurrence" :date="form_data.date" v-model="form_data.rrule"></az-list-input>
            
        </az-list>

        <!-- Save Button -->
        <f7-fab position="right-bottom" slot="fixed" @click="onSaveClick">
          <f7-icon ios="f7:floppy_disk" aurora="f7:floppy_disk" md="material:save"></f7-icon>
        </f7-fab> 
      </div>

           
    </div>


    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>


  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader.vue'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import moment from 'moment'
import { all_times } from "@/js/helpers/TimeOptions";

export default {
  props: ['form_data_prop'],
  components: {
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage,
    'az-list': AZList,
    'az-list-input': AZListInput,
  },

  mounted() {
    if (this.isEditMode) {
        this.form_data = this.form_data_prop;
        this.pageState = 'loaded';
    } else {
      this.form_data = this.getDefaultFormData()
    }
  },

  data() {
    return {
      pageState: this.isEditMode ? 'loading' : 'loaded',
      all_times: all_times,
      form_data: {}
    }
  },
  
  methods: {
    getDefaultFormData() {
      return {
        obstacle: '',
        action: '',
        who_where: '',
        resolved: 0,
        sync: 0,
        google_event_id: null,
        date: moment().add(1, 'days').format('DD-MM-YYYY'),
        start_time: '',
        end_time: '',
        is_recurring: 0,
        rrule: 'RRULE:FREQ=DAILY;INTERVAL=1'
      }
    },

    onSaveClick() {
      // Show Loading Screen
      this.pageState = 'loading';

      if (parseInt(this.form_data.sync)) {

        this.validateRequest()
        .then(() => {
          if (!this.isEditMode) {
            return this.$store.dispatch('googleCalendar/addGoogleEvent', this.googleEvent)
          }
          else {
            return this.$store.dispatch('googleCalendar/updateGoogleEvent', {
              event_id: this.form_data.google_event_id, 
              event: this.googleEvent
            })
          }
        })
        .then(res => {
          this.addEventToBackend(res.id)
        })
        .catch(err => {
          errorHandler(err, errors => {
              window.$f7.dialog.alert(errors[0], "Error");
            });
        })
        .then(() => {
          this.pageState = 'loaded'
        })
      }

      else {
        this.validateRequest()
        .then(() => {
          
          if (this.form_data.google_event_id) {
            this.$store.dispatch('googleCalendar/deleteGoogleEvent', this.form_data.google_event_id)
          }
          this.addEventToBackend();
        })
        .catch(err => {
          this.pageState = "loaded";
          errorHandler(err, errors => {
            window.$f7.dialog.alert(errors[0], "Error");
          });
        })
      }
    
    },

    addEventToBackend(google_event_id) {

      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = this.isEditMode ? `batch/${batchId}/breakdown/${this.form_data_prop.id}` : `batch/${batchId}/breakdown`

      this.form_data.parent_id = this.parent_id

      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
        google_event_id: google_event_id
      })
      .then(response => {
        // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          if (this.isEditMode)
            this.$f7router.back();
          else {
            this.pageState = 'loaded'
            // console.log(this.$refs.title.$el)
            this.$refs.obstacle.$el.querySelector('input').focus()
            this.form_data = this.getDefaultFormData()
          }
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    validateRequest() {
      let batchId = this.$store.getters["auth/getCurrentBatchID"];
      return participantAPI().post(
        `batch/${batchId}/breakdown/validate-request`,
        {
          ...this.form_data,
          invalid: this.invalidTimeDiff
        }
      );
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        
        if (this.form_data.google_event_id)
          this.$store.dispatch('googleCalendar/deleteGoogleEvent', this.form_data.google_event_id)
        
        participantAPI().delete(`batch/${batchId}/breakdown/${this.form_data.id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Reload list
          this.$f7router.back()
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    },


    // loadDataForId() {
    //   // Show Loading Screen
    //   this.pageState = 'loading'
    //   // Call API
    //   let batchId = this.$store.getters['auth/getCurrentBatchID'];
    //   participantAPI().get(`batch/${batchId}/breakdown/${this.form_data.id}`)
    //   .then(response => {
    //     this.form_data = response.data.data
    //     this.pageState = 'loaded'
    //   })
    //   .catch(error => {
    //     errorHandler(error)
    //     this.pageState = 'failed'
    //   })
    // },

    onIssueResolvedClick() {
      this.form_data.resolved = 1 - this.form_data.resolved
    }
  },

  computed: {
    isEditMode() {
      return (this.form_data_prop !== undefined);
    },

    formattedDate: {
      get() {
        if (this.form_data.date === null) return null
        let parts = this.form_data.date.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.date = dateValue.format('DD-MM-YYYY');
      }
    },

    computedRrule() {

        if (this.form_data.is_recurring) {
          return [this.form_data.rrule]
        }
        return null
    },

    eventStartTime() {
      return moment(
        this.form_data.date + " " + this.form_data.start_time,
        "DD-MM-YYYY HH:mm"
      ).toDate();
    },

    eventEndTime() {
      return moment(
        this.form_data.date + " " + this.form_data.end_time,
        "DD-MM-YYYY HH:mm"
      ).toDate();
    },

    invalidTimeDiff() {
      return parseInt(this.form_data.sync) && moment(this.eventEndTime).isSameOrBefore(
        moment(this.eventStartTime)
      );
    },

    googleEvent() {
      return {
        summary: this.form_data.obstacle,
        start: {
          dateTime: this.eventStartTime,
          timeZone: "Asia/Kolkata"
        },
        end: {
          dateTime: this.eventEndTime,
          timeZone: "Asia/Kolkata"
        },
        // attendees: this.form_data.selected_emails,
        recurrence: this.computedRrule
      }
    },
  },
}
</script>