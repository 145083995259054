<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar :title="currentTitle" back-link="Back">
      <!-- Delete Button -->
      <f7-link slot="right" @click="onDeleteClick">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Body -->
    <div v-if="pageState == 'loaded'">
      <f7-list media-list>
        <f7-list-item 
            :style="computedStyle(item.category)"
            v-for="(item, index) in entries"
            :key="index"
            link="#"
            :title="item.text" 
            :subtitle="item.entry || 'Unassigned'"
            @click="onItemClick(index)">
        </f7-list-item>
      </f7-list>

      <!-- Analyze Button -->
      <f7-fab v-if="showAnalyzeBtn" style="position: fixed" position="right-bottom" slot="" @click="onAnalyzeClick">
        <f7-icon ios="f7:chart_bar_alt_fill" aurora="f7:chart_bar_alt_fill" md="material:trending_up"></f7-icon>
      </f7-fab>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import { new_time_auditor_options } from '@/js/helpers/TimeOptions'; 
import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import { Categories } from '@/js/consts'
import {arrayRotate} from '@/js/helpers/ArrayOperations' 
import moment from 'moment'

export default {
  name: 'TimeIntervalIndex',
  props: {
    auditor_id: {
      required: true
    }
  },
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
  },

  data() {
    return {
      currentId: 0,
      pageState: 'loading',
      entries: [],
      currentDate: '',
      n: new_time_auditor_options
    }
  },

  mounted() {
    if (!this.auditor_id) {
      setTimeout(() => {
          this.$f7router.back();
      }, 500)
    }
    else 
      this.loadDataFromAPI()  
  },

  methods: {
    loadDataFromAPI() {
      this.pageState = 'loading'
      this.entries = []
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().get(`batch/${batchId}/time-auditor/${this.auditor_id}`)
            .then(res => {
              let time_keys = Object.keys(new_time_auditor_options).sort()
              for (let i = 0; i < 4; i++) {
                arrayRotate(time_keys)
              }
              let data = res.data.data
              this.currentDate = data.date

              time_keys.map(i => {
                let temp = {
                  key: i,
                  text: new_time_auditor_options[i].text,
                  label: new_time_auditor_options[i].label,
                  entry: data['entry_' + i],
                  work_related: data['work_related_' + i],
                  category: data['category_' + i]
                }
                this.entries.push(temp)
              }) 
              this.pageState = 'loaded'
            })
            .catch(error => {
              this.pageState = 'loaded';
                errorHandler(error, 
                    (errors) => {
                    window.$f7.dialog.alert(errors[0], "Error");
                    }
                )
            })
    },

    // Show Icon
    getIcon (flag) {
      switch (flag) {
        case 0:
          return 'material:update'
          break
        case 1:
          return 'material:done'
          break
        case 2:
          return 'material:done_all'
          break
        default:
          return 'material:update'
          break
      }
    },

    // Button Click Events
    
    refreshData () {
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    computedStyle(category) {
      if (!category) category = 'default'
      return Categories[category].style
    },

    onItemClick(index) {
      this.$f7router.navigate(`/time-auditor/entries/create`, {
        props: {
          item_prop: this.entries[index],
          currentIndex: index,
          entries_prop: this.entries,
          auditor_id: this.auditor_id
        }
      });
    },

    onAnalyzeClick() {
      this.$f7router.navigate(`/time-auditor/analyze/`, {
        props: {
          entries_prop: this.entries,
          date: this.getFancyDate(this.currentDate)
        }
      });
    },


    onEditRoutineClick() {
      
      this.$f7router.navigate(`/time-finder/${this.id}/routines/${this.currentId}/edit/`, {
        props: {
          form_data_prop: this.getCurrentItem()
        }
      })
    },

    onDeleteClick(){
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/time-auditor/${this.auditor_id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Reload list
          this.$f7router.back()
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    },

    getFancyDate (date) {
      return moment(date, 'DD-MM-YYYY').format('MMMM') + " " + 
              moment(date, 'DD-MM-YYYY').format('D') + ", " +
              moment(date, 'DD-MM-YYYY').format('YYYY')
    }
  },

  computed: {
    showAnalyzeBtn() {
      return this.entries.filter(entry => entry.category !== null).length > 0
    },

    currentTitle() {
      return this.currentDate.length > 0 ? this.getFancyDate(this.currentDate) : ''
    }
  }
}
</script>

<style scoped>
  
</style>