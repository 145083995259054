<template>
  <transition-group class="transition-group"
                    :enter-active-class="direction == 'F' ? 'fadeInRight' : 'fadeInLeft'"
                    :leave-active-class="direction == 'F' ? 'fadeOutLeft' : 'fadeOutRight'">
    <slot></slot>
  </transition-group>
</template>

<script>
  export default {
    name: 'az-transition-group',
    props: ['direction']
  }
</script>