<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="currentTitle" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Wrapper -->
    <div class="wrapper" v-if="pageState == 'loaded'">
      <az-list>
        <az-list-input label="Name" type="text" v-model="form_data.name" />

        <az-list-input label="Game Type" type="select" v-model="form_data.type">
          <option v-for="(type, key) in types" 
            :key="key"
            :value="key">
            {{ type }}
          </option>
          <option value="X">Other</option>
        </az-list-input>

      </az-list>

      <h5 style="margin-bottom: 10px; margin-left: 20px;">Enter Promised values</h5>
      <az-list style="margin-top: 5px;" v-if="form_data.entries.length >  0">
        <az-list-input 
          v-for="date in form_data.entries"
          :key="date.date"
          :label="date.date"
          type="number"
          v-model="date.promised"
        ></az-list-input>
      </az-list>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Save Button -->
    <f7-fab position="right-bottom"
            @click="onSaveClick">
      <f7-icon ios="f7:floppy_disk" 
               aurora="f7:floppy_disk"
               md="material:save" />
    </f7-fab>

  </f7-page>
</template>

<script>
import AZList from '@/components/ui/AZList.vue';
import AZLoader from '@/components/ui/AZLoader.vue'
import AZListInput from '@/components/ui/AZListInput.vue';
import moment from 'moment'
import { participantAPI, errorHandler } from '@/js/api'
import { GameTypes } from '@/js/consts';

export default {
  props: {
    form_data_prop: Object,
  },
  components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-loader': AZLoader
  },
  data() {
    return {
      types: GameTypes,
      pageState: 'loaded',
      form_data: {
          name: '',
          type: '',
          entries: []
      }
    }
  },

  mounted() {
    this.form_data = this.form_data_prop
  },

  methods: {
    onSaveClick() {
      this.pageState = 'loading'
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let entries = this.form_data.entries.map(entry => {
          entry.date = moment(entry.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
          return entry
      })
      this.form_data.entries = entries
      participantAPI().post(`batch/${batchId}/performance-builder/${this.form_data.id}`, {
        _method: 'PUT',
        ...this.form_data
      })
      .then(res => {
         window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.navigate('/performance-builder/');
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        // Delete data from project tag
        participantAPI().delete(`batch/${batchId}/performance-builder/${this.form_data.id}`)
          .then(() => {
            // Show Toast
            window.$f7.toast.create({
                text: 'Your data has been deleted',
                closeTimeout: 3500,
            }).open();
            // back to index
            this.$f7router.navigate('/performance-builder/');
          })
          .catch(error => {
            // Handle Errors
            errorHandler(error);
            this.pageState = 'loaded';
          });
      });
    }
  },

  computed: {
    currentTitle() {
      return this.form_data ? 'Edit ' + this.form_data.name : ''
    }
  }
}
</script>