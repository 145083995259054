<template>
  <f7-page :page-content="false" @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Time Auditor ©</f7-nav-title>
    </f7-navbar>

    <!-- Body -->
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#stats" tab-link-active>Dashboard</f7-link>
      <f7-link tab-link="#index">Audit Date</f7-link>
    </f7-toolbar>

    <f7-tabs swipeable>
    
      <f7-tab class="page-content" id="stats" tab-active>
        <stats-fragment :pageState="pageState" :stats="stats" ref="statsFragment" @refresh="refreshData"></stats-fragment>
      </f7-tab>

      <f7-tab class="page-content" id="index">
        <index-fragment :pageState="pageState" :auditors="auditors" ref="indexFragment" @refresh="refreshData"></index-fragment>
      </f7-tab>

    </f7-tabs>

  </f7-page>
</template>

<script>
import StatsFragment from '@/pages/TimeAuditor/StatsFragment'
import IndexFragment from '@/pages/TimeAuditor/IndexFragment'
import { participantAPI, errorHandler } from '@/js/api'

export default {
  components: {
    'stats-fragment': StatsFragment,
    'index-fragment': IndexFragment
  },
  data () {
    return {
      pageState: 'loading',
      auditors: [],
      stats: null
    }
  },

  mounted() {
      this.loadDataFromAPI()
  },

  methods: {
    loadDataFromAPI() {
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/time-auditor`)
        .then(res => {
          this.auditors = res.data.data.time_auditors
          this.stats = res.data.data.stats
          if (this.auditors.length === 0) {
            this.$refs.indexFragment.onAddClick()
          }
          this.pageState = 'loaded'
        })
        .catch(err => {
          console.log(err)
          this.pageState = 'failed'
          errorHandler(err)
        })
    },

    refreshData() {
      this.auditors = []
      console.log(this.auditors)
      this.stats = null
      this.pageState = 'loading'
      this.loadDataFromAPI()
    }
  }
}
</script>

<style scoped>
  
</style>