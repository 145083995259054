<template>
  <!-- <f7-page>
        <f7-navbar :title="currentTitle" back-link="Back">
            <f7-link slot="right" @click="onEditClick">
                <f7-icon ios="f7:pencil" aurora="f7:pencil" md="material:edit"></f7-icon>
            </f7-link>
        </f7-navbar> -->
        <div>
        <div class="wrapper">
            
            <div class="table-wrapper">

                <table class="table">
                    <thead>
                        <tr>
                            <th>Columns</th>
                            <th v-for="entry in dates"
                                :key="entry.id"    
                            >{{ entry.date }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Promised</td>
                            <td v-for="(entry, index) in targets"
                                :key="entry.id"
                            ><input type="number" v-model="builder.entries[index].promised" @input="generateTargetCumulative"></td>
                        </tr>
                        <tr>
                            <td>Actual</td>
                            <td v-for="(entry, index) in actuals"
                                :key="entry.id"
                            ><input type="number" v-model="builder.entries[index].actual" @input="generateActualCumulative"></td>
                        </tr>
                        <tr>
                            <td>Cumulative Promised</td>
                            <td v-for="entry in cumulativeTargets"
                                :key="entry.id"
                                class="readonly"
                            >{{ entry }}</td>
                        </tr>
                        <tr>
                            <td>Cumulative Actual</td>
                            <td v-for="entry in cumulativeActuals"
                                :key="entry.id"
                                class="readonly"
                            >{{ entry }}</td>
                        </tr>
                        <tr>
                            <td>Gap</td>
                            <td v-for="entry in gap"
                                class="readonly"
                                :style="getStyle(entry)"
                                :key="entry.id"
                            >{{ entry }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Loading Screen -->
        <az-loader class="show-page-wrapper" v-if="pageState == 'loading'"/>

        <!-- Save Button -->
        <!-- <f7-fab position="right-bottom"
                @click="onSaveClick">
        <f7-icon ios="f7:floppy_disk" 
                aurora="f7:floppy_disk"
                md="material:save" />
        </f7-fab> -->

        <f7-button class="save" raised fill @click="onSaveClick">Save</f7-button>
        </div>
  <!-- </f7-page> -->
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'
import { makeCumulativeArray } from '@/js/helpers/ArrayOperations'
import AZLoader from '@/components/ui/AZLoader'

export default {

    props: {
        builder_prop: Object
    },

    components: {
        'az-loader': AZLoader
    },

    data() {
        return {
            builder: null,
            cumulativeTargets: [],
            cumulativeActuals: [],
            pageState: 'loading'
        }
    },

    mounted() {
        this.builder = this.builder_prop
        this.generateTargetCumulative()
        this.generateActualCumulative()
        this.pageState = 'loaded'
    },

    methods: {

        onEditClick() {
            this.$f7router.navigate('/performance-builder/create/', {
                props: {
                    form_data_prop: this.builder
                }
            })
        },

        getStyle(entry) {
            if (entry == '-') return {}
            if (entry == 0) {
                return {
                    backgroundColor: '#bbdefb',
                }
            }
            if (entry > 0) return {
                backgroundColor: '#ef5350',
                color: 'white'
            }
            return {
                backgroundColor: '#26a69a',
                color: 'white'
            }
        },

        onSaveClick() {
            this.pageState = 'loading'
            let batchId = this.$store.getters['auth/getCurrentBatchID'];
            participantAPI().post(`batch/${batchId}/performance-builder-entry/${this.builder.id}`, {
                _method: 'PUT',
                entries: this.builder.entries
            })
            .then(res => {
                this.pageState = 'loaded'
                window.$f7.toast.create({
                    text: 'Your data has been saved',
                    closeTimeout: 3500,
                }).open();
            })
            .catch(err => {
                this.pageState = 'loaded'
                errorHandler(err, (error) => {
                if (Array.isArray(error)) {
                    error = error[0]
                }
                this.$f7.dialog.alert(error)
            })
            })
        },

        generateTargetCumulative() {
            this.cumulativeTargets = makeCumulativeArray(this.targets.map(target => target.target))
        },

        generateActualCumulative() {
            this.cumulativeActuals = makeCumulativeArray(this.actuals.map(actual => actual.actual))
        }
    },

    computed: {
        dates() {
            if (!this.builder) return null
            return this.builder.entries.map(entry => {
                return {
                    id: entry.id,
                    date: entry.date
                }
            })
        },

        targets() {
            if (!this.builder) return null
            return this.builder.entries.map(entry => {
                return {
                    id: entry.id,
                    target: entry.promised
                }
            })
        },

        actuals() {
            if (!this.builder) return null
            return this.builder.entries.map(entry => {
                return {
                    id: entry.id,
                    actual: entry.actual
                }
            })
        },

        gap() {
            return this.cumulativeTargets.map((target, index) => {
                let actual = this.cumulativeActuals[index]
                let res = target - actual
                if (parseInt(res) == res) {
                    res = parseInt(res)
                } 
                else {
                    res = res.toFixed(2)
                }
                if (isNaN(target) || isNaN(actual)) return '-'
                if (res < 0) {
                    res = `${Math.abs(res)} (ahead)`
                }
                return res
            })
        },

        currentTitle() {
            return this.builder ? this.builder.name : ''
        }
    }
}
</script>

<style scoped>
    
    .table-wrapper {
        max-width: 100%;
        overflow: scroll;
        overflow-y: hidden;
    }

    table {
        border: 1px solid #ddd;
        border-collapse: collapse;
        background: white;
    }

    td, th {
        white-space: nowrap;
        border: 1px solid #ddd;
        padding: 15px;
    }

    th {
        background-color: #eee;
        position: sticky;
        top: -1px;
        z-index: 2;
    }
    
    th:first-of-type {
        left: 0;
        z-index: 3;
    }
    
    tbody tr td:first-of-type {
        background-color: #eee;
        position: sticky;
        left: -1px;
        z-index: 1;
    }

    .readonly {
        background: #f5f5f5;
    }

    .save {
        margin: 10px 0;
    }

    .show-page-wrapper >>> .wrapper {
        padding-top: calc(25vh - 24px);
    }
</style>