<template>
  <f7-page name="home">

    <f7-navbar back-link="back">

      <!-- Page Title -->
      <f7-nav-title>{{ date }}</f7-nav-title>

    </f7-navbar>

    <div class="wrapper">
      <donut-chart
        style="width: 270px"
        id="entry-donut"
        :colors="colors" 
        :data="graphData" 
        :resize="true">
      </donut-chart>
    </div>

    <az-color-legend
      :categories="categories"
      :blacklist="['default']"
    ></az-color-legend>

    <div class="list media-list">
        <ul>
            <li v-for="datum in tableData"
                :key="datum.label">
                <a class="item-content">
                <div class="item-inner">
                    <div class="item-title-row">
                        <div class="item-title" style="color: black">{{ datum.label }}</div>
                        <div class="item-after">
                            {{ datum.value + ' Hrs' }}
                            {{ '(' + datum.percentage + '%)' }}
                        </div>
                    </div>
                </div>
                </a>
            </li>
        </ul>
    </div>
    
  </f7-page>
</template>

<script>
import AZColorLegend from '@/components/ui/AZColorLegend'
// Do not forget to import raphael
import Raphael from 'raphael/raphael'
global.Raphael = Raphael

import { DonutChart } from 'vue-morris'
import { CategoryColors, Categories } from '@/js/consts'

export default {
  name: 'TimeAuditorAnalyze',
  props: {
    entries_prop: Array,
    date: String
  },
  components: {
    DonutChart,
    'az-color-legend': AZColorLegend
  },
  data() {
    return {
      colors: CategoryColors,
      categories: Categories
    }
  },

  mounted() {
    if (!this.entries_prop) {
        setTimeout(() => {
            this.$f7router.back()
        }, 500)
    }
  },

  computed: {
    graphData() {
        return this.tableData.map(datum => {
            let temp = JSON.parse(JSON.stringify(datum))
            temp.label = temp.label + ' %'
            temp.value = temp.percentage
            return temp
        })
    },

    tableData() {
        return Object.keys(Categories).filter(category => category !== 'default').map(category => {
            let temp = {}
            temp.key = category
            temp.label = Categories[category].text
            let value = this.entries_prop.filter(entry => entry.category == category).length / 2
            temp.value = value
            temp.percentage = Math.round(value / this.totalHours * 100)
            return temp
        })
    },

    totalHours() {
        return (this.entries_prop.filter(entry => entry.category !== null).length) / 2
    }
  }
}
</script>


<style scoped>

    .wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #entry-donut {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>