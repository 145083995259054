<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar back-link="Back">
        <!-- Page Title -->
        <f7-nav-title>
            Select pictures
        </f7-nav-title>
    </f7-navbar>

    <div class="wrapper">
        <div class="pictures" v-if="pictures.length > 0">
            <p><b>You can upload upto 5 pictures</b></p>
            <div class="parent">
                <div class="child"
                    v-for="picture in pictures"
                    :key="picture.id"
                >
                    <div class="pic">
                        <div class="cross" @click="removePicture(picture.id)">
                            <f7-icon 
                                md="material:close"
                                ios="f7:multiply"
                                aurora="f7:multiply"
                            ></f7-icon>
                        </div>
                        <img :src="picture.thumb" class="lazy lazy-fade-in demo-lazy" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="no-pics" v-else>
            <h4>No pictures to show... yet</h4>
        </div>
        
        <f7-button v-if="pageState !== 'failed'" :disabled="maxImages" class="add-btn" raised fill @click="addPictures">Add Pictures</f7-button>

        <az-loader v-if="pageState === 'loading'"></az-loader>

        <az-page-error-message v-if="pageState === 'failed'" @try-again="loadDataFromAPI"></az-page-error-message>
    </div>
  </f7-page>

</template>

<script>
import { participantAPI, errorHandler, makeFormData, BASEURL } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';

export default {
    props: {
        pictures_prop: Array
    }, 

    components: {
        'az-loader': AZLoader,
        'az-page-error-message': AZPageErrorMessage
    },

    mounted() {
        if (this.pictures_prop !== undefined) {
            this.pictures = this.pictures_prop
            this.pageState = 'loaded'
        } 
        else {
            this.loadDataFromAPI()
        }
    },

    data() {
        return {
            pictures: [],
            pageState: 'loading',
            form_data: {
                files: [],
                removed_pictures: []
            }
        }
    },
    
    methods: {
        loadDataFromAPI() {
            this.pageState = 'loading'
            participantAPI().get('/profile-pictures')
            .then(res => {
                this.pageState = 'loaded'
                this.pictures = res.data.data.map(pic => {
                    return {
                        id: pic.id,
                        url: BASEURL + '/' + pic.path,
                        thumb: BASEURL + '/' + pic.thumb_path
                    }
                })
            })
            .catch(err => {
                this.pageState = 'failed'
            })
        },

        addPictures() {
            var input = document.createElement('input')
            input.type = 'file'
            input.multiple = true
            input.click()
            input.onchange = e => {
                this.pageState = 'loading'
                this.form_data.files = [...Array(5)].map(x => null)
                let fileList = e.target.files
                for (let i = 0; i < fileList.length; i++) {
                    this.form_data.files[i] = fileList[i]
                }
                let data = {
                    _method: 'POST',
                    ...this.form_data
                }
                let formData = makeFormData(data)

                participantAPI().post(
                    '/profile-pictures',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(res => {
                    // Show Toast
                    window.$f7.toast.create({
                        text: 'Pictures have been added successfully',
                        closeTimeout: 3500,
                    }).open();
                    this.loadDataFromAPI()
                })
                .catch(err => {
                    this.pageState = 'loaded'
                    errorHandler(err, (error) => {
                        if (Array.isArray(error)) {
                            error = error[0]
                        }
                        this.$f7.dialog.alert(error)
                    })
                })
            }
        },

        removePicture(id) {
            this.pageState = 'loading'
            participantAPI().delete(`/profile-pictures/${id}`)
            .then(() => {
                // Show Toast
                window.$f7.toast.create({
                    text: 'Picture has been removed',
                    closeTimeout: 3500,
                }).open();
                this.loadDataFromAPI()
            })
            .catch(err => {
                this.pageState = 'loaded'
                errorHandler(err, (error) => {
                    if (Array.isArray(error)) {
                        error = error[0]
                    }
                    this.$f7.dialog.alert(error)
                })
            })
        }
    },

    computed: {
        maxImages() {
            return this.pictures.length >= 5
        }
    }
}
</script>

<style scoped>
    .wrapper {
        margin: 10px;
        box-sizing: border-box;
    }

    img.demo-lazy {
        width: 100%;
        height: auto;
        display: block;
    }

    .pictures p {
        margin-bottom: 20px;
    }

    .parent {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        height: auto;
    }

    .add-btn {
        margin-top: 10px;
    }

    .pic {
        position: relative;
    }

    .cross {
        position: absolute;
        top: -5px;
        right: -5px;
        background: black;
        color: white;
        z-index: 99;
        padding: 0px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 2px;
        justify-content: center;
    }

    .cross i {
        font-size: 1rem;
    }

    .no-pics {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>