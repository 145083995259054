<template>
  <f7-page :page-content="false" @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link
          panel-open="left"
          icon-ios="f7:menu" 
          icon-aurora="f7:menu"
          icon-md="material:menu"
        ></f7-link>
      </f7-nav-left>
      
      <!-- Page Title -->
      <f7-nav-title>
        Framework Mastery ©
      </f7-nav-title>
    
    </f7-navbar>

    <!-- Body -->
    <f7-toolbar
      tabbar
      bottom
      key="framework-mastery">
      
      <f7-link
        tab-link="#fm-1"
        key="fm-1"
        tab-link-active
        icon-ios="f7:calendar_fill"
        icon-aurora="f7:calendar_fill"
        icon-md="material:today">
          Entries
      </f7-link>

      <f7-link
        tab-link="#fm-2"
        key="fm-2"
        icon-ios="f7:speedometer"
        icon-aurora="f7:speedometer"
        icon-md="material:dashboard">
          Analysis
      </f7-link>

    </f7-toolbar>

    <f7-tabs swipeable>

      <!-- Tab 1 -->
      <f7-tab class="page-content" id="fm-1" tab-active>
        <entries-fragment
          ref="entriesFragment"
        ></entries-fragment>
      </f7-tab>

      <!-- Tab 2 -->
      <f7-tab class="page-content" id="fm-2" >
        <f7-block-title>
          Under Construction
        </f7-block-title>
      </f7-tab>

    </f7-tabs>

  </f7-page>
</template>

<script>
import EntriesFragment from './EntriesFragment'

export default {

  components: {
    'entries-fragment': EntriesFragment
  },

  methods: {
    loadDataFromAPI () {
      this.$refs.entriesFragment.loadDataFromAPI()
    }
  }
}
</script>