<template>
  <div class="az-tab-page">

    <!-- List -->
    <div class="az-tab-scrollable">
      <div v-if="masters.length > 0" class="fab-wrapper">
        <f7-block-title>Master Plan</f7-block-title>
        <tree-menu
          v-for="master in masters"
          :key="master.id"
          :node="master"
          :nodes="master.children"
          :depth="0"
          @reload="refreshData"
          @action-event="openActionSheet"
          @mark="pageState = 'loading'"
          @edit-event="onEditClick"
          @delete-event="deleteTask"
          @add-event="addSubtask"
          @copy-event="sheetOpened = true"
        ></tree-menu>
      </div>

      <!-- No Data Message -->
      <template v-else>
        <az-page-message v-if="pageState != 'failed'" />
      </template>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

    <!-- Add Button -->
    <f7-fab class="fab-tab" position="right-bottom"  @click="onAddClick">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

    <!-- Action Sheet -->
    <f7-actions ref="actionSheet">
      <f7-actions-group>
        <f7-actions-label>Actions</f7-actions-label>
        <f7-actions-button @click="addSubtask">Add subtask</f7-actions-button>
        <f7-actions-button @click="deleteTask">Delete</f7-actions-button>
        <f7-actions-button @click="onEditClick">Edit</f7-actions-button>
        <f7-actions-button @click="sheetOpened = true">Copy</f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red">Cancel</f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <!-- Swipe to step demo sheet -->
    <f7-sheet
      :opened="sheetOpened"
      @sheet:closed="sheetOpened = false"
      class="demo-sheet-swipe-to-step"
      style="height:auto; --f7-sheet-bg-color: #fff;"
      swipe-to-close
      swipe-to-step
      backdrop
    >
      <!-- Initial swipe step sheet content -->
      <div class="sheet-modal-swipe-step">
        <f7-block-title medium class="margin-top">How do you want to proceed?</f7-block-title>
        <f7-list> 
          <f7-list-item
              checkbox
              @change.native="copyOptions.copyChildren = !copyOptions.copyChildren"
              radio-icon="end"
              title="Copy with subtasks"
              name="demo-radio-end"
              :checked="copyOptions.copyChildren"
              value="C"
          >
          </f7-list-item>
          <f7-list-item
              checkbox
              @change.native="copyOptions.retainParent = !copyOptions.retainParent"
              radio-icon="end"
              title="Retain Parent"
              name="demo-radio-end"
              :checked="copyOptions.retainParent"
              value="R"
          >
          </f7-list-item>
        </f7-list>
        <div class="padding-horizontal padding-bottom">
          <f7-button large fill @click="copyTask">Go Ahead</f7-button>
        </div>
      </div>
    </f7-sheet>

  </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import CQMasterList from '@/components/accomplishment-blueprint/master/CQMasterList';
import CQMasterItem from '@/components/accomplishment-blueprint/master/CQMasterItem';
import TreeMenu from '@/components/accomplishment-blueprint/master/TreeMenu';
import moment from 'moment';
import GoogleCalendarMixin from '@/js/mixins/google-calendar';

export default {
  name: 'MasterIndex',

  mixins: [GoogleCalendarMixin],

  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'cq-master-list': CQMasterList,
    'cq-master-item': CQMasterItem,
    'tree-menu': TreeMenu
  },

  data() {
    return {
      sheetOpened: false,
      copyOptions: {
        copyChildren: false,
        retainParent: false
      },
      currentId: 0,
      parent: undefined,
      pageState: 'loading',
      masters: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {
    openActionSheet() {
      this.$refs.actionSheet.open()
    },

    addSubtask() {
      let parent_id = this.$store.getters['blueprint/getCurrentBlueprint'].id
      this.$f7router.navigate('/event/create/blueprint/', {
        props: {
          parent_id: parent_id
        }
      });
    },

    deleteTask() {
      let task = this.$store.getters['blueprint/getCurrentBlueprint']
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
          // Set state to loading
          this.pageState = "loading";
          // Make API call
          let batchId = this.$store.getters['auth/getCurrentBatchID'];
          this.$store.dispatch('googleCalendar/deleteGoogleEvent', task.google_event_id)
          participantAPI().delete(`batch/${batchId}/event/${task.id}`)
          .then(() => {
              // Show Toast
              window.$f7.toast.create({
                  text: 'Your data has been deleted',
                  closeTimeout: 3500,
              }).open();
              // Reload list
              this.pageState = 'loaded'
              this.loadDataFromAPI()
          })
          .catch(error => {
              // Handle Errors
              errorHandler(error);
              this.pageState = 'loaded';
          });
      });
    },

    copyTask() {
      this.sheetOpened = false
      let parent_id = null
      let event = this.$store.getters['blueprint/getCurrentBlueprint']
      if (this.copyOptions.retainParent) {
        parent_id = event.parent_id
      }
      this.$f7router.navigate('/event/create/blueprint/', {
        props: {
          date: moment(this.start_date).format('DD-MM-YYYY'),
          form_data_prop: event,
          parent_id: parent_id,
          copyChildren: this.copyOptions.copyChildren,
          copyMode: true,
          originalId: event.id
        }
      });
    },

    loadDataFromAPI() {
      
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = `batch/${batchId}/event/list/blueprint`

      participantAPI().get(url)
      .then(response => {
        this.masters = response.data.data
        this.pageState = 'loaded'
      })
      .catch(error => {
        errorHandler(error)
        this.pageState = "failed"
      })
      
    },

    // Button Click Events
    
    refreshData () {
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    onAddClick() {
      this.$f7router.navigate('/event/create/blueprint/', {
        props: {
          parent_id: null
        }
      });
    },
    
    onEditClick() {
      let task = this.$store.getters['blueprint/getCurrentBlueprint']
      this.$f7router.navigate(`/event/edit/blueprint/`, {
          props: {
            form_data_prop: task
          }
        })
    },
  },
}
</script>

<style scoped>
  .fragment {
    height: 100%;
  }

  .scrollable {
    overflow-y: scroll;
    height: 100%;
  }
</style>