<template>
  <li>
    <a :href="href" class="item-link item-content">
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title">Reason: {{ reason }}</div>
          <div class="item-after">
            <f7-icon slot="media"
                     :ios="getAppleIcon"
                     :aurora="getAppleIcon"
                     :md="getMdIcon"
                     :color="getIconColor"
                      />
          </div>
        </div>
        <div class="item-subtitle">Action Word: {{ actionWord }}</div>
        <div class="item-text">Missing: {{ missing }}</div>
        <div class="item-text">Victory over Reason: {{ victory }}</div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: 'cq-reason-item',
  props: ['href', 'reason', 'actionWord', 'missing', 'completed', 'victory'],
  computed: {
    getMdIcon() {
      if (this.completed) {
        return 'material:done';
      } else {
        return 'material:update';
      }
    },
    getAppleIcon() {
      if (this.completed) {
        return 'f7:checkmark_alt';
      } else {
        return 'f7:timer';
      }
    },
    getIconColor() {
      if (this.completed) {
        return 'green';
      } else {
        return 'gray';
      }
    }
  }
}
</script>

<style>

</style>