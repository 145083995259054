const ui = {
    namespaced: true,
    state: {
        isLoading: false,
        isSidebarVisible: true
    },
    getters: {},
    mutations: {
        showLoading (state) {
            state.isLoading = true;
        },
        hideLoading (state) {
            state.isLoading = false;
        },
        showSidebar (state) {
            state.isSidebarVisible = true;
        },
        hideSidebar (state) {
            window.$f7.panel.get('.panel-left').close(false);
            state.isSidebarVisible = false;
        },
        goToHome (state) {
            // Navigate
            window.$f7.views.main.router.navigate('/home/');
            window.$f7.views.main.router.clearPreviousHistory();
            // Show Sidebar
            state.isSidebarVisible = true;
        },

        goToProfile(state) {
            // Navigate
            window.$f7.views.main.router.navigate('/profile/');
            window.$f7.views.main.router.clearPreviousHistory();
            // Show Sidebar
            state.isSidebarVisible = true;
        },

        goToLogin (state) {
            // Navigate
            window.$f7.views.main.router.navigate('/login/');
            window.$f7.views.main.router.clearPreviousHistory();
            // Hide Sidebar
            window.$f7.panel.get('.panel-left').close(false);
            state.isSidebarVisible = false;
        }
    },
    actions: {},
}

export default ui;