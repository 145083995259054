<template>
    <button
        class="toggle-btn"
        :class="{'active': active}"
        @click="onClick">
        <slot></slot>
    </button>
        
        
</template>

<script>
export default {
    name: 'AZToggleButton',
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick() {
            this.$emit('click')
        }
    }
}
</script>

<style scoped>

    /* @media screen and (max-width: 700px) {
        .toggle-btn {
            min-width: 10px !important;
        }
    } */
    button {
        display: inline-flex !important;
        justify-content: center;
        padding: 10px;
        background: #c5c5c5;
        box-shadow: none;
        border: none;
        margin: 5px;
        border-radius: 7px;
    }

    button.active {
        background: red;
        color: white;
    }

    @media screen and (max-width: 768px) {
        button {
            flex-basis: 47%;
        }
    }
</style>