<template>
    <f7-page>

        <!-- Navbar -->
        <f7-navbar :title="currentTitle" back-link="Back">
        </f7-navbar>

        <!-- Wrapper -->
        <az-steps :prev-visible="currentStepIndex != 0"
            :next-visible="currentStepIndex < (entries.length - 1)"
            :save-visible="currentStepIndex == (entries.length - 1)"

            :save-icon-md="saveIconMd"
            :save-icon-ios="saveIconIos"

            @prev-clicked="onPrevClicked"
            @next-clicked="onNextClicked"
            @save-clicked="onSaveClicked"
        >
            <div v-for="(entry, index) in entries"
                :key="index"
                v-if="index == currentStepIndex"
                :style="{ width: '100%', position: 'absolute', top: ($f7.device.ios ? '44px' : '56px'), paddingBottom: '70px' }"
            >
                <az-list>
                    <az-list-input label="What did you do?" v-model="currentEntry" type="textarea"></az-list-input>    
                    <f7-block-title>Nature of Activity</f7-block-title>
                    <az-toggle-btn-group>
                        <az-toggle-btn
                            :active="currentWorkRelated == 1"
                            @click="onWorkClick"
                        >Work Related</az-toggle-btn>
                        <az-toggle-btn
                            :active="currentWorkRelated == 0"
                            @click="onPersonalClick"
                        >Personal</az-toggle-btn>
                    </az-toggle-btn-group>
                    <!-- <az-list-input label="Work Related" 
                                    type="toggle" 
                                    v-model="currentWorkRelated">
                    </az-list-input> -->
                </az-list>
                
                <div v-if="currentWorkRelated == 1">
                    <f7-block-title>Analysis</f7-block-title>
                    <az-list>
                        <az-list-input label="Categorise" type="select" v-model="currentCategory">
                            <option v-for="(category, key) in categories"
                                    v-if="key != 'default' && key != 'PR'"
                                    :key="category.text" 
                                    :style="category.style" 
                                    :value="key">{{ category.text }}</option>    
                        </az-list-input>    
                    </az-list>
                </div>
                
            </div>
        </az-steps>

        <!-- Loading Screen -->
        <az-loader v-if="pageState == 'loading'"/>
    </f7-page>
</template>

<script>
import AZSteps from '@/components/ui/AZSteps'
import AZList from '@/components/ui/AZList'
import AZListInput from '@/components/ui/AZListInput'
import { time_array } from '@/js/helpers/TimeOptions'
import { participantAPI, errorHandler } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader.vue'
import { Categories } from '@/js/consts'
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';
import AZToggleButton from '@/components/ui/AZToggleButton';

export default {
    name: 'TimeIndex',
    props: {
        auditor_id: Number|String,
        start_time: String,
        entries_prop: Array,
        currentIndex: {
            default: 0
        },
        saveIconMd: {
            type: String,
            default: "material:save"
        },
        saveIconIos: {
            type: String,
            default: "f7:floppy_disk"
        }
    },
    components: {
        'az-steps': AZSteps,
        'az-list': AZList,
        'az-list-input': AZListInput,
        'az-loader': AZLoader,
        'az-toggle-btn-group': AZToggleButtonGroup,
        'az-toggle-btn': AZToggleButton
    },

    data() {
        return {
            pageState: 'loaded',
            entries: [],
            currentStepIndex: 0,
            categories: Categories
        }
    },

    mounted() {
        if (!this.entries_prop) {
            setTimeout(() => {
                this.$f7router.back();
            }, 500)
        }
        else {
            this.currentStepIndex = this.currentIndex
            this.entries = this.entries_prop
        }
    },
 
    methods: {
        onPrevClicked() {
            this.sendData()
            .then(() => {
                this.currentStepIndex--
            })
        },
        onNextClicked() {
            this.sendData()
            .then(() => {
                this.currentStepIndex++
            })
        },  
        onSaveClicked() {
            this.sendData()
            .then(() => {
                this.$f7router.back()
            })
        },

        onPersonalClick() {
            this.currentWorkRelated = 0
            this.entries[this.currentStepIndex].category = 'PR'
        },
        onWorkClick() {
            this.currentWorkRelated = 1
            this.entries[this.currentStepIndex].category = null
        },

        sendData() {
            this.pageState = 'loading'
            return new Promise((resolve, reject) => {
                let batchId = this.$store.getters['auth/getCurrentBatchID'];
                participantAPI().post(`batch/${batchId}/time-auditor/${this.auditor_id}`, {
                    _method: 'PUT',
                    key: this.entries[this.currentStepIndex].key,
                    entry: this.currentEntry,
                    work_related: this.currentWorkRelated,
                    category: this.currentCategory
                })
                .then(res => {
                    this.pageState = 'loaded'
                    resolve(res)
                })
                .catch(err => {
                    this.pageState = 'loaded';
                    errorHandler(error, 
                        (errors) => {
                        window.$f7.dialog.alert(errors[0], "Error");
                        }
                    )
                    reject(err)
                })
            })
            
        }
    },

    computed: {
        currentTitle() {
            return this.entries[this.currentStepIndex] ? this.entries[this.currentStepIndex].label : ''
        },

        currentWorkRelated: {
            get() {
                return this.entries[this.currentStepIndex].work_related
            },

            set(val) {
                this.entries[this.currentStepIndex].work_related = val
                if (!val)
                    this.currentCategory = null
            }
        },

        currentEntry: {
            get() {
                return this.entries[this.currentStepIndex].entry
            },

            set(val) {
                return this.entries[this.currentStepIndex].entry = val
            }
        },

        currentCategory: {
            get() {
                return this.entries[this.currentStepIndex].category
            },

            set(val) {
                this.entries[this.currentStepIndex].category = val
            }
        }
    }
}
</script>

<style scoped>
    
</style>