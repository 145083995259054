<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Performance Builder ©</f7-nav-title>
    </f7-navbar>

    <!-- List -->
    <f7-list accordion-list v-if="pageState == 'loaded'">
      <f7-list-item accordion-item title="Primary Game">
        <f7-accordion-content>
          <f7-list accordion-list v-if="primaryBuilders.length > 0">
            <f7-list-item 
              accordion-item
              v-for="builder in primaryBuilders"
              :key="builder.id"
              :title="builder.name"
            >
              <f7-accordion-content>
                <show-page :builder_prop="builder"></show-page>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-list v-else>
            <f7-list-item title="No items to show"></f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item title="Family Game">
        <f7-accordion-content>
          <f7-list accordion-list v-if="familyBuilders.length > 0">
            <f7-list-item 
              accordion-item
              v-for="builder in familyBuilders"
              :key="builder.id"
              :title="builder.name"
            >
              <f7-accordion-content>
                <show-page :builder_prop="builder"></show-page>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-list v-else>
            <f7-list-item title="No items to show"></f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item title="Dream Game">
        <f7-accordion-content>
          <f7-list accordion-list v-if="dreamBuilders.length > 0">
            <f7-list-item 
              accordion-item
              v-for="builder in dreamBuilders"
              :key="builder.id"
              :title="builder.name"
            >
              <f7-accordion-content>
                <show-page :builder_prop="builder"></show-page>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-list v-else>
            <f7-list-item title="No items to show"></f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item title="Have to do Game">
        <f7-accordion-content>
          <f7-list v-if="haveToDoBuilders.length > 0">
            <f7-list-item 
              accordion-item
              v-for="builder in haveToDoBuilders"
              :key="builder.id"
              :title="builder.name"
            >
              <f7-accordion-content>
                <show-page :builder_prop="builder"></show-page>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-list v-else>
            <f7-list-item title="No items to show"></f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item title="Other Game">
        <f7-accordion-content>
          <f7-list v-if="otherGameBuilders.length > 0">
            <f7-list-item 
              accordion-item
              v-for="builder in otherGameBuilders"
              :key="builder.id"
              :title="builder.name"
            >
              <f7-accordion-content>
                <show-page 
                  :builder_prop="builder"
                ></show-page>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-list v-else>
            <f7-list-item title="No items to show"></f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item title="Other">
        <f7-accordion-content>
          <f7-list v-if="otherBuilders.length > 0">
            <f7-list-item 
              accordion-item
              v-for="builder in otherBuilders"
              :key="builder.id"
              :title="builder.name"
            >
              <f7-accordion-content>
                <show-page :builder_prop="builder"></show-page>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-list v-else>
            <f7-list-item title="No items to show"></f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

     <!-- Loading Screen -->
    <az-loader
      v-if="pageState == 'loading'"
    ></az-loader>

    <!-- Error Message -->
    <az-page-error-message
      v-if="pageState == 'failed'"
      @try-again="loadDataFromAPI"
    ></az-page-error-message>

    <!-- Add Button -->
    <f7-fab position="right-bottom" slot="fixed" href="/performance-builder/create/">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage'
import ShowPage from './ShowPage.vue'
export default {
  name: 'PerformanceBuilderIndex',
  components: {
    'az-page-error-message': AZPageErrorMessage,
    'az-loader': AZLoader,
    'show-page': ShowPage
  },
  data() {
    return {
      performance_builders: [],
      pageState: "loading"
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {

    loadDataFromAPI() {
      this.pageState = 'loading'
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/performance-builder`)
      .then(res => {
        this.performance_builders = res.data.data
        this.pageState = 'loaded'
      })
      .catch(err => {
        errorHandler(err)
        this.pageState = 'failed'
      })
    },

    onItemClick(builder) {
      this.$f7router.navigate('/performance-builder/show/', {
        props: {
          builder_prop: builder
        }
      })
    }
  },

  computed: {
    primaryBuilders() {
      return this.performance_builders.filter(builder => builder.type == 'P')
    },

    familyBuilders() {
      return this.performance_builders.filter(builder => builder.type == 'F')
    },

    dreamBuilders() {
      return this.performance_builders.filter(builder => builder.type == 'D')
    },

    haveToDoBuilders() {
      return this.performance_builders.filter(builder => builder.type == 'H')
    },

    otherGameBuilders() {
      return this.performance_builders.filter(builder => builder.type == 'O')
    },

    otherBuilders() {
      return this.performance_builders.filter(builder => builder.type == 'X')
    }
  },

  watch: {
    pageState(val) {
      console.log('pageState', val)
    }
  }
}
</script>

<style scoped>
  .item-media i {
    color: var(--f7-theme-color) !important;
  }

  .accordion-item-content {
    margin: 0 10px !important;
  }
</style>