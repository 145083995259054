<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar title="Routines" back-link="Back">
      <!-- Delete Button -->
      <f7-link v-if="showAnalyzeBtn" slot="right" @click="onAnalyzeClick">
        <f7-icon ios="f7:chart_bar_alt_fill" aurora="f7:chart_bar_alt_fill" md="material:trending_up"></f7-icon>
      </f7-link>
      <f7-link slot="right" @click="onDeleteClick">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Body -->
    <div v-if="pageState == 'loaded'">
      <f7-list v-if="timeFinderRoutines.length > 0">
        <f7-list-item 
            v-for="(timeFinderRoutine, index) in timeFinderRoutines"
            :key="timeFinderRoutine.id"
            :title="timeFinderRoutine.task" 
            @click="onItemClick(index)">
          <f7-icon 
            slot="media" 
            :ios="getIcon(timeFinderRoutine.stage)"
            :aurora="getIcon(timeFinderRoutine.stage)"
            :md="getIcon(timeFinderRoutine.stage)"
          ></f7-icon>
        </f7-list-item>
      </f7-list>

      <!-- No Data Message -->
      <az-page-message v-else />

    </div>
    
    <!-- Add Button -->
    <f7-fab position="right-bottom" slot="fixed" @click="onAddClick">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

    <!-- Action Sheet -->
    <f7-actions ref="actionSheet">
      <f7-actions-group>
        <f7-actions-label>Select an option</f7-actions-label>
        <f7-actions-button @click="onEnterTimeClick">Enter Time Taken</f7-actions-button>
        <f7-actions-button @click="onSaveTimeClick">Start Saving Time</f7-actions-button>
        <f7-actions-button @click="onEditRoutineClick">Edit Routine</f7-actions-button>
        <f7-actions-button @click="onDeleteRoutineClick">Delete Routine</f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red">Cancel</f7-actions-button>
      </f7-actions-group>
    </f7-actions>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';

export default {
  name: 'TimeFinderRoutineIndex',
  props: ['id'],
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
  },

  data() {
    return {
      currentId: 0,
      pageState: 'loading',
      timeFinderRoutines: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {
    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      
      participantAPI().get(`batch/${batchId}/time-finder/${this.id}/time-finder-routine`)
        .then(response => {
          this.timeFinderRoutines = response.data.data.reverse()
          this.timeFinderRoutines = this.timeFinderRoutines.map(routine => {
            if (routine.meta === null) {
              routine.meta = {
                Sun: false,
                Mon: false,
                Tue: false,
                Wed: false,
                Thu: false,
                Fri: false,
                Sat: false
              }
            }
            return routine
          })
          this.pageState = 'loaded'
        })
        .catch(error => {  
                  
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    onAnalyzeClick() {
      this.$f7router.navigate(`/time-finder/analyze/`, {
        props: {
          entries_prop: this.timeFinderRoutines,
          // date: this.getFancyDate(this.currentDate)
        }
      });
    },

    // Show Icon
    getIcon (flag) {
      switch (flag) {
        case 0:
          return 'material:update'
          break
        case 1:
          return 'material:done'
          break
        case 2:
          return 'material:done_all'
          break
        default:
          return 'material:update'
          break
      }
    },

    getCurrentItem () {
      return this.timeFinderRoutines.filter((value) => {
        if (value.id == this.currentId) {
          return true;
        }
        return false;
      })[0];
    },

    // Button Click Events
    
    refreshData () {
      this.timeFinderRoutines = [];
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    onItemClick(index) {
      // this.currentId = id
      // this.$refs.actionSheet.open()
      
      this.$f7router.navigate(`/time-finder/${this.id}/routines/edit`, {
        props: {
          routines_prop: this.timeFinderRoutines,
          currentIndex: index,
          timeFinderId: this.id
        }
      });
    },

    onAddClick() {
      this.$f7router.navigate(`/time-finder/${this.id}/routines/create/`);
    },

    openActionSheet() {
      this.$f7.actions.get('#analyze').open();
    },

    onEditRoutineClick() {
      
      this.$f7router.navigate(`/time-finder/${this.id}/routines/${this.currentId}/edit/`, {
        props: {
          form_data_prop: this.getCurrentItem()
        }
      })
    },

    onDeleteRoutineClick(){
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/time-finder/${this.id}/time-finder-routine/${this.currentId}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Reload list
          this.refreshData()
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    },

    onEnterTimeClick() {
      this.$f7router.navigate(`/time-finder/${this.id}/routines/${this.currentId}/spend/`);
    },

    onSaveTimeClick() {
      this.$f7router.navigate(`/time-finder/${this.id}/routines/${this.currentId}/save/`);
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/time-finder/${this.id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Go Back
          this.$f7router.back();
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    }
  },

  computed: {
    showAnalyzeBtn() {
      return this.timeFinderRoutines.filter(entry => entry.action !== null).length > 0
    },
  }
}
</script>

<style>

</style>