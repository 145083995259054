<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen>
    <img :src="logo" class="logo" />
  </f7-page>
</template>

<script>
import api from '@/js/api'
import logo from '@/assets/logo.png';

export default {
  data() {
    return {
      logo
    }
  },
  created() {
    this.$store.commit('ui/hideSidebar');
  },
  mounted() {
    let app = this;

    // Check if logged in
    let token = app.$store.getters['auth/getToken'];
    let user = app.$store.getters['auth/getUser'];
    if (user && token) {

      // Go to home page
      setTimeout(function() {
        app.$store.commit('ui/goToHome');
      }, 2000);

    } else {

      // Not user found - Go to login
      setTimeout(function() {
        app.$store.commit('ui/goToLogin');
      }, 2000);

    }
  }
}
</script>

<style scoped>
  .logo {
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }

  .page, .page >>> .page-content {
    background: var(--f7-page-bg-color) !important;
  }
</style>
