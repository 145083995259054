<template>
  <f7-list-item :link="link"
                :title="title"
                :subtitle="subtitle"
                :class="{'active': this.isActive}"
                panel-close>
    <img v-if="icon !== null" 
        slot="media"
         width="42"
         :src="icon" />
  </f7-list-item>
</template>

<script>
export default {
  name: 'az-list-item',
  props: {
    title: String,
    subtitle: String,
    icon: {
      type: String,
      default: null
    },
    link: String
  },
  mounted() {
    window.$f7.views.main.router.on('routeChange', this.checkActive)
    this.checkActive()
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    removeTrailingSlash(url) {
      return url.replace(/\/$/, "");
    },

    getUrl() {
      return '/' + this.removeTrailingSlash(window.location.hash.split('#!')[1]).split('/')[1]
    },

    checkForSpecialCases() {
      let url = this.removeTrailingSlash(window.location.hash.split('#!')[1])
      if (url == '/event/create/blueprint' && this.link == '/accomplishment-blueprint/') {
        this.isActive = true
      }
      else if (url == '/event/create/planner' && this.link == '/power-planner/') {
        this.isActive = true
      } 
    },

    checkActive() {
      this.isActive = this.removeTrailingSlash(this.link) == this.getUrl()
      this.checkForSpecialCases()
    }
  },
}
</script>

<style scoped>
  .active {
    background: #e5e5e5;
  }
</style>