<template>
  <f7-list-item>
    <f7-list-item-cell class="width-auto flex-shrink-0">
      {{ min }}
    </f7-list-item-cell>
    <f7-list-item-cell class="flex-shrink-3">
      <f7-range
        :label="true"
        :min="min"
        :max="max"
        :step="1"
        :value="value"
        @range:changed="input"
      ></f7-range>
    </f7-list-item-cell>
    <f7-list-item-cell class="width-auto flex-shrink-0">
      {{ max }}
    </f7-list-item-cell>
  </f7-list-item>
</template>

<script>
export default {
  name: 'az-rating-input',

  props: {
    value: Number|String,
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 10
    }
  },
  
  methods: {
    input (value) {
      this.$emit('input', value)
    }
  }
}
</script>