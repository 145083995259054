<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Time Saver'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Wrapper -->
    <div class="az-page">
      <div class="az-scrollable">

        <az-list>
          
          <az-list-input label="Things that are saving my Time" type="text" v-model="form_data.task" />

          <li style="margin: 10px 0">
            <az-toggle-btn-group>
              <az-toggle-btn
                :active="form_data.implemented_value"
                @click="form_data.implemented_value = true"
              >
                Started
              </az-toggle-btn>
              <az-toggle-btn
                :active="!form_data.implemented_value"
                @click="form_data.implemented_value = false"
              >
                Stopped
              </az-toggle-btn>
            </az-toggle-btn-group>
          </li>

          <!-- <az-list-input label="Time Saved (In Minutes)" type="number" v-model="form_data.time_saved" /> -->
          <az-list-input label="Time Saved" type="time" :all_minutes="true" v-model="form_data.time_saved"></az-list-input>
        </az-list>

        <!-- Save Button -->
        <f7-fab position="right-bottom"
                @click="onSaveClick">
          <f7-icon ios="f7:floppy_disk" 
                  aurora="f7:floppy_disk"
                  md="material:save" />
        </f7-fab>
      </div>
    </div>
    
    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZLoader from '@/components/ui/AZLoader.vue';
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZToggleButton from '@/components/ui/AZToggleButton';
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';

export default {
  props:['form_data_prop'],

  components: {
    'az-loader': AZLoader,
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-toggle-btn': AZToggleButton,
    'az-toggle-btn-group': AZToggleButtonGroup,
  },

  data() {
    return { 
      pageState: 'loaded',
      form_data: {
        task: '',
        time_saved: 0,
        implemented : 0,
        implemented_value : true,
      }
    }
  },

  computed: {
    isEditMode() {
      return (this.form_data_prop !== undefined);
    },
  },

  mounted() {
    if (this.isEditMode) {
      this.form_data.id = this.form_data_prop.id;
      this.form_data.task = this.form_data_prop.task;
      this.form_data.time_saved = this.form_data_prop.time_saved;
      if (this.form_data_prop.implemented == 1) {
        this.form_data.implemented_value = true
      } else {
        this.form_data.implemented_value = false
      }
    }
  },

  methods: {
    
    onSaveClick() {
      if (this.form_data.implemented_value) {
        this.form_data.implemented = 1
      } else {
        this.form_data.implemented = 0
      }
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = this.isEditMode ? `batch/${batchId}/time-saver/${this.form_data.id}` : `batch/${batchId}/time-saver`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
      })
      .then(response => {
        // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.back();
      })
      .catch(error => {
        this.pageState = 'loaded'
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/time-saver/${this.form_data.id}`)
          .then(() => {
            // Show Toast
            window.$f7.toast.create({
                text: 'Your data has been deleted',
                closeTimeout: 3500,
            }).open();
            // Go Back
            this.$f7router.back();
          })
          .catch(error => {
            // Handle Errors
            errorHandler(error);
            this.pageState = 'loaded';
          });
      });
      
    },
  },

}
</script>