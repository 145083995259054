<template>
  <f7-page @page:reinit="loadDataFromAPI">
        <!-- Navbar -->
        <f7-navbar>
            <!-- Sidebar Button -->
            <f7-nav-left>
                <f7-link panel-open="left"
                        icon-ios="f7:menu" 
                        icon-aurora="f7:menu"
                        icon-md="material:menu" />
            </f7-nav-left>
            <!-- Page Title -->
            <f7-nav-title>Communication Manager ©</f7-nav-title>
            <f7-nav-right style="margin-right: 5px">
                <f7-link
                    icon-md="material:people"
                    icon-ios="f7:person_2"
                    icon-aurora="f7:person_2"
                    @click="openPeoplePopup"
                ></f7-link>
                <f7-link
                    icon-md="material:book"
                    icon-ios="f7:book_fill"
                    icon-aurora="f7:book_fill"
                    @click="openProjectsPopup"
                ></f7-link>
            </f7-nav-right>
        </f7-navbar>

        <div v-if="pageState == 'loaded'">
                <az-smart-select
                    ref="peopleSelect"
                    v-if="pageState == 'loaded'" 
                    :items="people" label="People" 
                    :selected="filter_people" 
                    :hidden="true"
                    @close="refreshData"
                    type="multiple"
                ></az-smart-select>
                <az-smart-select 
                    ref="projectSelect"
                    v-if="pageState == 'loaded'" 
                    :items="projects" label="Projects" 
                    :selected="filter_projects" 
                    :hidden="true"
                    @close="refreshData"
                    type="multiple"
                ></az-smart-select>
            <div v-if="tasks.length > 0">
                
                <!-- Desktop View -->
                <div class="data-table card desktop-name fab-wrapper" v-if="tasks.length > 0">
                    <table>
                    <thead>
                        <tr>
                        <th class="label-cell">Name</th>
                        <th class="label-cell">Project</th>
                        <th class="lable-cell">Status</th>
                        <th class="label-cell">Priority</th>
                        <th class="label-cell">Doers</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="task in tasks"
                            :key="task.id"
                            class="pointer"
                            @click="onItemClick(task)">
                        <td class="label-cell">{{ task.task || 'NA' }}</td>
                        <td class="label-cell">{{ task.project ? task.project.name : 'NA' }}</td>
                        <td class="label-cell"><span class="badge" :style="{background: getIconColor(task)}">{{ action_statuses[task.action_status] }}</span></td>
                        <!-- <td class="label-cell">{{ getFullName(task).trim() || 'NA' }}</td> -->
                        <td class="label-cell">{{ task.priority || 'NA' }}</td>
                        <td class="label-cell">{{ task.doers_text }}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>

                <az-custom-list class="mobile-name fab-wrapper">
                    <az-custom-list-item
                        v-for="task in tasks"
                        :key="task.id"
                        :title="task.task"
                        :subtitle="task.done_date"
                        :noMenu="true"
                        :is_done="isTaskDone(task)"
                        :priority="task.priority"
                        :action_status="action_statuses[task.action_status]"
                        :doers="task.doers"
                        @item-click="onItemClick(task)"
                    >
                        <template v-slot:text>
                            <p>{{ task.project ? this.project.name : 'NA' }} &#183; {{ action_statuses[task.action_status] }}</p>
                            <p>Assigned to {{ task.doers_text }}</p>
                        </template>
                        <template v-slot:icon>
                            <f7-icon 
                                slot="icon"
                                :ios="getIconIos(task)"
                                :aurora="getIconIos(task)"
                                :md="getIconMd(task)"
                                :color="getIconColor(task)"    
                            ></f7-icon>
                        </template>
                    </az-custom-list-item>
                </az-custom-list>

                <az-paginator 
                    class="fab-wrapper"
                    :current="meta.current_page" 
                    :last="meta.last_page" 
                    :links="links"
                    @pageChange="getData"
                ></az-paginator>

            </div>

            <!-- No Data Message -->
            <az-page-message v-else />
        </div>

        <!-- Loading Screen -->
        <az-loader v-if="pageState == 'loading'"/>

        <!-- Error Message -->
        <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />
        
        <!-- Add Button -->
        <f7-fab position="right-bottom" slot="fixed" @click="onAddClick">
            <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-fab>
  </f7-page>
</template>

<script>
import{ participantAPI, errorHandler } from '@/js/api'
import { ActionStatuses } from '@/js/consts'
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZLoader from '@/components/ui/AZLoader';
import AZPaginator from '@/components/ui/AZPaginator';
import AZSmartSelect from '@/components/ui/AZSmartSelect';
import AZCustomList from '@/components/ui/AZCustomList';
import AZCustomListItem from '@/components/ui/AZCustomListItem';
import axios from 'axios';

export default {
    components: {
        'az-page-message': AZPageMessage,
        'az-page-error-message': AZPageErrorMessage,
        'az-loader': AZLoader,
        'az-paginator': AZPaginator,
        'az-smart-select': AZSmartSelect,
        'az-custom-list': AZCustomList,
        'az-custom-list-item': AZCustomListItem
    },

    data() {
        return {
            pageState: 'loading',
            tasks: [],
            meta: null,
            links: null,
            people: [],
            projects: [],
            filter_people: [],
            filter_projects: [],
            action_statuses: ActionStatuses
        }
    },

    mounted() {
        this.loadDataFromAPI()
    },

    methods: {

        getDoersText(task) {
        return task.doers ? task.doers.map(doer => `${doer.full_name}`).join(',') : 'none'
        },

        getIconMd(task) {
            if (this.isTaskDone(task)) {
                return 'material:done'
            }
            return 'material:update'
        },

        getIconIos(task) {
            if (this.isTaskDone(task)) {
                return 'f7:checkmark_alt'
            }
            return 'f7:timer'
        },

        getIconColor(task) {
            if (this.isTaskDone(task)) {
                return 'green'
            }
            return 'gray'
        },

        openPeoplePopup() {
            this.$refs.peopleSelect.popupOpened = true
        },

        openProjectsPopup() {
            this.$refs.projectSelect.popupOpened = true
        },

        isTaskDone(task) {
            return this.action_statuses[task.action_status] == 'Done' 
        },

        loadDataFromAPI(dropdowns = true) {
            this.pageState = 'loading'
            let batchId = this.$store.getters['auth/getCurrentBatchID'];

            let task_url = this.makeUrl()
            let apis = [
                participantAPI().get(task_url),
            ]

            if (dropdowns) {
                apis = apis.concat([
                                    participantAPI().get(`batch/${batchId}/people`),
                                    participantAPI().get(`batch/${batchId}/project`)
                                    ])
            }


            axios.all(apis).then((...res) => {
                this.tasks = res[0][0].data.data
                this.meta = res[0][0].data.meta
                this.links = res[0][0].data.links

                if (dropdowns)
                {
                    this.people = res[0][1].data.data.map(person => {
                        return {
                            key: person.id,
                            value: person.full_name
                        }
                    })

                    this.projects = res[0][2].data.data.map(project => {
                        return {
                            key: project.id,
                            value: project.name
                        }
                    })
                }
                this.pageState = 'loaded'
            })
            .catch(err => {
                errorHandler(err)
                this.pageState = 'failed'
            })
        },

        makeUrl(page = null) {
            let batchId = this.$store.getters['auth/getCurrentBatchID'];
            let task_url = `batch/${batchId}/task`
            
            if (page) {
                task_url = this.addQueryGlue(task_url)
                task_url += `page=${page}`   
            }

            if (this.filter_people.length > 0) {
                let people = this.filter_people.join(',')
                task_url = this.addQueryGlue(task_url)
                task_url += `people=${people}`
            }
            if (this.filter_projects.length > 0) {
                let projects = this.filter_projects.join(',')
                task_url = this.addQueryGlue(task_url)
                task_url += `projects=${projects}`
            }
            return task_url
        },

        addQueryGlue(url) {
            if (url.includes('?')) {
                url += '&'
            }
            else {
                url += '?'
            }
            return url
        },

        getData(page) {
            this.pageState = 'loading'

            if (isNaN(page)) {
                page = this.links[page].split('').pop()
            }

            let url = this.makeUrl(page)

            participantAPI().get(url)
            .then(res => {
                this.pageState = 'loaded'
                this.tasks = res.data.data
                this.meta = res.data.meta
                this.links = res.data.links
            })
        },

        refreshData() {
            this.loadDataFromAPI(false)
        },

        onAddClick() {
            this.$f7router.navigate('/communication-manager/create/')
        },

        onItemClick(task) {
            let res = JSON.parse(JSON.stringify(task))
            res.doers = res.doers.map(doer => doer.id)
            res.supervisors = res.supervisors.map(supervisor => supervisor.id)
            res.initiator_id = res.initiator ? res.initiator.id : res.initiator

            this.$f7router.navigate('/communication-manager/create/', {
                props: {
                    form_data_prop: res
                }
            })
        }
    }
}
</script>

<style scoped>
    .paginator {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    @media screen and (min-width: 600px) {
        .mobile-name {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
        }
    }

    @media screen and (max-width: 600px) {
        .desktop-name {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
        }
    }
</style>