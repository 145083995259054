const blueprint = {
    namespaced: true,
    state: {
        currentBlueprint: null
    },
    getters: {
        getCurrentBlueprint (state) {
            return state.currentBlueprint;
        },
    },
    mutations: {
        
        setCurrentBlueprint (state, value) {
            state.currentBlueprint = value;
        },
        
    },
}

export default blueprint;