<template>
  <div class="loading">
    <div class="wrapper">
      <f7-preloader color="multi"></f7-preloader>
    </div>
  </div>
</template>

<script>
export default {
    name: 'az-loader'
}
</script>

<style scoped>
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
    z-index: 9999;
  }
  .loading .wrapper {
    padding-top: calc(50vh - 24px);
  }
  .loading .wrapper .preloader {
    display: block;
    margin: 0 auto;
    
  }

  @media screen and (min-width: 960px) {
    /* .loading {
      width: calc(100% + 320px);
    } */
  }
</style>