<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Money Maker ©</f7-nav-title>
    </f7-navbar>


    <!-- Page Content -->
    <div class="az-page">
      <div class="az-scrollable">

        <!-- Desktop View -->
        <div class="data-table card desktop-name fab-wrapper" v-if="moneyMakers.length > 0">
          <table>
            <thead>
              <tr>
                <th class="label-cell">Organisation</th>
                <th class="lable-cell">Status</th>
                <th class="label-cell">Name of the Person</th>
                <th class="label-cell">Cell No</th>
                <th class="label-cell">Email Address</th>
                <th class="label-cell">Lead Acquired On</th>
                <th class="label-cell">Last Spoken On</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="moneyMaker in moneyMakers"
                  :key="moneyMaker.id"
                  @click="onEditClick(moneyMaker)">
                <td class="label-cell">{{ moneyMaker.organisation || 'NA' }}</td>
                <td class="label-cell"><span class="badge" :style="{background: moneyMaker.lead_status_color}">{{ moneyMaker.lead_status }}</span></td>
                <td class="label-cell">{{ getFullName(moneyMaker).trim() || 'NA' }}</td>
                <td class="label-cell">{{ moneyMaker.phone || 'NA' }}</td>
                <td class="label-cell">{{ moneyMaker.email || 'NA' }}</td>
                <td class="label-cell">{{ moneyMaker.lead_acquired_on }}</td>
                <td class="label-cell">{{ moneyMaker.last_spoken_on }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Mobile View -->
        <az-custom-list class="mobile-name fab-wrapper" v-if="moneyMakers.length > 0">
          <az-custom-list-item 
              style="margin-bottom:2px;"
              v-for="moneyMaker in moneyMakers"
              :key="moneyMaker.id"
              :title="moneyMaker.organisation" 
              :subtitle="getFullName(moneyMaker).trim() || 'NA'"
              :noMenu="true"
              @item-click="onEditClick(moneyMaker)" 
            >
              <template v-slot:text>
                <p>{{ (moneyMaker.phone || 'NA') + ' | ' + (moneyMaker.email || 'NA')}}</p>
                <p class="pin" :style="{background: getStageBgColor(moneyMaker), color: getStageTextColor(moneyMaker)}">{{ moneyMaker.lead_stage }}</p>
                <p class="pin" :style="{background: getStatusBgColor(moneyMaker), color: getStatusTextColor(moneyMaker)}">{{ moneyMaker.lead_status }}</p>
              </template>
          </az-custom-list-item>
        </az-custom-list>
        
        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>

        <!-- Add Button -->
        <f7-fab position="right-bottom" slot="fixed" href="/money-maker/create/">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-fab>
      </div>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import { LeadStatuses, LeadStages } from '@/js/consts';
import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZCustomList from '@/components/ui/AZCustomList';
import AZCustomListItem from '@/components/ui/AZCustomListItem';

export default {
  name: 'MoneyMaker',
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'az-custom-list': AZCustomList,
    'az-custom-list-item': AZCustomListItem
  },

  data() {
    return {
      currentId: 0,
      pageState: 'loading',
      moneyMakers: [],
      lead_statuses: LeadStatuses,
      lead_stages: LeadStages
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {
    getFullName(moneyMaker) {
      return `${moneyMaker.first_name || ''} ${moneyMaker.last_name || ''}`
    },

    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/money-maker`)
        .then(response => {
          this.moneyMakers = response.data.data
          this.pageState = 'loaded'
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    getStatusBgColor(item) {
      return this.lead_statuses[item.lead_status].color
    },

    getStatusTextColor(item) {
      if (this.lead_statuses[item.lead_status].textColor) {
        return 'white'
      }
      return 'black'
    },

    getStageBgColor(item) {
      return this.lead_stages[item.lead_stage].color
    },

    getStageTextColor(item) {
      if (this.lead_stages[item.lead_stage].textColor) {
        return 'white'
      }
      return 'black'
    },

    // Button Click Events
    
    refreshData () {
      this.moneyMakers = [];
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    onEditClick(moneyMaker) {
      this.$f7router.navigate(`/money-maker/${moneyMaker.id}/edit/`, {
        props: {
          form_data_prop: moneyMaker
        }
      })
    },

    
    // openEditLead() {
    //   this.$f7.actions.navigate('/money-maker/create/');
    // }
  },
}
</script>

<style scoped>

  .badge {
    padding: 15px 17px;
  }

  .edit-btn{
    color: #fff;
    background-color: var(--f7-theme-color);
    border-color: var(--f7-theme-color);
    cursor: pointer;
    background-image: none;
    padding: 4px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .edit-btn:hover{
    color: #fff;
    background-color: rgb(238, 1, 1);
    border-color: rgb(238, 1, 1);
  }

  @media screen and (max-width: 600px) {
    .desktop-name {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }
  }

  .pin {
    display: inline-block; 
    padding: 3px; 
    border-radius: 3px;
  }

  @media screen and (min-width: 600px) {
    .mobile-name {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }
  }
</style>