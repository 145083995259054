<template>
  <f7-page>

    <f7-navbar title="National Trust Housing" back-link="Back">
      <f7-nav-right>
          <f7-link icon-ios="f7:pencil"
                   icon-aurora="f7:pencil"
                   icon-md="material:create"
                   href="/meeting-success-maximizer/create/" />
      </f7-nav-right>
    </f7-navbar>
    
    <f7-block-title>1. Identify a meeting you want or need to have</f7-block-title>
    <f7-block strong>
      <p>National Trust Housing</p>
    </f7-block>

    <f7-block-title>2. How much time do you think this meeting will take?</f7-block-title>
    <f7-block strong>
      <p>2 Hours</p>
    </f7-block>

    <f7-block-title>3. What purpose of yours &amp; others will get fulfilled? What's the point of this meeting?</f7-block-title>
    <f7-block strong>
      <p>To understand their organization goals, and or operational challanges which will help us to advise them necessary mobility solution at the time accordingly</p>
    </f7-block>

    <f7-block-title>4. If this meeting were a 'GRAND SUCCESS' for everyone involved, what outcomes would you have realized in this meeting? What would you have accomplished?</f7-block-title>
    <f7-block strong>
      <p>Moving this client from Pre-qualified to Proposal Stage and negoitiate it with a closure in this month</p>
    </f7-block>

  </f7-page>
</template>

<script>
export default {
  // props: ['id']
}
</script>