<template>
  <f7-page @page:reinit="loadData">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Mtg Success Maximizer ©</f7-nav-title>
    </f7-navbar>
    
    <!-- Page Content -->
    <div v-if="pageState == 'loaded'">

      <!-- List -->
      <div 
        v-if="meetings.length > 0"
        class="list media-list"
      >
        <ul>
          <li
            v-for="meeting in meetings"
            :key="meeting.id"
            @click="onItemClick(meeting)"
          >
            <a href="javascript:void(0);" class="item-link item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title"><b>{{ meeting.meeting_name }}</b></div>
                  <!-- <div class="item-after">...</div> -->
                </div>
                <div class="item-subtitle">{{ meeting.date }}</div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <!-- No Data Message -->
      <az-page-message v-else />

    </div>

    <!-- Loading Screen -->
    <az-loader 
      v-if="pageState == 'loading'"
    />

    <!-- Error Message -->
    <az-page-error-message
      v-if="pageState == 'failed'"
      @try-again="refreshData"
    />

    <!-- Add Button -->
    <f7-fab position="right-bottom" slot="fixed" @click="onAddClick">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';

export default {
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
  },

  mounted() {
    this.loadData();
  },

  data() {
    return {
      pageState: 'loading',
      meetings: []
    }
  },

  methods: {
    
    loadData () {
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/meeting`)
        .then((response) => {
          this.meetings = response.data.data;
          this.pageState = 'loaded';
        })
        .catch((error) => {
          errorHandler(error);
          this.pageState = 'failed';
        });
    },

    refreshData () {
      this.meetings = [];
      this.pageState = 'loading';
      this.loadData();
    },

    onAddClick () {
      this.$f7router.navigate('/meeting-success-maximizer/create/');
    },

    onItemClick (meeting) {
      this.$f7router.navigate(`/meeting-success-maximizer/create/`, {
        props: {
          form_data_prop: meeting,
          current_id_prop: meeting.id
        }
      })
    },
  }
}
</script>