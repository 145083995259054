<template>
  <f7-page @page:reinit="loadData">

    <!-- Navbar -->
    <f7-navbar>
      
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link
          panel-open="left"
          icon-ios="f7:menu" 
          icon-aurora="f7:menu"
          icon-md="material:menu"
        ></f7-link>
      </f7-nav-left>
      
      <!-- Page Title -->
      <f7-nav-title>
        Habits Enhancer ©
      </f7-nav-title>

    </f7-navbar>

    <!-- Toolbar -->
    <f7-toolbar
      tabbar
      bottom
      key="habit-enhancer">
      
      <!-- <f7-link
        tab-link="#he-1"
        key="he-1"
        icon-ios="f7:speedometer"
        icon-aurora="f7:speedometer"
        icon-md="material:dashboard">
          Analysis
      </f7-link> -->
      
      <f7-link
        tab-link="#he-2"
        key="he-2"
        tab-link-active
        icon-ios="f7:calendar_fill"
        icon-aurora="f7:calendar_fill"
        icon-md="material:today">
          Log
      </f7-link>
      
      <f7-link
        tab-link="#he-3"
        key="he-3"
        icon-ios="f7:chart_bar_alt_fill"
        icon-aurora="f7:chart_bar_alt_fill"
        icon-md="material:show_chart">
          My Habits
      </f7-link>
    
    </f7-toolbar>

    <!-- Tabs -->
    <f7-tabs swipeable>

      <!-- Tab 1 -->
      <!-- <f7-tab id="he-1">
        <f7-block-title>
          Under Construction
        </f7-block-title>
      </f7-tab> -->

      <!-- Tab 2 -->
      <f7-tab id="he-2" tab-active>
        <entries-fragment
          ref="entriesFragment"
        ></entries-fragment>
      </f7-tab>

      <!-- Tab 3 -->
      <f7-tab id="he-3">
        <habits-fragment
          @dataUpdate="setAddBtnVisibility"
          ref="habitsFragment"
        ></habits-fragment>
      </f7-tab>

    </f7-tabs>

  </f7-page>
</template>

<script>
import HabitsFragment from './Habits/IndexFragment'
import EntriesFragment from './Entries/IndexFragment'

export default {

  components: {
    HabitsFragment,
    EntriesFragment
  },

  methods: {
    loadData() {
      this.$refs.habitsFragment.loadDataFromAPI()
      this.$refs.entriesFragment.loadDataFromAPI()
    },

    setAddBtnVisibility(present) {
      this.$refs.entriesFragment.fabBtnVisible = present
    }
  }
}
</script>