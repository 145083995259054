<template>
  <li>
    <a :href="href" class="item-link item-content">
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title"><span v-html="breakdown"></span></div>
          <div class="item-after">
            <f7-icon slot="media"
                      :ios="getAppleIcon()"
                      :aurora="getAppleIcon()"
                      :md="getMdIcon()"
                      :color="getIconColor()"
                      />
          </div>
        </div>
        <div v-if="action" class="item-subtitle">Action: <span v-html="action"/></div>
        <div class="item-text" v-show="false">Who/Where: <span v-html="whoWhere" /></div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: 'cq-breakdown-item',
  props: ['href', 'breakdown', 'overcome', 'action', 'whoWhere', 'resolved'],
  methods: {
    getMdIcon() {
      if (this.resolved) {
        return 'material:done';
      } else {
        return 'material:update';
      }
    },
    getAppleIcon() {
      if (this.resolved) {
        return 'f7:checkmark_alt';
      } else {
        return 'f7:timer';
      }
    },
    getIconColor() {
      if (this.resolved) {
        return 'green';
      } else {
        return 'gray';
      }
    }
  }
}
</script>

<style>

</style>