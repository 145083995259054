<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar back-link="Back">

          <!-- Page Title -->
          <f7-nav-title>
                Business Details
          </f7-nav-title>

    </f7-navbar>

    <div>

      <az-list>
        <az-list-input label="Brief Description of your business *" type="textarea" v-model="form.business_description"></az-list-input>
        <az-list-input label="Your USP (Why should someone do business with you) *" type="textarea" v-model="form.usp"></az-list-input>
        <az-list-input label="Keywords to find you *" type="textarea" v-model="form.keywords"></az-list-input>
        <az-list-input label="Significant accomplishments in your business *" type="textarea" v-model="form.accomplishments"></az-list-input>
        <az-list-input label="Case Studies of your Business" type="textarea" v-model="form.case_studies"></az-list-input>
        <az-list-item
          style="padding-left:5px"
          title="Attachments"
          link="/profile/business-attachments/"
        ></az-list-item>
      </az-list>

      <div class="vouchers-list">
        <f7-block-title>People who can vouch for you</f7-block-title>
        <div v-if="form.vouchers.length + new_vouchers.length > 0">
          <az-list
            class="people"
            style="margin: 0 0 10px 0"
            v-for="(voucher, index) in form.vouchers"
            :key="voucher.id"
          >
            <div class="cross" @click="removeOldVoucher(index)">
                <f7-icon 
                    md="material:close"
                    ios="f7:multiply"
                    aurora="f7:multiply"
                ></f7-icon>
            </div>
            <az-list-input
              label="Name"
              v-model="voucher.name"
            ></az-list-input>
            <az-list-input
              type="number"
              label="Phone"
              v-model="voucher.phone"
            ></az-list-input>
          </az-list>

          <az-list
            class="people"
            style="margin: 0 0 10px 0"
            v-for="(voucher, index) in new_vouchers"
            :key="voucher.id"
          >
            <div class="cross" @click="removeNewVoucher(index)">
                <f7-icon 
                    md="material:close"
                    ios="f7:multiply"
                    aurora="f7:multiply"
                ></f7-icon>
            </div>
            <az-list-input
              label="Name"
              v-model="voucher.name"
            ></az-list-input>
            <az-list-input
              type="number"
              label="Phone"
              v-model="voucher.phone"
            ></az-list-input>
          </az-list>
        </div>
        <div class="no-vouchers" v-else>
          <p>No people added</p>
        </div>
        <f7-button style="margin: 10px" raised fill @click="addPerson">Add Person</f7-button>
      </div>

      <p class="question-title">Would it be ok for you if we publish your Business Profile to other Colloquium Graduates?</p>
      <az-toggle-btn-group class="fab-wrapper">
        <az-toggle-btn :active="form.consent_to_share == '1'" @click="form.consent_to_share = '1'">Yes</az-toggle-btn>
        <az-toggle-btn :active="form.consent_to_share == '0'" @click="form.consent_to_share = '0'">No</az-toggle-btn>
      </az-toggle-btn-group>

    </div>

    <az-loader v-if="pageState == 'loading'"></az-loader>

    <f7-fab class="fixed"
            v-if="pageState == 'loaded'"
            position="right-bottom"
            @click="onSaveClick">
        <f7-icon ios="f7:floppy_disk" 
            aurora="f7:floppy_disk"
            md="material:save" />
    </f7-fab>

  </f7-page>
</template>

<script>
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZListItem from '@/components/ui/AZListItem.vue';
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import { participantAPI, errorHandler, makeFormData } from '@/js/api'
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';
import AZToggleButton from '@/components/ui/AZToggleButton';

export default {
    props: {
      form_data_prop: Object
    },

    components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-list-item': AZListItem,
    'az-loader': AZLoader,
    'az-error-message': AZPageErrorMessage,
    'az-toggle-btn-group': AZToggleButtonGroup,
    'az-toggle-btn': AZToggleButton,
  },

  mounted() {
    this.form = this.form_data_prop
  },

  data() {
      return {
        pageState: 'loaded',
        form: {
          business_description: '',
          usp: '',
          keywords: '',
          accomplishments: '',
          case_studies: '',
          vouchers: []

        },
        removed_vouchers: [],
        new_vouchers: []
      }
    },

    methods: {
      onSaveClick() {
        let data = {
          _method: 'PUT',
          ...this.form,
          removed_vouchers: this.removed_vouchers,
          new_vouchers: this.new_vouchers
        }
        let formData = makeFormData(data)
        // return
        this.pageState = 'loading'
        participantAPI().post(
          '/business-profile',
          formData
        )
        .then(res => {
          this.$f7router.back()
        })
        .catch(error => {
          this.pageState = 'loaded'
          errorHandler(error, 
          (errors) => {
            window.$f7.dialog.alert(errors[0], "Error");
          })
        })
      },

      addPerson() {
        this.new_vouchers.push({
          name: '',
          phone: ''
        })
      },

      removeOldVoucher(index) {
        let removed = this.form.vouchers.splice(index, 1)
        if (removed[0].id) {
          this.removed_vouchers.push(removed[0].id)
        }
      },

      removeNewVoucher(index) {
        this.new_vouchers.splice(index, 1)
      }
    },
}
</script>

<style scoped>
  .cross {
        position: absolute;
        top: -5px;
        right: -5px;
        background: black;
        color: white;
        z-index: 99;
        padding: 0px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 2px;
        justify-content: center;
    }

    .cross i {
        font-size: 1rem;
    }
    
    .people {
      position: relative;
      margin: 15px !important;
    }

    .vouchers-list {
      padding: 20px 0;
      background: white;
    }

    .vouchers-list .block-title {
      margin-top: 0;
    }

    .no-vouchers {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .question-title {
      font-weight: 600;
      margin: 5px 20px;
      margin-top: 50px;
    }
</style>