import { participantAPI, errorMessages, errorHandler } from '@/js/api';

const auth = {
    namespaced: true,
    state: {
        token: undefined,
        user: undefined,
        currentBatch: undefined,
        batches: undefined,
        permissions: undefined
    },
    getters: {
        getToken (state) {
            if (state.token === undefined) {
                state.token = window.localStorage.getItem('token');
            }
            return state.token;
        },
        getUser (state) {
            if (state.user === undefined) {
                state.user = JSON.parse(window.localStorage.getItem('user'));
            }
            return state.user;
        },
        isLoggedIn (state, getters) {
            if (getters.getToken && getters.getUser) {
                return true;
            }
            return false;
        },

        getBatches(state, getters) {
            if (state.batches === undefined) {
                state.batches = JSON.parse(window.localStorage.getItem('batches'));
            }
            if (!state.batches && getters.getUser) {
                state.batches = getters.getUser.batches
            }
            return state.batches
        },

        getPermissions(state) {
            if (state.permissions === undefined) {
                state.permissions = JSON.parse(window.localStorage.getItem('permissions'));
            }
            return state.permissions
        },

        getCurrentBatch (state) {
            if (state.currentBatch === undefined) {
                state.currentBatch = (window.localStorage.getItem('current_batch') || 0);
            }
            return state.currentBatch;
        },
        getCurrentBatchID (state, getters) {
            let batches = getters.getBatches
            if (batches.length > 0)
                return batches[getters.getCurrentBatch].id;
            return 0;
        }
    },
    mutations: { 
        setCurrentBatch(state, value) {
            state.currentBatch = value
        },

        setBatches(state, value) {
            window.localStorage.setItem('batches', JSON.stringify(value))
            state.batches = value
        },

        setPermissions(state, value) {
            window.localStorage.setItem('permissions', JSON.stringify(value))
            state.permissions = value
        }
    },
    actions: {

        // Local Storage Actions
        addUser ({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                // Add to state
                state.token = payload.token;
                state.user = payload.user;
                // Add to local storage
                window.localStorage.setItem('token', payload.token);
                window.localStorage.setItem('user', JSON.stringify(payload.user));
                commit('setBatches', payload.user.batches)
                commit('setPermissions', payload.user.permissions)
                // Resolve
                resolve();
            });
        },
        removeUser ({ state }) {
            return new Promise((resolve, reject) => {
                // Remove from state
                state.token = undefined;
                state.user = undefined;
                // Remove from local storage
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('user');
                window.localStorage.removeItem('batches');
                // Resolve
                resolve();
            });
        },

        
        // API Actions

        sendOTP ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                // Show Loading Screen
                commit('ui/showLoading', null, { root: true });
                // Send OTP API
                participantAPI().post('send-otp', payload)
                    .then(function (response) {
                        // Hide Loading Screen
                        commit('ui/hideLoading', null, { root: true });

                        // Show Toast
                        window.$f7.toast.create({
                            text: 'The OTP has been sent on your phone number',
                            closeTimeout: 3500,
                        }).open();

                        // Resolve Request
                        resolve(response);
                    })
                    .catch(function (error) {
                        // Hide Loading Screen
                        commit('ui/hideLoading', null, { root: true });

                        // Show Error Message
                        if (error.response != undefined) {
                            window.$f7.dialog.alert(error.response.data.data[0], 'Error');
                        } else {
                            window.$f7.dialog.alert(errorMessages.internet, 'Error');
                        }
                    });
            });
        },

        verifyOTP({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                // Show Loading Screen
                commit('ui/showLoading', null, { root: true });
                // Verify OTP API
                participantAPI().post('verify-otp', payload)
                    .then(function (response) {
                        // Save User
                        let data = response.data.data;
                        console.log(data)
                        dispatch('addUser', data).then(() => {
                            // Hide Loading Screen
                            commit('ui/hideLoading', null, { root: true });
                            // Go to home page
                            if (data.user.batches.length === 0) {
                                commit('ui/goToProfile', null, { root: true });
                            }
                            // Go to Profile
                            else {
                                commit('ui/goToHome', null, { root: true });
                            }
                            // Resolve request
                            resolve();
                        });
                    })
                    .catch(function (error) {
                        // Hide Loading Screen
                        commit('ui/hideLoading', null, { root: true });

                        // Show Error Message
                        if (error.response != undefined) {
                            window.$f7.dialog.alert(error.response.data.data[0], 'Error');
                        } else {
                            window.$f7.dialog.alert(errorMessages.internet, 'Error');
                        }       
                    });
            });
        },

        adminLogin({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                // Show Loading Screen
                commit('ui/showLoading', null, { root: true });
                // Verify OTP API
                participantAPI().post('admin-login', payload)
                    .then(function (response) {
                        // Save User
                        let data = response.data.data;
                        dispatch('addUser', data).then(() => {
                            // Hide Loading Screen
                            commit('ui/hideLoading', null, { root: true });
                            // Go to home page
                            if (data.user.batches.length === 0) {
                                commit('ui/goToProfile', null, { root: true });
                            }
                            // Go to Profile
                            else {
                                commit('ui/goToHome', null, { root: true });
                            }
                            // Resolve request
                            resolve();
                        });
                    })
                    .catch(function (error) {
                        // Hide Loading Screen
                        commit('ui/hideLoading', null, { root: true });

                        // Show Error Message
                        if (error.response != undefined) {
                            window.$f7.dialog.alert(error.response.data.data[0], 'Error');
                        } else {
                            window.$f7.dialog.alert(errorMessages.internet, 'Error');
                        }       
                    });
            });
        },

        logout({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                // Show Loading Screen
                commit('ui/showLoading', null, { root: true });
                // Verify OTP API
                participantAPI().post('logout')
                    .then(function () {
                        // Remove user from storage
                        dispatch('removeUser').then(() => {
                            // Hide Loading Screen
                            commit('ui/hideLoading', null, { root: true });
                            // Go to login page
                            commit('ui/goToLogin', null, { root: true });
                            // Resolve request
                            resolve();
                        });
                    })
                    .catch(function (error) {
                        // Hide Loading Screen
                        commit('ui/hideLoading', null, { root: true });
                        // Handle errors
                        errorHandler(error);
                    });
            });
        }
    }
}

export default auth;