<template>
    <f7-page>

        <az-text-wizard
            :list="rules"
        ></az-text-wizard>
    </f7-page>
</template>

<script>
import AZReadOnly from '@/components/ui/AZReadOnly'
import AZTextWizard from '@/components/ui/AZTextWizard'
import makeithappen from '@/assets/makeithappen.png'
import connectframework from '@/assets/connectframework.png'

export default {
    name: 'KeyHandouts',
    components: {
        'az-readonly': AZReadOnly,
        'az-text-wizard': AZTextWizard
    },
    data() {
        return {
            rules: [
                        {
                            title: 'Ground Rules',
                            list_header: 'I Promise',
                            type: 'text',
                            rules: [
                                `To attend all Habit Builder Sessions unless communicated prior
                                to Habit Builder 1 & I promise to be there for all Habit Builder
                                Sessions on time unless communicated before Habit Builder 1
                                begins`,
                                
                                `To spend 18 hours a week on my Primary Game & 12 hours 
                                a week on my other Games except 
                                in the weeks I inform prior to Habit Builder 1`,
                                
                                `To complete my Power Planner and transfer all actions into my
                                Calendar within 24 hours after the Habit Builder Session ends`,

                                `To follow the instructions EXACTLY!`,

                                `To consider a new point of view, follow new practices & be coachable`,

                                `To ASK when I have a doubt & I promise to COMMUNICATE when I am stuck`,

                                `To carry my laptop/tablet fully charged (& charger) & my Note
                                Pad for all sessions along with a working internet connection`,

                                `To fill/send all tools, templates, trackers, surveys & feedback
                                forms to the concerned people on or before time`,

                                `To switch off my cell phone(s) & internet connection and put
                                them in my bag during the sessions except when I am asked
                                to use my cell phone`
                            ],
                        },

                        {
                            title: 'Operative Practices',
                            list_header: 'I Promise',
                            type: 'text',
                            rules: [
                                `To ask the Question I want an Answer to`,
                                
                                `To Reply to the Question Asked`,
                                
                                `To not help others & not to talk to others during the session
                                except when I am asked to do so`,

                                `To fill up all tools, templates, trackers, surveys & feedback
                                forms SERIOUSLY, FULLY & TRUTHFULLY in as much detail as I
                                can`,

                                `To respond in 6 hours if my Designated Consultant emails, calls
                                or messages me and if I can't then I will let them know when I
                                would reply before I sleep`,

                                `To check the Colloquium WhatsApp Group once every day
                                before I sleep & reply (in private chat) if anything is asked of
                                me`
                            ],
                        },

                        {
                            title: 'Consent to follow Ground Rules and Operative Measures',
                            list_header: 'I acknowledge',
                            type: 'text',
                            slot: `The SECRET to mastering MILLION DOLLAR HABITS & producing TANGIBLE RESULTS
                                    is to follow the Colloquium System FULLY, TRUTHFULLY & EXACTLY week after
                                    week.`,
                            rules: [
                                        `I have read & understood the above-mentioned
                                        Ground Rules & Operative Practices for participation.`,
                                        
                                        `I understand that the Intention, Outcome & Promise
                                        of the Colloquium will be fulfilled for me only if I
                                        follow the Ground Rules of Participation at all times
                                        till the end of the Colloquium.`,
                                        
                                        `I also understand that the results I will produce out of
                                        the Colloquium will be in direct proportion to the
                                        work I do between the Habit Builder Sessions and the
                                        Operative Practices I follow during the course of the
                                        Colloquium.`
                                    ]
                        },

                        {
                            title: 'Make it Happen',
                            type: 'picture',
                            picture: makeithappen
                        },

                        {
                            title: `Categories of Why Things Don't Happen`,
                            type: 'multiple',
                            rules: [
                                {
                                    list_header: 'Lack of Time',
                                    type: 'text',
                                    rules: [
                                        `Started Late`,
                                        `Inaccurate Time Estimate`,
                                        `Don't Have Sufficient Time`,
                                        `Don't Have Totality`
                                    ]
                                },
                                {
                                    list_header: 'Lack of Focus',
                                    type: 'text',
                                    rules: [
                                        `Interrupted (something you don’t like)`,
                                        `Distracted (something you like)`,
                                        `No Reminders`,
                                        `Involved in things other than what is most important`
                                    ]
                                },
                                {
                                    list_header: 'Lack of Clarity',
                                    type: 'text',
                                    rules: [
                                        `Who will do it?`,
                                        `Lack of Purpose or Motivation (Why?)`,
                                        `Lack of Knowing all the Steps (How?)`,
                                        `Lack of Skills/Competency (How?)`,
                                        `Lack of Information`
                                    ]
                                },
                                {
                                    list_header: 'Lack of Reality',
                                    type: 'text',
                                    rules: [
                                        `What does it look like in the World if your Game was alive?`,
                                        `Do the Numbers Add Up?`,
                                        `Does the Timeline Match?`,
                                        `Is it Actionable?`,
                                        `Are there External Factors at Play?`
                                    ]
                                },
                            ]
                        },

                        {
                            title: 'Connecting the Framework to Colloquium Tools',
                            type: 'picture',
                            picture: connectframework
                        },

            ],


        }
    }
}
</script>

<style>

</style>