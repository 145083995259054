<template>
  <f7-page class="re-analyze">

    <!-- Navbar -->
    <f7-navbar :title="currentTitle" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>
    
    <az-steps :prev-visible="true"
              :next-visible="currentStepIndex < (reasons.length - 1)"
              :save-visible="currentStepIndex == (reasons.length - 1)"

              :save-icon-md="saveIconMd"
              :save-icon-ios="saveIconIos"

              @prev-clicked="onPrevClicked"
              @next-clicked="onNextClicked"
              @save-clicked="onSaveClicked"
    >
      
      <!-- Pages -->
      <div v-for="(reason, index) in reasons"
          :key="index"
          v-if="index == currentStepIndex"
          :style="{ width: '100%', position: 'absolute', top: ($f7.device.ios ? '44px' : '56px'), paddingBottom: '70px' }"
          >
        
        <!-- Edit -->
        <f7-block-title>Edit</f7-block-title>
        <az-list>
          <az-list-input label="Date Noticed" type="datepicker" v-model="formattedDate" />

          <az-list-input label="Reason Stopping Me" type="text" v-model="currentReasonName" />
        </az-list>

        <!-- Toggle Button -->
        <f7-block-title>Missing</f7-block-title>
        <az-toggle-btn-group>
          <az-toggle-btn
            :active="currentTime"
            @click="currentTime = 1 - currentTime"
          >
            Time
          </az-toggle-btn>
          <az-toggle-btn
            :active="currentFocus"
            @click="currentFocus = 1 - currentFocus"
          >
            Focus
          </az-toggle-btn>
          <az-toggle-btn
            :active="currentClarity"
            @click="currentClarity = 1 - currentClarity"
          >
            Clarity
          </az-toggle-btn>
          <az-toggle-btn
            :active="currentReality"
            @click="currentReality = 1 - currentReality"
          >
            Reality
          </az-toggle-btn>
          
        </az-toggle-btn-group>

        <!-- Action Word -->
        <f7-block-title>Action Word</f7-block-title>
        <az-list>
          <az-list-input label="Action Word" type="text" v-model="currentNewThinking" />
        </az-list>  

        <az-list>
          <az-list-input label="Victory over Reason" type="slider" :min="0" @change="sliderChange" v-model="currentVictory"></az-list-input>
        </az-list>

        <div class="pg-no">
          <p>{{ pageNumberText }}</p>
        </div>
      </div>

    </az-steps>
    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import moment from 'moment';
import AZSteps from '@/components/ui/AZSteps.vue'
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader.vue'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';
import AZToggleButton from '@/components/ui/AZToggleButton';

export default {
  name: 'AnalyseReason',
  props: {
    reasons_prop : {
      type: Array
    },
    currentIndex: {
      default: 0
    },
    saveIconMd: {
      type: String,
      default: "material:save"
    },
    saveIconIos: {
      type: String,
      default: "f7:floppy_disk"
    }
  },
  components: {
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage,
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-steps': AZSteps,
    'az-toggle-btn-group': AZToggleButtonGroup,
    'az-toggle-btn': AZToggleButton
  },
  data() {
    return {
      pageState: 'loaded',
      reasons: [],
      currentStepIndex: 0,
    }
  },

  mounted() {
    if (!this.reasons_prop) {
      setTimeout(() => {
        this.$f7router.back();
      }, 500)
    }
    else {
      this.currentStepIndex = this.currentIndex
      this.reasons = this.reasons_prop
      
    }
  },

  methods: {
    sliderChange(val) {
      this.currentVictory = val
    },
    // Button Clicks
    onPrevClicked() {
      this.onSaveClick()
        .then(() => {
          if (this.currentStepIndex == 0) {
            // Go back to Index Page 
            this.$f7router.back();
          }
          else {
            this.pageState = 'loaded'
            this.currentStepIndex--;
          }
        })
    },

    onNextClicked() {
      this.onSaveClick()
        .then(() => {
          this.pageState = 'loaded'
          this.currentStepIndex++;
        })
      
    },

    onSaveClicked() {
      this.onSaveClick()
        .then(() => {
          this.pageState = 'loaded'
          this.$emit('save-clicked');
        })
      // Go back to Index Page 
      this.$f7router.back();
    },

    onDeleteClick() {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/reason-eliminator/${this.currentId}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          this.pageState = 'loaded'
          this.reasons.splice(this.currentStepIndex, 1)
          if (this.reasons.length <= this.currentStepIndex || this.reasons.length == 0) {
            // Go back to Index Page 
            this.$f7router.back();
          }
        })
        .catch(error => {
          // Handle Errors          
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    },

    onSaveClick() {
      this.pageState = 'loading';
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = `batch/${batchId}/reason-eliminator/${this.currentId}`;

      return new Promise((res, rej) => {
        participantAPI().post(url, {
          _method: 'PUT',
          ...this.reasons[this.currentStepIndex],
        })
        .then(response => {
          // Show Toast
            window.$f7.toast.create({
                text: 'Your data has been saved',
                closeTimeout: 3500,
            }).open();
            res()
        })
        .catch(error => {
          this.pageState = 'loaded';
          errorHandler(error, 
          (errors) => {
            window.$f7.dialog.alert(errors[0], "Error");
          })
          rej(error)
        })
      })
      
    },
  },

  computed: {
    isEditMode() {
      return (this.id !== undefined);
    },

    pageNumberText() {
      return `Page ${this.currentStepIndex + 1} of ${this.reasons.length}`
    },

    formattedDate: {
      get() {
        let parts = this.currentDateNoticed.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.currentDateNoticed = dateValue.format('DD-MM-YYYY');
      }
    },

    currentReasonName: {
      get() {
        return this.reasons[this.currentStepIndex].reason
      },

      set(value) {
        this.reasons[this.currentStepIndex].reason = value
      }
    },

    currentDateNoticed: {
      get() {
        return this.reasons[this.currentStepIndex].date_noticed
      },

      set(value) {
        this.reasons[this.currentStepIndex].date_noticed = value
      }
    },

    currentTime: {
      get() {
        return this.reasons[this.currentStepIndex].time == 1
      },

      set(value) {
        this.reasons[this.currentStepIndex].time = value
      }
    },

    currentFocus: {
      get() {
        return this.reasons[this.currentStepIndex].focus == 1
      },

      set(value) {
        this.reasons[this.currentStepIndex].focus = value
      }
    },

    currentClarity: {
      get() {
        return this.reasons[this.currentStepIndex].clarity == 1
      },

      set(value) {
        this.reasons[this.currentStepIndex].clarity = value
      }
    },

    currentReality: {
      get() {
        return this.reasons[this.currentStepIndex].reality == 1
      },

      set(value) {
        this.reasons[this.currentStepIndex].reality = value
      }
    },

    currentNewThinking: {
      get() {
        return this.reasons[this.currentStepIndex].new_thinking
      },

      set(value) {
        this.reasons[this.currentStepIndex].new_thinking = value
      }
    },

    currentVictory: {
      get() {
        return this.reasons[this.currentStepIndex].victory
      },

      set(value) {
        this.reasons[this.currentStepIndex].victory = value
      }
    },

    currentId() {
      return this.reasons[this.currentStepIndex].id
    },

    currentTitle() {
      return this.reasons[this.currentStepIndex] ? this.reasons[this.currentStepIndex].reason : ''
    } 
  }
}
</script>

<style scoped>
  .wrapper {
    padding-bottom: 50px;
  }
  .pg-no {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .pg-no p {
    margin: 0;
    padding: 5px;
    font-weight: 800; 
    border-radius: 5px;
  }
</style>