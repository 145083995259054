<template>
    <div class="flex">
        <img class="imgsrc" :src="image" alt="">
        
        <f7-fab class="fixed"
                v-if="prev"
                position="left-bottom"
                @click="goto(prev)">
            <f7-icon ios="f7:arrow_left"
                aurora="f7:arrow_left"
                md="material:arrow_backward" />
        </f7-fab>

        <!-- Next Button -->
        <f7-fab class="fixed"
                position="right-bottom"
                v-if="next"
                @click="goto(next)">
            <f7-icon ios="f7:arrow_right" 
                aurora="f7:arrow_right"
                md="material:arrow_forward" />
        </f7-fab>
    </div>
</template>

<script>
export default {
    name: 'AZReadOnlyImg',
    props: ['image', 'next', 'prev'],
    methods: {
      goto(url) {
          this.$f7router.navigate(url)
      }
    }
}
</script>

<style scoped>
    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80vh;
        margin-top: 20px;
    }

    .imgsrc {
        max-width: 100vw;
        
    }

    .fixed {
        position: fixed;
    }
</style>