<template>
    <div class="wrapper-week-switcher">
        <!-- prev button -->
        <div class="nav-icon pointer" @click="prevClick">
            <f7-icon
                ios="f7:chevron_left"
                aurora="f7:chevron_left"
                md="material:keyboard_arrow_left"
            ></f7-icon>
        </div>
        <!-- current date label -->
        <h4 class="pointer" ref="dateLabel">{{ currentDate }}</h4>
        <!-- next button -->
        <div class="nav-icon pointer" @click="nextClick">
            <f7-icon
                ios="f7:chevron_right"
                aurora="f7:chevron_right"
                md="material:keyboard_arrow_right"
            ></f7-icon>
        </div>
        <v-date-picker 
            ref="datepicker"
            is-inline 
            v-model="start_date"
            :disabled-dates='{ weekdays: [2, 3, 4, 5, 6, 7] }'
            :input-props='{
                class:"w-full"
            }'
        />
    </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
export default {
    name: 'WeekSwitcher',

    props: {
        date: Date
    },

    data() {
        return {
            start_date: ''
        }
    },

    mounted() {
        this.start_date = this.date
        this.addListener()
    },

    beforeDestroy() {
        this.removeListener()
    },

    components: {
        'v-date-picker': DatePicker
    },

    methods: {

        listener(event) {
            let dateLabel = this.$refs.dateLabel
            let datepicker = this.$refs.datepicker.$el
            var isClickInside = datepicker.contains(event.target);

            if (dateLabel.contains(event.target)) {
                if (datepicker.classList.contains('show')) {
                    datepicker.classList.remove('show')
                }
                else {
                    datepicker.classList.add('show')
                }
                return 
            }

            if (!isClickInside) {
                this.$refs.datepicker.$el.classList.remove('show')
            }
        },


        addListener() {
            document.addEventListener('click', this.listener)
        },

        removeListener() {
            document.removeEventListener('click', this.listener)
        },

        prevClick() {
            let prev = moment(this.start_date)
            this.start_date = prev.subtract(7, 'd').toDate()
        },

        nextClick() {
            let next = moment(this.start_date)
            this.start_date = next.add(7, 'd').toDate()
        },
    },

    computed: {
        currentDate() {
            return moment(this.start_date).format('MMMM DD, YYYY')
        }
    },

    watch: {
        start_date: {
            handler(newval, oldval) {
                if (oldval) this.$emit('change', newval)
                this.$refs.datepicker.$el.classList.remove('show')
            }
        }
    }
}
</script>

<style scoped>
    .wrapper-week-switcher {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        font-size: 20px;
        z-index: 9999999999;
    }

    .vc-container {
      width: 100% !important;
      position: fixed;
      z-index: 9999;
      bottom: 30px;
      transform: translateY(250px);
      transition: 0.4s ease-in all;
  }

  .vc-container.show {
    transform: translateY(-20px);
  }

  .nav-icon i {
      font-size: 30px;
  }
</style>