<template>
  <div class="az-tab-page">
    
    <!-- List -->
    <div class="az-tab-scrollable" style="position: relative">
      <az-week-switcher ref="weekSwitcher" @change="dateChange" :date="start_date"></az-week-switcher>
      <div v-if="planners.length > 0" class="fab-wrapper">
        <!-- <f7-block-title>Power Planner</f7-block-title> -->
        <tree-menu
          v-for="planner in planners"
          :key="planner.id"
          :node="planner"
          :nodes="planner.children"
          :depth="0"
          @reload="refreshData"
          @action-event="openActionSheet"
          @mark="pageState = 'loading'"
          @edit-event="onEditClick"
          @delete-event="deleteTask"
          @add-event="addSubtask"
          @copy-event="sheetOpened = true"
        ></tree-menu>
      </div>

      <!-- <az-page-message message="No actions on this day" v-else></az-page-message> -->

      <!-- No Data Message -->
      <template v-else>
        <az-page-message v-if="pageState != 'failed'" />
      </template>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

    <!-- Add Button -->
    <f7-fab class="fab-tab" position="right-bottom"  @click="onAddClick">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

    <!-- Action Sheet -->
    <f7-actions ref="actionSheet">
      <f7-actions-group>
        <f7-actions-label>Actions</f7-actions-label>
        <f7-actions-button @click="addSubtask">Add subtask</f7-actions-button>
        <f7-actions-button @click="deleteTask">Delete</f7-actions-button>
        <f7-actions-button @click="onEditClick">Edit</f7-actions-button>
        <f7-actions-button @click="sheetOpened = true">Copy</f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="red">Cancel</f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <!-- Swipe to step demo sheet -->
    <f7-sheet
      :opened="sheetOpened"
      @sheet:closed="sheetOpened = false"
      class="demo-sheet-swipe-to-step"
      style="height:auto; --f7-sheet-bg-color: #fff;"
      swipe-to-close
      swipe-to-step
      backdrop
    >
      <!-- Initial swipe step sheet content -->
      <div class="sheet-modal-swipe-step">
        <f7-block-title medium class="margin-top">How do you want to proceed?</f7-block-title>
        <f7-list> 
          <f7-list-item
              checkbox
              @change.native="copyOptions.copyChildren = !copyOptions.copyChildren"
              radio-icon="end"
              title="Copy with subtasks"
              name="demo-radio-end"
              :checked="copyOptions.copyChildren"
              value="C"
          >
          </f7-list-item>
          <f7-list-item
              checkbox
              @change.native="copyOptions.retainParent = !copyOptions.retainParent"
              radio-icon="end"
              title="Retain Parent"
              name="demo-radio-end"
              :checked="copyOptions.retainParent"
              value="R"
          >
          </f7-list-item>
        </f7-list>
        <div class="padding-horizontal padding-bottom">
          <f7-button large fill @click="copyTask">Go Ahead</f7-button>
        </div>
      </div>
    </f7-sheet>

  </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZCustomList from '@/components/ui/AZCustomList'
import AZCustomListItem from '@/components/ui/AZCustomListItem'
import TreeMenu from '@/components/accomplishment-blueprint/master/TreeMenu';
import moment from 'moment';
import AZWeekSwitcher from '@/components/ui/AZWeekSwitcher';

export default {
  name: 'PowerPlannerIndex',

  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'az-custom-list': AZCustomList,
    'az-custom-list-item': AZCustomListItem,
    'tree-menu': TreeMenu,
    'az-week-switcher': AZWeekSwitcher,
  },

  data() {
    return {
      sheetOpened: false,
      copyOptions: {
        copyChildren: false,
        retainParent: false
      },
      pageState: 'loading',
      planners: [],
      currentPlanner: null,
      events: [],
      start_date: moment().subtract(moment().day(), 'd').toDate()
    }
  },

  mounted() {
    // this.loadDataFromAPI()
  },

  methods: {
    openActionSheet() {
      this.$refs.actionSheet.open()
    },

    removeListener() {
      this.$refs.weekSwitcher.removeListener()
    },

    addListener() {
      this.$refs.weekSwitcher.addListener()
    },

    dateChange(val) {
      this.start_date = val
      this.refreshData()
    },

    addSubtask() {
      let parent_id = this.$store.getters['blueprint/getCurrentBlueprint'].id
      this.$f7router.navigate('/event/create/blueprint/', {
        props: {
          date: moment(this.start_date).format('DD-MM-YYYY'),
          parent_id: parent_id
        }
      });
    },

    copyTask() {
      this.sheetOpened = false
      let parent_id = null
      let event = this.$store.getters['blueprint/getCurrentBlueprint']
      if (this.copyOptions.retainParent) {
        parent_id = event.parent_id
      }
      this.$f7router.navigate('/event/create/blueprint/', {
        props: {
          date: moment(this.start_date).format('DD-MM-YYYY'),
          form_data_prop: event,
          parent_id: parent_id,
          copyChildren: this.copyOptions.copyChildren,
          copyMode: true,
          originalId: event.id
        }
      });
    },

    deleteTask() {
      let task = this.$store.getters['blueprint/getCurrentBlueprint']
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
          // Set state to loading
          this.pageState = "loading";
          // Make API call
          let batchId = this.$store.getters['auth/getCurrentBatchID'];
          this.$store.dispatch('googleCalendar/deleteGoogleEvent', task.google_event_id)
          participantAPI().delete(`batch/${batchId}/event/${task.id}`)
          .then(() => {
              // Show Toast
              window.$f7.toast.create({
                  text: 'Your data has been deleted',
                  closeTimeout: 3500,
              }).open();
              // Reload list
              this.pageState = 'loaded'
              this.loadDataFromAPI()
          })
          .catch(error => {
              // Handle Errors
              errorHandler(error);
              this.pageState = 'loaded';
          });
      });
    },

    onAddClick() {
      this.$f7router.navigate('/event/create/planner/', {
        props: {
          date: moment(this.start_date).format('DD-MM-YYYY'),
          parent_id: null
        }
      });
    },
    
    onEditClick() {
      let task = this.$store.getters['blueprint/getCurrentBlueprint']
      this.$f7router.navigate(`/event/edit/planner/`, {
          props: {
            form_data_prop: task
          }
        })
    },

    loadDataFromAPI() {
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().post(`batch/${batchId}/event/get-events-by-week`, {
        start_date: moment(this.start_date).format('DD-MM-YYYY')
      })
      .then(res => {
        this.planners = res.data.data
        this.pageState = 'loaded'
      })
      .catch(err => {
        errorHandler(err)
        this.pageState = 'failed'
      })
    },

    refreshData() {
      this.planners = []
      this.pageState = 'loading'
      this.loadDataFromAPI()
    },

    onItemClick(planner) {
      this.$f7router.navigate(`/power-planner-result/${planner.id}/`, {
        props: {
          planner_title: planner.date
        }
      })
    },

    onMenuClick(planner) {
      this.currentPlanner = planner
      this.$refs.actionsGroup.open()
    },
  },

  computed: {
    gapi() {
      return this.$store.getters['googleAuth/getGapiLoaded']
    },
  },

}
</script>

<style scoped>
  .list >>> .item-title{
    white-space: initial !important;
  }

  .item-media i {
    color: var(--f7-theme-color) !important;
  }

  .wrapper {
    transform:translateY(75px);
    height: calc(100% - 75px);
  }

  .wrapper >>> .inner {
    top: calc(50% - 37.5px) !important;
  }
</style>