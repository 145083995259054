<template>
    <fragment>
      <p v-if="label" style="margin-left: 20px; margin-bottom: 5px;">{{ label }}</p>
      <div class="row">
        <f7-list-input
          label="Repeats Every"
          class="col"
          type="text"
          :value="computedFrequency"
          @input="computedFrequency = $event.target.value"
          outline
          floating-label
        >  
        </f7-list-input>
        <f7-list-input
          label="Option"
          class="col"
          type="select"
          :value="computedRecurrence"
          @input="computedRecurrence = $event.target.value"
          outline
          floating-label
        >
          <option value="D">Daily</option>      
          <option value="W">Weekly</option>
          <option value="M">Monthly</option>
        </f7-list-input>
      </div>

      <div class="buttons" v-show="computedRecurrence == 'W'">
          <button
            v-for="(item, key) in days" 
            :key="key"
            :class="{'active': item.selected}"
            class="pointer" 
            @click="dayClick(key)"
        >{{ item.text }}</button>
      </div>

      <div class="monthly" v-show="computedRecurrence == 'M'">
          {{ computedLabel }}
      </div>
    </fragment>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: 'RRULE:FREQ=DAILY;INTERVAL=1'
        },
        label: String,
        date: String,
    },

    data() {
        return {
            dataRrule: 'RRULE:FREQ=DAILY;INTERVAL=1',
            days: {}
        }
    },

    mounted() {
        if (this.value) this.dataRrule = this.value
        this.setDefaultDays()
        this.initialiseDays()
    },

    methods: {
        setDefaultDays() {
            this.days = {
                SU: {
                    text: 'Sunday',
                    selected: false
                },
                MO: {
                    text: 'Monday',
                    selected: false
                },
                TU: {
                    text: 'Tuesday',
                    selected: false
                },
                WE: {
                    text: 'Wednesday',
                    selected: false
                },
                TH: {
                    text: 'Thursday',
                    selected: false
                },
                FR: {
                    text: 'Friday',
                    selected: false
                },
                SA: {
                    text: 'Saturday',
                    selected: false
                }
            }
        },
        some(day) {
            this.value[2][day] = 1 - this.value[2][day]
            this.$emit('input', [this.computedFrequency, this.computedRecurrence, this.value[2]])
        },

        initialiseDays() {
            if (this.dataRrule) {
                let matched = this.dataRrule.match(/BYDAY=([\w|,]+);/)
                if (matched) {
                    let selectedDays = matched[1].split(',')
                    selectedDays.map(sd => {
                        this.days[sd].selected = true
                    })
                }
            }
        },

        dayClick(day) {
            if ( !(this.lastDaySelected && this.days[day].selected) ) {

                this.days[day].selected = !this.days[day].selected
                let selectedDays = []
                Object.entries(this.days).map(([key, value]) => {
                    if (value.selected) {
                        selectedDays.push(key)
                    }
                })
                selectedDays = selectedDays.join(',')
                this.dataRrule = this.dataRrule.replace(/(BYDAY=)([\w|,]*)(;)/, '$1' + selectedDays + '$3')
            }
            this.$emit('input', this.dataRrule)
        }
    },

    computed: {
        computedRecurrence: {
            get() {
                if (this.dataRrule === null) {
                    return ''
                }
                let matched = this.dataRrule.match(/FREQ=([A-Z]*);/)
                return matched ? matched[1][0] : 'D'
            },

            set(val) {
                let index = {
                    'D': 'DAILY',
                    'W': 'WEEKLY',
                    'M': 'MONTHLY'
                }
                this.dataRrule = this.dataRrule.replace(/(FREQ=)([A-Z]+)/, '$1' + index[val])
                
                if (val === 'D') {
                    this.dataRrule = this.dataRrule.replace(/BYDAY=([\w|,]+;)/, '')
                    this.dataRrule = this.dataRrule.replace(/BYMONTH=([\d|,|;]+)/, '')
                }
                if (val === 'M') {
                    this.dataRrule = this.dataRrule.replace(/BYDAY=([\w|,]+;)/, '')
                    this.dataRrule = this.dataRrule.replace(/(FREQ=[A-Z]*;)/, '$1BYMONTH=' + this.date.substring(0, 2) + ';')
                    // this.dataRrule = this.dataRrule.replace(/(BYDAY=)([\w|,]*)(;)/, 'BYMONTH=' + this.date.substring(0,2) + '$3')
                }
                else if (val === 'W') {
                    this.dataRrule = this.dataRrule.replace(/BYMONTH=([\d|,|;]+)/, '')
                    this.dataRrule = this.dataRrule.replace(/(FREQ=[A-Z]*;)/, '$1BYDAY=SU;')
                    this.setDefaultDays()
                    this.initialiseDays()
                    // this.dataRrule = this.dataRrule.replace(/(BYDAY=)([\w|,]*)(;)/, 'BYMONTH=' + this.date.substring(0,2) + '$3')
                }
                this.$emit('input', this.dataRrule)
            }
        },

        computedFrequency: {
            get() {
                if (this.dataRrule === null) {
                    return ''
                }
                let matched = this.dataRrule.match(/INTERVAL=([0-9]+)/)
                return matched ? matched[1] : 1
            },

            set(val) {
                if (!val) 
                    val = 1 
                this.dataRrule = this.dataRrule.replace(/(INTERVAL=)([0-9]*)/, '$1' + val)
                this.$emit('input', this.dataRrule)
            }
        },

        lastDaySelected() {
            return Object.entries(this.days).filter(([key, value]) => {
                return value.selected
            }).length == 1
        },

        computedLabel() {
            return this.date.length > 0 ? `This event will repeat monthly on day ${parseInt(this.date.substring(0,2))}`: ''
        },
    },

    watch: {
        date: {
            handler(val) {
                this.dataRrule = this.dataRrule.replace(/(BYMONTH=)([\w|,]*)(;)/, '$1' + val.substring(0,2) + '$3')
                this.$emit('input', this.dataRrule)
            }
        }
    }
}
</script>

<style scoped>
    .buttons {
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: flex-start;
    }

    .buttons button {
        display: inline-block !important;
        padding: 10px;
        background: #c5c5c5;
        box-shadow: none;
        border: none;
        margin: 5px;
        border-radius: 7px;
    }

    .buttons button.active {
        background: red;
        color: white;
    }

    .monthly {
        padding-left: 15px !important;
    }

    @media screen and (max-width: 768px) {
        .buttons button {
            flex-basis: 47%;
        }

        .buttons {
            flex-wrap: wrap;
        }
    }
</style>