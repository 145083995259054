<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar back-link="Back">
        <!-- Page Title -->
        <f7-nav-title>
            Select logos
        </f7-nav-title>
    </f7-navbar>

    <div class="wrapper">

        <f7-photo-browser
            :photos="browserLogos"
            ref="standalone"
            :navbar-show-count="false"
        ></f7-photo-browser>

        <div class="logos" v-if="logos.length > 0">
            <div class="pic-section" v-if="pictureLogos.length > 0">
                <f7-block-title>Pictures</f7-block-title>
                <div class="parent">
                    <div class="child"
                        v-for="(logo, index) in pictureLogos"
                        :key="logo.id"
                    >
                        <div class="pic">
                            <div class="cross" @click="removeLogo(logo.id)">
                                <f7-icon 
                                    md="material:close"
                                    ios="f7:multiply"
                                    aurora="f7:multiply"
                                ></f7-icon>
                            </div>
                            <img @click.self="openBrowser(index)" :src="logo.thumb" class="lazy lazy-fade-in demo-lazy" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="pdf-section" v-if="pdfLogos.length > 0">
                <f7-block-title>PDFs</f7-block-title>
                <div class="parent">
                    <div class="child"
                        v-for="logo in pdfLogos"
                        :key="logo.id"
                    >
                        <div class="doc" @click.self="openDoc(logo.url)">
                            <div class="cross" @click="removeLogo(logo.id)">
                                <f7-icon 
                                    md="material:close"
                                    ios="f7:multiply"
                                    aurora="f7:multiply"
                                ></f7-icon>
                            </div>
                            <f7-icon
                                @click.native="openDoc(logo.url)"
                                class="file-icon"
                                md="material:insert_drive_file"
                                ios="f7:doc"
                                aurora="f7:doc"
                            ></f7-icon>
                        </div>
                        <p style="word-break: break-all">{{ logo.original_name }}</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="no-pics" v-else>
            <h4>No logos to show... yet</h4>
        </div>
        
        <f7-button v-if="pageState !== 'failed'" class="add-btn" raised fill @click="addLogos">Add Logos</f7-button>

        <az-loader v-if="pageState === 'loading'"></az-loader>

        <az-page-error-message v-if="pageState === 'failed'" @try-again="loadDataFromAPI"></az-page-error-message>
    </div>
  </f7-page>

</template>

<script>
import { participantAPI, errorHandler, makeFormData, BASEURL } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import { arrayRotate } from '@/js/helpers/ArrayOperations'

export default {
    props: {
        logos_prop: Array
    }, 

    components: {
        'az-loader': AZLoader,
        'az-page-error-message': AZPageErrorMessage
    },

    mounted() {
        if (this.logos_prop !== undefined) {
            this.logos = this.logos_prop
            this.pageState = 'loaded'
        } 
        else {
            this.loadDataFromAPI()
        }
    },

    data() {
        return {
            logos: [],
            browserLogos: [],
            pageState: 'loading',
            form_data: {
                files: []
            },
        }
    },
    
    methods: {
        loadDataFromAPI() {
            this.pageState = 'loading'
            participantAPI().get('/logos')
            .then(res => {
                this.pageState = 'loaded'
                this.logos = res.data.data.map(logo => {
                    logo.url = BASEURL + '/' + logo.path
                    logo.thumb = BASEURL + '/' + logo.thumb_path
                    return logo
                })
            })
            .catch(err => {
                this.pageState = 'failed'
            })
        },

        openBrowser(index) {
            let logos = JSON.parse(JSON.stringify(this.pictureLogos))
            for (let i = 0; i < index; i++)
                this.browserLogos = arrayRotate(logos)
                this.$nextTick(() => {
                    this.$refs.standalone.open()  
                })
            // setTimeout(() => {
            // }, 100)
        },

        openDoc(path) {
            window.open(path)
        },

        addLogos() {
            var input = document.createElement('input')
            input.type = 'file'
            input.multiple = true
            input.click()
            input.onchange = e => {
                this.pageState = 'loading'
                let fileList = e.target.files
                this.form_data.files = [...Array(fileList.length)].map(x => null)
                for (let i = 0; i < fileList.length; i++) {
                    this.form_data.files[i] = fileList[i]
                }
                let data = {
                    _method: 'POST',
                    ...this.form_data
                }
                let formData = makeFormData(data)

                participantAPI().post(
                    '/logos',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(res => {
                    // Show Toast
                    window.$f7.toast.create({
                        text: 'Logos have been added successfully',
                        closeTimeout: 3500,
                    }).open();
                    this.loadDataFromAPI()
                })
                .catch(err => {
                    this.pageState = 'loaded'
                    errorHandler(err, (error) => {
                        if (Array.isArray(error)) {
                            error = error[0]
                        }
                        this.$f7.dialog.alert(error)
                    })
                })
            }
        },

        removeLogo(id) {
            this.pageState = 'loading'
            participantAPI().delete(`/logos/${id}`)
            .then(() => {
                // Show Toast
                window.$f7.toast.create({
                    text: 'Logo has been removed',
                    closeTimeout: 3500,
                }).open();
                this.loadDataFromAPI()
            })
            .catch(err => {
                this.pageState = 'loaded'
                errorHandler(err, (error) => {
                    if (Array.isArray(error)) {
                        error = error[0]
                    }
                    this.$f7.dialog.alert(error)
                })
            })
        }
    },

    computed: {

        pdfLogos() {
            var re = /(?:\.([^.]+))?$/;
            return this.logos.filter(logo => re.exec(logo.url)[1] === 'pdf')
        },

        pictureLogos() {
            var re = /(?:\.([^.]+))?$/;
            return this.logos.filter(logo => re.exec(logo.url)[1] !== 'pdf')
        }
    }
}
</script>

<style scoped>
    .wrapper {
        margin: 10px;
        box-sizing: border-box;
    }

    img.demo-lazy {
        width: 100%;
        height: auto;
        display: block;
    }

    .logos p {
        margin-bottom: 20px;
    }

    .parent {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        height: auto;
    }

    .add-btn {
        margin-top: 10px;
    }

    .pic {
        position: relative;
    }

    .cross {
        position: absolute;
        top: -5px;
        right: -5px;
        background: black;
        color: white;
        z-index: 99;
        padding: 0px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 2px;
        justify-content: center;
    }

    .cross i {
        font-size: 1rem;
    }

    .no-pics {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .doc {
        position: relative;
        display: flex;
        width: 100%;
        height: 125px;
        align-items: center;
        justify-content: center;
        border: 1px solid #959595;
    }

    .doc i.file-icon {
        font-size: 60px;
    }

    .block-title {
        margin-left: 0;
    }
</style>