<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Framework Mastery'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Body -->
    <az-wizard :questions="questions"
               @save-clicked="onSaveClick" />

    <!-- Loading Screen -->
    <az-loader 
      v-if="pageState == 'loading'"
    ></az-loader>

  </f7-page>
</template>

<script>
import AZWizard from '@/components/ui/AZWizard.vue'
import AZLoader from '@/components/ui/AZLoader.vue'

import { formatWizardData, unformatWizardData, participantAPI, errorHandler } from '@/js/api';

export default {
  components: {
    'az-loader': AZLoader,
    'az-wizard': AZWizard
  },

  props: ['form_data_prop'],

  mounted () {
    if (this.isEditMode) {
      this.questions = unformatWizardData(this.form_data_prop, this.questions)
    }
  },

  data() {
    return {
      pageState: 'loaded',
      questions: [
        {
          title: "Date",
          subtitle: "",
          type: "datepicker",
          key: 'date',
          value: this.getDefaultDate(),
          error: ''
        },
        {
          title: "Question 1",
          subtitle: "Be Complete at the end of day by Emptying My Head & Transfer Everything into 'Calendar' or '4.1 Power Planner' or '4.4 Communication Manager'or '3.3 Games Accomplishment Blueprint'?",
          type: 'slider',
          key: 'question_1',
          value: 1,
          error: ''
        },
        {
          title: "Question 2",
          subtitle: "Review WHY any Incomplete Action didn't get done & update my learnings in  '5. My Personal Space' or '3.4 Reasons Eliminator'?",
          type: 'slider',
          key: 'question_2',
          value: 1,
          error: ''
        },
        {
          title: "Question 3",
          subtitle: "Transfer everything that didn't get done today to the next days and Schedule CLEAR Actions for Next 2 Days in my Calendar?",
          type: 'slider',
          key: 'question_3',
          value: 1,
          error: ''
        },
        {
          title: "Question 4",
          subtitle: "Respond to whoever I didn't keep my Promise with as to when will I now do it?",
          type: 'slider',
          key: 'question_4',
          value: 1,
          error: ''
        },
        {
          title: "Question 5",
          subtitle: "Have a deeper relationship with Time than yesterday? (Started Late, Inaccurate Time Estimate, Don't have Sufficient Time, Don't have Totality)",
          type: 'slider',
          key: 'question_5',
          value: 1,
          error: ''
        },
        {
          title: "Question 6",
          subtitle: "Have a deeper relationship with Focus than yesterday? (Interrupted, Distracted, No Reminders, Got involved in things Other than what is Most Important)",
          type: 'slider',
          key: 'question_6',
          value: 1,
          error: ''
        },
        {
          title: "Question 7",
          subtitle: "Have a deeper relationship with Clarity than yesterday? (Who will do it, Lack of Purpose or Motivation, Lack of knowing all steps, Lack of Skills/Competency, Lack of Information)",
          type: 'slider',
          key: 'question_7',
          value: 1,
          error: ''
        },
        {
          title: "Question 8",
          subtitle: "Have a deeper relationship with Reality than yesterday? (What does it look like in the world if your Game was alive, Do the Numbers add up, Does the Timeline Match, is it Actionable, Are there External Factors at play)",
          type: 'slider',
          key: 'question_8',
          value: 1,
          error: ''
        },
      ]
    }
  },

  computed: {
    isEditMode() {
      return (this.form_data_prop !== undefined);
    },
  },

  methods: {

    getDefaultDate () {
      var date = new Date()
      date.setDate(date.getDate() - 1)
      return [date.toDateString()]
    },

    onSaveClick () {
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID']
      let data = formatWizardData(this.questions);
      let url = this.isEditMode ? `batch/${batchId}/framework-mastery/${this.form_data_prop.id}` : `batch/${batchId}/framework-mastery`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...data,
      }).then((response) => {
        // Show Toast
        window.$f7.toast.create({
            text: 'Your data has been saved',
            closeTimeout: 3500,
        }).open();
        // Go Back
        this.$f7router.back();
      })
      .catch((error) => {
        errorHandler(error,
          (errors) => {
            window.$f7.dialog.alert(errors[0], "Error");
          });
      })
      .finally(() => {
        this.pageState = 'loaded';
      });
    },

    onDeleteClick () {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/framework-mastery/${this.form_data_prop.id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Go Back
          this.$f7router.back();
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    }
  }
}
</script>

