<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar title="Add Time Finder" back-link="Back"></f7-navbar>

    <!-- Wrapper -->
    <div class="wrapper">
      <az-list>
        <az-list-input label="Date" type="datepicker" v-model="formattedDate" />
      </az-list>

      <!-- Next Button -->
      <f7-fab position="right-bottom" slot="fixed" @click="onSaveAndNextClick">
        <f7-icon ios="f7:arrow_right" aurora="f7:arrow_right" md="material:arrow_forward"></f7-icon>
      </f7-fab>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZLoader from '@/components/ui/AZLoader.vue';
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import moment from 'moment';

export default {

  components: {
    'az-loader': AZLoader,
    'az-list': AZList,
    'az-list-input': AZListInput,
  },
  data() {
    return {
      pageState: 'loaded',
      form_data: {
        date: '',
      }
    }
  },

  methods: {
    onSaveAndNextClick() {
      this.pageState = 'loading';
      
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = `batch/${batchId}/time-finder`
      participantAPI().post(url,this.form_data,
      ).then(response => {
        // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.navigate(`/time-finder/${response.data.data}/routines/`, {
            reloadCurrent: true
          }); 
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },
  },

  computed: {
    formattedDate: {
      get() {
        let parts = this.form_data.date.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.date = dateValue.format('DD-MM-YYYY');
      }
    }
  }
}
</script>

<style>

</style>