<template>
  <f7-page @page:reinit="loadData" @page:beforeout="removeListener">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Accomplishments Diary ©</f7-nav-title>
    </f7-navbar>

    <!-- Page Content -->
    <div class="az-page">
      <div class="az-scrollable">

        <!-- Timeline -->
        <az-timeline v-if="accomplishments.length > 0" class="fab-wrapper">
          <az-timeline-item
            v-for="(accomplishment, index) in calculated_accomplishments"
            :key="accomplishment.id"
            :date="getFancyDate(accomplishment.date)"
            :text="getTextAttribute(accomplishment)"
            @click="onEditClick(accomplishments[index])"
          ></az-timeline-item>                  
        </az-timeline>

        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>

        <!-- Add Button -->
        <f7-fab position="right-bottom" slot="fixed" @click="onAddClick">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-fab>
      </div>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import moment from 'moment';

import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZTimeline from '@/components/ui/AZTimeline';
import AZTimelineItem from '@/components/ui/AZTimelineItem';

export default {
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'az-timeline': AZTimeline,
    'az-timeline-item': AZTimelineItem,
  },
  mounted() {
    this.loadData()
  },
  data() {
    return {
      pageState: 'loading',
      accomplishments: [],
      calculated_accomplishments: []
    }
  },
  methods: {
    keyPress(e) {
      var evtobj = window.event? event : e

      if (evtobj.key === 'n'  &&  evtobj.altKey) {  // case sensitive
          this.onAddClick()
      }
      evtobj.stopPropagation ();
      evtobj.preventDefault ()
    },

    addListener() {
      document.addEventListener('keydown', this.keyPress)
    },

    removeListener() {
      document.removeEventListener('keydown', this.keyPress)
    },

    mediaListener(ruleset) {
      let res = JSON.parse(JSON.stringify(this.accomplishments))
      if (ruleset.matches) { // If media query matches
        this.calculated_accomplishments = res.map(acc => {
          acc.body = this.shortenText(acc.body, 150)
          acc.feel = this.shortenText(acc.feel, 150)
          return acc
        })
      } else {
        this.calculated_accomplishments = res.map(acc => {
          acc.body = this.shortenText(acc.body, 500)
          acc.feel = this.shortenText(acc.feel, 500)
          return acc
        })
      }
    },

    shortenTextRoutine() {
      var ruleset = window.matchMedia("(max-width: 700px)")
      this.mediaListener(ruleset) // Call listener function at run time
      ruleset.addListener(this.mediaListener) // Attach listener function on state changes
    },

    shortenText(text, limit) {
      if (text !== null && text.length > limit) {
        text = text.substring(0, limit) + '...'
      }
      return text
    },

    getTextAttribute(accomplishment) {
      let text = `<b class='text-theme text-heavy'>
                    ${accomplishment.type_name}
                  </b><br>
                  <span style='word-break: break-all'>${accomplishment.body}</span><br>`
      
      if (accomplishment.feel) 
        text +=`<br><b class='text-theme text-heavy'>
                    How does that make you feel?
                  </b><br>${accomplishment.feel}`
      
      return text
    },

    // Helpers

    getFancyDate (date) {
      return moment(date, 'DD-MM-YYYY').format('DD') + " " +
            "<small>" + 
              moment(date, 'DD-MM-YYYY').format('MMM').toUpperCase() + " " + 
              moment(date, 'DD-MM-YYYY').format('YYYY') + 
            "</small>";
    },
    
    loadData () {
      this.addListener()
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/accomplishment`)
        .then((response) => {
          this.accomplishments = response.data.data;
          this.calculated_accomplishments = response.data.data
          this.pageState = 'loaded';
          this.shortenTextRoutine()
        })
        .catch((error) => {
          errorHandler(error);
          this.pageState = 'failed';
        });
    },

    // Button Click Events

    refreshData () {
      this.accomplishments = [];
      this.pageState = 'loading';
      this.loadData();
    },

    onAddClick () {
      this.$f7router.navigate('/accomplishments-diary/create/')
    },

    onItemClick(id) {
      this.currentId = id
      this.$refs.actionSheet.open()
    },

    onEditClick(accomplishment) {
      this.$f7router.navigate(`/accomplishments-diary/create/`, {
        props: {
          form_data_prop: accomplishment
        }
      })
    },

    
  }
}
</script>