<template>
    <f7-page>
        <!-- Navbar -->
        <f7-navbar>
        <!-- Sidebar Button -->
        <f7-nav-left>
            <f7-link 
                panel-open="left"
                icon-ios="f7:menu" 
                icon-aurora="f7:menu"
                icon-md="material:menu"
                >
            </f7-link>

            <!-- Page Title -->
            <f7-nav-title>
                Batches
            </f7-nav-title>

        </f7-nav-left>
        </f7-navbar>
        <div class="az-page">
            <div class="az-scrollable">
                <f7-list class="fab-wrapper"> 
                    <f7-list-item
                        v-for="(batch, index) in batches"
                        :key="batch.id"
                        radio
                        @change.native="updateSelect"
                        radio-icon="end"
                        :title="batch.prefix + batch.number"
                        name="demo-radio-end"
                        :checked="index == selected"
                        :value="index"
                    >
                    </f7-list-item>
                </f7-list>
            </div>

            <!-- Add Button -->
            <f7-fab class="fab-tab" position="right-bottom" slot="fixed" @click="onAddClick">
                <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
            </f7-fab>

            <!-- Loading Screen -->
            <az-loader v-if="pageState == 'loading'"/>
        </div>
    </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZLoader from '@/components/ui/AZLoader';

export default {

    name:'BatchSelect',
    components: {
        'az-loader': AZLoader
    },
    data() {
        return {
            pageState: 'loaded',
            selected: null
        }
    },

    mounted() {
       this.selected = this.$store.getters['auth/getCurrentBatch']
    },

    methods: {
        onAddClick() {
            window.$f7.dialog.confirm("Are you sure? This will create a new batch", "Colloquium", 
            () => {
                this.pageState = 'loading'
                participantAPI().post('batch')
                .then(res => {
                    this.$store.commit('auth/setBatches', res.data.data)
                    this.selected = this.$store.getters['auth/getCurrentBatch']
                    this.pageState = 'loaded'
                })
                .catch(err => {
                    this.pageState = 'loaded'
                }) 
            });
        },

        updateSelect(e) {
            this.selected = e.target.value 
            this.$store.commit("auth/setCurrentBatch", this.selected)
        }
    },

    computed: {
        batches() {
            return this.$store.getters['auth/getBatches']
        }
    }
}
</script>

<style>

</style>