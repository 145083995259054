<template>
  <div>
    
    <!-- Label -->
    <div class="padding" style="padding-bottom: 8px !important" v-if="title || subtitle">
      <h3 v-if="title" :class="{ 'no-margin' : noMargin }">{{ title }}</h3>
      <p v-if="subtitle" :class="{ 'no-margin' : noMargin }">{{ subtitle }}</p>
    </div>
    
    <!-- Input -->
    <az-list>
      <az-list-input 
        :valuePosition="valuePosition"
        :type="type"
        :clearButton="clearButton"
        v-model="dat_value"
        @input="update"
        :calendar-params="calendarParams"
      >
        <!-- Options for Select -->
        <option v-for="(value, key) in options"
                :key="key" :value="key">
          {{ value }}
        </option>
      </az-list-input>
    </az-list>

    <!-- Error Message -->
    <az-error-message v-if="error">
      {{ error }}
    </az-error-message>

  </div>
</template>

<script>
  import AZErrorMessage from '@/components/ui/AZErrorMessage.vue'
  import AZList from '@/components/ui/AZList.vue';
  import AZListInput from '@/components/ui/AZListInput.vue';
  export default {
    name: 'az-question',
    components: {
      'az-error-message': AZErrorMessage,
      'az-list': AZList,
      'az-list-input': AZListInput,
    },
    data() {
      return {
        // dat_value: ''
      }
    },
    // Props
    props: {
      title: String,
      subtitle: String,
      type: {
        type: String,
        default: 'text'
      },
      clearButton: {
        type: Boolean,
        default: false
      },
      calendarParams: Object,
      value: Object|String,
      error: String,
      noMargin: {
        type: Boolean,
        default: false
      },
      options: {
        type: Array|Object
      },
      valuePosition: {
        type: String,
      }
    },
    // Methods
    methods: {
      update(newValue) {
        this.$emit('input', newValue);
      },
    },

    computed: {
      dat_value: {
        get() {
          return this.value
        },

        set(val) {
          this.update(val)
        }
      }
    }
    
  }
</script>

<style scoped>

  /* Fix font size and padding */
  h3 {
    font-size: 16px;
  }
  .list >>> .item-inner {
    padding-top: 0;
  }

  /* Remove BG color */
  .list >>> ul {
    background: transparent !important;
  }
  .list >>> ul:before, .list >>> ul:after {
    content: none;
  }
  
  /* For Textarea */
  .list >>> textarea {
    height: 150px !important;
  }

  /* For Select */
  .list >>> option {
    color: var(--f7-input-text-color);
    background-color: var(--f7-page-bg-color);
  }

  .list {
    margin: 0 !important;
  }

</style>