<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Task'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Wrapper -->
    <div class="wrapper" v-if="pageState == 'loaded'">
      <az-list>
        <az-list-input label="Entry made on" type="datepicker" v-model="formattedEntryDate" />

        <az-list-input label="Project" type="smart-select" :options="projects" v-model="form_data.project_id">
        </az-list-input>

        <az-list-input label="Task initiated by" type="smart-select" :options="people" v-model="form_data.initiator_id">
        </az-list-input>

        <az-list-input label="Supervisors" type="smart-select" selection_type="multiple" :options="people" v-model="form_data.supervisors">
        </az-list-input>

        <az-list-input label="Doers" type="smart-select" selection_type="multiple" :options="people" v-model="form_data.doers">
        </az-list-input>

        <az-list-input label="Task" type="text" v-model="form_data.task" />

        <az-list-input label="Original Target Date" type="datepicker" v-model="formattedOriginalDate" />

        <az-list-input label="Revised Target Date" type="datepicker" v-model="formattedRevisedDate" />

        <az-list-input label="Priority" type="slider" v-model="form_data.priority"></az-list-input>

        <az-list-input label="Status" type="select" v-model="form_data.action_status">
            <option 
                v-for="(status, key) in actionStatuses"
                :key="key"
                :value="key"
            >{{ status }}</option>
        </az-list-input>

        <az-list-input v-show="form_data.action_status == 'Z'" label="Done Date" type="datepicker" v-model="formattedDoneDate" />

        <az-list-input label="Initiator Notes" type="textarea" v-model="form_data.initiator_notes"></az-list-input>

        <az-list-input label="Supervisor Notes" type="textarea" v-model="form_data.supervisor_notes"></az-list-input>

        <az-list-input label="Doer Notes" type="textarea" v-model="form_data.doer_notes"></az-list-input>

      </az-list>

    </div>

    <!-- Save Button -->
    <f7-fab position="right-bottom"
            @click="onSaveClick">
      <f7-icon ios="f7:floppy_disk" 
               aurora="f7:floppy_disk"
               md="material:save" />
    </f7-fab>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'
import axios from 'axios'
import AZList from '@/components/ui/AZList'
import AZListInput from '@/components/ui/AZListInput'
import { ActionStatuses } from '@/js/consts'
import moment from 'moment'
import AZLoader from '@/components/ui/AZLoader';
export default {
    props: {
        form_data_prop: Object
    },
    components: {
        'az-list': AZList,
        'az-list-input': AZListInput,
        'az-loader': AZLoader,
    },
    data() {
        return {
            pageState: 'loaded',
            projects: [],
            people: [],
            actionStatuses: ActionStatuses,
            form_data: {
                entry_made_on: '',
                project_id: null,
                initiator_id: null,
                supervisors: [],
                doers: [],
                task: '',
                original_target_date: null,
                revised_target_date: null,
                priority: 1,
                action_status: 'N',
                done_date: null,
                initiator_notes: '',
                supervisor_notes: '',
                doer_notes: ''
            }
        }
    },

    mounted() {
        if (this.isEditMode) {
            this.form_data = this.form_data_prop
        }
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        let apis = [
            participantAPI().get(`batch/${batchId}/people`),
            participantAPI().get(`batch/${batchId}/project`)
        ]

        axios.all(apis)
            .then((...res) => {
                this.people = res[0][0].data.data.map(person => {
                    return {
                        key: person.id,
                        value: person.full_name
                    }
                })
                this.projects = res[0][1].data.data.map(project => {
                    return {
                        key: project.id,
                        value: project.name
                    }
                })
            })
    },

    methods: {
        onSaveClick() {
            this.pageState = 'loading'
            let batchId = this.$store.getters['auth/getCurrentBatchID'];
            let url = this.isEditMode ? `batch/${batchId}/task/${this.form_data.id}` : `batch/${batchId}/task`
            participantAPI().post(url, {
                _method: this.isEditMode ? 'PUT' : 'POST',
                ...this.form_data
            })
            .then(res => {
                this.pageState = 'loaded'
                this.task = ''
                // Show Toast
                window.$f7.toast.create({
                    text: 'Your data has been saved',
                    closeTimeout: 3500,
                }).open();
                if (this.isEditMode) {
                    this.$f7router.back()
                    return
                }
                this.form_data.task = ''
                this.pageState = 'loaded'
            })
            .catch(err => {
                this.pageState = 'loaded'
                errorHandler(err,
                (errors) => {
                    window.$f7.dialog.alert(errors[0], "Error");
                });
            })
        },

        onDeleteClick() {
            // Show Confirmation
            window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
            () => {
                // Set state to loading
                this.pageState = "loading";
                // Make API call
                let batchId = this.$store.getters['auth/getCurrentBatchID'];
                // Delete data from people tag
                participantAPI().delete(`batch/${batchId}/task/${this.form_data.id}`)
                .then(() => {
                    // Show Toast
                    window.$f7.toast.create({
                        text: 'Your data has been deleted',
                        closeTimeout: 3500,
                    }).open();
                    // back to index
                    this.$f7router.back();
                })
                .catch(error => {
                    // Handle Errors
                    errorHandler(error);
                    this.pageState = 'loaded';
                });
            });
        }
    },

    computed: {
        isEditMode() {
            return (this.form_data_prop !== undefined);
        },

        formattedEntryDate: {
            get() {
                    let parts = this.form_data.entry_made_on.split('-');
                    if (parts.length >= 3) {
                    let day = parts[0];
                    let month = parts[1] - 1;
                    let year = parts[2];
                    return [new Date(year, month, day).toString()];
                }
                return [];
            },
            set(value) {
                let dateValue = moment(value[0]);
                this.form_data.entry_made_on = dateValue.format('DD-MM-YYYY');
            }
        },

        formattedOriginalDate: {
            get() {
                if (!this.form_data.original_target_date) return null
                let parts = this.form_data.original_target_date.split('-');
                if (parts.length >= 3) {
                    let day = parts[0];
                    let month = parts[1] - 1;
                    let year = parts[2];
                    return [new Date(year, month, day).toString()];
                }
                return [];
            },
            set(value) {
                let dateValue = moment(value[0]);
                this.form_data.original_target_date = dateValue.format('DD-MM-YYYY');
            }
        },

        formattedRevisedDate: {
            get() {
                if (!this.form_data.revised_target_date) return null
                let parts = this.form_data.revised_target_date.split('-');
                if (parts.length >= 3) {
                    let day = parts[0];
                    let month = parts[1] - 1;
                    let year = parts[2];
                    return [new Date(year, month, day).toString()];
                }
                return [];
            },
            set(value) {
                let dateValue = moment(value[0]);
                this.form_data.revised_target_date = dateValue.format('DD-MM-YYYY');
            }
        },

        formattedDoneDate: {
            get() {
                if(!this.form_data.done_date) return null
                
                let parts = this.form_data.done_date.split('-');
                if (parts.length >= 3) {
                    let day = parts[0];
                    let month = parts[1] - 1;
                    let year = parts[2];
                    return [new Date(year, month, day).toString()];
                }
                return [];
            },
            set(value) {
                let dateValue = moment(value[0]);
                this.form_data.done_date = dateValue.format('DD-MM-YYYY');
            }
        },
    },

    watch: {
        'form_data.action_status': {
            handler(val) {
                if (val != 'Z') {
                    this.form_data.done_date = null
                }
            }
        }
    }
}
</script>

<style>

</style>