<template>
  <div>
    
    <az-time-picker
      v-if="type == 'time'"
      :label="label"
      :all_minutes="all_minutes"
      :complete="complete"
      :value="value"
      @input="update"
    ></az-time-picker>

    <div v-else-if="type == 'datemask'">
      
        <az-date-mask :label="label" :value="value" @input="update"></az-date-mask>
    </div>

    <div v-else-if="type == 'slider'">
      <p v-if="valuePosition == 'top'" style="margin-left: 20px; margin-bottom: 10px">{{ label }}&nbsp;&nbsp;&nbsp;<span class="bubble">{{ value }}</span></p>
      <az-rating-input :min="min" :max="max" :value="value" @input="update"></az-rating-input>
      <p v-if="valuePosition == 'bottom'" style="display:flex; align-items: center; justify-content: center;"><span class="bubble">{{ value }}</span></p>
    </div>

    <az-recurrence 
      v-else-if="type == 'recurrence'"
      :date="date"
      :rrule="rrule"
      label="Recurrence" 
      :value="value" 
      @input="update">
    </az-recurrence>

    <az-smart-select
      v-else-if="type == 'smart-select'"
      :selected="value" 
      :items="options" 
      :label="label"
      :type="selection_type"
      @input="update"
    ></az-smart-select>

    <f7-list-item v-else-if="type == 'toggle'">
      <span>{{ label }}</span>
      <f7-toggle :checked="value == 1"
                  @toggle:change="updateToggle"></f7-toggle>
    </f7-list-item>

    <f7-list-input
      v-else
      :label="label"
      :placeholder="placeholder"
      :type="type"
      :readonly="readonly"
      
      :value="value"
      @input="update($event.target.value)"
      @keyup.native.enter="enterPress"
      
      :clear-button="clearButton"
      :calendar-params="calendarParams"
      @calendar:change="updateDate"
      
      outline
      floating-label
    >
      <slot></slot>
    </f7-list-input>
  </div>
</template>

<script>
import AZRatingInput from '@/components/ui/AZRatingInput'
import AZTimePicker from '@/components/ui/AZTimePicker'
import AZSmartSelect from '@/components/ui/AZSmartSelect'
import AZRecurrence from '@/components/ui/AZRecurrence';
import AZDateMask from '@/components/ui/AZDateMask';

export default {
  name: 'az-list-input',
  components: {
    'az-rating-input': AZRatingInput,
    'az-time-picker': AZTimePicker,
    'az-smart-select': AZSmartSelect,
    'az-recurrence': AZRecurrence,
    'az-date-mask': AZDateMask
  },
  props: {
    label: String,
    type: {
      type: String,
      default: 'text'
    },
    options: Array,
    selected_prop: Array,
    placeholder: String,
    calendarParams: Object,
    clearButton: {
      default: false
    },
    value: Object|String|Array,
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 10
    },
    selection_type: String,
    readonly: {
      type: Boolean,
      default: false
    },
    all_minutes: {
      type: Boolean,
      default: false
    },
    valuePosition: {
      type: String,
      default: 'top'
    },
    date: String,
    rrule: String,
    complete: Boolean
  },
  data() {
    return {
      cleaveOptions: {
              date: {
                    date: true,
                    datePattern: ['d', 'm', 'Y'],
                    delimiter: '-',
                },
            },
    }
  },
  // Methods
  methods: {
    update(newValue) {
      this.$emit('input', newValue);
    },
    updateDate(dateArray) {
      this.$emit('input', dateArray);
    },
    updateToggle(val) {
      this.$emit('input', val ? 1 : 0)
    },
    goToSelect() {
      this.$f7router.navigate('/smart-select/', {
        props: {
          items: this.options
        }
      })
    },
    enterPress() {
      this.$emit('enter-pressed')
    }
  },
  computed: {
    computedHours: {
      get() {
        return Math.floor(this.value / 60)
      },

      set(val) {
        this.calculatedHours = val
      }
    },

    computedMinutes: {
      get() {
        return this.value % 60
      },

      set(val) {
        this.calculatedMinutes = val
      }
    },
  }
}
</script>

<style scoped>
  .bubble {
    background: red;
    color: white;
    padding: 0px 7px;
    font-size: 16px;
    border-radius: 5px;
  }
</style>