<template>
  <f7-page class="people-index" @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>My People ©</f7-nav-title>
    </f7-navbar>

    <!-- Page Content -->
    <div class="az-page">
      <div class="az-scrollable">

        <!-- List -->
        <az-custom-list  v-if="people.length > 0">
          <az-custom-list-item 
            v-for="person in people"
            :key="person.id"
            :title="person.full_name" 
            :subtitle="person.type_name"
            :noMenu="true"
            @item-click="onEditClick(person)"
          > 
            <template v-slot:text>
              <p>{{ getPermissionText(person) }}</p>
            </template>
          </az-custom-list-item>
        </az-custom-list>

        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>

        <!-- Add Button -->
        <f7-fab position="right-bottom" slot="" @click="onAddClick">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-fab>
      </div>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZCustomList from '@/components/ui/AZCustomList';
import AZCustomListItem from '@/components/ui/AZCustomListItem'

export default {
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'az-custom-list': AZCustomList,
    'az-custom-list-item': AZCustomListItem
  },

  data() {
    return {
      pageState: 'loading',
      people: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {    
    loadDataFromAPI () {
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      // Load data for people tag
      participantAPI().get(`batch/${batchId}/people`)
        .then((response) => {
          this.people = response.data.data;
          this.pageState = 'loaded';
        }).catch((error) => {
            errorHandler(error);
            this.pageState = 'failed';
        });
    },

    getPermissionText(person) {
      let permissions = []
      if (person.access_communication) permissions.push('Communication Manager')
      if (person.access_money_maker) permissions.push('Money Maker')
      if (person.access_plan) permissions.push('Master Plan')
      return permissions.join(' | ')
    },

    // Button Click Events 

    refreshData () {
      this.people = [];
      this.pageState = 'loading';
      this.loadDataFromAPI(); 
    },

    // Person Click Events
    onAddClick() {
      this.$f7router.navigate('/people/create/');
    },

    onEditClick(person) {
      this.$f7router.navigate(`/people/create/`, {
        props: {
          form_data_prop: person
        }
      })
    },
  }
}
</script>