<template>
    <div class="legends row">
        <div class="legend col-100 small-50 medium-33"
            v-for="(category, key) in categories"
            :key="category.style.background"
            v-if="!blacklist.includes(key)"
        >
            <div class="color"
                :style="{background: category.style.background}"
            ></div>
            <span>{{ category.text }}</span>
        </div>
    </div>
</template>

<script>
import { Categories } from '@/js/consts'
export default {
    props: {
        categories: Object,
        blacklist: {
            type: Array,
            default: function() { return [] }
        }
    },
}
</script>

<style scoped>
    .legends {
        padding: 10px 30px;
    }
    
    .legend {
        display: inline-block;
        margin: 4px;
    }

    .legend .color{
        display: inline-flex; 
        background: red; 
        width: 10px; 
        height: 10px
    }
</style>