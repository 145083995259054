const GameTypes = {
    P: 'Primary Game',
    F: 'Family Game',
    D: 'Dream Game',
    H: 'Have To Do Game',
    O: 'Other Game'
};

const GameTypeIcons = {
    P: {
        ios: 'f7:briefcase',
        md: 'material:work'
    },
    F: {
        ios: 'f7:person_2',
        md: 'material:supervisor_account'
    },
    D: {
        ios: 'f7:flame',
        md: 'material:whatshot'
    },
    H: {
        ios: 'f7:star',
        md: 'material:star'
    },
    O: {
        ios: 'f7:gamecontroller',
        md: 'material:videogame_asset'
    },
    X: {
        ios: 'f7:lightbulb',
        md: 'material:flare'
    }
}

const ImplementationStatuses = {
    Y: 'Implemented',
    P: 'Practising',
    S: 'Stopped',
};

const ImplementationStatusNames = {
    Y: 'Implemented',
    P: 'Practising',
    S: 'Stopped',
};

const LeadSources = {
    EX: 'Existing Customer',
    BN: 'BNI',
    FF: 'Friends & Family',
    WB: 'Website',
    FB: 'Facebook',
    GS: 'Google Search',
    GA: 'Google Ads',
    IG: 'Instagram',
    LK: 'LinkedIn',
    JD: 'JustDial',
    IM: 'IndiaMart',
    EB: 'Exhibition',
    AD: 'Advertisement',
    NG: 'Networking Groups',
    OT: 'Other',
}

const LeadStatuses = {
    WON: {
        name : 'Won',
        color :  '#4CAF50'
    },
    LST: {
        name : 'Lost',
        color :  '#F44336'
    },
    HOT: {
        name : 'Hot',
        color :  '#FF9800'
    },
    WRM: {
        name : 'Warm',
        color :  '#FFEB3B'
    },
    CLD: {
        name : 'Cold',
        color :  '#f1f1f1',
    },
    LTR: {
        name : 'Later',
        color :  '##64b5f6'
    },
    ABN: {
        name : 'Abandoned ',
        color :  '#b71c1c',
        textColor: 'white'
    },
}

const LeadStages = {
    LED: {
        name : 'Lead',
        color :  '#9E9E9E'
    },
    ENQ: {
        name : 'Enquiry Recd',
        color :  '#FFFFFF'
    },
    PIT: {
        name : 'Pitched',
        color :  '#03A9F4'
    },
    QUT: {
        name : 'Quote Sent',
        color :  '#2196F3'
    },
    FOL: {
        name : 'Follow Up',
        color :  '#FFEB3B'
    },
    NEG: {
        name : 'Negotiation',
        color :  '#FF9800'
    },
    VEB: {
        name : 'Verbal Closed',
        color :  '#8BC34A'
    },
    RCD: {
        name : 'Order Recd',
        color :  '#4CAF50'
    },
    PYD: {
        name : 'Payment Recd',
        color :  '#E91E63'
    },
    LST: {
        name : 'Lost',
        color :  '#F44336'
    },
}

const Categories = {
    
    'T': {
        text: 'Top Thing I am paid for',
        style: {
            background: '#4a148c',
            color: 'white'
        }
    },

    'P': {
        text: 'Productive (Planned)',
        style:{
            background: '#66bb6a',
            color: 'black'
        }
    },

    'R': {
        text: 'Productive (Unplanned)',
        style:{
            background: '#f57c00',
            color: 'white'
        }
    },

    'A': {
        text: 'Productive (Accidental)',
        style: {
            background: '#26c6da',
            color: 'black'
        }
    },

    'N': {
        text: 'Not Sure',
        style: {
            background: '#ffd54f',
            color: 'black'
        }
    },

    'U': {
        text: 'Unproductive',
        style: {
            background: 'red',
            color: 'white'
        }
    },

    'PR': {
        text: 'Personal',
        style: {
            background: '#ec407a',
            color: 'white'
        }
    },

    'default': {
        text: '',
        style: {
            background: '#e5e5e5',
            color: 'black'
        }
    }
}

const ActionStatuses = {
    N: 'Not Started',
    P: 'In Process',
    E: 'External Follow Up',
    Z: 'Done',
    A: 'Abandoned',
    U: 'Stuck',
    O: 'SOS',
    S: 'Supervisor to Check',
    I: 'Initiator to Check'
}

let temp = []
for (let [key, value] of Object.entries(Categories)) {
    temp.push(value.style.background)
}

const CategoryColors = temp

const TimeFinderActions = {
    'A': {
        text: 'Automate',
        style: {
            background: '#66bb6a',
            color: 'black'
        }
    },

    'D': {
        text: 'Delegate',
        style: {
            background: '#f57c00',
            color: 'white'
        }
    },

    'R': {
        text: 'Reduce',
        style: {
            background: '#26c6da',
            color: 'black'
        }
    },

    'S': {
        text: 'Stop',
        style: {
            background: 'red',
            color: 'white'
        }
    }
}

const IndustryTypes = {
    'M': 'Manufacturer',
    'T': 'Trader',
    'P': 'Professional/Service Provider',
    'S': 'Start Up',
    'F': 'Solopreneur/Freelancer',
    'C': 'Corporate'
}

const Genders = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Other'
}

const MaritalStatuses = {
    'Y': 'Married',
    'N': 'Unmarried',
    'D': 'Divorced',
    'S': 'Separated'
}

export {
    GameTypes,
    GameTypeIcons,
    ImplementationStatuses,
    ImplementationStatusNames,
    LeadSources,
    LeadStatuses,
    LeadStages,
    Categories,
    CategoryColors,
    ActionStatuses,
    TimeFinderActions,
    IndustryTypes,
    Genders,
    MaritalStatuses
};