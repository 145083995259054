<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Insights Log ©</f7-nav-title>
    </f7-navbar>

    <!-- Page Content -->
    <div class="az-page">
      <div class="az-scrollable">
        <!-- Timeline -->
        <az-timeline v-if="insightLogs.length > 0" class="fab-wrapper">
          <az-timeline-item 
            v-for="(insightLog, index) in calculatedInsightLogs"
            :key="insightLog.id"
            :date="getFancyDate(insightLog.date)"
            :text="getInsightText(insightLog)"
                  @click="onEditClick(insightLogs[index])"
                  />
        
        </az-timeline>

        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>

        <!-- Add Button -->
        <f7-fab position="right-bottom" slot="fixed" @click="onAddClick">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-fab>
      </div>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZLoader from '@/components/ui/AZLoader';
import AZTimeline from '@/components/ui/AZTimeline';
import AZTimelineItem from '@/components/ui/AZTimelineItem';
import moment from 'moment';
import { ImplementationStatusNames } from '@/js/consts';

export default {
  name:'InsightIndex',
  components: {
    'az-timeline': AZTimeline,
    'az-timeline-item': AZTimelineItem,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'az-loader': AZLoader,
  },

  data() {
    return {
      pageState: 'loading',
      insightLogs: [],
      calculatedInsightLogs: []
    }
  },
  
  mounted() {
    this.loadDataFromAPI()
  },

  methods: {

    mediaListener(ruleset) {
      let res = []
      if (this.insightLogs.length) {
        res = JSON.parse(JSON.stringify(this.insightLogs))
      }
      if (ruleset.matches) { // If media query matches
        this.calculatedInsightLogs = res.map(acc => {
          acc.my_insight = this.shortenText(acc.my_insight, 150)
          acc.new_action = this.shortenText(acc.new_action, 150)
          return acc
        })
      } else {
        this.calculatedInsightLogs = res.map(acc => {
          acc.my_insight = this.shortenText(acc.my_insight, 500)
          acc.new_action = this.shortenText(acc.new_action, 500)
          return acc
        })
      }
    },

    shortenTextRoutine() {
      var ruleset = window.matchMedia("(max-width: 700px)")
      this.mediaListener(ruleset) // Call listener function at run time
      ruleset.addListener(this.mediaListener) // Attach listener function on state changes
    },

    shortenText(text, limit) {
      if (text !== undefined && text !== null && text.length > limit) {
        text = text.substring(0, limit) + '...'
      }
      return text
    },

    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/insight`)
        .then(response => {          
          this.insightLogs = response.data.data
          this.pageState = 'loaded'
          this.shortenTextRoutine()
        })
        .catch(error => {
          console.log(error)
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    getFancyDate (date) {
      return moment(date, 'DD-MM-YYYY').format('DD') + " " +
            "<small>" + 
              moment(date, 'DD-MM-YYYY').format('MMM').toUpperCase() + " " + 
              moment(date, 'DD-MM-YYYY').format('YYYY') + 
            "</small>";
    },
    
    getInsightText(insightLog) {
      let text = `<b class='text-theme text-heavy'>Insight:</b> ${insightLog.my_insight} <br>`
      if( (insightLog.new_action != null) && (insightLog.new_action.length > 0)){
        text += `<br><b class='text-theme text-heavy'>New Action:</b> ${insightLog.new_action}<br>`
      }
      text += `<div class="impl-btn"> ${ImplementationStatusNames[insightLog.implemented]}</div>`
      return text
    },
    // Button Click Events
    
    refreshData () {
      this.insightLogs = [];
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    onAddClick() {
      this.$f7router.navigate('/insights-log/create/');
    },

    onEditClick(insightLog) {      
      this.$f7router.navigate(`/insights-log/create/`, {
        props: {
          form_data_prop: insightLog
        }
      })
    },
  }
}
</script>