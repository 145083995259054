import { render, staticRenderFns } from "./CreatePage.vue?vue&type=template&id=1caece9c&"
import script from "./CreatePage.vue?vue&type=script&lang=js&"
export * from "./CreatePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports