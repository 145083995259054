<template>
  <f7-app :params="f7params">

    <f7-panel v-if="isSidebarVisible && isGApiLoaded"
              :left="true"
              :cover="this.$theme.md" 
              :reveal="!this.$theme.md"
              :visible-breakpoint="960">
      <cq-navigation/>
    </f7-panel>

    <f7-view v-show="isGApiLoaded" main class="safe-areas" url="/"></f7-view>

    <!-- Loading Screen -->
    <az-loader v-if="isLoading || !isGApiLoaded" />

  </f7-app>
</template>

<script>
import { mapState } from 'vuex';
import routes from '../js/routes.js';
import AZLoader from '@/components/ui/AZLoader';
import CQNavigation from '@/components/layout/CQNavigation';
import VueScript2 from 'vue-script2';
import { participantAPI, errorHandler } from '@/js/api';

export default {
  components: {
    'cq-navigation': CQNavigation,
    'az-loader': AZLoader
  },
  data() {
    return {
      isGApiLoaded: false,
      // Framework7 Parameters
      f7params: {
        name: 'Colloquium', // App name
        theme: 'auto', // Automatic theme detection
        // View params
        view: {
          pushState: true
        },
        // Calendar Parameters
        calendar: {
          dateFormat: 'dd-mm-yyyy',
          closeOnSelect: true
        },
        // App routes
        routes: routes,
        // Register service worker
        serviceWorker: {
          path: '/service-worker.js',
        },
      },
    }
  },
  computed: {
    ...mapState('ui', [
      'isLoading',
      'isSidebarVisible',
    ])
  },
  beforeCreate() {
    participantAPI().get('permission')
    .then(res => {
      this.$store.commit('auth/setPermissions', res.data.data)
    })
    .catch(err => {
      errorHandler(err)
    })
    VueScript2.load('https://apis.google.com/js/api.js')
    .then(() => {
      // store.dispatch('googleAuth/googleSignOut')
      this.$store.dispatch('googleAuth/handleClientLoad', () => {
        this.isGApiLoaded = true
        this.$store.commit('googleAuth/setGapiLoaded', true)
      });
    })
    .catch(err => {
      window.$f7.dialog.alert('Google Sign in services could not be loaded');
      this.isGApiLoaded = true
    })
  },
  mounted() {
    this.$f7ready((f7) => {
      window.$f7 = f7;
    });
  }
}
</script>