<template>
    <div class="pagination-wrapper">
        <div class="pagination" v-if="last != 1">
            <!-- <span @click="changePage('first')"><f7-icon material="fast_rewind"></f7-icon></span> -->
            <div class="page-numbers-container">
                <span v-if="links.prev" class="page-numbers" @click="changePage('prev')">Prev</span>
                <span 
                    v-for="page in pageNumbers"
                    :key="page"
                    aria-current="page" 
                    class="page-numbers"
                    :class="{'current' : current == page}"
                    @click="changePage(page)"
                >{{ page }}</span>
                <span v-if="links.next" class="page-numbers" @click="changePage('next')">Next</span>
            </div>
            
            <!-- <span @click="changePage('last')"><f7-icon material="fast_forward"></f7-icon></span> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'AZPaginator',
    props: {
        current: String|Number,
        last: String|Number,
        links: Object,
        base_url: String
    },
    data() {
        return {
            pageNumbers: []
        }
    },

    mounted() {
        let start = 1
        let finish = this.last
        
        if (this.current > 3) {
            start = this.current - 2
            if (this.last - this.current >= 2) {
                finish = this.current + 2
            }
            else {
                start = start - (2 - this.last + this.current)
            }
        }
        else {
            if (this.last > 5) {
                finish = 5
            }
        }
        for (let i = start; i <= finish; i++) {
            this.pageNumbers.push(i)
        }
    },

    methods: {
        changePage(page) {
            this.$emit('pageChange', page)
        }
    }
}
</script>

<style scoped>

    .pagination-wrapper {
        position: absolute;
        bottom: -40px;
        width: 100%;
    }

    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;   
        align-items: center;  
    }

    .page-numbers-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        color: black;
        margin: 10px 5px;
        border-radius: 20px;   
    }

    .page-numbers {
        min-height: 30px;
        min-width: 30px;
        margin: 10px 1px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #a5a5a5;
    }

    .page-numbers.current {
        background: red;
        color: white;
    }

</style>