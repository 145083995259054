<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar title="Time Auditor" back-link="Back"></f7-navbar>

    <!-- Body -->
    <div>
    <f7-block-title>Start Auditing</f7-block-title>
      <az-list>
        <az-list-input
          label="Enter a date that you would like to audit"
          type="datepicker"
          v-model="formattedDate"
        ></az-list-input>

        <az-list-input label="Start Time" type="select" v-model="start_time">
          <option 
            v-for="(time,key) in start_times"
            :key="key"
            :value="key">{{ time.replace('.', ':') }}</option>
        </az-list-input>
      </az-list>

      <!-- Next button -->
      <f7-fab position="right-bottom" slot="fixed" @click="onNextClick">
        <f7-icon ios="f7:arrow_right" aurora="f7:arrow_right" md="material:arrow_forward"></f7-icon>
      </f7-fab>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import AZList from '@/components/ui/AZList'
import AZListInput from '@/components/ui/AZListInput'
import { start_times, new_time_auditor_options } from '@/js/helpers/TimeOptions.js'
import AZLoader from '@/components/ui/AZLoader';
import { participantAPI, errorHandler } from '@/js/api'
import {arrayRotate} from '@/js/helpers/ArrayOperations' 
import moment from 'moment'

export default {
  components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-loader': AZLoader
  },
  data() {
    return {
      date: '',
      start_time: '',
      pageState: 'loaded',
      start_times: start_times
    }
  },
  methods: {
    onNextClick() {
      this.pageState = 'loading'
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().post(`batch/${batchId}/time-auditor`, {
        method: 'POST',
        date: this.date,
        start_time: this.start_time
      })
      .then(res => {
        this.pageState = 'loaded'
        let start = this.start_time.split(':').join('')
        let entries = []

        let time_keys = Object.keys(new_time_auditor_options).sort()
        for (let i = 0; i < 4; i++) {
          arrayRotate(time_keys)
        }
        let data = res.data.data
        let currentIndex = 0

        time_keys.map((i, index) => {
          if (i == start) {
            currentIndex = index
          }
          let temp = {
            key: i,
            text: new_time_auditor_options[i].text,
            label: new_time_auditor_options[i].label,
            entry: data['entry_' + i],
            work_related: 1,
            category: null
          }
          entries.push(temp)
        })

        this.$f7router.navigate('/time-auditor/entries/create/', {
          reloadCurrent: true,
          props: {
            auditor_id: res.data.data.id,
            currentIndex: currentIndex,
            entries_prop: entries
          }
        })
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    }
  },

  computed: {
    formattedDate: {
      get() {
        let parts = this.date.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.date = dateValue.format('DD-MM-YYYY');
      }
    },
  }
}
</script>

