<template>
  <f7-page>

    <f7-navbar back-link="Back">
      <f7-nav-left>
        <f7-link 
          :icon-ios="currentIcon.ios" 
          :icon-aurora="currentIcon.ios"
          :icon-md="currentIcon.md"
        ></f7-link>
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title style="margin:0">
        {{ form_data_prop.name }}
      </f7-nav-title>
      <f7-nav-right>
          <f7-link icon-ios="f7:pencil"
                   icon-aurora="f7:pencil"
                   icon-md="material:create"
                   @click="onEditClick" />

          <f7-link icon-ios="f7:trash"
                   icon-aurora="f7:trash"
                   icon-md="material:delete"
                   @click="onDeleteClick" />
      </f7-nav-right>
    </f7-navbar>
    
    <f7-block-title>Write down some Result you want to Produce</f7-block-title>
    <f7-block strong>
      <p>{{ form_data_prop.result ? form_data_prop.result : 'N/A' }}</p>
    </f7-block>

    <f7-block-title>Question 1: What will you get if you achieved this Result? Write what will give you power &amp; energy.</f7-block-title>
    <f7-block strong>
      <p>{{ form_data_prop.power_energy ? form_data_prop.power_energy : 'N/A' }}</p>
    </f7-block>

    <f7-block-title>Question 2: What will Answer 1 give you? Why do you care about Answer 1?</f7-block-title>
    <f7-block strong>
      <p>{{ form_data_prop.care ? form_data_prop.care : 'N/A' }}</p>
    </f7-block>

    <f7-block-title>Question 3: What will Answer 2 prove to you about you?</f7-block-title>
    <f7-block strong>
      <p>{{ form_data_prop.prove ? form_data_prop.prove : 'N/A' }}</p>
    </f7-block>

    <f7-block-title>Your Purpose</f7-block-title>
    <f7-block strong>
      <p>{{ form_data_prop.purpose ? form_data_prop.purpose : 'N/A' }}</p>
    </f7-block>

    <f7-block-title>Being aware of your Purpose, now write your Result as a SUCCESS STATEMENT</f7-block-title>
    <f7-block strong>
      <p>{{ form_data_prop.success_statement ? form_data_prop.success_statement : 'N/A' }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import { GameTypeIcons } from '@/js/consts';
export default {
  props: {
    form_data_prop: {
      required: true
    }
  },

  methods: {
    onEditClick() {
      this.$f7router.navigate(`/game-creator/create/`, {
        props: {
          form_data_prop: this.form_data_prop,
        }
      });
    },

    onDeleteClick() {
       // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/game-creator/${this.form_data_prop.id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Reload list
          this.$f7router.navigate('/game-creator/')
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    }
  },

  computed: {
    currentIcon() {
      return GameTypeIcons[this.form_data_prop.type]
    }
  }
}
</script>