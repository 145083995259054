<template>

    <div class="az-tab-page">
      <!-- Wrapper -->
      <div class="az-tab-scrollable">
        <!-- List -->
        <f7-list media-list v-if="auditors.length > 0" class="fab-wrapper">
          <f7-list-item v-for="auditor in auditors"
                        :key="auditor.id"
                        link="#"
                        :title="getFancyDate(auditor.date)"
                        :subtitle="getDayText(auditor.date)"
                        @click="itemClick(auditor.id)"/>
        </f7-list>

        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>

      </div>

      <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData"></az-page-error-message>

      <!-- Add Button -->
      <f7-fab class="fab-tab" position="right-bottom" slot="fixed" @click="onAddClick">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>

      <!-- Loading Screen -->
      <az-loader v-if="pageState == 'loading'"/>

    </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api' 
import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import moment from 'moment';

export default {
  name: 'TimeAuditorIndex',
  props: {
    auditors: Array,
    pageState: String
  },
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
  },
  data() {
    return {
    }
  },
  methods: {
    loadDataFromAPI() {
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/time-auditor`)
        .then(res => {
          this.auditors = res.data.data.time_auditors
          this.pageState = 'loaded'
        })
        .catch(err => {
          this.pageState = 'loaded'
          errorHandler(err)
        })
    },
    onAddClick() {
      this.$f7router.navigate('/time-auditor/create/');
    },

    getFancyDate (date) {
      return moment(date, 'DD-MM-YYYY').format('MMMM') + " " + 
              moment(date, 'DD-MM-YYYY').format('D') + ", " +
              moment(date, 'DD-MM-YYYY').format('YYYY')
    },

    getDayText(date) {
      return moment(date, 'DD-MM-YYYY').format('dddd')
    },

    itemClick(id) {
      this.$f7router.navigate('/time-auditor/entries/', {
        props: {
          auditor_id: id
        }
      });
    },

    refreshData() {
      this.$emit('refresh')
    }
  },

  mounted() {
    // this.loadDataFromAPI()
  },
}
</script>

<style scoped>
  .item-media i {
    color: var(--f7-theme-color) !important;
  }
  .list >>> .item-title {
    font-weight: 600 !important;
  }
</style>