<template>
  <li>
    <a :href="href" class="item-link item-content">
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title"><span v-html="action"></span></div>
          <div class="item-after">
            <f7-icon slot="media"
                     :ios="getAppleIcon"
                     :aurora="getAppleIcon"
                     :md="getMdIcon"
                     :color="getIconColor"
                      />
          </div>
        </div>
        <div class="item-text">
          <span v-if="date">Target Date: {{ date }}</span>
          <span class="blocke" v-if="delegate"><br>Delegate To: {{ delegate }}</span>
          <div class="inline pin" v-if="actionable">Actionable</div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: 'cq-master-item',
  props: ['href', 'action', 'date', 'actionable', 'delegate', 'status'],
  computed: {
    getMdIcon() {
      if (!this.actionable)
        return 'material:chevron_right';
      if (this.status) {
        return 'material:done_all';
      } 
      else return 'material:update';
    },
    getAppleIcon() {
      if (!this.actionable)
        return 'f7:chevron_right'
      if (this.status) {
        return 'f7:checkmark_alt';
      } 
      else return 'f7:timer';
    },
    getIconColor() {
      if (this.status) {
        return 'green';
      } else {
        return 'gray';
      }
    }
  }
}
</script>

<style scoped>
  li >>> .item-link .item-title-row {
    padding-right: 0;
  }
  li >>> .item-link .item-title-row:before {
    content: none;
  }
  li >>> .item-text {
    display: block;
    max-height: initial;
    overflow: initial;
    text-overflow: initial;
  }
  .pin {
    margin-top: 5px;
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: center;
    padding: 2px 3px;
    border-radius: 5px;
    background: grey;
    color: white;
  }
</style>