<template>
  <f7-page class="main-pg" @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link 
          panel-open="left"
          icon-ios="f7:menu" 
          icon-aurora="f7:menu"
          icon-md="material:menu"
        ></f7-link>
      </f7-nav-left>

      <!-- Page Title -->
      <f7-nav-title>
        Game Creator ©
      </f7-nav-title>
    
    </f7-navbar>

    <!-- Page Content -->
    <div class="az-page">
      <div class="az-scrollable">

        <f7-list v-if="gameCreators.length > 0" class="fab-wrapper">
          <f7-list-item media-list
            v-for="gameCreator in gameCreators"
            :key="gameCreator.id"
            :title="gameCreator.name"
            :subtitle="gameCreator.success_statement || 'NA'"
            @click="onGameClick(gameCreator)"
            link="#"
          >
              <f7-icon
                slot="media"
                color="primary"
                :aurora="typeIcons[gameCreator.type].ios"
                :ios="typeIcons[gameCreator.type].ios"
                :md="typeIcons[gameCreator.type].md"
              ></f7-icon>

          </f7-list-item>
        </f7-list>

        <!-- No Data Message -->
        <template v-else>
          <az-page-message v-if="pageState != 'failed'" />
        </template>
      </div>

      <!-- Add Button -->
      <f7-fab
        slot="fixed"
        position="right-bottom"
        href="/game-creator/create/"
      >
          <f7-icon
            ios="f7:plus"
            aurora="f7:plus"
            md="material:add"
          ></f7-icon>

      </f7-fab>
    
    </div>

    <!-- Loading Screen -->
    <az-loader
      v-if="pageState == 'loading'"
    ></az-loader>

    <!-- Error Message -->
    <az-page-error-message
      v-if="pageState == 'failed'"
      @try-again="refreshData"
    ></az-page-error-message>

  </f7-page>
</template>

<script>
import { GameTypeIcons } from '@/js/consts'

import { participantAPI, errorHandler } from '@/js/api'
import AZCustomList from '@/components/ui/AZCustomList'
import AZCustomListItem from '@/components/ui/AZCustomListItem'

import AZLoader from '@/components/ui/AZLoader'
import AZPageMessage from '@/components/ui/AZPageMessage'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage'

export default {

  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'az-custom-list': AZCustomList,
    'az-custom-list-item': AZCustomListItem
  },

  data () {
    return {
      pageState: 'loading',
      typeIcons: GameTypeIcons,
      gameCreators: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {

    loadDataFromAPI () {
      let batchId = this.$store.getters['auth/getCurrentBatchID']
      participantAPI().get(`batch/${batchId}/game-creator`)
        .then((response) => {
          this.pageState = 'loaded'
          this.gameCreators = response.data.data
        }).catch((error) => {
            errorHandler(error)
            this.pageState = 'failed'
        });
    },

    refreshData () {
      this.gameCreators = []
      this.pageState = 'loading'
      this.loadDataFromAPI()
    },

    onGameClick (item) {
      this.$f7router.navigate(`/game-creator/show/`, {
        props: {
          form_data_prop: item
        }  
      })
    }

  }
}
</script>

<style scoped>
  .item-media i {
    color: var(--f7-theme-color) !important;
  }

  .main-pg >>> .item-subtitle {
    color: #757575 !important;
    overflow:visible;
    white-space: normal;
    word-break: break-all;
  }

  .main-pg >>> .item-title {
    overflow:visible;
    white-space: normal;
    word-break: break-all;
  }
</style>