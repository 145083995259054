<template>
    <f7-page class="outer">
        <!-- Navbar -->
        <f7-navbar :title="currentTitle" back-link="Back">
            <f7-link slot="right" @click="onEditClick">
                <f7-icon ios="f7:pencil" aurora="f7:pencil" md="material:edit"></f7-icon>
            </f7-link>
        </f7-navbar>

        <div class="wrapper" v-if="pageState == 'loaded'">
            
            <f7-card v-if="graphData.labels.length > 0" class="summary-card">
                <div class="wrapper">
                    <div id="chart"></div>
                </div>
            </f7-card>
            
            <div class="no-data" v-else>
                <h4>No data to show</h4>
            </div>
        </div>

        <!-- Loading Screen -->
        <az-loader v-if="pageState == 'loading'"/>
    </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader.vue';
import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
export default {
    name: 'HabitShow',
    props: ['id', 'form_data_prop'],
    components: {
        'az-loader': AZLoader
    },
    data() {
        return {
            pageState: 'loading',
            timings: [],
            colors: ['#7cd6fd', '#743ee2', '#ff5623']
        }
    },
    mounted() {
        
        this.loadDataFromAPI()
        .then(() => {

            const chart = new Chart("#chart", {  // or a DOM element,
                                                // new Chart() in case of ES6 module with above usage
                title: this.form_data_prop.name,
                data: this.graphData,
                type: 'line', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
                height: 250,
                colors: this.colors
            })
        })
    },
    methods: {
        loadDataFromAPI() {
            let batchId = this.$store.getters['auth/getCurrentBatchID']
            return participantAPI().get(`batch/${batchId}/top-thing/${this.id}/get-timings`)
                .then(res => {
                    this.timings = res.data.data
                    this.pageState = 'loaded'
                })
        },

        onEditClick() {
            this.$f7router.navigate(`/top-things-paid/top-things/create/`, {
                props: {
                    form_data_prop: this.form_data_prop
                }
            })
        }
    },

    computed: {
        currentTitle() {
            return this.form_data_prop ? this.form_data_prop.name : ''
        },

        graphData() {
            if (this.timings) {
                let entries = JSON.parse(JSON.stringify(this.timings))
                entries.sort((a, b) => {
                    let keyA = new Date(a.date),
                    keyB = new Date(b.date);
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                })
                return {
                    labels: entries.map(entry => entry.date),
                    datasets: [
                        {
                            name: 'Actual', type: 'line',
                            values: entries.map(timing => timing.time)
                        }
                    ]
                }
            } 
            return {}
        },
    }
}
</script>

<style scoped>

    .outer >>> .graph-svg-tip {
        display: none !important;
        height: 0 !important;
    }

    .outer >>> .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80vh;
    }

</style>