<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Performance Builder'" back-link="Back"></f7-navbar>

    <!-- Wrapper -->
    <div class="wrapper" v-if="pageState == 'loaded'">
      <az-list>
        <az-list-input label="Promised Outcome" type="text" v-model="form_data.name" />

        <az-list-input label="Game Type" type="select" v-model="form_data.type">
          <option v-for="(type, key) in types" 
            :key="key"
            :value="key">
            {{ type }}
          </option>
          <option value="X">Other</option>
        </az-list-input>

        <az-list-input v-if="!isEditMode" label="Start Date" type="datepicker" v-model="formattedStartDate"></az-list-input>

        <az-list-input v-if="!isEditMode" label="End Date" type="datepicker" v-model="formattedEndDate"></az-list-input>
      </az-list>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Save Button -->
    <f7-fab position="right-bottom"
            @click="onSaveClick">
      <f7-icon ios="f7:floppy_disk" 
               aurora="f7:floppy_disk"
               md="material:save" />
    </f7-fab>

  </f7-page>
</template>

<script>
import AZList from '@/components/ui/AZList.vue';
import AZLoader from '@/components/ui/AZLoader.vue'
import AZListInput from '@/components/ui/AZListInput.vue';
import moment from 'moment'
import { participantAPI, errorHandler } from '@/js/api'
import { GameTypes } from '@/js/consts';

export default {
  props: {
    form_data_prop: Object
  },
  components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-loader': AZLoader
  },

  mounted() {
    if (this.isEditMode) {
      this.form_data = this.form_data_prop
    }
  },

  data() {
    return {
      types: GameTypes,
      pageState: 'loaded',
      form_data: {
        name: '',
        type: '',
        start_date: '',
        end_date: '',
        entries: []
      },
      promises: []
    }
  },

  methods: {
    onSaveClick() {
      this.pageState = 'loading'
      // }
      this.form_data.entries = this.entries
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = `batch/${batchId}/performance-builder`
      let method = 'POST'
      
      if (this.isEditMode) {
        url += `/${this.form_data.id}`
        method = 'PUT'
      }
      participantAPI().post(url, {
        _method: method,
        ...this.form_data
      })
      .then(res => {
         window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          this.$f7router.navigate('/performance-builder/');
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    }
  },

  computed: {
    formattedStartDate: {
      get() {
        let parts = this.form_data.start_date.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.start_date = dateValue.format('DD-MM-YYYY');
      }
    },

    formattedEndDate: {
      get() {
        let parts = this.form_data.end_date.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.end_date = dateValue.format('DD-MM-YYYY');
      }
    },

    sundays() {
      // Test values
      let start = moment(this.form_data.start_date, 'DD-MM-YYYY')
      let end = moment(this.form_data.end_date, 'DD-MM-YYYY')

      var arr = [];
      // Get "next" sunday
      let first_sun = start.clone()
      if (start.day() != 0) first_sun = start.add(7 - start.day(), 'days')
      if (first_sun.isAfter(end)) {
        return []
      }
      let current_day = first_sun
      while (current_day.isSameOrBefore(end)) {
        arr.push({
          human_date: current_day.format('DD-MM-YYYY'),
          db_date: current_day.format('YYYY-MM-DD')  
        })
        current_day = current_day.add(7, 'days')
      }
      this.promises = Array(arr.length)
      return arr
    },  
    
    entries() {
      return this.sundays.map(sunday => {
        return {
          date: sunday.db_date,
        }
      })
    },

    isEditMode() {
      return this.form_data_prop !== undefined
    }
  }
}
</script>