let all_times = {
    "00:00": "12.00 AM",
    "00:30": "12.30 AM",
    "01:00": "01.00 AM",
    "01:30": "01.30 AM",
    "02:00": "02.00 AM",
    "02:30": "02.30 AM",
    "03:00": "03.00 AM",
    "03:30": "03.30 AM",
    "04:00": "04.00 AM",
    "04:30": "04.30 AM",
    "05:00": "05.00 AM",
    "05:30": "05.30 AM",
    "06:00": "06.00 AM",
    "06:30": "06.30 AM",
    "07:00": "07.00 AM",
    "07:30": "07.30 AM",
    "08:00": "08.00 AM",
    "08:30": "08.30 AM",
    "09:00": "09.00 AM",
    "09:30": "09.30 AM",
    "10:00": "10.00 AM",
    "10:30": "10.30 AM",
    "11:00": "11.00 AM",
    "11:30": "11.30 AM",
    "12:00": "12.00 PM",
    "12:30": "12.30 PM",
    "13:00": "01.00 PM",
    "13:30": "01.30 PM",
    "14:00": "02.00 PM",
    "14:30": "02.30 PM",
    "15:00": "03.00 PM",
    "15:30": "03.30 PM",
    "16:00": "04.00 PM",
    "16:30": "04.30 PM",
    "17:00": "05.00 PM",
    "17:30": "05.30 PM",
    "18:00": "06.00 PM",
    "18:30": "06.30 PM",
    "19:00": "07:00 PM",
    "19:30": "07.30 PM",
    "20:00": "08.00 PM",
    "20:30": "08.30 PM",
    "21:00": "09.00 PM",
    "21:30": "09.30 PM",
    "22:00": "10.00 PM",
    "22:30": "10.30 PM",
    "23:00": "11.00 PM",
    "23:30": "11.30 PM"
}

let time_auditor_options = {
    "0500": "05:00 AM",
    "0530": "05:30 AM",
    "0600": "06:00 AM",
    "0630": "06:30 AM",
    "0700": "07:00 AM",
    "0730": "07:30 AM",
    "0800": "08:00 AM",
    "0830": "08:30 AM",
    "0900": "09:00 AM",
    "0930": "09:30 AM",
    "1000": "10:00 AM",
    "1030": "10:30 AM",
    "1100": "11:00 AM",
    "1130": "11:30 AM",
    "1200": "12:00 PM",
    "1230": "12:30 PM",
    "1300": "01:00 PM",
    "1330": "01:30 PM",
    "1400": "02:00 PM",
    "1430": "02:30 PM",
    "1500": "03:00 PM",
    "1530": "03:30 PM",
    "1600": "04:00 PM",
    "1630": "04:30 PM",
    "1700": "05:00 PM",
    "1730": "05:30 PM",
    "1800": "06:00 PM",
    "1830": "06:30 PM",
    "1900": "07:00 PM",
    "1930": "07:30 PM",
    "2000": "08:00 PM",
    "2030": "08:30 PM",
    "2100": "09:00 PM",
    "2130": "09:30 PM",
    "2200": "10:00 PM",
    "2230": "10:30 PM",
    "2300": "11:00 PM",
    "2330": "11:30 PM",
    "0000": "12:00 AM",
    "0030": "12:30 AM",
    "0100": "01:00 AM",
    "0130": "01:30 AM",
    "0200": "02:00 AM"
}

let new_time_auditor_options = Object.entries(time_auditor_options).sort()

let temp = {}
for ( let i = 0; i < new_time_auditor_options.length - 1; i++ ) {
    
    if (false) {
        temp[new_time_auditor_options[i][0]] = {
            text: new_time_auditor_options[i][1],
            label: new_time_auditor_options[i][1] + ' to 02:00 AM'
        } 
    }
    
    else {

        temp[new_time_auditor_options[i][0]] = {
            text: new_time_auditor_options[i][1],
            label: new_time_auditor_options[i][1] + ' to ' + new_time_auditor_options[i + 1][1]
        }
    }
}
temp['2330'] = {
    text: '11:30 PM',
    label: '11:30 PM to 12:00 AM'
}
delete temp['0200']
new_time_auditor_options = temp

let start_times = {
    "05:00": "05.00 AM",
    "05:30": "05.30 AM",
    "06:00": "06.00 AM",
    "06:30": "06.30 AM",
    "07:00": "07.00 AM",
    "07:30": "07.30 AM",
    "08:00": "08.00 AM",
    "08:30": "08.30 AM",
    "09:00": "09.00 AM",
    "09:30": "09.30 AM",
    "10:00": "10.00 AM",
    "10:30": "10.30 AM",
    "11:00": "11.00 AM",
    "11:30": "11.30 AM",
    "12:00": "12.00 PM",
    "12:30": "12.30 PM",
    "13:00": "01.00 PM",
    "13:30": "01.30 PM",
    "14:00": "02.00 PM",
    "14:30": "02.30 PM",
    "15:00": "03.00 PM",
    "15:30": "03.30 PM",
    "16:00": "04.00 PM",
}

let time_array = Object.values(all_times)

let hourOptions = {}
for (let i = 0; i < 24; i++) {
    if (i < 10) hourOptions[i] = '0' + i
    else hourOptions[i] = '' + i
}

let completeHourOptions = {}
for (let i = 0; i <= 24; i++) {
    if (i < 10) completeHourOptions[i] = '0' + i
    else completeHourOptions[i] = '' + i
}

let allMinuteOptions = {}
for (let i = 0; i < 60; i+=5) {
    if (i < 10) allMinuteOptions[i] = '0' + i
    else allMinuteOptions[i] = '' + i
}

let quarterMinuteOptions = {}
for (let i = 0; i < 60; i+=15) {
    if (i < 10) quarterMinuteOptions[i] = '0' + i
    else quarterMinuteOptions[i] = '' + i
}

let completeMinuteOptions = {}
for (let i = 0; i < 60; i++) {
    if (i < 10) completeMinuteOptions[i] = '0' + i
    else completeMinuteOptions[i] = '' + i
}

export { all_times, start_times, hourOptions, completeHourOptions, allMinuteOptions, completeMinuteOptions, quarterMinuteOptions, time_array, time_auditor_options, new_time_auditor_options };