<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar title="Time Auditor" back-link="Back">
      <f7-nav-right>
        <f7-link icon-ios="f7:floppy_disk"
                 icon-aurora="f7:floppy_disk"
                 icon-md="material:save"
                 tooltip="Finish Day"
                 @click="onSaveClick" />
      </f7-nav-right>
    </f7-navbar>

    <!-- Body -->
    <az-wizard :questions="questions"
               @save-clicked="onSaveClick" />

  </f7-page>
</template>

<script>
import AZWizard from '@/components/ui/AZWizard.vue'

export default {
  components: {
    'az-wizard': AZWizard
  },
  data() {
    let questions = [];
    let start_time = 9;
    for (let i = start_time; i < 27; i++) {
      let num = i;
      if (num >= 24) {
        num -= 24;
      }

      // First half
      questions.push({
        title: "What did you do at " + num + ":00?",
        type: 'textarea',
        key: num + ":00",
        value: '',
        error: '',
        required: true
      });

      // Second half
      questions.push({
        title: "What did you do at " + num + ":30?",
        type: 'textarea',
        key: num + ":30",
        value: '',
        error: '',
        required: true
      });
    }

    // Return Data
    return {
      questions: questions
    }
  },
  methods: {
    onSaveClick() {
      this.$f7router.back();
    }
  }
}
</script>

