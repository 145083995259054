<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Habit Enhancer'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Body -->
    <az-wizard
      v-if="pageState == 'loaded'"
      :questions="questions"
      @save-clicked="onSaveClick"
    ></az-wizard>

    <!-- Loading Screen -->
    <az-loader 
      v-if="pageState == 'loading'"
    ></az-loader>

    <!-- Error Message -->
    <az-page-error-message
      v-if="pageState == 'failed'"
      @try-again="refreshData"
    ></az-page-error-message>

  </f7-page>
</template>

<script>
import AZWizard from '@/components/ui/AZWizard.vue'
import AZLoader from '@/components/ui/AZLoader.vue'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';

import { formatWizardData, unformatDate, participantAPI, errorHandler } from '@/js/api';

export default {

  components: {
    'az-wizard': AZWizard,
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage,
  },

  props: ['id'],

  mounted () {
    this.loadDataFromAPI()
  },

  data() {
    return {
      pageState: 'loading',
      questions: []
    }
  },

  computed: {
    isEditMode() {
      return (this.id !== undefined);
    },
  },

  methods: {

    getDefaultDate () {
      var date = new Date()
      date.setDate(date.getDate() - 1)
      return [date.toDateString()]
    },

    loadDataFromAPI () {
      if (this.isEditMode) {
        this.loadEditModeAPI()
      } else {
        this.loadCreateModeAPI()
      }
    },

    loadCreateModeAPI () {
      let batchId = this.$store.getters['auth/getCurrentBatchID']

      participantAPI().get(`batch/${batchId}/habit`)
      .then((response) => {
        
        let questions = [
          {
            title: "Select Date",
            subtitle: "",
            type: "datepicker",
            key: 'date',
            value: this.getDefaultDate(),
            error: ''
          }
        ]

        response.data.data.map((habit, index) => {
          questions.push({
            title: "Habit " + (index + 1) + ': ' + habit.name,
            type: "slider",
            valuePosition: "bottom",
            key: 'habit_' + habit.id,
            value: 1,
            error: ''
          })
        })

        this.questions = questions

        this.pageState = 'loaded'

      }).catch((error) => {
          errorHandler(error)
          this.pageState = 'failed'
      });
    },

    loadEditModeAPI () {
      let batchId = this.$store.getters['auth/getCurrentBatchID']

      participantAPI().get(`batch/${batchId}/habit-enhancer/${this.id}`)
      .then((response) => {

        let data = response.data.data
        
        let questions = [
          {
            title: "Date",
            subtitle: "",
            type: "datepicker",
            key: 'date',
            value: unformatDate(data.date),
            error: ''
          }
        ]

        data.ratings.map((rating, index) => {
          questions.push({
            title: "Habit " + (index + 1),
            subtitle: rating.habit.name,
            type: "slider",
            key: 'habit_' + rating.habit.id,
            value: rating.number,
            error: ''
          })
        })

        this.questions = questions

        this.pageState = 'loaded'

      }).catch((error) => {
          errorHandler(error)
          this.pageState = 'failed'
      });
    },

    onSaveClick () {
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID']
      let data = formatWizardData(this.questions);
      let url = this.isEditMode ? `batch/${batchId}/habit-enhancer/${this.id}` : `batch/${batchId}/habit-enhancer`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...data,
      }).then((response) => {
        // Show Toast
        window.$f7.toast.create({
            text: 'Your data has been saved',
            closeTimeout: 3500,
        }).open();
        // Go Back
        this.$f7router.back();
      })
      .catch((error) => {
        errorHandler(error,
          (errors) => {
            window.$f7.dialog.alert(errors[0], "Error");
          });
      })
      .finally(() => {
        this.pageState = 'loaded';
      });
    },

    onDeleteClick () {
      // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/habit-enhancer/${this.id}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Go Back
          this.$f7router.back();
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          this.pageState = 'loaded';
        });
      });
    }
  }
}
</script>

