import { GOOGLE_CLIENT_ID, GOOGLE_API_KEY } from '@/js/api'

const googleAuth = {
    namespaced: true,
    state: {
        isSignedIn: undefined,
        gapiLoaded: false
    },

    getters: {
        getIsSignedIn(state) {
            return () => gapi.auth2.getAuthInstance().isSignedIn.get()
        },

        getCurrentEmail(state, getters) {
            if (gapi.auth2.getAuthInstance().isSignedIn.get())
                return gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()
            return null
        },

        getGapiLoaded(state) {
            return state.gapiLoaded
        }
    },

    mutations: {
        updateSigninStatus(state, isSignedIn) {
            state.isSignedIn = isSignedIn
        },

        setGapiLoaded(state, value) {
            state.gapiLoaded = value
        }
    },

    actions: {
        handleClientLoad({state, dispatch, commit}, callback) {
            gapi.load('client:auth2', () => {
                gapi.client.init({
                    apiKey: GOOGLE_API_KEY,
                    clientId: GOOGLE_CLIENT_ID,
                    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
                    scope: "https://www.googleapis.com/auth/calendar",
                    prompt: 'select_account'
                }).then(() => {
                    // Listen for sign-in state changes.
                    // gapi.auth2.getAuthInstance().isSignedIn.listen(state.updateSigninStatus)
                    // Set initial value
                    // console.log('initial', gapi.auth2.getAuthInstance().isSignedIn.get())
                    // commit('updateSigninStatus', gapi.auth2.getAuthInstance().isSignedIn.get())
                    callback()
                }, function (error) {
                    // console.log(error)
                });

            });
        },

        googleSignIn(context) {
            return gapi.auth2.getAuthInstance().signIn()
        },

        googleSignOut(context) {
            return gapi.auth2.getAuthInstance().signOut()
        },

        googlePromise(context, request) {
            return new Promise((resolve, reject) => {
                request.execute(response => {
                    if (response.code) {
                        reject(response)
                    } else {
                        resolve(response)
                    }
                })
            })
        },

        googleAuthMiddleware({state, getters, dispatch, commit}) {
            return new Promise((resolve, reject) => {
                if (getters.getIsSignedIn()) {
                    resolve()
                } else {
                    window.$f7.dialog.alert(
                        'Your Google account is not synced. Please sign in with Google to continue',
                        'Error'
                    )
                    reject()
                }
            })
        }
    }
}

export default googleAuth;