<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Reason'" back-link="Back"></f7-navbar>

    <!-- Wrapper -->
    <div class="wrapper">
      <az-list>
        <az-list-input label="Date Noticed" type="datepicker" v-model="formattedDate" @enter-pressed="onSaveClick"/>

        <az-list-input ref="reason" label="Reason Stopping Me" type="text" v-model="form_data.reason" @enter-pressed="onSaveClick" />

      </az-list>
      
      <!-- Save Button -->
      <f7-fab position="right-bottom"
              @click="onSaveClick">
        <f7-icon ios="f7:floppy_disk" 
                aurora="f7:floppy_disk"
                md="material:save" />
      </f7-fab>
    </div>

    
    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="onTryAgainClick" />


  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader.vue'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import moment from 'moment';
import BlurActivevInput from '@/js/mixins/blur-active-input';

export default {
  name: 'ReasonEliminatorCreate',
  props: ['id', 'form_data_prop'],
  mixins: [BlurActivevInput],
  components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage,
  },

  mounted() {
    if (this.isEditMode) {
      if (!this.form_data_prop) {
        this.loadDataForId()
      } else {
         this.form_data = this.form_data_prop;
         this.pageState = 'loaded';
      }
    }
  },

  data() {
    return {
      pageState: this.isEditMode ? 'loading' : 'loaded',
      form_data: {
        date_noticed: '',
        reason: ''
      }
    }
  },

  computed: {
    isEditMode() {
      return (this.id !== undefined);
    },
    formattedDate: {
      get() {
        let parts = this.form_data.date_noticed.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.date_noticed = dateValue.format('DD-MM-YYYY');
      }
    }
  },

  methods: {
    onSaveClick() {
      this.blurAnyActiveInput()
      this.pageState = 'loading';
      
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = this.isEditMode ? `batch/${batchId}/reason-eliminator/${this.id}` : `batch/${batchId}/reason-eliminator`
      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
      })
      .then(response => {
        // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();

          if (this.isEditMode) {
            this.$f7router.back()
          }
          else {

            this.pageState = 'loaded';
            this.form_data.reason = '';
            this.$refs.reason.$el.querySelector('input').focus()
          }
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    onTryAgainClick() {
      this.pageState = 'loading';
      this.loadDataForId();
    },

    loadDataForId() {
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/reason-eliminator/${this.id}`)
      .then(response => {
        this.form_data = response.data.data
        this.pageState = 'loaded'
      })
      .catch(error => {
        errorHandler(error)
        this.pageState = 'failed'
      })
    },
  }
}
</script>