<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + ' Game'" back-link="Back">
      <f7-link slot="right" @click="onDeleteClick" v-if="isEditMode">
        <f7-icon ios="f7:trash" aurora="f7:trash" md="material:delete"></f7-icon>
      </f7-link>
    </f7-navbar>

    <!-- Body -->
    <!-- Wrapper -->
    <div class="wrapper" v-if="pageState == 'loaded'">
      <az-wizard
        :questions="questions"
        @next-clicked="onPageChange"
        @prev-clicked="onPageChange"
        @save-clicked="onSaveClick"
      ></az-wizard>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZWizard from '@/components/ui/AZWizard.vue'
import { GameTypes } from '@/js/consts'
import { formatWizardData, unformatWizardData } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader.vue'

export default {
  components: {
    'az-wizard': AZWizard,
    'az-loader': AZLoader,
  },
  props: {
    form_data_prop: {
      required: false
    }
  },

  data() {
    return {
      pageState: 'loaded',
      currentId: undefined,
      questions: [
        {
          title: "Choose a game type...",
          type: "select",
          options: GameTypes,
          key: 'type',
          value: 'P',
          error: '',
          required: true
        },
        {
          title: "",
          subtitle: "What would you like to name your game?",
          type: "text",
          key: 'name',
          value: '',
          error: '',
          required: true
        },
        {
          title: "Let's begin...",
          subtitle: "Write down some Result you want to Produce",
          type: 'textarea',
          key: 'result',
          value: '',
          error: '',
          required: true
        },
        {
          title: "Question 1",
          subtitle: "What will you get if you achieved this Result? Write what will give you power & energy.",
          type: 'textarea',
          key: 'care',
          value: 'Because I want ',
          error: '',
          required: true
        },
        {
          title: "Question 2",
          subtitle: "What will Answer 1 give you? Why do you care about Answer 1?",
          type: 'textarea',
          key: 'power_energy',
          value: 'Because I want ',
          error: '',
          required: true
        },
        {
          title: "Question 3",
          subtitle: "What will Answer 2 prove to you about you?",
          type: 'textarea',
          key: 'prove',
          value: 'Because I want ',
          error: '',
          required: true
        },
        {
          title: "Your Purpose",
          type: 'textarea',
          key: 'purpose',
          value: '',
          error: '',
          required: true
        },
        {
          title: "Being aware of your Purpose, now write your Result as a SUCCESS STATEMENT",
          type: 'textarea',
          key: 'success_statement',
          value: '',
          error: '',
          required: true
        }
      ]
    }
  },

  mounted() {
    if (this.isEditMode) {
      this.currentId = this.form_data_prop.id
      this.questions = unformatWizardData(this.form_data_prop, this.questions)
    }
  },

  methods: {
    onPageChange (currentStep) {
      this.saveData (currentStep)
    },

    onSaveClick (currentStep) {
      this.pageState = 'loading'
      this.saveData(currentStep, true)
    },

    saveData (currentStep, closeAfterSaving = false) {
      let url = '';
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let res = formatWizardData(this.questions)

      if (!this.currentId) {
        if (currentStep >= 2) {
          let url = `batch/${batchId}/game-creator`
          participantAPI().post(url, {
            ...res,
          })
          .then ((response) => {
            this.currentId = response.data.data;
          })
        }
      } else {
        let url = `batch/${batchId}/game-creator/${this.currentId}`
        participantAPI().post(url, {
          _method: 'PUT',
          ...res,
        })
        .then ((response) => {
          if (closeAfterSaving) {
            this.$f7router.navigate('/game-creator/')
          }
        })
      }
    },

    onDeleteClick() {
       // Show Confirmation
      window.$f7.dialog.confirm("Are you sure you want to delete?", "Delete Item", 
      () => {
        // Set state to loading
        this.pageState = "loading";
        // Make API call
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().delete(`batch/${batchId}/game-creator/${this.currentId}`)
        .then(() => {
          // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been deleted',
              closeTimeout: 3500,
          }).open();
          // Reload list
          this.$f7router.navigate('/game-creator/')
        })
        .catch(error => {
          // Handle Errors
          errorHandler(error);
          console.log(error)
          this.pageState = 'loaded';
        });
      });
    }
  },

  computed: {
    isEditMode() {
      return this.form_data_prop !== undefined
    }
  }
}
</script>

