<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar :title="(isEditMode ? 'Edit' : 'Add') + (isReason ? ' Result' : ' Action')" back-link="Back">
    </f7-navbar>

    <!-- Wrapper -->
    <div class="az-page">
      <div class="az-scrollable">
        <az-list>
          <!-- Task Input -->
          <az-list-input ref="title" label="Title" type="text" v-model="form_data.title"  @enter-pressed="onSaveClick"/>
          
          <az-list-input v-if="isRoot" type="select" label="Game" v-model="form_data.game_creator">
            <az-game-options></az-game-options>
          </az-list-input>

          <!-- Delegate Input -->
          <az-list-input label="Delegate To" type="smart-select" selection_type="multiple" :options="people" v-model="form_data.delegate_to">
          </az-list-input>

          <az-list-input label="Notes" type="text" v-model="form_data.notes"/>

          <az-list-input v-if="isEditMode" label="Progress" type="number" v-model="form_data.progress"></az-list-input>

          <az-list-input type="select" label="Schedule" v-model="form_data.sync">
            <option value="0">No</option>
            <option value="1">Yes</option>
          </az-list-input>

        </az-list>

        <f7-block-title v-if="form_data.sync == 1">Scheduling Details</f7-block-title>
        <az-list v-if="form_data.sync == 1" :class="{'fab-wrapper': !showAnalyse}">

          <az-list-input label="Date" type="datepicker" v-model="formattedDate" />

          <az-list-input label="Start Time" type="select" v-model="form_data.start_time">
            <option v-for="(time,key) in all_times" :key="key" :value="key">{{ time }}</option>
          </az-list-input>

          <az-list-input label="End Time" type="select" v-model="form_data.end_time">
            <option v-for="(time,key) in all_times" :key="key" :value="key">{{ time }}</option>
          </az-list-input>

          <az-list-input label="Recurring Event" type="select" v-model="form_data.is_recurring">
            <option value="0">No</option>
            <option value="1">Yes</option>
          </az-list-input>

          <az-list-input v-if="parseInt(form_data.is_recurring) == 1" :rrule="form_data.rrule" type="recurrence" :date="form_data.date" v-model="form_data.rrule"></az-list-input>
            
        </az-list>

        <!-- Toggle Button -->
        <div class="analyse fab-wrapper" v-if="showAnalyse">
          <f7-block-title>What are you lacking</f7-block-title>
          <az-toggle-btn-group>
            <az-toggle-btn
              :active="form_data.time == 1"
              @click="form_data.time = 1 - form_data.time"
            >Time</az-toggle-btn>
            <az-toggle-btn
              :active="form_data.focus == 1"
              @click="form_data.focus = 1 - form_data.focus"
            >Focus</az-toggle-btn>
            <az-toggle-btn
              :active="form_data.clarity == 1"
              @click="form_data.clarity = 1 - form_data.clarity"
            >Clarity</az-toggle-btn>
            <az-toggle-btn
              :active="form_data.reality == 1"
              @click="form_data.reality = 1 - form_data.reality"
            >Reality</az-toggle-btn>
          </az-toggle-btn-group>

          <f7-block-title>Was purpose missing?</f7-block-title>
          <az-toggle-btn-group>
            <az-toggle-btn :active="form_data.purpose == 'Y'" @click="form_data.purpose = 'Y'">Yes</az-toggle-btn>
            <az-toggle-btn :active="form_data.purpose == 'N'" @click="form_data.purpose = 'N'">No</az-toggle-btn>
            <az-toggle-btn :active="form_data.purpose == 'M'" @click="form_data.purpose = 'M'">Maybe</az-toggle-btn>
          </az-toggle-btn-group>

          <az-list>
            <az-list-input type="textarea" label="What is my learning?" v-model="form_data.learning" />
          </az-list>
        </div>


        <!-- Save Button -->
        <f7-fab position="right-bottom" slot="fixed" @click="onSaveClick">
          <f7-icon ios="f7:floppy_disk" aurora="f7:floppy_disk" md="material:save"></f7-icon>
        </f7-fab>
      </div>
      
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="onTryAgainClick" />

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import axios from 'axios';
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader.vue'
import AZGameOptions from '@/components/ui/AZGameOptions';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZToggleButtonGroup from '@/components/ui/AZToggleButtonGroup';
import AZToggleButton from '@/components/ui/AZToggleButton';
import AZRecurrence from '@/components/ui/AZRecurrence';
import moment from 'moment';
import { all_times } from "@/js/helpers/TimeOptions";
import GoogleCalendarMixin from '@/js/mixins/google-calendar';

export default {
  props: {
    mode: String,
    id: String|Number,
    form_data_prop: Object,
    parent_id: {
      type: String|Number,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    copyChildren: {
      type: Boolean,
      default: false
    },
    copyMode: {
      type: Boolean,
      default: false
    },
    originalId: {
      type: String|Number,
      default: null
    }
  },
  components: {
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage,
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-game-options': AZGameOptions,
    'az-toggle-btn-group': AZToggleButtonGroup,
    'az-toggle-btn': AZToggleButton,
    'az-recurrence': AZRecurrence
  },

  mounted() {
    if (this.date !== null) {
      this.form_data.date = this.date
    }
    if (this.isEditMode || this.copyMode) {
      this.form_data = this.form_data_prop
    }
    this.loadPeople()
  },
  
  data() {
    return {
      pageState: 'loading',
      all_times: all_times,
      form_data: {
        title: '',
        date: moment().add(1, 'days').format('DD-MM-YYYY'),
        start_time: '',
        end_time: '',
        notes: '',
        delegate_to: [],
        progress: 0,
        sync: 1,
        google_event_id: null,
        game_creator: null,
        actionable: 0,
        parent_id: null,
        status: 0,
        is_recurring: 0,
        time: 0,
        focus: 0,
        clarity: 0,
        reality: 0,
        purpose: 'Y',
        learning: '',
        rrule: 'RRULE:FREQ=DAILY;INTERVAL=1'
      },
      people: [],
      gcs: [],
      googleRecurrence: '',
      selected_emails: []
    }
  },

  mixins: [GoogleCalendarMixin],

  methods: {
    
    onSaveClick() {
      // Show Loading Screen
      this.pageState = 'loading';

      if (!this.isEditMode) this.form_data.parent_id = this.parent_id
      this.form_data.is_planner_event = this.isPlannerMode ? 1 : 0

      if (parseInt(this.form_data.sync)) {

        this.validateRequest()
        .then(() => {
          if (!this.isEditMode) {
            return this.$store.dispatch('googleCalendar/addGoogleEvent', this.googleEvent)
          }
          else {
            return this.$store.dispatch('googleCalendar/updateGoogleEvent', {
              event_id: this.form_data.google_event_id, 
              event: this.googleEvent
            })
          }
        })
        .then(res => {
          this.addEventToBackend(res.id)
        })
        .catch(err => {
          errorHandler(err, errors => {
              window.$f7.dialog.alert(errors[0], "Error");
            });
        })
        .then(() => {
          this.pageState = 'loaded'
        })
      }

      else {
        this.validateRequest()
        .then(() => {
          if (this.form_data.google_event_id) {
            this.$store.dispatch('googleCalendar/deleteGoogleEvent', this.form_data.google_event_id)
          }
          this.addEventToBackend();
        })
        .catch(err => {
          this.pageState = "loaded";
          errorHandler(err, errors => {
            window.$f7.dialog.alert(errors[0], "Error");
          });
        })
      }
    
    },

    addEventToBackend(google_event_id) {

      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let url = this.isEditMode ? `batch/${batchId}/event/${this.form_data_prop.id}` : `batch/${batchId}/event`

      participantAPI().post(url, {
        _method: this.isEditMode ? 'PUT' : 'POST',
        ...this.form_data,
        copyChildren: this.copyChildren,
        originalId: this.originalId,
        google_event_id: google_event_id
      })
      .then(response => {
        // Show Toast
          window.$f7.toast.create({
              text: 'Your data has been saved',
              closeTimeout: 3500,
          }).open();
          // back to index
          if (this.isEditMode)
            this.$f7router.back();
          else {
            this.pageState = 'loaded'
            // console.log(this.$refs.title.$el)
            this.$refs.title.$el.querySelector('input').focus()
            this.form_data.title = ''
            this.form_data.notes = ''
          }
      })
      .catch(error => {
        this.pageState = 'loaded';
        errorHandler(error, 
        (errors) => {
          window.$f7.dialog.alert(errors[0], "Error");
        })
      })
    },

    onActionableToggleClick(value) {
      this.form_data.actionable = value ? 1 : 0;
    },

    onStatusToggleClick(value) {
      this.form_data.status = value ? 1 : 0;
    },

    validateRequest() {
      let batchId = this.$store.getters["auth/getCurrentBatchID"];
      return participantAPI().post(
        `batch/${batchId}/event/validate-request`,
        {
          ...this.form_data,
          id: this.form_data.id
        }
      );
    },

    loadPeople() {
      // Show Loading Screen
      this.pageState = 'loading'
      // Call API
      let batchId = this.$store.getters['auth/getCurrentBatchID'];

      let apis = [participantAPI().get(`batch/${batchId}/people`), participantAPI().get(`batch/${batchId}/game-creator`)]
        axios.all(apis)
        .then((...response) => {
          let peopleRepsonse = response[0][0]
          let gcResponse = response[0][1]

          this.people = peopleRepsonse.data.data.map(person => {
            return {
              key: person.id,
              value: person.full_name,
              email: person.email
            }
          })

          this.syncAttendees(this.form_data.delegate_to)

          this.gcs = gcResponse.data.data.map(game => {
          return {
            key: game.id,
            value: game.name
          }
        })
          this.pageState = 'loaded'
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = 'failed'
        })
    },

    onTryAgainClick() {
      this.pageState = 'loading';
      this.loadPeople();
    },

    syncAttendees(val) {
      this.selected_emails = this.people
      .filter(person => person.email && val.includes(person.key))
      .map(person => {
        return {
          email: person.email
        };
      });
    }
  },

  computed: {
    isEditMode() {
      return !this.copyMode && this.form_data_prop !== undefined;
    },
    formattedDate: {
      get() {
        if (this.form_data.date === null) return null
        let parts = this.form_data.date.split('-');
        if (parts.length >= 3) {
          let day = parts[0];
          let month = parts[1] - 1;
          let year = parts[2];
          return [new Date(year, month, day).toString()];
        }
        return [];
      },
      set(value) {
        let dateValue = moment(value[0]);
        this.form_data.date = dateValue.format('DD-MM-YYYY');
      }
    },
    computedRecurrence: {
      get() {
        return [this.form_data.frequency, this.form_data.recurrence, {
          sun: this.form_data.sun,
          mon: this.form_data.mon,
          tue: this.form_data.tue,
          wed: this.form_data.wed,
          thu: this.form_data.thu,
          fri: this.form_data.fri,
          sat: this.form_data.sat,
        }]
      },
      set(val) {
        this.form_data.frequency = val[0]
        this.form_data.recurrence = val[1]
        this.form_data = {...this.form_data, ...val[2]}
        this.googleRecurrence = val[3]
      }
    },

    computedRrule() {

        if (this.form_data.is_recurring) {
          return [this.form_data.rrule]
        }
        return null
    },

    eventStartTime() {
      return moment(
        this.form_data.date + " " + this.form_data.start_time,
        "DD-MM-YYYY HH:mm"
      ).toDate();
    },

    eventEndTime() {
      return moment(
        this.form_data.date + " " + this.form_data.end_time,
        "DD-MM-YYYY HH:mm"
      ).toDate();
    },

    invalidTimeDiff() {
      return parseInt(this.form_data.sync) && moment(this.eventEndTime).isSameOrBefore(
        moment(this.eventStartTime)
      );
    },

    googleEvent() {
      return {
        summary: this.form_data.title,
        start: {
          dateTime: this.eventStartTime,
          timeZone: "Asia/Kolkata"
        },
        end: {
          dateTime: this.eventEndTime,
          timeZone: "Asia/Kolkata"
        },
        attendees: this.selected_emails,
        recurrence: this.computedRrule
      }
    },

    showAnalyse() {
      return (
        moment().subtract(1 ,'d').isAfter(moment(this.form_data.date, "DD-MM-YYYY")) &&
        this.form_data.status == 0
      );
    },

    isRoot() {
      return this.isEditMode ? this.form_data.parent_id === null : this.parent_id === null
    },

    isPlannerMode() {
      return this.mode === 'planner'
    },

    isReason() {
      return this.isEditMode ? (this.form_data.parent_id === null) : (this.parent_id === null)
    }
  },

  watch: {

    "form_data.delegate_to": {
      handler(val) {
        this.syncAttendees(val)
      }
    },
  }
}
</script>

<style scoped>
  .recurrence {
    margin-top: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>