<template>
  <f7-page :page-content="false" @page:reinit="loadDataFromAPI">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Time Finder ©</f7-nav-title>
    </f7-navbar>

    <!-- Body -->
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#stats" tab-link-active>Dashboard</f7-link>
      <f7-link tab-link="#index">Index</f7-link>
    </f7-toolbar>

    <f7-tabs swipeable>
    
      <f7-tab class="page-content" id="stats" tab-active>
        <stats-fragment :pageState="pageState" :stats="stats" ref="statsFragment" @refresh="loadDataFromAPI"></stats-fragment>
      </f7-tab>

      <f7-tab class="page-content" id="index">
        <index-fragment :pageState="pageState" :timeFinders="timeFinders" ref="indexFragment" @refresh="loadDataFromAPI"></index-fragment>
      </f7-tab>

    </f7-tabs>

  </f7-page>
</template>

<script>
import StatsFragment from '@/pages/TimeFinder/StatsFragment'
import IndexFragment from '@/pages/TimeFinder/IndexFragment'
import { participantAPI, errorHandler } from '@/js/api'

export default {
  components: {
    'stats-fragment': StatsFragment,
    'index-fragment': IndexFragment
  },
  data () {
    return {
      reloadFlag: true,
      timeFinders: [],
      stats: null,
      pageState: 'loading'
    }
  },

  mounted() {
      this.loadDataFromAPI()
  },

  methods: {
    loadDataFromAPI() {
      this.pageState = 'loading'
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/time-finder`)
        .then(res => {
          this.timeFinders = res.data.data.time_finders
          this.stats = res.data.data.stats
          this.pageState = 'loaded'
        })
        .catch(err => {
          this.pageState = 'failed'
          errorHandler(err)
        })
    },
  }
}
</script>

<style scoped>
  #stats {
      overflow: scroll !important;
  }
</style>