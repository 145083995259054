<template>
  <f7-page :page-content="false" @page:reinit="reloadAllAPIs">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Games Accomplishment Blueprint ©</f7-nav-title>
    </f7-navbar>

    <!-- Body -->
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#master" tab-link-active>Master</f7-link>
      <f7-link tab-link="#clarity">Clarity</f7-link>
      <f7-link tab-link="#breakdown">Obstacles</f7-link>
    </f7-toolbar>

    <f7-tabs swipeable>
    
      <f7-tab class="page-content" id="master" tab-active>
        <master-fragment ref="masterFragment"></master-fragment>
      </f7-tab>

      <f7-tab class="page-content" id="clarity">
        <clarity-fragment ref="clarityFragment"></clarity-fragment>
      </f7-tab>

      <f7-tab class="page-content" id="breakdown">
        <breakdown-fragment ref="breakdownFragment"></breakdown-fragment>
      </f7-tab>

    </f7-tabs>

  </f7-page>
</template>

<script>
// Master Plan (TAB 1)
import MasterFragment from '@/pages/AccomplishmentBlueprint/Master/IndexFragment'
// Clarity Missing (TAB 2)
import ClarityFragment from '@/pages/AccomplishmentBlueprint/ClarityMissing/IndexFragment'
// Breakdown (TAB 3)
import BreakdownFragment from '@/pages/AccomplishmentBlueprint/BreakdownsObstacles/IndexFragment'

export default {
  components: {
    'master-fragment': MasterFragment,
    'clarity-fragment': ClarityFragment,
    'breakdown-fragment': BreakdownFragment
  },
  data () {
    return {
      reloadFlag: true
    }
  },
  methods: {
    reloadAllAPIs () {
      this.$refs.masterFragment.loadDataFromAPI()
      this.$refs.clarityFragment.loadDataFromAPI()
      this.$refs.breakdownFragment.loadDataFromAPI()
      // this.reloadFlag = !this.reloadFlag
    }
  }
}
</script>

<style scoped>
  .tabs{
    height: 100%;
  }
</style>