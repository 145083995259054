<template>
  <f7-page :page-content="false" @page:reinit="reloadAllAPIs" @page:beforeout="removeListener">

    <!-- Navbar -->
    <f7-navbar>
      <!-- Sidebar Button -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>
      <!-- Page Title -->
      <f7-nav-title>Power Planners ©</f7-nav-title>
    </f7-navbar>

    <!-- Body -->
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#list" tab-link-active>List</f7-link>
      <f7-link tab-link="#calendar">Calendar</f7-link>
    </f7-toolbar>

    <f7-tabs swipeable>
    
      <f7-tab class="page-content" id="list" tab-active>
        <list-fragment ref="listFragment"></list-fragment>
      </f7-tab>

      <f7-tab class="page-content" id="calendar">
        <calendar-fragment ref="calendarFragment"></calendar-fragment>
      </f7-tab>

    </f7-tabs>

  </f7-page>
</template>

<script>
import ListFragment from '@/pages/PowerPlanner/ListFragment'
import CalendarFragment from '@/pages/PowerPlanner/CalendarFragment'
import AZLoader from '@/components/ui/AZLoader';
import { participantAPI, errorHandler } from '@/js/api'

export default {
  components: {
    'list-fragment': ListFragment,
    'calendar-fragment': CalendarFragment,
    'az-loader': AZLoader
  },
  data () {
    return {
    }
  },

  mounted() {
    if (this.gapi)
      this.reloadAllAPIs()
  },

  methods: {
    reloadAllAPIs() {
      this.$refs.listFragment.loadDataFromAPI()
      this.$refs.calendarFragment.loadDataFromAPI()
      this.$refs.listFragment.addListener()
    },

    removeListener() {
      this.$refs.listFragment.removeListener()
    }
  },

  computed: {
    gapi() {
      return this.$store.getters['googleAuth/getGapiLoaded']
    }
  },


  watch: {
    gapi(val) {
      if (val) {
        this.reloadAllAPIs()
      }
    }
  }
}
</script>

<style scoped>
</style>