<template>
  <div>
    <az-page-message message="<b>Oops!</b><br>There was an error loading your data.">
      <f7-button fill @click="onTryAgainClick">Try Again</f7-button>
    </az-page-message>
  </div>
</template>

<script>
import AZPageMessage from '@/components/ui/AZPageMessage'

export default {
  name: 'az-page-error-message',
  components: {
    'az-page-message': AZPageMessage
  },
  methods: {
    onTryAgainClick() {
      this.$emit('try-again');
    }
  }
}
</script>

<style>

</style>