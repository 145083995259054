import moment from 'moment'

const googleCalendar = {

    namespaced: true,
    
    actions: {
        addCalendar({state, dispatch, commit}) {
            let request = gapi.client.calendar.calendars.insert({
                summary: 'Colloquium',
                backgroundColor: '#ff0000',
                timeZone: 'Asia/Kolkata'
            })
            return dispatch('googleAuth/googlePromise', request, {root: true}).then(res => {
                return res.id
            })
        },

        getGoogleEvents({dispatch}, date) {
            return dispatch('checkCalendar').then(result => {
                let getEvents = (res, start_date, end_date) => {
                    
                    let request = gapi.client.calendar.events.list({
                        'calendarId': res,
                        "timeMin":  start_date.toISOString(),
                        "timeMax": end_date.toISOString(),
                        'singleEvents': true,
                        "showDeleted": false,
                        // "maxResults": 10,
                    })
                    return dispatch('googleAuth/googlePromise', request, {root: true})
                }

                if (result) {
                    
                    let start_date = date
                    let end_date = moment(date).toDate()
                    start_date.setHours(0, 0, 0, 0)
                    end_date.setHours(23, 59, 59, 999)

                    return getEvents(result, start_date, end_date)
                }
                else {
                    return null
                }
            })
            
        },

        checkCalendar({dispatch}){
            return dispatch('googleAuth/googleAuthMiddleware', null, {root: true})
            .then(() => {
                let request = gapi.client.calendar.calendarList.list()
                return dispatch('googleAuth/googlePromise', request, {root: true})
                .then(res => {
                    let cals = res.items
                    cals = cals.filter(cal => cal.summary == 'Colloquium')
                    if(cals.length > 0) {
                        return cals[0].id
                    }
                    else {
                        return false
                    }
                })
            })
            .catch(err => {
                
            })
        },

        checkEvent({state, dispatch, commit}, payload) {
            
            return new Promise((resolve, reject) => {
                let request = gapi.client.calendar.events.get({
                    calendarId: payload.result,
                    eventId: payload.event_id
                })
                dispatch('googleAuth/googlePromise', request, {root: true})
                .then(() => {
                    resolve(true)
                })
                .catch(() =>{
                    resolve(false)
                })
            })
                
            
            
        },

        addGoogleEvent({state, dispatch, commit}, event) {
            return dispatch('checkCalendar')
            .then(result => {
                let addEvent = (res) => {
                    let request = gapi.client.calendar.events.insert({
                        calendarId: res,
                        resource: event
                    })
                    return dispatch('googleAuth/googlePromise', request, {root: true})
                }
                if (result) {
                    return addEvent(result)
                } else {
                    return dispatch('addCalendar').then(result => {
                        return addEvent(result)
                    })
                }
            })
            .catch(err => {
                
            })
        },

        deleteGoogleEvent({state, dispatch, commit}, event_id) {
            
            return dispatch('checkCalendar')
            .then(result => {
                
                let deleteEvent = (res) => {
                    let request = gapi.client.calendar.events.delete({
                        calendarId: res,
                        eventId: event_id,
                    })
                    return dispatch('googleAuth/googlePromise', request, {root: true})
                }
                if (result) {
                    return deleteEvent(result)
                } 
            })
            .catch(err => {
                
            })
        },

        updateGoogleEvent({state, dispatch, commit}, payload) {
            return dispatch('checkCalendar')
            .then(result => {
                let updateEvent = (res) => {
                    let request = gapi.client.calendar.events.update({
                        calendarId: res,
                        eventId: payload.event_id,
                        resource: payload.event
                    })
                    return dispatch('googleAuth/googlePromise', request, {root: true})
                }

                let addEvent = (res) => {
                    let request = gapi.client.calendar.events.insert({
                        calendarId: res,
                        resource: payload.event
                    })
                    return dispatch('googleAuth/googlePromise', request, {root: true})
                }
                
                if (!result) {
                    return dispatch('addCalendar').then(result => {
                        return addEvent(result)
                    })
                }
                else {
                    if (payload.event_id) {
                        return dispatch('checkEvent', {event_id: payload.event_id, result: result})
                        .then((res) => {
                            if (res) {
                                return updateEvent(result)
                            }
                            else {
                                return addEvent(result)
                            }
                        })
                    }
                    else 
                        return addEvent(result)
                } 
            })
            .catch(err => {
                // console.log(err)
            })
        }
    }
}

export default googleCalendar;