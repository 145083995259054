<template>
  <div class="az-tab-page">
    <!-- Page Content -->
    <div class="az-tab-scrollable">
      <!-- Desktop View -->
      <div class="data-table card desktop-name fab-wrapper" v-if="reasons.length > 0">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Reason</th>
              <th class="label-cell">Missing</th>
              <th class="label-cell">Action Word</th>
              <th class="label-cell">Victory over Reason</th>
              <!-- <th class="label-cell">Status</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(reason,index) in reasons"
                :key="reason.id"
                @click="onAnalyzeClick(reason, index)"
                class="pointer">
              <td class="label-cell" style="max-width: 200px; word-break: break-word">{{ reason.reason }}</td>
              <td class="label-cell">{{ getTFCR(reason) }}</td>
              <td class="label-cell">{{ reason.new_thinking }}</td>
              <td class="label-cell">{{ reason.victory }}</td>
              <!-- <td class="label-cell">{{ reason.is_analyzed ? 'Analyzed' : 'Not Analyzed' }} </td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Mobile View -->
      <cq-reason-list class="mobile-name fab-wrapper" v-if="reasons.length > 0">
        <cq-reason-item 
            v-for="(reason, index) in reasons"
            :key="reason.id"
            :reason="reason.reason" 
            :actionWord="reason.new_thinking"
            :missing="getTFCR(reason)"
            :victory="reason.victory"
            @click.native="onAnalyzeClick(reason, index)"
            :completed="reason.is_analyzed" />
      </cq-reason-list>

      <!-- No Data Message -->
      <template v-else>
        <az-page-message v-if="pageState != 'failed'" />
      </template>
    
    </div>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Add Button -->
    <f7-fab class="fab-tab" position="right-bottom" slot="fixed" href="/reasons-eliminator/create/">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

  </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import CQReasonsEliminatorList from '@/components/reasons-eliminator/CQReasonsEliminatorList';
import CQReasonsEliminatorItem from '@/components/reasons-eliminator/CQReasonsEliminatorItem';
import AZLoader from '@/components/ui/AZLoader';

export default {
  name: 'ReasonEliminatorIndexFragment',

  props: {
    reasons: Array,
    pageState: String
  },

  components: {
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'az-loader': AZLoader,
    'cq-reason-list': CQReasonsEliminatorList,
    'cq-reason-item': CQReasonsEliminatorItem
  },

  data() {
    return {
      currentId: 0,
    }
  },

  mounted() {
    // this.loadDataFromAPI()
  },

  methods: {
    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/reason-eliminator`)
        .then(response => {
          this.reasons = response.data.data
          this.pageState = 'loaded'
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    // Button Click Events
    
    refreshData () {
      this.$emit('refresh')
    },

    onAnalyzeClick(reason, index) {
      this.$f7router.navigate(`/reasons-eliminator/analyze/`, {
        props: {
          reasons_prop: this.reasons,
          currentIndex: index
        }
      });
    },
    

    getTFCR({time, focus, clarity, reality}) {
      let tfcr = ''
      if (time) {
        tfcr += 'Time, '
      }
      if (focus) {
        tfcr += 'Focus, ';
      }
      if (clarity) {
        tfcr += 'Clarity, '
      }
      if (reality) {
        tfcr += 'Reality, '
      }

      if (tfcr == '') {
        return 'N/A';
      } else {
        var newStr = tfcr.substring(0, tfcr.length - 2);
        return newStr;
        
      }
    }
  },
}
</script>

<style scoped>
  @media screen and (max-width: 600px) {
    .desktop-name {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }

    .re-indexpage >>> .list .item-title{
      white-space: initial;
    }
  }

  @media screen and (min-width: 600px) {
    .mobile-name {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }
  }
</style>