<template>
    <div>
      <p v-if="label" style="margin-left: 20px; margin-bottom: 5px;">{{ label }}</p>
      <div class="row">
        <f7-list-input
          label="Hours"
          class="col"
          type="select"
          :value="computedHours"
          @input="computedHours = $event.target.value"
          outline
          floating-label
        >
          <option v-for="(value, key) in computedHourOptions"
                  :key="key"
                  :value="key">{{ value }}</option>    
        </f7-list-input>
        <f7-list-input
          label="Minutes"
          class="col"
          type="select"
          :value="computedMinutes"
          @input="computedMinutes = $event.target.value"
          outline
          floating-label
        >
          <option v-for="(value, key) in computedMinuteOptions"
                  :key="key"
                  :value="key">{{ value }}</option>      
        </f7-list-input>
      </div>
    </div>
</template>

<script>
import { hourOptions, completeHourOptions, allMinuteOptions, quarterMinuteOptions, completeMinuteOptions } from '@/js/helpers/TimeOptions'
export default {
    name: 'AZTimePicker',
    props: {
        value: {
            type: String|Number,
            default: 0
        },

        label: {
            type: String
        },
        all_minutes: {
            type: Boolean,
            default: false
        },
        complete: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hourOptions: hourOptions,
        }
    },

    mounted() {
        if (this.complete) {
            this.hourOptions[24] = '24'
        }
    },

    computed: {
        computedHours: {
            get() {
                return Math.floor(parseInt(this.value) / 60)
            },

            set(val) {
                this.$emit('input', parseInt(val) * 60 + parseInt(this.computedMinutes))
            }
        },

        computedMinutes: {
            get() {
                return parseInt(this.value) % 60
            },

            set(val) {
                this.$emit('input', parseInt(val) + parseInt(this.computedHours) * 60)
            }
        },

        computedMinuteOptions() {
            if (this.complete) {
                return completeMinuteOptions
            }
            if (this.all_minutes) {
                return allMinuteOptions
            }
            return quarterMinuteOptions
        },

        computedHourOptions() {
            if (this.complete) {
                return completeHourOptions
            }
            return hourOptions
        }
    }
}
</script>

<style>

</style>