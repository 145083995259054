<template>
  <f7-page name="home">

    <f7-navbar>

      <!-- Left Buttons -->
      <f7-nav-left>
        <f7-link panel-open="left"
                 icon-ios="f7:menu" 
                 icon-aurora="f7:menu"
                 icon-md="material:menu" />
      </f7-nav-left>

      <!-- Page Title -->
      <f7-nav-title>Dashboard</f7-nav-title>

    </f7-navbar>

    <div class="main" v-if="pageState == 'loaded'">
      <az-timeline v-if="sessions.length > 0">
        <az-timeline-item 
          v-for="session in sessions"
          :key="session.id"
          :date="getFancyDate(session.date)"
          :text="getSessionText(session)"
        />
      </az-timeline>  
      <!-- No Data Message -->
      <az-page-message message="<b>No sessions to show</b>" v-else />
    </div>

    <az-loader v-if="pageState == 'loading'"/>

    <az-page-error-message v-if="pageState == 'failed'" @try-again="loadDataFromAPI"></az-page-error-message>
  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import moment from 'moment';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import AZTimeline from '@/components/ui/AZTimeline';
import AZTimelineItem from '@/components/ui/AZTimelineItem';
import { ImplementationStatusNames } from '@/js/consts';

export default {
  components: {
    'az-timeline': AZTimeline,
    'az-timeline-item': AZTimelineItem,
    'az-page-message': AZPageMessage,
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage
  },

  data() {
    return {
      pageState: 'loading',
      sessions: []
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {

    getFancyDate (date) {
      return moment(date, 'DD-MM-YYYY').format('DD') + " " +
            "<small>" + 
              moment(date, 'DD-MM-YYYY').format('MMM').toUpperCase() + " " + 
              moment(date, 'DD-MM-YYYY').format('YYYY') + 
            "</small>";
    },

    getSessionText(session) {
      let text = ''
      text += `<b class='text-theme text-heavy'> ${session.name} </b><br>`
      
      text += `${session.start_time} - ${session.end_time}`
    
      return text
    },

    loadDataFromAPI() {

      this.pageState = 'loading'
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/session`)
        .then(response => {
          this.sessions = response.data.data
          this.pageState = 'loaded'
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    refreshData () {
      this.sessions = [];
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },
  }
}
</script>

<style scoped>
</style>