<template>
  <div 
    class="step-page"
    :class="{
      'step-ios-page': $f7.device.ios,
      'step-android-page': $f7.device.android
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'az-step-page'
}
</script>

<style scoped>
  .step-page {
    width: 100%;
    position: absolute;
    padding-bottom: 70px;
  }
  .step-android-page {
    top: 56px;
  }
  .step-ios-page {
    top: 44px;
  }
  h5 {
    padding: 15px;
    padding-bottom: 0px;
  }
</style>