<template>
  <f7-page name="home">

    <f7-navbar back-link="back">

      <!-- Page Title -->
      <f7-nav-title>Analysis</f7-nav-title>

    </f7-navbar>
    
    <div v-if="total != 0">

      <div class="wrapper">
        <donut-chart
          style="width: 270px"
          id="entry-donut"
          :colors="colors" 
          :data="graphData" 
          :resize="true">
        </donut-chart>
      </div>

      <az-color-legend
        :actions="actions"
      ></az-color-legend>

      <div class="list media-list">
          <ul>
              <li v-for="datum in tableData"
                  :key="datum.label">
                  <a class="item-content">
                  <div class="item-inner">
                      <div class="item-title-row">
                          <div class="item-title" style="color: black">{{ datum.label }}</div>
                          <div class="item-after">
                              {{ datum.value }}
                              {{ '(' + datum.percentage + '%)' }}
                          </div>
                      </div>
                  </div>
                  </a>
              </li>
          </ul>
      </div>
    </div>

    <div v-else>
        <f7-card class="summary-card">
            <h1 class="no-margin">No data to show</h1>
            <p>Create entries to see your stats here</p>
        </f7-card>
    </div>
    
  </f7-page>
</template>

<script>
import AZColorLegend from '@/components/ui/AZColorLegend'
// Do not forget to import raphael
import Raphael from 'raphael/raphael'
global.Raphael = Raphael

import { DonutChart } from 'vue-morris'
import { TimeFinderActions } from '@/js/consts'

export default {
  name: 'TimeAuditorAnalyze',
  props: {
    entries_prop: Array,
    date: String
  },
  components: {
    DonutChart,
    'az-color-legend': AZColorLegend
  },
  data() {
    return {
      colors: Object.values(TimeFinderActions).map(action => action.style.background),
      actions: TimeFinderActions
    }
  },

  mounted() {
    if (!this.entries_prop) {
        setTimeout(() => {
            this.$f7router.back()
        }, 500)
    }
  },

  computed: {
    graphData() {
        return this.tableData.map(datum => {
            let temp = JSON.parse(JSON.stringify(datum))
            temp.label = temp.label + ' %'
            temp.value = temp.percentage
            return temp
        })
    },

    tableData() {
        return Object.keys(this.actions).map(action => {
            let temp = {}
            temp.key = action
            temp.label = this.actions[action].text
            let value = this.entries_prop.filter(entry => entry.action == action).length
            temp.value = value
            temp.percentage = Math.round(value / this.total * 100)
            return temp
        })
    },

    total() {
        return (this.entries_prop.filter(entry => entry.action !== null).length) 
    },

  }
}
</script>


<style scoped>
    .summary-card {
        text-align: center;
        max-width: 300px;
        padding: 10px;
        margin: var(--f7-list-margin-vertical) auto 0 auto;
    }
    
    .wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #entry-donut {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>