<template>
  <p class="error-message">
    <slot></slot>
  </p>
</template>

<script>
  export default {
    name: 'az-error-message'
  }
</script>

<style scoped>
  .error-message {
    padding-right: var(--f7-typography-padding) !important;
    padding-left: var(--f7-typography-padding) !important;
    padding-bottom: var(--f7-typography-padding) !important;
    color: #ff3b30;
  }
</style>