<template>
  <az-steps :prev-visible="currentStepIndex > 0"
            :next-visible="currentStepIndex < (questions.length - 1)"
            :save-visible="currentStepIndex == (questions.length - 1)"

            :save-icon-md="saveIconMd"
            :save-icon-ios="saveIconIos"

            @prev-clicked="onPrevClicked"
            @next-clicked="onNextClicked"
            @save-clicked="onSaveClicked"
  >
    
    <!-- Pages -->
    <div v-for="(question, index) in questions"
         v-if="index == currentStepIndex"
         :key="index"
         :style="{ width: '100%', position: 'absolute', top: ($f7.device.ios ? '44px' : '56px') }">

      <!-- Question -->
      <az-question v-model="question.value"
                   :title="question.title"
                   :subtitle="question.subtitle"
                   :type="question.type"
                   :valuePosition="question.valuePosition"
                   :error="question.error"
                   :options="question.options">
      </az-question>

    </div>

  </az-steps>
</template>

<script>
import AZSteps from '@/components/ui/AZSteps.vue'
import AZQuestion from '@/components/ui/AZQuestion.vue'

export default {
  name: 'az-wizard',
  components: {
    'az-steps': AZSteps,
    'az-question': AZQuestion
  },
  props: {
    questions: {
      required: true,
      type: Array
    },
    saveIconMd: {
      type: String,
      default: "material:save"
    },
    saveIconIos: {
      type: String,
      default: "f7:floppy_disk"
    }
  },
  data() {
    return {
      currentStepIndex: 0
    }
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentStepIndex];
    }
  },
  methods: {
    // Button Clicks
    onPrevClicked() {
      this.currentStepIndex--;
      this.$emit('prev-clicked', this.currentStepIndex);
    },
    onNextClicked() {
      if (this.isCurrentQuestionValid()) {
        this.removeAllErrors();
        this.currentStepIndex++;
        this.$emit('next-clicked', this.currentStepIndex);
      }
    },
    onSaveClicked() {
      if (this.isCurrentQuestionValid()) {
        this.$emit('save-clicked');
      }
    },
    // Helpers
    isCurrentQuestionValid() { 
      if (this.currentQuestion.required && 
            (
              [null, undefined].includes(this.currentQuestion.value) ||
              // String Required Validation
              (typeof this.currentQuestion.value == "string" && this.currentQuestion.value.trim().length == 0) ||
              // Array Required Validation
              (Array.isArray(this.currentQuestion.value) && this.currentQuestion.value.length == 0)
            )
         )
      {
        this.currentQuestion.error = "This field is required";
        return false;
      } else {
        return true;
      }
    },
    removeAllErrors() {
      this.questions.forEach(function(question, index) {
        question.error = "";
      });
    }
  }
}
</script>
