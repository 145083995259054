// Import Stuff
import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
import Fragment from 'vue-fragment';


// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';
import 'vue2-animate/dist/vue2-animate.min.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from './app.vue';

// Import Store
import store from './store';

// Init Plugins
Framework7.use(Framework7Vue);
Vue.use(Fragment.Plugin);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  components: {
    app: App
  },
});