<template>
    <fragment>
        <li class="item-content item-input item-input-outline">
            <div class="item-inner">
                <div class="item-title item-floating-label">{{ label }}</div>
                <div class="item-input-wrap">
                    <cleave :value="date" @input="update" class="form-control" :raw="false" :options="cleaveOptions.date" placeholder="DD-MM-YYYY"></cleave>
                <span class="input-clear-button"></span>
                </div>
            </div>
        </li>
    </fragment>
</template>

<script>

import Cleave from 'vue-cleave-component';
export default {
    components: {
        Cleave
    },

    props: {
        value: String,
        label: String
    },

    mounted() {
        this.date = this.value
    },

    data() {
        return {
            date: '',
            cleaveOptions: {
              date: {
                    date: true,
                    datePattern: ['d', 'm', 'Y'],
                    delimiter: '-',
                },
            },
        }
    },

    methods: {
        update(val) {
            this.date = val
            this.$emit('input', this.date)
        }
    }
}
</script>

<style>

</style>