import axios from 'axios';
import moment from 'moment';
import store from '@/js/store';

const DEBUG = false;
// API Keys
const GOOGLE_CLIENT_ID = '79573066821-je5ujpdncagi5ogmsf45pgagg24fnc5d.apps.googleusercontent.com'
const GOOGLE_API_KEY = 'AIzaSyDsDUf3QHxvkYdHjv_5KgfsR4G0rMHyfqA'

// Error strings
const errorMessages = {
    access: "You do not have access to this data.",
    notfound: "The info you are looking for does not exist.",
    server: "There was a server error. Please try again after some time.",
    internet: "We were unable to connect. Please check your internet connection and try again.",
    unknown: "Unknown error. Please check your internet connection and try again."
};

// Helper functions
const errorHandler = (error, validationCallback, showAlerts = true) => {

    if (error.response) {
        switch(error.response.status) {
            case 401: // Not Logged In
                store.dispatch('auth/removeUser').then(() => {
                    store.commit('ui/goToLogin');
                });
                return 401;

            case 403: // Forbidden
                store.dispatch('auth/removeUser').then(() => {
                    store.commit('ui/goToLogin');
                });
                return 403;
            
            case 404: // Not Found
                if (showAlerts)
                    window.$f7.dialog.alert(errorMessages.notfound);
                return 404;
            
            case 422: // Validation Error
                validationCallback(error.response.data.data);
                return 422;
            
            case 500: // Server Error
                if (showAlerts)
                    window.$f7.dialog.alert(errorMessages.server);
                return 500;

            default: // Other Errors
                if (showAlerts)
                    window.$f7.dialog.alert(errorMessages.unknown);
                return 0;
        }
    } else {
        // Axios Failed - Internet Issue
        if (showAlerts)
            window.$f7.dialog.alert(errorMessages.internet);
        return -1;
    }
}

const BASEURL = DEBUG ? 'http://localhost:8000' : 'https://backend.thecolloquium.in'

// Create axios instance
const participantAPI = () => {
    let api_url = `${BASEURL}/api/participant`
    let config = {
        baseURL: api_url,
        headers: {
            Accept: 'application/json'
        }
    };
    if (store.getters['auth/getToken']) {
        config.headers.Authorization = 'Bearer ' + store.getters['auth/getToken'];
    }
    return axios.create(config);
}

// Helper Functions

const formatDate = (unformattedDate) => {
    return moment(unformattedDate).format('DD-MM-YYYY')
}

const unformatDate = (formattedDate) => {
    let finalVal = []
    let parts = formattedDate.split('-')
    if (parts.length >= 3) {
        let day = parts[0]
        let month = parts[1] - 1
        let year = parts[2]
        finalVal = [new Date(year, month, day).toString()]
    }
    return finalVal
}

const formatWizardData = (data) => {
    let res = {}
    data.map(question => {
        if (question.type == 'datepicker') {
            res[question.key] = formatDate(question.value[0])
        } else {
            res[question.key] = question.value 
        }
    })
    return res
}

const unformatWizardData = (data, questions) => {
    return questions.map((question) => {
        if (question.type == 'datepicker') {
            question.value = unformatDate(data[question.key])
        } else {
            question.value = data[question.key]
        }
        return question
    })
}

const makeFormData = (data) => {
    // Objects or arrays can't be appended but regular data or files can
    let isAppendable = (item) => {
        return !(typeof item === 'object') || (item instanceof File)
    }

    // Recursive helper function to generate form data
    let formDataGenerator = (formData, payload, namespace) => {
        // Loop through each key of the payload to check
        if (payload) {
            Object.keys(payload).forEach(key => {
                // Appendable data
                if (isAppendable(payload[key])) {
                    let value = payload[key] ? payload[key] : ''
                    if (namespace) {
                        formData.append(namespace + '[' + key + ']', value)
                    } else {
                        formData.append(key, value)
                    }
                }
                // Non appendable data (objects & arrays)
                else {
                    // Data is an array
                    if (Array.isArray(payload[key])) {
                        // Loop over array to append data
                        for (let i = 0; i < payload[key].length; i++) {
                            let item = payload[key][i]
                            // Generate namespace
                            let newNamespace = ''
                            if (namespace) {
                                newNamespace = namespace + '[' + key + ']' + '[' + i + ']'
                            } else {
                                newNamespace = key + '[' + i + ']'
                            }
                            // Append data
                            if (isAppendable(item)) {
                                let value = payload[key][i] ? payload[key][i] : '' 
                                formData.append(newNamespace, value)
                            } else {
                                formDataGenerator(formData, payload[key][i], newNamespace)
                            }
                        }
                    }
                    // Data is an object
                    else {
                        // Generate namespace
                        let newNamespace = ''
                        if (namespace) {
                            newNamespace = namespace + '[' + key + ']'
                        } else {
                            newNamespace = key
                        }
                        // Append data
                        formDataGenerator(formData, payload[key], newNamespace)
                    }
                }
            })
        }
    }

    // Generate form data and return
    let formData = new FormData()
    formDataGenerator(formData, data)
    return formData
}

// Export Data
export {
    GOOGLE_CLIENT_ID,
    GOOGLE_API_KEY,
    participantAPI,
    errorMessages,
    errorHandler,
    formatDate,
    unformatDate,
    formatWizardData,
    unformatWizardData,
    makeFormData,
    BASEURL
};