<template>
  <div class="pd-btm" :class="{'no-pd': no_pd}">
    
    <slot></slot>
    <f7-block-title v-if="list_header">{{ list_header }}</f7-block-title>
    <f7-block strong v-for="data in data_prop"
        :key="data">
      <p v-html="'&nbsp;&nbsp;' + data"></p>
    </f7-block>
    
  </div>
</template>

<script>
export default {
    name: 'AZReadOnly',
    props: {
      data_prop: {
        required: true
      },
      list_header: {
        required: false
      },
      no_pd: {
        default: false
      }
    },
}
</script>

<style scoped>
    .block {
        margin: 0 !important;
    }

    .block > p::before {
        content: '‣';
    }

    .fixed {
      position: fixed;
    }

    .pd-btm {
      padding-bottom: 80px;
    }

    .no-pd {
      padding-bottom: 0 !important;
    }
</style>