<template>
  <div>
    <az-custom-list :style="indent">
      <az-custom-list-item
        :preIconMd="materialIcon"
        :preIconIos="iosIcon"
        :title="node.index + '. ' + node.node.title"
        :noMenu="false"
        @item-click="toggleChildren"
        @menu-click="onMenuClick"
        @action-click="onActionClick"
        :stricken="node.node.status == 1"
        :desktopMode="true"
        :desktopIcons="options"
      >
        <!-- <template v-slot:text>
          <p>{{ node.node.delegates_text }}</p>
        </template> -->
        <template v-slot:icon>
          <f7-checkbox 
            name="checkbox-1"
            @change="markTask(node.node)"
            :checked="node.node.status == 1"
            :indeterminate="indeterminateState"
            ></f7-checkbox>
        </template>
      </az-custom-list-item>
    </az-custom-list>
    <tree-menu
      class="tree-item" 
      v-if="showChildren"
      v-for="node in nodes" 
      :key="node.id"
      :nodes="node.children" 
      :node="node"
      :depth="depth + 1"
      @reload="reload"
      @action-event="emitActionEvent"
      @mark="emitMarkEvent"
      @edit-event="emitEditEvent"
      @delete-event="emitDeleteEvent"
      @add-event="emitAddEvent"
      @copy-event="emitCopyEvent"
    >
    </tree-menu>  
  </div>
</template>
<script>
  import {participantAPI, errorHandler} from '@/js/api' 
  import AZCustomList from '@/components/ui/AZCustomList'
  import AZCustomListItem from '@/components/ui/AZCustomListItem'
  export default { 
    props: [ 'node', 'nodes', 'depth'],
    components: {
      'az-custom-list': AZCustomList,
      'az-custom-list-item': AZCustomListItem
    },
    data() {
      return { 
        showChildren: true,
        options: [
          {
            text: 'Edit',
            material: 'material:edit',
            ios: 'f7:pencil'
          },
          {
            text: 'Delete',
            material: 'material:delete',
            ios: 'f7:trash'
          },
          {
            text: 'Add',
            material: 'material:playlist_add',
            ios: 'f7:plus_rectangle_on_rectangle'
          },
          {
            text: 'Copy',
            material: 'material:content_copy',
            ios: 'f7:doc_on_doc'
          },
        ]
      }
    },
    name: 'tree-menu',
    computed: {
      indent() {
        return { marginLeft: `${this.depth * 20}px` }
      },

      indeterminateState() {
        let count = this.nodes.filter(node => node.node.status == 0).length
        return count < this.nodes.length && count > 0
      },

      materialIcon() {
        return this.nodes.length == 0 ? "" : !this.showChildren ? "material:keyboard_arrow_down" : "material:keyboard_arrow_up"
      },

      iosIcon() {
        return this.nodes.length == 0 ? "" : !this.showChildren ? "f7:chevron_down" : "f7:chevron_up"
      }
    },
    methods: {
      toggleChildren() {
        this.showChildren = !this.showChildren;
      },

      onActionClick(action) {
        this.setCurrentBlueprint()
        if (action == 'Edit') {
          this.emitEditEvent()
        }
        else if (action == 'Delete') {
          this.emitDeleteEvent()
        }
        else if (action == 'Add') {
          this.emitAddEvent()
        }
        else if (action == 'Copy') {
          this.emitCopyEvent()
        }
      },

      onMenuClick() {
        this.setCurrentBlueprint()
        this.emitActionEvent()
      },

      setCurrentBlueprint() {
        this.$store.commit('blueprint/setCurrentBlueprint', this.node.node)
      },

      markTask(task) {
        this.emitMarkEvent()
        let batchId = this.$store.getters['auth/getCurrentBatchID']
        participantAPI().post(`batch/${batchId}/event/${task.id}/mark`, {
          status: 1 - task.status
        })
        .then(response => {
          this.pageState = 'loaded'
          this.reload()
        })
        .catch(err => {
          errorHandler(err)
          this.pageState = 'loaded'
        })
      },

      reload() {
        this.$emit('reload')
      },

      emitActionEvent() {
        this.$emit('action-event')
      },

      emitMarkEvent() {
        this.$emit('mark')
      },

      emitEditEvent() {
        this.$emit('edit-event')
      },

      emitDeleteEvent() {
        this.$emit('delete-event')
      },

      emitAddEvent() {
        this.$emit('add-event')
      },

      emitCopyEvent() {
        this.$emit('copy-event')
      }
    }
  }
</script>

<style scoped>

  .list {
    margin: 0;
  }
</style>