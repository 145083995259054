<template>
  <f7-page>
    <!-- Navbar -->
    <f7-navbar back-link="Back">

      <!-- Page Title -->
      <f7-nav-title>
            Business Details
      </f7-nav-title>

    </f7-navbar>

    <div>
      <az-list class="fab-wrapper">
        <az-list-input label="Your Facebook link *" type="textarea" v-model="form.facebook"></az-list-input>
        <az-list-input label="Your LinkedIn profile" type="textarea" v-model="form.linkedin"></az-list-input>
        <az-list-input label="Your Instagram handle" type="textarea" v-model="form.instagram"></az-list-input>
        <az-list-input label="Your Twitter handle" type="textarea" v-model="form.twitter"></az-list-input>
        <az-list-input label="Business Networks/Organisations you are a part of *" type="textarea" v-model="form.part_of_organizations"></az-list-input>
        <az-list-input label="Training Programs/Workshops you have done before Colloquium *" type="textarea" v-model="form.workshops_before_cq"></az-list-input>
      </az-list>
    </div>

    <az-loader v-if="pageState == 'loading'"></az-loader>

    <f7-fab class="fixed"
            v-if="pageState == 'loaded'"
            position="right-bottom"
            @click="onSaveClick">
        <f7-icon ios="f7:floppy_disk" 
            aurora="f7:floppy_disk"
            md="material:save" />
    </f7-fab>

  </f7-page>
</template>

<script>
import AZList from '@/components/ui/AZList.vue';
import AZListInput from '@/components/ui/AZListInput.vue';
import AZLoader from '@/components/ui/AZLoader';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import { participantAPI, errorHandler, makeFormData } from '@/js/api'
import AZDateMask from '@/components/ui/AZDateMask.vue';

export default {
    props: {
      form_data_prop: Object
    },

    components: {
    'az-list': AZList,
    'az-list-input': AZListInput,
    'az-loader': AZLoader,
    'az-error-message': AZPageErrorMessage,
    'az-date-mask': AZDateMask
  },

  mounted() {
    this.form = this.form_data_prop
  },

  data() {
      return {
        pageState: 'loaded',
        form: {}
      }
    },

    methods: {
      onSaveClick() {
        let data = {
            _method: 'PUT',
            ...this.form
        }
        let formData = makeFormData(data)
        // return
        this.pageState = 'loading'
        participantAPI().post(
            '/communication-profile',
            formData
        )
        .then(res => {
            this.$f7router.back()
        })
        .catch(error => {
            this.pageState = 'loaded'
            errorHandler(error, 
            (errors) => {
            window.$f7.dialog.alert(errors[0], "Error");
            })
        })
      },
    },
}
</script>

<style>

</style>