<template>
  <li>
    <a :href="href" class="item-link item-content">
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title">Date: {{ date }}</div>
        </div>
        <div class="item-subtitle">Total Time: {{ totalTime }}</div>
        <div class="item-text">Time Saved: {{ timeSaved }}</div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: 'cq-timefinder-item',
  props: ['href', 'date', 'totalTime', 'timeSaved']
}
</script>

<style>

</style>