<template>
  <div class="wrapper">
    <div class="inner">
      <p v-html="message" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    name: 'az-page-message',
    props: {
      message: {
        default: `<b>No Data</b><br>
                  Click on the (+) button to make an entry`
      }
    }
}
</script>

<style scoped>
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .wrapper .inner {
    position: absolute;
    width: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .wrapper p {
    display: block;
    width: 100%; 
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
  }
  .wrapper >>> .button {
    max-width: 150px;
    margin: 0 auto;
  }
</style>