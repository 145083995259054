export function arrayRotate(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
}

export function makeCumulativeArray(arr) {
    let new_array = []
    arr.reduce((a,b,i) => { 
        let inta = parseFloat(a)
        let intb = parseFloat(b)
        if (isNaN(inta) || isNaN(intb)) {
            return new_array[i] = '-'
        }
        let res = inta + intb
        if (parseInt(res) == res) {
            return new_array[i] = parseInt(res)
        }
        return new_array[i] = (res).toFixed(2) 
    }, 0)
    return new_array
}