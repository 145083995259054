<template>
  <div class="az-tab-page">

    <!-- List -->
    <div class="az-tab-scrollable">
      <div v-if="clarities.length > 0">
        <f7-block-title>Clarity Missing</f7-block-title>
        <cq-clarity-list class="fab-wrapper">
          <cq-clarity-item 
              v-for="clarity in clarities"
              :key="clarity.id"
              :clarity-about="clarity.about"
              :action="clarity.who_where" 
              :resolved="clarity.resolved == 1"
              @click.native="onEditClick(clarity)" />
        </cq-clarity-list>
      </div>

      <!-- No Data Message -->
      <template v-else>
        <az-page-message v-if="pageState != 'failed'" />
      </template>

    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="refreshData" />

    <!-- Add Button -->
    <f7-fab class="fab-tab" position="right-bottom"  @click="onAddClick">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>

  </div>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';

import AZLoader from '@/components/ui/AZLoader';
import AZPageMessage from '@/components/ui/AZPageMessage';
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
import CQClarityList from '@/components/accomplishment-blueprint/clarity/CQClarityList';
import CQClarityItem from '@/components/accomplishment-blueprint/clarity/CQClarityItem';

export default {
  name: 'ClarityIndex',
  components: {
    'az-loader': AZLoader,
    'az-page-message': AZPageMessage,
    'az-page-error-message': AZPageErrorMessage,
    'cq-clarity-list': CQClarityList,
    'cq-clarity-item': CQClarityItem,
  },
  
  props: ['flag'],

  data() {
    return {
      pageState: 'loading',
      clarities: []
    }
  },

  watch: {
    flag (value) {
      this.loadDataFromAPI()
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {
    loadDataFromAPI() {

      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`batch/${batchId}/clarity-missing`)
        .then(response => {
          this.clarities = response.data.data
          this.pageState = 'loaded'
        })
        .catch(error => {
          errorHandler(error)
          this.pageState = "failed"
        })
    },

    onAddClick() {
      this.$f7router.navigate('/accomplishment-blueprint/clarity/create/')
    },

    // Button Click Events
    
    refreshData () {
      this.clarities = [];
      this.pageState = 'loading';
      this.loadDataFromAPI();
    },

    onEditClick(item) {
      this.$f7router.navigate(`/accomplishment-blueprint/clarity/create/`, {
        props: {
          form_data_prop: item
        }
      })
    },
  }
}
</script>

<style scoped>
</style>