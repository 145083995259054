<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar :title="'Why MIH Framework'" back-link="Back">
    </f7-navbar>

    <!-- Body -->
    <!-- Wrapper -->
    <div class="wrapper" v-if="pageState == 'loaded'">
      <az-wizard
        :questions="questions"
        @next-clicked="onPageChange"
        @prev-clicked="onPageChange"
        @save-clicked="onSaveClick"
      ></az-wizard>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import AZWizard from '@/components/ui/AZWizard.vue'
import { GameTypes } from '@/js/consts'
import { formatWizardData, unformatWizardData } from '@/js/api'
import AZLoader from '@/components/ui/AZLoader.vue'

export default {
  name: 'FrameworkCreate',
  components: {
    'az-wizard': AZWizard,
    'az-loader': AZLoader,
  },
  props: {
    form_data_prop: {
      required: false
    }
  },

  data() {
    return {
      pageState: 'loaded',
      currentId: undefined,
      questions: [
        {
          title: `Q1. Why do you want to
                    implement this
                    FRAMEWORK in
                    your life? What
                    benefit will it give
                    you?
                    What does it mean
                    to you?
                    What is in it for you?`,
          type: "textarea",
          key: 'benefit',
          value: '',
          error: '',
          required: true
        },
        {
          title: `Q2. If you implemented
                    this FRAMEWORK,
                    what benefit will it
                    create for your
                    family & friends?
                    What will it mean
                    for them?
                    What is in it for
                    them?`,
          type: "textarea",
          options: GameTypes,
          key: 'family',
          value: '',
          error: '',
          required: true
        },
        {
          title: `Q3. If you implemented
                    this FRAMEWORK,
                    what experiences
                    and feelings will
                    disappear from your
                    life?`,
          type: 'textarea',
          key: 'feelings',
          value: '',
          error: '',
          required: true
        },
        {
          title: `Q4. What reasons can
                    come in your way of
                    you implementing
                    this FRAMEWORK?`,
          type: 'textarea',
          key: 'reasons',
          value: '',
          error: '',
          required: true
        },
        {
          title: `Q5. What will it cost you
                    if you don’t take the
                    time to implement
                    this FRAMEWORK?`,
          type: 'textarea',
          key: 'cost',
          value: '',
          error: '',
          required: true
        },
        {
          title: `Q6. Paint a vivid picture
                    of how your life
                    looks like, when you
                    are implementing
                    this FRAMEWORK
                    daily in all areas of
                    your life.`,
          type: 'textarea',
          key: 'picture',
          value: '',
          error: '',
          required: true
        },
      ]
    }
  },

  mounted() {
    if (!this.isEditMode) {
        setTimeout(() => {
        this.$f7router.back();
      }, 500)
    }
    else {
        this.questions = unformatWizardData(this.form_data_prop, this.questions)
    }
  },

  methods: {
    onPageChange (currentStep) {
    //   this.saveData (currentStep)
    },

    onSaveClick (currentStep) {
      this.pageState = 'loading'
      this.saveData(currentStep, true)
    },

    saveData (currentStep, closeAfterSaving = false) {
      let url = '';
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      let res = formatWizardData(this.questions)
      
      participantAPI().post(`batch/${batchId}/why-implement-framework`, {
          ...res
        })
        .then(res => {
            this.$f7router.back()
        })
        .catch(err => {
            errorHandler(error);
            this.pageState = 'loaded';
        })
    
    },
  },

  computed: {
    isEditMode() {
      return this.form_data_prop !== undefined
    }
  }
}
</script>

