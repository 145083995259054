<template>
  <f7-page name="home">

    <!-- Top Navbar -->
    <f7-navbar>
      <!-- Page Title -->
      <f7-nav-title>Hello {{ first_name }}</f7-nav-title>

      <!-- Right Buttons -->
      <f7-nav-right>
        <f7-link class="searchbar-enable" 
                data-searchbar=".cq-searchbar"
                icon-ios="f7:search"
                icon-aurora="f7:search"
                icon-md="material:search" />
      </f7-nav-right>

      <!-- Searchbar -->
      <f7-searchbar class="cq-searchbar" 
                    expandable
                    search-container=".media-list"
                    search-item=".media-item"
                    search-in=".item-title"
                    :disable-button="!$theme.aurora" />
    </f7-navbar>
    
    <f7-block-title v-if="!noBatches">Colloquium Tools</f7-block-title>
    <f7-list v-if="!noBatches" accordion-list>

      <az-list-item title="1. Participant Info" panel-close link="/participant-information/">
      </az-list-item>

      <az-list-item title="2. Key Documents" panel-close link="/key-handouts/">
      </az-list-item>
      
      <f7-list-item v-if="isThreePresent" accordion-item title="3. Intensive Tools">
        <f7-accordion-content>
          <f7-list media-list>
            <az-list-item v-if="permissions['time-auditor']" title="Time Auditor" subtitle="3.1" :icon="icons.circular_clock" link="/time-auditor/" />
            <az-list-item v-if="permissions['game-creator']" title="Game Creator" subtitle="3.2" :icon="icons.dice" link="/game-creator/" />
            <az-list-item v-if="permissions['games-blueprint']" title="Games Blueprint" subtitle="3.3" :icon="icons.list" link="/accomplishment-blueprint/" />
            <az-list-item v-if="permissions['reasons-eliminator']" title="Reasons Eliminator" subtitle="3.4" :icon="icons.scissors" link="/reasons-eliminator/" />
            <az-list-item v-if="permissions['time-finder']" title="Time Finder" subtitle="3.5" :icon="icons.search" link="/time-finder/" />
            <az-list-item v-if="permissions['moment']" title="Moment I am Living for" subtitle="3.6" :icon="icons.search" link="/moments/"></az-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item v-if="isFourPresent" accordion-item title="4. Habit Builder Tools">
        <f7-accordion-content>
          <f7-list media-list>
            <az-list-item v-if="permissions['performance-builder']" title="Performance Builder" subtitle="4.1" :icon="icons.speedometer" link="/performance-builder/" />
            <az-list-item v-if="permissions['power-planner']" title="Power Planner" subtitle="4.2" :icon="icons.puzzle" link="/power-planner/" />
            <az-list-item v-if="permissions['habits-enhancer']" title="Habits Enhancer" subtitle="4.3" :icon="icons.magic_wand" link="/habits-enhancer/" />
            <az-list-item v-if="permissions['framework-mastery']" title="Framework Mastery" subtitle="4.4" :icon="icons.magic_wand" link="/framework-mastery/" />
            <az-list-item v-if="permissions['communication-manager']" title="Communication Manager" subtitle="4.5" :icon="icons.chat" link="/communication-manager/" />
            <az-list-item v-if="permissions['mtg-success-maximizer']" title="Mtg Success Maximizer" subtitle="4.6" :icon="icons.conversation" link="/meeting-success-maximizer/" />
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item v-if="isFivePresent" accordion-item title="5. Learning Space">
        <f7-accordion-content>
          <f7-list media-list>
            <az-list-item v-if="permissions['accomplishment-diary']" title="Accomplishments Diary" subtitle="5.1" :icon="icons.diary" panel-close link="/accomplishments-diary/" />
            <az-list-item v-if="permissions['habit-change-journal']" title="Habit Change Journal" subtitle="5.2" :icon="icons.exchange" panel-close link="/habit-change-journal/" />
            <az-list-item v-if="permissions['insights-log']" title="Insights Log" subtitle="5.3" :icon="icons.idea" panel-close link="/insights-log/" />
            <az-list-item v-if="permissions['time-saver']" title="Time Saver" subtitle="5.4" :icon="icons.save_time" panel-close link="/time-savers/" />
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item v-if="isSixPresent" accordion-item title="6. Personal Space">
        <f7-accordion-content>
          <f7-list media-list>
            <az-list-item v-if="permissions['top-things']" title="Top Things I Get Paid For" subtitle="6.1" :icon="icons.diary" panel-close link="/top-things-paid/" />
            <az-list-item v-if="permissions['mih-framework']" title="Why MIH Framework" subtitle="6.2" :icon="icons.exchange" panel-close link="/framework/" />
            <az-list-item v-if="permissions['money-maker']" title="Money Maker" subtitle="6.3" :icon="icons.search" link="/money-maker/" />
            <az-list-item v-if="permissions['people']" title="My People" subtitle="6.4" :icon="icons.idea" panel-close link="/people/" />
            <az-list-item v-if="permissions['projects']" title="My Projects" subtitle="6.5" :icon="icons.save_time" panel-close link="/project/" />
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

    </f7-list>

    <f7-block-title>Your Account</f7-block-title>
    <f7-list style="margin-bottom: 250px;">
      <az-list-item v-if="!noBatches" title="Schedule" panel-close link="/home/"></az-list-item>
      <az-list-item title="My Profile" panel-close link="/profile/"></az-list-item>
      <az-list-item v-if="!noBatches" title="Google Sign In" panel-close link="/google-login/"></az-list-item>
      <az-list-item v-if="!noBatches" title="Select Batch" panel-close link="/batch/"></az-list-item>
      <f7-list-item title="Log Out" panel-close link="#" @click="onLogoutClick"></f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
// Components
import AZListItem from '@/components/ui/AZListItem';

// Images
import home from '@/assets/tools/home.png';
import circular_clock from '@/assets/tools/circular-clock.png';
import dice from '@/assets/tools/dice.png';
import list from '@/assets/tools/list.png';
import scissors from '@/assets/tools/scissors.png';
import search from '@/assets/tools/search.png';
import speedometer from '@/assets/tools/speedometer.png';
import magic_wand from '@/assets/tools/magic-wand.png';
import puzzle from '@/assets/tools/puzzle.png';
import chat from '@/assets/tools/chat.png';
import conversation from '@/assets/tools/conversation.png';
import diary from '@/assets/tools/diary.png';
import exchange from '@/assets/tools/exchange.png';
import idea from '@/assets/tools/idea.png';
import save_time from '@/assets/tools/save-time.png';

export default {
  name: 'cq-navigation',
  components: {
    'az-list-item': AZListItem,
  },
  data() {
    return {
      icons: {
        home,
        circular_clock,
        dice,
        list,
        scissors,
        search,
        speedometer,
        magic_wand,
        puzzle,
        chat,
        conversation,
        diary,
        exchange,
        idea,
        save_time,
      }
    }
  },
  computed: {
    first_name() {
      let user = this.$store.getters['auth/getUser'];
      if (user) {
        return user.first_name;
      }
      return 'User';
    },

    permissions() {
      return this.$store.getters['auth/getPermissions']
      
    },

    allPermissions() {
      return Object.values(this.permissions).filter(val => val).length === 20
    },

    isThreePresent() {
      if (this.allPermissions) return true
      if (this.permissions) {
        let slugs = ['time-auditor', 'game-creator', 'event', 'reasons-eliminator', 'time-finder', 'moment']
        return slugs.filter(slug => this.permissions[slug]).length > 0
      }
      return true
    },

    isFourPresent() {
      if (this.allPermissions) return true
      if (this.permissions) {

        let slugs = ['performance-builder', 'event', 'habits-enhancer', 'framework-mastery', 'communication-manager', 'mtg-success-maximizer']
        return slugs.filter(slug => this.permissions[slug]).length > 0
      }

      return true
    },

    isFivePresent() {
      if (this.allPermissions) return true
      if (this.permissions) {

        let slugs = ['accomplishment-diary', 'habit-change-journal', 'insights-log', 'time-saver']
        return slugs.filter(slug => this.permissions[slug]).length > 0
      }

      return true
    },

    isSixPresent() {
      if (this.allPermissions) return true
      if (this.permissions) {

        let slugs = ['top-things', 'mih-framework', 'money-maker', 'people', 'projects']
        return slugs.filter(slug => this.permissions[slug]).length > 0
      }

      return true
    },

    noBatches() {
      return this.$store.getters['auth/getBatches'].length === 0
    }
  },
  methods: {
    onLogoutClick() {
      window.$f7.dialog.confirm("Are you sure you want to logout?", "Logout", 
        () => {
          this.$store.dispatch('auth/logout');
        });
    },

    permissionVisibility(slug) {
      return false
    },
  },
}
</script>

<style scoped>
  .block-title {
    font-weight: 700;
    text-transform: uppercase;
  }
  .page-content {
    min-height: 100vh;
  }
</style>