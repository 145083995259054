<template>
    <li class="outer" :style="{background: background}" @dblclick="onDesktopIconClick('Edit')">
        <div class="row">
          <div class="col-80">

            <div class="text-side" @click="onClick">
                
                <div class="title" :class="{'stricken': stricken}"><span class="wrap" v-html="title"></span>
                  &nbsp;
                  <span>
                    <f7-icon slot="media"
                      :ios="preIconIos"
                      :aurora="preIconIos"
                      :md="preIconMd"
                    />
                  </span>
                </div>
              
                <div class="subtitle"><span v-html="subtitle"></span></div>
                <div class="other">
                  <slot name="text"></slot>
                </div>
            </div>
          </div>
          <div class="col-20 phone-icons" :class="{'hide-lg': desktopMode}">
            <div class="phone-icon"><slot name="icon"></slot></div>
            <div class="phone-icon" v-if="!noMenu" @click="menuClick">
                  <f7-icon slot="media"
                          ios="f7:ellipsis_vertical"
                          aurora="f7:ellipsis_vertical"
                          md="material:more_vert"
                            />
                </div>
            
          </div>

          <div class="col-20 desktop-icons" v-if="desktopMode">
            <div class="desktop-icon"><slot name="icon"></slot></div>
            <div v-for="icon in desktopIcons" 
                :key="icon.text"
                @click="onDesktopIconClick(icon.text)"
                class="desktop-icon">
              <f7-icon slot="media"
                  :ios="icon.ios"
                  :aurora="icon.material"
                  :md="icon.material"
                />
            </div>
          </div>
        </div>
        
        
    </li>
    
</template>

<script>
export default {
  name: 'az-custom-list-item',
  props: {
    title: String,
    subtitle: String,
    background: {
      type: String,
    },
    noMenu: {
      type: Boolean,
      default: false
    },
    stricken: {
      type: Boolean,
      default: false
    },
    preIconMd: {
      type: String
    },
    preIconIos: {
      type: String
    },
    desktopMode: {
      type: Boolean,
      default: false
    },
    desktopIcons: Array
  },

  data() {
    return {
      clicks: 0
    }
  },

  methods: {
      onClick() {
        this.clicks++
        setTimeout(() => {
          if(this.clicks == 1)
            this.$emit('item-click')
          this.clicks = 0
        }, 150)
      },

      menuClick() {
          this.$emit('menu-click')
      },

      onDesktopIconClick(action) {
        this.$emit('action-click', action)
      },
  },
}
</script>

<style scoped>
  .outer {
    padding: 10px 15px;
    cursor: pointer;
  }

  .other {
    margin-top: 4px;
  }

  .other > p {
    margin: 0;
    font-size: 12px;
    color: #656565;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
  }

  .subtitle {
    margin-top: 3px;
    font-weight: 400;
    font-size: 13px;
    color: #454545;
  }

  i {
    font-size: 25px;
    color: #454545;
  }
  
  .stricken {
    text-decoration: line-through;
  }

  .wrap {
    word-break: break-word;
  }

  .ttl {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .desktop-icons {
    display: none;
  }

  .phone-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .phone-icon {
    padding: 2px 10px;
  }

  @media screen and (min-width: 960px) {
    .col-20 {
      --f7-cols-per-row: 15;
    }

    .hide-lg {
      display: none;
    }

    .desktop-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .desktop-icon {
      padding: 2px 15px;
    }
  }
</style>