<template>
    <f7-page>
        <f7-navbar>
            <!-- Sidebar Button -->
            <f7-nav-left>
                <f7-link 
                    panel-open="left"
                    icon-ios="f7:menu" 
                    icon-aurora="f7:menu"
                    icon-md="material:menu"
                    >
                </f7-link>
            </f7-nav-left>

            <!-- Page Title -->
            <f7-nav-title>
                {{ currentList.title }}
            </f7-nav-title>
        </f7-navbar>

        <az-steps :prev-visible="currentStepIndex > 0"
            :next-visible="currentStepIndex < (list.length - 1)"
            :save-visible="false"

            :save-icon-md="saveIconMd"
            :save-icon-ios="saveIconIos"

            @prev-clicked="onPrevClicked"
            @next-clicked="onNextClicked"
            @save-clicked="onSaveClicked"
  >
    
            <!-- Pages -->
            <div v-for="(question, index) in list"
                v-if="index == currentStepIndex"
                :key="index"
                :style="{ width: '100%', position: 'absolute', top: ($f7.device.ios ? '44px' : '56px') }">

                <az-readonly
                    v-if="currentList.type == 'text'"
                    :data_prop="currentList.rules"
                    :list_header="currentList.list_header"
                >
                    <f7-block v-if="currentList.slot" strong>
                        <h4>{{ currentList.slot }}</h4>
                    </f7-block>
                </az-readonly>

                <az-readonly-img
                    v-if="currentList.type == 'picture'"
                    :image="currentList.picture"
                >
                </az-readonly-img>

                <div v-if="currentList.type == 'multiple'">
                    <az-readonly
                        v-for="(item, index) in currentList.rules"
                        :key="index"
                        :data_prop="item.rules"
                        :list_header="item.list_header"
                        :no_pd="index != currentList.rules.length - 1"
                    >   
                    </az-readonly>
                </div>

            </div>

        </az-steps>
    </f7-page>
</template>

<script>
import AZSteps from '@/components/ui/AZSteps.vue'
import AZReadOnly from '@/components/ui/AZReadOnly'
import AZReadOnlyImg from '@/components/ui/AZReadOnlyImg'
export default {
    name: 'AZTextWizard',
    props: {
        list: {
            required: true,
            type: Array
        },
        saveIconMd: {
            type: String,
            default: "material:check"
        },
        saveIconIos: {
            type: String,
            default: "f7:checkmark"
        }
    },
    components: {
        'az-steps': AZSteps,
        'az-readonly': AZReadOnly,
        'az-readonly-img': AZReadOnlyImg
    },
    data() {
        return {
            currentStepIndex: 0
        }
    },

    methods: {
        onNextClicked() {
            this.currentStepIndex++;
        },

        onPrevClicked() {
            this.currentStepIndex--;
        },

        onSaveClicked() {

        }
    },

    computed: {
        currentList() {
            return this.list[this.currentStepIndex]
        }
    }
}
</script>

<style>

</style>