<template>
    <f7-page>

        <az-text-wizard
            :list="rules"
        ></az-text-wizard>
    </f7-page>
</template>

<script>
import AZReadOnly from '@/components/ui/AZReadOnly'
import AZTextWizard from '@/components/ui/AZTextWizard'

export default {
    name: 'ParticipantInformation',
    components: {
        'az-readonly': AZReadOnly,
        'az-text-wizard': AZTextWizard
    },
    data() {
        return {
            rules: [
                {
                    title: 'Intention of the Colloquium',
                    type: 'text',
                    list_header: 'You are enabled in',
                    rules: [
                        `Expanding your ability to create Time, Focus, Clarity & Reality in any Games that you take on`,
                
                        `Making Master Plans of your Goals`,
                        
                        `Realizing the gaps in converting your Goals into reality and overcoming them`,

                        `Using a System to ensure that you are implementing MILLION DOLLAR HABITS.`
                    ]
                },
                {
                    title: 'Outcome of the Colloquium',
                    type: 'text',
                    rules: [
                            `You are producing TANGIBLE RESULTS that you really want, as a by product of you 
                            having developed MILLION DOLLAR HABITS around time, focus, clarity & reality`,
                            
                            `You practice keeping your Promises.`
                    ]
                },

                {
                    title: 'Promise of the Colloquium',
                    type: 'text',
                    rules: [
                            `You are implementing a Fool Proof System to produce any result in all areas of your life`,
                
                            `Producing TANGIBLE RESULTS is a habit.`
                    ]
                },

                {
                    title: 'Colloquium Tenets',
                    type: 'text',
                    rules: [
                        `Walking the Talk`,
                
                        `Producing Tangible Results`,
                        
                        `Creating Work-life Balance`,

                        `Transforming Unproductive Habits`,

                        `Mastering Million Dollar Habits`
                    ]
                },

                {
                    title: 'Colloquium Values',
                    type: 'multiple',
                    rules: [
                        {
                            list_header: 'Excellence',
                            type: 'text',
                            rules: [
                                `Excellence has its signature over everything we do!`,

                                `We aspire to be world-class in every way.`
                            ]
                        },
                        {
                            list_header: 'Transparency',
                            type: 'text',
                            rules: [
                                `Nothing hidden. We are what you see.`
                            ]
                        },
                        {
                            list_header: 'Authenticity',
                            type: 'text',
                            rules: [
                                `We are who we hold ourselves to be. We do what we say.`,

                                `When we don’t do what we say, we say so, & we face our consequences
                                with open arms.`
                            ]
                        },
                        {
                            list_header: 'Passion',
                            type: 'text',
                            rules: [
                                `Our Passion is that you produce TANGIBLE RESULTS week after week.`,

                                `We draw our energy and inspiration from our Passion.`
                            ]
                        },
                        {
                            list_header: 'Clarity',
                            type: 'text',
                            rules: [
                                `We worship clarity. We seek clarity whenever we find anything in grey.`
                            ]
                        },
                        {
                            list_header: 'Commitment',
                            type: 'text',
                            rules: [
                                `We match other people’s commitment to produce results that they
                                really want.`,

                                `We are unstoppable.`
                            ]
                        }
                    ]
                },

                {
                    title: 'Colloquium Culture',
                    type: 'text',
                    rules: [
                        `We are committed to making your experience with us and at the Colloquium,
                        professional and WORLD-CLASS.`,

                        `WE ALL MAKE THE ROCK TALK!`,

                        `We stop at nothing, and our participants stop at nothing, when it comes to
                        producing TANGIBLE RESULTS.`
                    ]
                },

                {
                    title: 'Success Gyms',
                    type: 'text',
                    rules: [
                        `Once a week, after the Million Dollar Habits Intensive, Success Gyms will be
                        scheduled for those participants, who find it difficult to fulfill their Success Plans for
                        the week or who may have challenges in completing their games.`,

                        `Success Gyms are designed to overcome Specific Personal Challenges (that may not
                        be covered in the Habit Builder Sessions) that some participants may be facing in
                        implementing our System to Unique Circumstances of their Life.`,

                        `Some participants will be asked to attend these Success Gyms to ensure that they
                        do not lag behind in their performance and they are able to cope up with the pace
                        of the Colloquium.`,

                        `Dates of Success Gyms for the next week will be announced in every Habit Builder
                        Session.`
                    ]
                },

                {
                    title: 'Clarity Audits',
                    type: 'text',
                    rules: [
                        `Clarity Audits are conducted to monitor Weekly Progress of your Habits & Results.`,

                        `Our uniquely designed Surveys, Tools, Forms & Templates allow us to know if you
                        are Transforming your Unproductive Habits & actually forming Million Dollar Habits
                        or not.`,

                        `You need to fill these Surveys, Tools, Forms & Templates within 12 hours of every
                        Session and 12 hours prior to the next Session.`,

                        `We give you a COOL REPORT CARD at the end of the Colloquium that captures your
                        HABIT CHANGE, Time Saved, Tangible Results Produced & Money Actually Made!`
                    ]
                },

                {
                    title: 'Personal Accountability Maximizer',
                    type: 'text',
                    rules: [
                        `Personal Reminders will be sent to you over emails/whatsapp and sometimes over
                        phone calls to Turbocharge your Performance.`,

                        `Some of these Reminders would be Persistent Follow Ups to make you accountable
                        to produce Specific Results.`,

                        `You will need to let us know if you would like us to stop giving you these Personal
                        Reminders.`
                    ]
                },

                {
                    title: 'Communication Structures',
                    type: 'text',
                    rules: [
                        `Each one of you will be given a Designated Consultant from Unleashed Success Team.`,

                        `Respect your Designated Consultant’s time. Always set up a time that works for you
                        and the other person at least 12 hours prior to your calls.`,

                        `If you are likely to miss your call or get late due to any circumstances, immediately
                        call or message the other person the minute you realize that you are going to miss
                        your call or be late on your call.`,

                        `Please e-mail all your Surveys, Tools, Forms & Templates to your Designated
                        Consultant on support@unleashed.in only. Always write your name and the
                        document name in the Subject of your e-mail.`,

                        `DO NOT SEND E-MAILS ON manan@unleashed.in. They will not be answered
                        unless you are specifically asked to do so.`
                    ]
                },

                {
                    title: 'Communication Protocols',
                    type: 'text',
                    slot: 'We need your support to be true to our tenets, values and culture.',
                    rules: [
                        `For any issue or support during the week, you must always go to your Designated
                        Consultant first.`,

                        `If your issue remains unresolved or you are not satisfied with the support you got
                        from your Designated Consultant after 3 interactions on that same issue/support,
                        please get in touch with Manan Vasa immediately, your issue will be resolved.`,

                        `Please leave a message if your Designated Consultant or Manan Vasa don’t answer
                        your call along with purpose of your call.`
                    ]
                },

                {
                    title: 'Internet Connection',
                    type: 'text',
                    rules: [
                        `Please carry your own Internet dongles or Internet enabled tablets with you for all
                        sessions. You may also use hotspot via tethering from your phone to connect to
                        Internet on your laptops.`,

                        `You will be doing your calendar planning and filling some Forms & Surveys in the
                        Sessions itself for which you will need a stable working Internet connection.`,

                        `We recommend that you do your work on your laptops/tablets ONLY.`
                    ]
                },

                {
                    title: 'Colloquium Folder',
                    type: 'text',
                    rules: [
                        `Your Participant Binder has the following sections:<br>
                        1. Participant Information<br>
                        2. Key Documents<br>
                        3. Intensive Tools<br>
                        4. Habit Builder Tools<br>
                        5. Personal Space`,

                        `We will be e-mailing all relevant documents, forms and templates in xlsx or PDF forms.`,

                        `Save all these files on your desktop, call it “Colloquium Folder” and keep it as your
                        Master Copy.`,

                        `You are recommended to create a duplicate folder of our Master Copy on your
                        Desktop and then use those forms.`
                    ]
                },

                {
                    title: 'Reminders for Surveys, Trackers & Forms',
                    type: 'text',
                    rules: [
                        `Timely WhatsApp reminders will be sent to you periodically between two sessions
                        for any work or relevant announcements.`,

                        `Reminders of surveys, trackers and forms that you need to fill & send to us will be
                        posted on WhatsApp group and e-mailed to you 24 hours before the next session.
                        You need to fill them up and send them to support@unleashed.in 12 hours before
                        the next session starts.`,

                        `For updates, information and news, keep checking the Whatsapp Group and your
                        registered e-mail address with us.`
                    ]
                }
            ]
        }
    }
}

</script>