<template>
    <div class="outer">
        <span class="label" v-if="label">{{ label }}</span>
        <div class="btn-group">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AZToggleButtonGroup',
    props: ['label']
}
</script>

<style scoped>
    .outer {
        background: white;
        margin: 20px 0;
        
    }

    .outer span {
        font-size: 16px;
    }

    .btn-group {
        display: flex;
        padding: 10px;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .label {
        display: inline-block;
        margin: 10px 20px;
    }

    @media screen and (min-width: 768px) {
        .btn-group {
            flex-wrap:initial;
            justify-content: flex-start;
        }
    }
</style>