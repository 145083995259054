<template>
  <f7-page @page:reinit="loadDataFromAPI">

    <f7-navbar back-link="Back">
      <!-- Page Title -->
      <f7-nav-title style="margin:0">
        {{ 'Why MIH Framework ©' }}
      </f7-nav-title>
      <f7-nav-right>
          <f7-link icon-ios="f7:pencil"
                   icon-aurora="f7:pencil"
                   icon-md="material:create"
                   @click="onEditClick" />
      </f7-nav-right>

    </f7-navbar>
    
    <div class="wrapper" v-if="pageState == 'loaded'">
      <f7-block>Q1. Why do you want to
                      implement this
                      FRAMEWORK in
                      your life? What
                      benefit will it give
                      you?
                      What does it mean
                      to you?
                      What is in it for you?
      </f7-block>
      <f7-block class="ans" strong>
        <p>{{ form_data.benefit ? form_data.benefit : 'N/A' }}</p>
      </f7-block>

      <f7-block>Q2. If you implemented
                      this FRAMEWORK,
                      what benefit will it
                      create for your
                      family & friends?
                      What will it mean
                      for them?
                      What is in it for
                      them?
      </f7-block>
      <f7-block class="ans" strong>
        <p>{{ form_data.family ? form_data.family : 'N/A' }}</p>
      </f7-block>

      <f7-block>Q3. If you implemented
                      this FRAMEWORK,
                      what experiences
                      and feelings will
                      disappear from your
                      life?
      </f7-block>
      <f7-block class="ans" strong>
        <p>{{ form_data.feelings ? form_data.feelings : 'N/A' }}</p>
      </f7-block>

      <f7-block>Q4. What reasons can
                      come in your way of
                      you implementing
                      this FRAMEWORK?
      </f7-block>
      <f7-block class="ans" strong>
        <p>{{ form_data.reasons ? form_data.reasons : 'N/A' }}</p>
      </f7-block>

      <f7-block>Q5. What will it cost you
                      if you don’t take the
                      time to implement
                      this FRAMEWORK?
      </f7-block>
      <f7-block class="ans" strong>
        <p>{{ form_data.cost ? form_data.cost : 'N/A' }}</p>
      </f7-block>

      <f7-block>Q6. Paint a vivid picture
                      of how your life
                      looks like, when you
                      are implementing
                      this FRAMEWORK
                      daily in all areas of
                      your life.
      </f7-block>
      <f7-block class="ans" strong>
        <p>{{ form_data.picture ? form_data.picture : 'N/A' }}</p>
      </f7-block>
    </div>

    <!-- Loading Screen -->
    <az-loader v-if="pageState == 'loading'"/>

    <!-- Error Message -->
    <az-page-error-message v-if="pageState == 'failed'" @try-again="onTryAgainClick" />
    
  </f7-page>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api';
import { GameTypeIcons } from '@/js/consts';
import AZLoader from '@/components/ui/AZLoader.vue'
import AZPageErrorMessage from '@/components/ui/AZPageErrorMessage';
export default {
  name: 'FrameworkShow',
  components: {
    'az-loader': AZLoader,
    'az-page-error-message': AZPageErrorMessage
  },
  data() {
    return {
      pageState: 'loading',
      form_data: {}
    }
  },

  mounted() {
    this.loadDataFromAPI()
  },

  methods: {
    onEditClick() {
      this.$f7router.navigate(`/framework/create/`, {
        props: {
          form_data_prop: this.form_data
        }
      })
    },

    loadDataFromAPI() {
      let batchId = this.$store.getters['auth/getCurrentBatchID'];
      participantAPI().get(`/batch/${batchId}/why-implement-framework`)
        .then(res => {
          this.form_data = res.data.data
          if (!this.form_data.batch_id) {
            this.$f7router.navigate(`/framework/create/`, {
              reloadCurrent: true,
              props: {
                form_data_prop: this.form_data,
              }
            });
          }
          this.pageState = 'loaded'
        })
    },

    onTryAgainClick() {
      this.pageState = 'loading'
      this.loadDataFromAPI()
    }

  },
}
</script>

<style scoped>
  .wrapper {
    margin-top: 20px;
  }

  .block {
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
  }

  .block.ans {
    margin-bottom: 30px;
  }
</style>