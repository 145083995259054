<template>
    <fragment>
        <option v-if="game_creators.length == 0" :value="null">Loading..</option>
        <option 
                v-for="game_creator in game_creators"
                :key="game_creator.key"
                :value="game_creator.key">{{ game_creator.value }}</option>
    </fragment>
</template>

<script>
import { participantAPI, errorHandler } from '@/js/api'

export default {
    name: 'AZGameOptions',
    data() {
        return {
            game_creators: []
        }
    },

    mounted() {
        let batchId = this.$store.getters['auth/getCurrentBatchID'];
        participantAPI().get(`batch/${batchId}/game-creator`)
        .then(res => {
            this.game_creators = res.data.data.map(game => {
                return {
                    key: game.id,
                    value: game.name
                }
            })
        })
    },
}
</script>

<style>

</style>