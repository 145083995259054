<template>
  <f7-page>

    <!-- Navbar -->
    <f7-navbar back-link="Back">
      <!-- Page Title -->
      <f7-nav-title>28 Jan 2020</f7-nav-title>
    </f7-navbar>

    <!-- Summary -->
    <f7-card class="summary-card">
      <h1 class="no-margin">Total: 24.8 Hours</h1>
      <h1 class="no-margin">Saving: 3 Hours</h1>
      <!-- <h class="no-margin">Total Time Saved</p> -->
    </f7-card>

    <!-- List -->
    <f7-list>
      <f7-list-item title="Lorem ipsum dolor sit amet." after="10 MIN"></f7-list-item>
      <f7-list-item title="Consectetur adipisicing elit recusandae " after="20 MIN"></f7-list-item>
      <f7-list-item title="Quam similique necessitatibus debitis sint qui." after="5 MIN"></f7-list-item>
      <f7-list-item title="Lorem ipsum dolor sit amet." after="10 MIN"></f7-list-item>
      <f7-list-item title="Consectetur adipisicing elit recusandae " after="60 MIN"></f7-list-item>
      <f7-list-item title="Quam similique necessitatibus debitis sint qui." after="5 MIN"></f7-list-item>
    </f7-list>


  </f7-page>
</template>

<script>
export default {
  methods: {
    onAddClick() {
      this.$f7router.navigate('/time-savers/create/');
    }
  }
}
</script>

<style scoped>
  .summary-card {
    text-align: center;
    max-width: 300px;
    padding: 10px;
    margin: var(--f7-list-margin-vertical) auto 0 auto;
  }
</style>